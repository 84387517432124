import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  RouterPersistence,
  USERSETTINGS_FEATURE_KEY,
  UserSettingsState,
} from './user-settings.reducer';
import { Units } from '../../models/user-settings.models';

// Lookup the 'UserSettings' feature state managed by NgRx
export const getUserSettingsState = createFeatureSelector<UserSettingsState>(
  USERSETTINGS_FEATURE_KEY
);

export const getUserSettingsLoaded = createSelector(
  getUserSettingsState,
  (state: UserSettingsState) => state.loaded
);

export const getUserSettingsError = createSelector(
  getUserSettingsState,
  (state: UserSettingsState) => state.error
);

export const getAllUnits = createSelector(
  getUserSettingsState,
  (state: UserSettingsState) => state.units
);

export const loaded = createSelector(
  getUserSettingsState,
  (state: UserSettingsState) => state.loaded
);

export const getTempUnit = createSelector(
  getAllUnits,
  (state: Units) => state.tempUnit
);

export const getPrecipUnit = createSelector(
  getAllUnits,
  (state: Units) => state.precipUnit
);

export const getWindUnit = createSelector(
  getAllUnits,
  (state: Units) => state.windUnit
);

export const getRouterPersistance = createSelector(
  getUserSettingsState,
  (state: UserSettingsState) => state.routerPersistance
);

export const getRouterPersistenceData = (path: string[]) =>
  createSelector(getRouterPersistance, (state: RouterPersistence) => {
    const { data } =
      path.reduce<any>((acc, urlSegment) => acc[urlSegment] || {}, state) || {};
    return data;
  });

export const getHomePage = createSelector(
  getUserSettingsState,
  (state: UserSettingsState) => state.homePage
);
