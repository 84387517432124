import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { IsoCountryForecastService } from 'libs/weighted/src/lib/modules/iso-forecast-container/services/iso-country-forecast.service';
import { IWeightedGraphPanelData, WidgetTypes } from '../../constants';
import { filter, first, tap } from 'rxjs';
import { widgetSizes } from '@firebird-web/shared-constants';
import {
  EnsembleSpread,
  IWeightedModelParams,
  WeightedForecastGraph,
} from 'libs/weighted/src/lib/interfaces/weighted-model-forecast.interface';
import * as defaults from '../../constants/widget-configuration';
import { DashboardService } from '../../services/dashboard.service';
import { WeightedLocationSelectionComponent } from 'libs/shared/components/src/lib/weighted-location-selection/weighted-location-selection.component';
import { WidgetService } from '../../services/widget.service';
import { WidgetPanelService } from '../../services';
import { DropdownOption } from '../../../../../shared/interfaces/src/lib';
import { MatSelectChange } from '@angular/material/select';
import { WEIGHTED_WIDGET_PANEL_NO_ENSEMBLE_SPREAD_DROPDOWN_OPTION } from '../../constants/widget-defaults';
import { modelForecastDetailsDropdown } from '@firebird-web/shared-constants';

@Component({
  selector: 'firebird-web-weighted-graph-panel',
  templateUrl: './weighted-graph-panel.component.html',
  styleUrls: ['./weighted-graph-panel.component.scss'],
})
export class WeightedGraphPanelComponent implements OnInit {
  modelWeightedForecastOptions = modelForecastDetailsDropdown;
  selectedContinent: string;
  selectedSiteId: string;
  biasCorrect: boolean;
  weightedRegions = [];
  referenceEnsembleSpread: EnsembleSpread[];
  modelWeightedForecast: string;
  ensembleSpreads: DropdownOption[] = [];
  selectedSize: string;
  chartData: WeightedForecastGraph;
  selectedEnsembleSpread!: DropdownOption | undefined;
  labels = ['Location', 'Parameter'];
  widgetSizes = widgetSizes;
  modelWeightedForecastParams: IWeightedModelParams;
  legendsState: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IWeightedGraphPanelData,
    private matDialog: MatDialog,
    private isoCountryService: IsoCountryForecastService,
    private dialogRef: MatDialogRef<WeightedGraphPanelComponent>,
    private dashboardService: DashboardService,
    private widgetService: WidgetService,
    private widgetPanelService: WidgetPanelService
  ) {}

  get isPrecip() {
    return this.modelWeightedForecast === 'precipitationDetails';
  }

  ngOnInit(): void {
    this.modelWeightedForecast = this.data.widgetConfig?.modelWeightedForecast;
    if (this.data.isNew) {
      this.configureDefault();
      this.updateLocationBasedOnState();
    } else {
      this.handleEditState();
    }
    this.isoCountryService
      .getModelWeightedForecastGraph(this.modelWeightedForecastParams)
      .pipe(
        first(),
        tap((resp: any) => {
          const ensembleSpreads: EnsembleSpread[] = resp?.ensembleSpreads ?? [];

          this.generateEnsembleSpreadsDropdownOptions(ensembleSpreads);
          this.defineSelectedEnsembleSpread(ensembleSpreads);
        })
      )
      .subscribe();
  }

  public ensSpreadChange({ value }: MatSelectChange): void {
    this.selectedEnsembleSpread = { labelKey: value, value };
  }

  detailsChanged(event: any) {
    this.modelWeightedForecast = event;
  }
  toggleBias({ target: { checked } }: any) {
    this.biasCorrect = checked;
  }
  getChartData(params: IWeightedModelParams) {
    return this.isoCountryService.getModelWeightedForecastGraph(params);
  }
  locationChange(event: any) {
    this.selectedSiteId = event.region;
    this.selectedContinent = event.continent;
  }
  sizeChange(event: any) {
    this.selectedSize = event.value;
  }
  configureDefault() {
    this.modelWeightedForecastParams = {
      continent: this.getDefaultContinent,
      siteId:
        this.getDefaultContinent === 'NA'
          ? defaults.WEIGHTED_GRAPH_WIDGET_DEFAULT_NA_REGION
          : defaults.WEIGHTED_GRAPH_WIDGET_DEFAULT_EUR_REGION,
      isBiasCorrected: true,
      secondaryLens: 'AvgYrThirty',
      tempUnit: 'F',
      precipUnit: 'in',
      windUnit: 'mph',
    };
    this.selectedSize = 'full';
    this.biasCorrect = true;
    this.selectedContinent = this.getDefaultContinent;
    this.selectedSiteId =
      this.getDefaultContinent === 'NA' ? 'MISO' : 'AUSTRIA';
    this.modelWeightedForecast = 'temperatureDailySummary';
  }
  handleEditState() {
    this.modelWeightedForecastParams = {
      continent: this.data.widgetConfig.continent,
      siteId: this.data.widgetConfig.siteId,
      isBiasCorrected: this.data.widgetConfig.isBiasCorrected,
      secondaryLens: 'AvgYrThirty',
      tempUnit: 'F',
      precipUnit: 'in',
      windUnit: 'mph',
      isPrecip: this.isPrecip,
    };
    this.legendsState = { ...this.data.widgetConfig?.chartLegends };
    this.isoCountryService
      .getModelWeightedForecastGraph(this.modelWeightedForecastParams)
      .pipe(
        tap((resp: any) => {
          const ensembleSpreads: EnsembleSpread[] = resp?.ensembleSpreads ?? [];

          this.generateEnsembleSpreadsDropdownOptions(ensembleSpreads);
          this.defineSelectedEnsembleSpread(ensembleSpreads);
          this.selectedSiteId = resp.siteId;
          this.selectedContinent = this.data.widgetConfig.continent;
          this.biasCorrect = this.data.widgetConfig.isBiasCorrected;
        })
      )
      .subscribe();
  }
  onSave() {
    const chartDataParams = {
      continent: this.selectedContinent,
      siteId: this.selectedSiteId,
      isBiasCorrected: this.biasCorrect,
      modelWeightedForecast: this.modelWeightedForecast,
      chartSpread: this.selectedEnsembleSpread,
      weightedContinent: this.selectedContinent,
      weightedRegion: this.selectedSiteId,
      size: this.selectedSize === 'full' ? 4 : 2,
      type: WidgetTypes.weightedGraph,
    };

    if (this.data.isNew) {
      this.dashboardService.createWidget(
        chartDataParams,
        WidgetTypes.weightedGraph
      );
      this.widgetService.updateWidgetLocationState({
        type: WidgetTypes.weightedGraph,
        continent: this.selectedContinent,
        region: this.selectedSiteId,
      });
      this.dialogRef.close();
      this.dashboardService.saveDashboard();
    } else {
      this.dialogRef.close({
        ...chartDataParams,
        chartLegends: this.legendsState,
      });
    }
  }
  openWeightedLocationSelector() {
    this.matDialog
      .open(WeightedLocationSelectionComponent, {
        width: '500px',
        minHeight: '300px',
        maxHeight: '90vh',
        data: {
          filter: 'iso',
          continentId:
            this.data.widgetConfig.continent || this.getDefaultContinent,
          continentList: [
            { id: 'NA', text: 'North America' },
            { id: 'EUR', text: 'Europe' },
          ],
        },
      })
      .afterClosed()
      .pipe(
        first(),
        filter((resp: any) => resp),
        tap((resp: any) => {
          this.selectedContinent = resp?.continentId;
          this.selectedSiteId = resp?.regionId;
        })
      )
      .subscribe();
  }
  getContinent(contId: string): string {
    return contId === 'NA' ? 'North America' : 'Europe';
  }
  findEnsembleSpread(spreadValue: string, listToSearch: EnsembleSpread[]) {
    return listToSearch?.filter(
      (item: any) => item.displayName === spreadValue
    )[0];
  }
  onClose() {
    this.dialogRef.close();
  }
  private get getDefaultContinent(): string {
    return this.widgetService.getDefaultContinentId();
  }
  updateLocationBasedOnState() {
    const { continent, region } = this.widgetService.widgetLocationState || {
      continent: '',
      region: '',
    };
    if (this.widgetService.isSameWidgetType(WidgetTypes.weightedGraph)) {
      this.updateLocationForSameWidgetType(continent, region || '');
    } else {
      this.updateLocationForDifferentWidgetType(continent, region || '');
    }
  }

  private updateLocationForSameWidgetType(continent: string, region: string) {
    this.selectedContinent = continent;
    this.selectedSiteId = region;
  }

  private updateLocationForDifferentWidgetType(
    continent: string,
    region: string
  ) {
    if (!['NA', 'EUR'].includes(continent)) return;

    this.selectedContinent = continent;
    this.getWeightedRegionsByContinent(continent)
      .pipe(first())
      .subscribe((regions) => {
        const newRegion = this.getNewRegion(regions, region);
        this.selectedSiteId = newRegion;
      });
  }

  getNewRegion(regions: any, region: string) {
    const foundRegion = regions.find((reg: any) => reg.text === region);
    return foundRegion ? foundRegion.id : regions[0].id;
  }
  getWeightedRegionsByContinent(continent: string) {
    return this.widgetPanelService.getWeightedRegions(
      continent,
      'iso-country-forecast'
    );
  }

  private generateEnsembleSpreadsDropdownOptions(
    ensembleSpreads: EnsembleSpread[]
  ): void {
    this.ensembleSpreads = [
      WEIGHTED_WIDGET_PANEL_NO_ENSEMBLE_SPREAD_DROPDOWN_OPTION,
      ...ensembleSpreads.map(
        ({ displayName }): DropdownOption => ({
          labelKey: displayName,
          value: displayName,
        })
      ),
    ];
  }

  private defineSelectedEnsembleSpread(
    ensembleSpreads: EnsembleSpread[]
  ): void {
    let chartSpread: DropdownOption | undefined =
      this.data?.widgetConfig?.chartSpread;

    if (
      !chartSpread ||
      !ensembleSpreads
        .map(({ displayName }) => displayName)
        .includes(chartSpread.value)
    ) {
      chartSpread = WEIGHTED_WIDGET_PANEL_NO_ENSEMBLE_SPREAD_DROPDOWN_OPTION;
    }

    this.selectedEnsembleSpread = chartSpread;
  }
}
