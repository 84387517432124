import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { RenewablesNetworkingService } from './renewables-networking.service';
import { PermissionsService } from 'libs/acl/src/lib/permissions.service';
import {
  RENEWABLES_MAPS_CONTINENT_PICKER_EUR_PERMISSIONS,
  RENEWABLES_MAPS_CONTINENT_PICKER_MEX_PERMISSIONS,
  RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION_MAP,
} from '../constants';
import { ContinentPickerResult } from '../../../../continent-picker/src/lib/types';
import { LocationData, RenewableRegionData } from '../models';
import { RenewablesProduct } from '../types';
import { IOption } from '../../../../shared/ui/src/lib/components/dropdown/dropdown.component';
import { RenewablesMapsNaRegions } from '../modules/renewables-maps/enums';
import { RenewablesProductsEnum } from '../enums';
import { TitleCasePipe } from '@angular/common';

@Injectable()
export class RenewablesService {
  public locationChangeMessage$ =
    new BehaviorSubject<ContinentPickerResult | null>(null);
  public currentProduct$ = new BehaviorSubject<RenewablesProduct | null>(null);

  constructor(
    private readonly networking: RenewablesNetworkingService,
    private readonly permissionsService: PermissionsService,
    private readonly titleCasePipe: TitleCasePipe
  ) {}

  public prepareGetLocationsData(): Observable<
    LocationData<RenewableRegionData[]>[]
  > {
    return this.networking.getLocationsData().pipe(
      map((resp) =>
        resp.map(({ continent, regions }) => {
          return {
            continent,
            regions: regions
              .filter(({ id }) => id != 'UK')
              .map(
                ({ id, displayName, isSolarAvailable, isWindAvailable }) => ({
                  labelKey: displayName,
                  value: id,
                  isPermitted: this.isNaContinent(continent)
                    ? this.permissionsService.isPermitted([
                        'Renewables',
                        continent,
                        this.titleCasePipe.transform(
                          RenewablesProductsEnum.WIND
                        ),
                        id,
                      ]) ||
                      this.permissionsService.isPermitted([
                        'Renewables',
                        continent,
                        this.titleCasePipe.transform(
                          RenewablesProductsEnum.SOLAR
                        ),
                        id,
                      ])
                    : this.permissionsService.isPermitted([
                        `Renewables_${continent}`,
                      ]),
                  isSolarAvailable,
                  isWindAvailable,
                })
              ),
          };
        })
      )
    );
  }

  public getPreparedMapsLocations(): Observable<LocationData<IOption[]>[]> {
    return this.networking.getMapsLocationsData().pipe(
      map((locations) => {
        return locations.map(({ continent, regions }) => {
          return {
            continent,
            regions: regions.map(({ id, displayName }) => {
              const continentPermission = {
                NA: RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION_MAP[
                  id as RenewablesMapsNaRegions
                ],
                EUR: RENEWABLES_MAPS_CONTINENT_PICKER_EUR_PERMISSIONS,
                MEX: RENEWABLES_MAPS_CONTINENT_PICKER_MEX_PERMISSIONS,
              }[continent];

              return {
                labelKey: displayName,
                value: id,
                isPermitted: continentPermission
                  ? this.permissionsService.isPermitted(continentPermission)
                  : false,
              };
            }),
          };
        });
      })
    );
  }

  private isNaContinent(continent: string): boolean {
    return continent === 'NA';
  }
}
