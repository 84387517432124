import { createRenewablesMapsNaRegionPermissionKey } from '../utils';
import {
  MapsForecastType,
  RenewablesMapsNaRegions,
} from '../modules/renewables-maps/enums';

export const RENEWABLES_PERMISSION = 'Renewables_Combined';

export const RENEWABLES_MAPS_PERMISSION = 'Wind_Graphics_80m_Combined';

export const RENEWABLES_EUR_COMBINED_PERMISSION = 'Renewables_EUR';

export const RENEWABLES_NA_COMBINED_PERMISSION = 'Renewables_NA';

export const RENEWABLES_TAB_COMBINED_PERMISSION = 'Renewables_Tab';

export const RENEWABLES_MAPS_NA_WIND_COMBINED_PERMISSION =
  'Renewables_Maps_NA_Wind';

export const RENEWABLES_MAPS_NA_HUB_HEIGHT_COMBINED_PERMISSION =
  'Renewables_Maps_NA_Hub_Height';

export const RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION =
  'Renewables_Maps_NA_Region';

export const RENEWABLES_EUR_COMBINED_PERMISSIONS: string[] = [
  'View__Renewables__EUR__Global_Models',
  'View__Renewables__EUR__GRAF',
];

export const RENEWABLES_NA_COMBINED_PERMISSIONS: string[] = [
  'View__Renewables__NA__Wind__CAISO',
  'View__Renewables__NA__Wind__ERCOT',
  'View__Renewables__NA__Wind__MISO',
  'View__Renewables__NA__Wind__NEISO',
  'View__Renewables__NA__Wind__NYISO',
  'View__Renewables__NA__Wind__PJM',
  'View__Renewables__NA__Wind__SPP',
  'View__Renewables__NA__Wind__BPA',
  'View__Renewables__NA__Solar__ERCOT',
  'View__Renewables__NA__Solar__CAISO',
];

export const RENEWABLES_COMBINED_PERMISSIONS: string[] = [
  ...RENEWABLES_EUR_COMBINED_PERMISSIONS,
  ...RENEWABLES_NA_COMBINED_PERMISSIONS,
];

export const RENEWABLES_MAPS_COMBINED_PERMISSIONS: string[] = [
  'View__Wind_Graphics_80m__NA_East',
  'View__Wind_Graphics_80m__NA_West',
  'View__Wind_Graphics_80m__EUR',
  'View__Wind_Graphics_80m__MEX',
  ...RENEWABLES_NA_COMBINED_PERMISSIONS,
];

export const RENEWABLES_TAB_COMBINED_PERMISSIONS: string[] = [
  ...RENEWABLES_EUR_COMBINED_PERMISSIONS,
  ...RENEWABLES_NA_COMBINED_PERMISSIONS,
  ...RENEWABLES_MAPS_COMBINED_PERMISSIONS,
];

export const RENEWABLES_MAPS_NA_WIND_COMBINED_PERMISSIONS: string[] = [
  'View__Wind_Graphics_80m__NA_East',
  'View__Wind_Graphics_80m__NA_West',
  'View__Renewables__NA__Wind__NEISO',
  'View__Renewables__NA__Wind__NYISO',
  'View__Renewables__NA__Wind__PJM',
  'View__Renewables__NA__Wind__MISO',
  'View__Renewables__NA__Wind__ERCOT',
  'View__Renewables__NA__Wind__BPA',
  'View__Renewables__NA__Wind__CAISO',
  'View__Renewables__NA__Wind__SPP',
];

export const RENEWABLES_MAPS_NA_FORECAST_DATA_SOURCES_COMBINED_PERMISSION_MAP: Record<
  MapsForecastType,
  string[]
> = {
  [MapsForecastType.Wind]: [RENEWABLES_MAPS_NA_WIND_COMBINED_PERMISSION],
  [MapsForecastType.Solar]: ['Renewables', 'NA', 'Solar'],
};

export const RENEWABLES_MAPS_NA_HUB_HEIGHT_COMBINED_PERMISSIONS =
  RENEWABLES_MAPS_NA_WIND_COMBINED_PERMISSIONS.slice(2);

export const RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION_MAP: Record<
  RenewablesMapsNaRegions,
  string[]
> = {
  [RenewablesMapsNaRegions.CONUS]: [
    createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.CONUS
    ),
  ],
  [RenewablesMapsNaRegions.NORTHEAST]: [
    createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.NORTHEAST
    ),
  ],
  [RenewablesMapsNaRegions.MISO]: [
    createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.MISO
    ),
  ],
  [RenewablesMapsNaRegions.MISO_NIOWA]: [
    createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.MISO_NIOWA
    ),
  ],
  [RenewablesMapsNaRegions.ERCOT]: [
    createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.ERCOT
    ),
  ],
  [RenewablesMapsNaRegions.ERCOT_WEST_TEXAS]: [
    createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.ERCOT_WEST_TEXAS
    ),
  ],
  [RenewablesMapsNaRegions.NORTHWEST]: [
    createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.NORTHWEST
    ),
  ],
  [RenewablesMapsNaRegions.CAISO]: [
    createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.CAISO
    ),
  ],
  [RenewablesMapsNaRegions.SPP]: [
    createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.SPP
    ),
  ],
  [RenewablesMapsNaRegions.SOUTHWEST]: [
    createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.SOUTHWEST
    ),
  ],
};

export const RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSIONS_LIST: Array<{
  name: string;
  permissions: string[];
}> = [
  {
    name: `View__${createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.CONUS
    )}`,
    permissions: [
      ...RENEWABLES_MAPS_NA_WIND_COMBINED_PERMISSIONS,
      'View__Renewables__NA__Solar__ERCOT',
      'View__Renewables__NA__Solar__CAISO',
    ],
  },
  {
    name: `View__${createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.NORTHEAST
    )}`,
    permissions: [
      'View__Wind_Graphics_80m__NA_East',
      'View__Renewables__NA__Wind__NEISO',
      'View__Renewables__NA__Wind__NYISO',
      'View__Renewables__NA__Wind__PJM',
    ],
  },
  {
    name: `View__${createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.MISO
    )}`,
    permissions: [
      'View__Wind_Graphics_80m__NA_East',
      'View__Wind_Graphics_80m__NA_West',
      'View__Renewables__NA__Wind__MISO',
    ],
  },
  {
    name: `View__${createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.MISO_NIOWA
    )}`,
    permissions: [
      'View__Wind_Graphics_80m__NA_East',
      'View__Wind_Graphics_80m__NA_West',
      'View__Renewables__NA__Wind__MISO',
    ],
  },
  {
    name: `View__${createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.ERCOT
    )}`,
    permissions: [
      'View__Wind_Graphics_80m__NA_East',
      'View__Wind_Graphics_80m__NA_West',
      'View__Renewables__NA__Wind__ERCOT',
      'View__Renewables__NA__Solar__ERCOT',
    ],
  },
  {
    name: `View__${createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.ERCOT_WEST_TEXAS
    )}`,
    permissions: [
      'View__Wind_Graphics_80m__NA_East',
      'View__Wind_Graphics_80m__NA_West',
      'View__Renewables__NA__Wind__ERCOT',
    ],
  },
  {
    name: `View__${createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.NORTHWEST
    )}`,
    permissions: [
      'View__Wind_Graphics_80m__NA_West',
      'View__Renewables__NA__Wind__BPA',
    ],
  },
  {
    name: `View__${createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.CAISO
    )}`,
    permissions: [
      'View__Wind_Graphics_80m__NA_West',
      'View__Renewables__NA__Wind__CAISO',
      'View__Renewables__NA__Solar__CAISO',
    ],
  },
  {
    name: `View__${createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.SPP
    )}`,
    permissions: [
      'View__Wind_Graphics_80m__NA_East',
      'View__Wind_Graphics_80m__NA_West',
      'View__Renewables__NA__Wind__SPP',
    ],
  },
  {
    name: `View__${createRenewablesMapsNaRegionPermissionKey(
      RENEWABLES_MAPS_NA_REGIONS_COMBINED_PERMISSION,
      RenewablesMapsNaRegions.SOUTHWEST
    )}`,
    permissions: ['View__Wind_Graphics_80m__NA_West'],
  },
];

export const RENEWABLES_MAPS_CONTINENT_PICKER_EUR_PERMISSIONS = [
  'Wind_Graphics_80m',
  'EUR',
];

export const RENEWABLES_MAPS_CONTINENT_PICKER_MEX_PERMISSIONS = [
  'Wind_Graphics_80m',
  'MEX',
];
