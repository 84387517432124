import { Injectable } from '@angular/core';
import { SettingsComponent } from '@firebird-web/settings';
import { DialogService } from '@firebird-web/shared-ui';

@Injectable()
export class SettingsService {
  constructor(private readonly dialogService: DialogService) {}

  public openManageCustomList(): void {
    this.dialogService.openDialog({
      component: SettingsComponent,
      title: 'settings',
      params: { labelKey: 'favoriteLocations', isActive: true },
      config: {
        width: '1000px',
        height: 'calc(100vh - 100px)',
        maxHeight: '845px',
      },
    });
  }
}
