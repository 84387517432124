import { NaRenewablesHeatMapCellComponent } from '../components/na-renewables-heat-map-cell/na-renewables-heat-map-cell.component';
import { ColDef } from 'ag-grid-community';

export const NA_RENEWABLES_HEAT_MAP_PROPS = [
  'region',
  'poolId',
  'siteId',
  'primaryLens',
  'forecast',
  'comparisonForecast',
  'productType',
] as const;

export const NA_RENEWABLES_HEAT_MAP_TOTAL_ROWS_MAPPING = [
  'max',
  'peak',
  'twentyFourHour',
  'offPeak',
] as const;

export const NA_RENEWABLES_HEAT_MAP_SOLAR_TOTAL_ROWS_MAPPING = [
  'peak',
  'daylightAvg',
] as const;

export const NA_RENEWABLES_HEAT_MAP_SHARED_COL_DEF: ColDef = {
  cellRenderer: NaRenewablesHeatMapCellComponent,
};

export const NA_RENEWABLES_HEAT_MAP_ROW_HEIGHT = 27;

export const NA_RENEWABLES_HEAT_MAP_ROW_WITH_SEPARATOR_HEIGHT = 31;

export const NA_RENEWABLES_HEAT_MAP_ROW_MAX_HEIGHT = 22;

export const NA_RENEWABLES_HEAT_MAP_HEADER_HEIGHT = 32;

export const NA_RENEWABLES_HEAT_MAP_HOUR_COLUMN_WIDTH = 70;

export const NA_RENEWABLES_HEAT_MAP_DAY_COLUMN_WIDTH = 50;

export const NA_RENEWABLES_HEAT_MAP_CELL_NOT_AVAILABLE = 'n/a';

export const NA_RENEWABLES_HEAT_MAP_CELL_NO_COLOR_CODE = 'no-color-code';
