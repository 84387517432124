import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSettingsFacade } from '@firebird-web/user-settings-store';
@Component({
  selector: 'firebird-web-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss'],
})
export class UnitsComponent implements OnInit {
  tempUnitsFormGroup = this.formBuilder.group({
    tempUnit: '',
  });
  precipUnitsFormGroup = this.formBuilder.group({
    precipUnit: '',
  });
  windUnitsFormGroup = this.formBuilder.group({
    windUnit: '',
  });

  public constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly userSettings: UserSettingsFacade
  ) {}

  public ngOnInit(): void {
    this.userSettings.allUserUnits$.subscribe((units) => {
      this.tempUnitsFormGroup.setValue({ tempUnit: units.tempUnit });
      this.precipUnitsFormGroup.setValue({ precipUnit: units.precipUnit });
      this.windUnitsFormGroup.setValue({ windUnit: units.windUnit });
    });
  }

  public handleChange(form: FormGroup, type: string): void {
    const value = form.get(type)?.value;
    if (!value) {
      return;
    }

    if (type === 'tempUnit') {
      this.userSettings.changeTempUnit(value);
      return;
    }

    if (type === 'precipUnit') {
      this.userSettings.changePrecipUnit(value);
      return;
    }

    if (type === 'windUnit') {
      this.userSettings.changeWindUnit(value);
      return;
    }
  }
}
