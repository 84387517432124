export enum SettingsTabKeys {
  Settings = 'settings',
  Units = 'units',
  Favorite = 'favoriteLocations',
  Notifications = 'notifications',
  Mobile = 'mobileApps',
  Notes = 'notes',
}

export enum StoreLinksType {
  Apple = 'apple',
  Google = 'google',
}
