import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromRegions from './+state/regions/regions.reducer';
import { RegionsEffects } from './+state/regions/regions.effects';
import { RegionsService } from './services/regions.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromRegions.REGIONS_FEATURE_KEY,
      fromRegions.regionsReducer
    ),
    EffectsModule.forFeature([RegionsEffects]),
  ],
  providers: [RegionsService],
})
export class RegionsStoreModule {}
