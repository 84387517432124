import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { INotificationEmail } from '../../interfaces/notification-email.interface';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  INotificationGroupInfo,
  INotificationGroups,
} from '../../interfaces/notification-group-info.interface';
import { NotificationsService } from '../../services/notifications.service';
import { NotificationsFacade } from '@firebird-web/notifications-store';
import { INotificationGroupChanges } from '../../interfaces/notification-group-changes.interface';

@Component({
  selector: 'firebird-web-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NotificationsService], // TODO: delete after structure refactoring
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notificationEmails$: Observable<INotificationEmail[]> =
    this.notificationsFacade.notificationEmails$;

  notifications$: Observable<INotificationGroups>;
  alerts: INotificationGroupInfo[];
  notifications: INotificationGroupInfo[];
  blogs: INotificationGroupInfo[];
  startEndTimes: INotificationGroupInfo[];
  isAllEmailAlert: boolean;
  isAllEmailBlog: boolean;
  isAllEmailNotifications: boolean;
  isAllEmailStartEnd: boolean;
  isAllInAppAlert: boolean;
  isAllInAppBlog: boolean;
  isAllInAppNotifications: boolean;
  isAllInAppStartEnd: boolean;

  notificationForm: FormGroup;
  notificationGroupChanges: INotificationGroupChanges[];
  isLoaded = false;

  private isAlive = true;

  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly notificationsFacade: NotificationsFacade,
    private readonly formBuilder: FormBuilder,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.notificationGroupChanges = [];
    this.notifications$ = this.notificationsService.getNotificationGroupInfo();
    this.notifications$
      .pipe(
        tap((data) => {
          this.alerts = data.alerts;
          this.notifications = data.notifications;
          // this.not
          this.notifications.forEach((data) =>
            data.notificationGroup === 'MORNING_FORECAST_SUMMARY_PDF'
              ? (data.isClickable = false)
              : (data.isClickable = true)
          );
          this.blogs = data.blogs;
          this.startEndTimes = data.startEndTime;
          this.isAllEmailAlert = data.alerts.every((item) => item.isEmail);
          this.isAllEmailBlog = data.blogs.every((item) => item.isEmail);
          this.isAllEmailStartEnd = data.startEndTime.every(
            (item) => item.isEmail
          );
          this.isAllEmailNotifications = data.notifications.every(
            (item) => item.isEmail
          );

          this.isAllInAppAlert = data.alerts.every((item) => item.isInApp);
          this.isAllInAppBlog = data.blogs.every((item) => item.isInApp);
          this.isAllInAppStartEnd = data.startEndTime.every(
            (item) => item.isInApp
          );
          this.isAllInAppNotifications = data.notifications.every(
            (item) => item.isInApp
          );
          const controls = this.getControls(
            this.alerts,
            this.blogs,
            this.startEndTimes,
            this.notifications
          );
          this.notificationForm = this.formBuilder.group(controls);
          this.isLoaded = true;
          this.cd.markForCheck();
        }),
        switchMap(() => this.notificationForm.valueChanges),
        distinctUntilChanged(),
        debounceTime(3000),
        switchMap(() => {
          const notificationGroups = this.getDirtyValues(this.notificationForm);
          const changes = [
            ...this.notificationGroupChanges,
            ...notificationGroups,
          ];
          return this.notificationsService.updateNotificationGroupInfo(changes);
        }),
        takeWhile(() => this.isAlive)
      )
      .subscribe(() => {
        this.notificationGroupChanges = [];
      });
  }

  disableCheckbox(event: any, noti: INotificationGroupInfo, index: number) {
    if (noti.isClickable || index === 1) {
      return;
    } else {
      event.preventDefault();
    }
  }

  private getControls(
    alerts: INotificationGroupInfo[],
    blogs: INotificationGroupInfo[],
    startEndTime: INotificationGroupInfo[],
    notification: INotificationGroupInfo[]
  ) {
    return [...alerts, ...notification, ...blogs, ...startEndTime].reduce(
      (acc, item) => {
        return {
          ...acc,
          [item.notificationGroup || '']: this.generateFormArray({
            isEmail: item.isEmail,
            isInApp: item.isInApp,
          }),
        };
      },
      {}
    );
  }

  private getDirtyValues(form: any) {
    const result: any = [];
    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        result.push({
          notificationGroup: key,
          isEmail: currentControl.value[1],
          isInApp: currentControl.value[0],
        });
      }
    });

    return result;
  }

  private generateFormArray({ isEmail, isInApp }: any): FormArray {
    return new FormArray([
      new FormControl(isInApp || false),
      new FormControl(isEmail || false),
    ]);
  }

  selectAll(array: INotificationGroupInfo[], event: any) {
    if (event.currentTarget.value === 'selectAllEmail') {
      array.forEach((data) => {
        (
          this.notificationForm.controls[data.notificationGroup || ''] as any
        ).controls[1].patchValue(event.currentTarget.checked);
      });
    } else {
      array.forEach((data) => {
        (
          this.notificationForm.controls[data.notificationGroup || ''] as any
        ).controls[0].patchValue(event.currentTarget.checked);
      });
    }

    array.forEach((data) =>
      this.notificationGroupChanges.push({
        notificationGroup: data.notificationGroup,
        isEmail: (
          this.notificationForm.controls[data.notificationGroup || ''] as any
        ).controls[1].value,
        isInApp: (
          this.notificationForm.controls[data.notificationGroup || ''] as any
        ).controls[0].value,
      } as INotificationGroupChanges)
    );
  }

  selectCheckboxAlert(index: number, event: any) {
    const changedIdx = this.alerts.findIndex(
      (x) => x.notificationGroup === event.currentTarget.value
    );
    if (index === 1) {
      this.alerts[changedIdx].isEmail = event.currentTarget.checked;
      this.isAllEmailAlert = this.alerts.every((item) => item.isEmail);
    } else {
      this.alerts[changedIdx].isInApp = event.currentTarget.checked;
      this.isAllInAppAlert = this.alerts.every((item) => item.isInApp);
    }
  }

  selectCheckboxBlog(index: number, event: any) {
    const changedIdx = this.blogs.findIndex(
      (x) => x.notificationGroup === event.currentTarget.value
    );
    if (index === 1) {
      this.blogs[changedIdx].isEmail = event.currentTarget.checked;
      this.isAllEmailBlog = this.blogs.every((item) => item.isEmail);
    } else {
      this.alerts[changedIdx].isInApp = event.currentTarget.checked;
      this.isAllInAppBlog = this.blogs.every((item) => item.isInApp);
    }
  }

  selectCheckboxStartEnd(index: number, event: any) {
    const changedIdx = this.startEndTimes.findIndex(
      (x) => x.notificationGroup === event.currentTarget.value
    );
    if (index === 1) {
      this.startEndTimes[changedIdx].isEmail = event.currentTarget.checked;
      this.isAllEmailStartEnd = this.startEndTimes.every(
        (item) => item.isEmail
      );
    } else {
      this.alerts[changedIdx].isInApp = event.currentTarget.checked;
      this.isAllInAppStartEnd = this.startEndTimes.every(
        (item) => item.isInApp
      );
    }
  }

  selectCheckboxNotification(index: number, event: any) {
    const changedIdx = this.notifications.findIndex(
      (x) => x.notificationGroup === event.currentTarget.value
    );
    if (index === 1) {
      this.notifications[changedIdx].isEmail = event.currentTarget.checked;
      this.isAllEmailNotifications = this.notifications.every(
        (item) => item.isEmail
      );
    } else {
      this.notifications[changedIdx].isInApp = event.currentTarget.checked;
      this.isAllInAppNotifications = this.notifications.every(
        (item) => item.isInApp
      );
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
