import { createAction, props } from '@ngrx/store';
import {
  ModelFilterConfig,
  ModelRun,
  PanelData,
  SelectedConfigs,
} from '../../models/model-page.types';

export const loadModelComparisonModels = createAction(
  '[ModelStore Page] Load Model Comparison',
  props<{ silentLoading: boolean }>()
);
export const loadModelComparisonModelsSuccess = createAction(
  'ModelStore/API] Load Model Comparison Success',
  props<{ data: PanelData[] }>()
);
export const loadModelComparisonModelsFailure = createAction(
  '[ModelStore/API] Load Model Comparison Failure',
  props<{ error: any }>()
);
// configs
export const loadModelComparisonConfigs = createAction(
  '[ModelStore Page] Load Model Comparison Configs'
);
export const loadModelComparisonConfigsSuccess = createAction(
  'ModelStore/API] Load Model Comparison Success Configs',
  props<{ configs: ModelFilterConfig[] }>()
);
export const loadModelComparisonConfigsFailure = createAction(
  '[ModelStore/API] Load Model Comparison Failure Configs',
  props<{ error: any }>()
);
// runs
export const loadModelComparisonRuns = createAction(
  '[ModelStore Page] Load Model Comparison Runs'
);
export const loadModelComparisonRunsSuccess = createAction(
  'ModelStore/API] Load Model Comparison Success Runs',
  props<{ runs: ModelRun[] }>()
);
export const loadModelComparisonRunsFailure = createAction(
  '[ModelStore/API] Load Model Comparison Failure Runs',
  props<{ error: any }>()
);

export const changeModelComparisonModelsConfigs = createAction(
  '[ModelStore Page] Change Model Comparison Selected Configs',
  props<{ selectedConfigs: SelectedConfigs }>()
);
export const setModelComparisonInUse = createAction(
  '[ModelStore Page] Set Multi Panel In Use',
  props<{ inUse: boolean }>()
);
export const updateModelComparisonAlwaysShowLatest = createAction(
  '[ModelStore Page] Update Model Comparison Always Show Latest Parameter',
  props<{ alwaysShowLatest: boolean }>()
);
