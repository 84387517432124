import { Injectable } from '@angular/core';
import {
  CategoryDataSourceList,
  ForecastDataSourceList,
  secondaryLensOptions,
} from '../constants/widget-configuration';
import { InitDataSources } from '../interfaces';
import {
  ISummaryWidgetConfig,
  ISumTableConfigOptions,
  ListOfContinents,
} from '../constants';
import { first } from 'lodash';
import { IContinent } from '../../../../shared/components/src/lib/location-selection/location.interface';
import {
  continentList,
  secondaryLensOptions as secondaryLensList,
} from '../containers/summary-table-widget/WidgetFilterOptions';
import {
  WeightedRegionsService,
  WeightedRundateService,
  WeightedSinglerunDropdownService,
} from '@firebird-web/shared-services';
import { Observable } from 'rxjs';
import { CategoryValues, RunTimeModelValues } from '../enums';
import {
  DataSource,
  DropdownOption,
} from '../../../../shared/interfaces/src/lib';

@Injectable()
export class WidgetPanelService {
  constructor(
    private weightedSinglerunDropdownService: WeightedSinglerunDropdownService,
    private weightedRunDateService: WeightedRundateService,
    private readonly weightedRegionsServices: WeightedRegionsService
  ) {}

  public adaptSecondaryLensDataSource(): DataSource[] {
    return secondaryLensOptions.map((secondaryLens: DropdownOption) => ({
      ...secondaryLens,
      id: secondaryLens.value,
      label: secondaryLens.labelKey,
    }));
  }

  public getLabel(
    parameter: string,
    listToSearch: ISumTableConfigOptions[]
  ): string {
    const selectedList = listToSearch.filter((item) => item.id === parameter);
    return first(selectedList)?.text || '';
  }

  public getContinentName(continentAbbreviation: string): string {
    const selectedContinent = continentList.find(
      (cont) => cont.id === continentAbbreviation
    );
    return selectedContinent?.text || '';
  }

  public getContinentByName(name: string): IContinent | undefined {
    return ListOfContinents.find((continent: IContinent) => {
      return continent.name?.toLowerCase() === name?.toLowerCase();
    });
  }

  public prepareWidgetConfig(
    details: ISummaryWidgetConfig,
    currentDate?: string
  ): ISummaryWidgetConfig {
    return {
      ...details,
      secondaryLens: this.getLabel(
        details.secondaryLens as string,
        secondaryLensList
      ),
      continent: this.getContinentName(details.continent),
      currentDate: currentDate ?? details.currentDate,
    };
  }

  public prepareWeightWidgetConfig(
    details: ISummaryWidgetConfig
  ): ISummaryWidgetConfig {
    return {
      ...details,
      filter: CategoryDataSourceList.find(
        (item: DropdownOption) => item.value === details.filter
      )?.labelKey as string,
      listName: details.filter,
      continent: this.getContinentName(details.continent),
      continentId: details.continent,
      region: details.region,
      primaryLens: this.getForecastName(details.primaryLens),
      primaryLensId: details.primaryLens,
    };
  }

  public getRunTimeDataSource(
    runTimeModel: string,
    category: string,
    continet: string
  ): Observable<InitDataSources[]> {
    if (runTimeModel === RunTimeModelValues.single) {
      return this.weightedSinglerunDropdownService.geWeightedModelsRun();
    }

    return category === CategoryValues.iso
      ? this.weightedRunDateService.getWeightedIsoRunDate(continet)
      : this.weightedRunDateService.getWeightedDegreeRunDate(continet);
  }

  private getForecastName(forecastId: string): string {
    return ForecastDataSourceList.find((item: DropdownOption) => {
      return item.value === forecastId;
    })?.labelKey as string;
  }

  public getWeightedRegions(continentId: string, filter: string) {
    switch (filter) {
      case 'iso-country-forecast': {
        return this.weightedRegionsServices.getWeightedIsoRegions(continentId);
      }
      case 'degree-day-forecast': {
        return this.weightedRegionsServices.getWeightedDegreeDaysRegions(
          continentId
        );
      }
      default: {
        return this.weightedRegionsServices.getWeightedUsGasBcfRegions(
          continentId
        );
      }
    }
  }
}
