import { createAction, props } from '@ngrx/store';
import { LocationPickerActionTypes } from '../enums/location-picker.enum';
import {
  InitDataActionPayload,
  SaveRegionDataActionPayload,
  SaveCityDataActionPayload,
  InitDataSuccessPayload,
  SaveCustomListDataActionPayload,
} from '../models/location-picker.model';

export const initLocationPicker = createAction(LocationPickerActionTypes.INIT);

export const reinitLocationPicker = createAction(
  LocationPickerActionTypes.REINIT,
  props<{ continent: string }>()
);

export const initLocationPickerSuccess = createAction(
  LocationPickerActionTypes.INIT_SUCCESS,
  props<InitDataSuccessPayload>()
);

export const initLocationPickerFailure = createAction(
  LocationPickerActionTypes.INIT_FAILURE,
  props<any>()
);

export const initDataForCurrentPage = createAction(
  LocationPickerActionTypes.INIT_DATA_FOR_CURRENT_PAGE,
  props<InitDataActionPayload>()
);

export const saveDataForRegion = createAction(
  LocationPickerActionTypes.SAVE_DATA_FOR_REGION,
  props<SaveRegionDataActionPayload>()
);

export const saveDataForCustomList = createAction(
  LocationPickerActionTypes.SAVE_DATA_FOR_CUSTOM_LIST,
  props<SaveCustomListDataActionPayload>()
);

export const saveDataForCity = createAction(
  LocationPickerActionTypes.SAVE_DATA_FOR_CITY,
  props<SaveCityDataActionPayload>()
);

export const initCityData = createAction(
  LocationPickerActionTypes.INIT_CITY_DATA
);
