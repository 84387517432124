import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { environment } from '@firebird-web/shared-config';
import { map, Observable } from 'rxjs';
import { ServerResponse } from '@firebird-web/shared-interfaces';
import { INotificationEmail } from '../+state/notifications-store.models';
import { INotification } from 'libs/alerts-notifications/src/lib/interfaces/notification.interface';
import { IAlert } from 'libs/alerts-notifications/src/lib/interfaces/alert.interface';
import { CommonUtils } from '@firebird-web/shared-utils';

@Injectable({
  providedIn: 'root',
})
export class NotificationsStoreService {
  constructor(private http: HttpClient) {}

  getNotificationEmails(): Observable<INotificationEmail[]> {
    const endpointUrl = `${environment.apiDomain}/${apiEndpoints.emailNotifications}`;
    return this.http
      .get<ServerResponse<INotificationEmail[]>>(endpointUrl)
      .pipe(
        map(({ data }: ServerResponse<INotificationEmail[]>) =>
          data?.length ? data : []
        )
      );
  }

  putNotificationEmail(oldEmail: string, email: string): Observable<void> {
    const endpointUrl = `${environment.apiDomain}/${apiEndpoints.emailNotifications}`;
    return this.http.put<void>(endpointUrl, {
      oldEmail: oldEmail,
      newEmail: email,
    });
  }

  postNotificationEmail(email: string): Observable<void> {
    const endpointUrl = `${environment.apiDomain}/${apiEndpoints.emailNotifications}`;
    return this.http.post<void>(endpointUrl, { email });
  }

  deleteNotificationEmail(email: string): Observable<void> {
    email = email.replace('.', '%2E');
    const encodedEmail = <string>encodeURIComponent(email);
    const endpointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.emailNotificationsWithEmail}`,
      { email: encodedEmail }
    );
    return this.http.delete<void>(endpointUrl);
  }

  getNotificationList(): Observable<INotification[]> {
    const endpointUrl = `${environment.apiDomain}/${apiEndpoints.getNotificationList}`;
    return this.http
      .get<ServerResponse<INotification[]>>(endpointUrl)
      .pipe(map(({ data }: ServerResponse<INotification[]>) => data || []));
  }

  getAlertList(): Observable<IAlert[]> {
    const endpointUrl = `${environment.apiDomain}/${apiEndpoints.getAlertList}`;
    return this.http
      .get<ServerResponse<IAlert[]>>(endpointUrl)
      .pipe(map(({ data }: ServerResponse<IAlert[]>) => data || []));
  }

  updateLastSeen(): Observable<void> {
    const endpointUrl = `${environment.apiDomain}/${apiEndpoints.updateLastSeenNotification}`;
    return this.http.post<void>(endpointUrl, {});
  }
}
