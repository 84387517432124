<div class="widget-config-panel">
  <div class="header">
    <span class="universal"
      >Widget Settings<mat-icon (click)="onClose()" class="close"
        >close</mat-icon
      ></span
    >

    <span class="subtitle">{{ getWidgetLabel(data.widgetType) }}</span>
  </div>
  <ng-container [ngSwitch]="data.widgetType">
    <ng-template [ngSwitchCase]="widgetTypes.heatMap">
      <firebird-web-heat-map-panel></firebird-web-heat-map-panel>
    </ng-template>

    <ng-template [ngSwitchCase]="widgetTypes.renewables">
      <firebird-web-renewables-panel></firebird-web-renewables-panel>
    </ng-template>

    <ng-template [ngSwitchCase]="widgetTypes.analysis">
      <firebird-web-analisys-panel></firebird-web-analisys-panel>
    </ng-template>
    <ng-template [ngSwitchCase]="widgetTypes.weightedGraph">
      <firebird-web-weighted-graph-panel></firebird-web-weighted-graph-panel>
    </ng-template>
    <ng-template [ngSwitchCase]="widgetTypes.model">
      <firebird-web-model-panel> </firebird-web-model-panel>
    </ng-template>
    <ng-template [ngSwitchCase]="widgetTypes.weighted">
      <firebird-web-weighted-panel></firebird-web-weighted-panel>
    </ng-template>
    <ng-template [ngSwitchCase]="widgetTypes.demand">
      <firebird-web-demand-panel></firebird-web-demand-panel>
    </ng-template>
  </ng-container>
</div>
