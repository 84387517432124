import { Component, HostListener, OnInit } from '@angular/core';
import { RefreshService } from '@firebird-web/shared-services';
import { UserSettingsFacade } from '@firebird-web/user-settings-store';
import { newDataAvailable } from '@firebird-web/ws';
import { Store } from '@ngrx/store';
import { AuthService } from 'libs/acl/src/lib/auth.service';
@Component({
  selector: 'firebird-web-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'app';
  constructor(
    private readonly authService: AuthService,
    private readonly store: Store,
    private readonly userService: UserSettingsFacade,
    private readonly refreshService: RefreshService
  ) {}
  @HostListener('document:resume', ['$event'])
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (!document.hidden) {
      this.store.dispatch(newDataAvailable({ data: null }));
      this.refreshService.tabUnfreezeUpdateTrigger$.next();
    }
  }
  ngOnInit(): void {
    if (!this.authService.isAuthenticated()) {
      this.authService.login();
    }
    this.userService.init();
  }
}
