import { Injectable } from '@angular/core';
import { ForecastTypeEnum } from '../../../../../../shared/enums/forecast-type.enum';
import { Observable } from 'rxjs';
import {
  IRenewablesSubRegionDTO,
  RenewablesGraphData,
  RenewablesResponse,
} from '../../../models';
import { CommonUtils } from '@firebird-web/shared-utils';
import { environment } from '@firebird-web/shared-config';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '@firebird-web/shared-interfaces';
import { TableProps, RenewablesRunDates } from '../types';

@Injectable()
export class EurRenewablesNetworkingService {
  constructor(private readonly http: HttpClient) {}

  public getRpmGraphicsSubRegions(
    continent: string,
    forecastType: ForecastTypeEnum
  ): Observable<IRenewablesSubRegionDTO> {
    const url = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getStaticGraphicsSubregions}`,
      { forecastType, continent }
    );
    return this.http.get<IRenewablesSubRegionDTO>(url);
  }

  public getGraphRenewables(
    subLink: string,
    version?: string
  ): Observable<ServerResponse<RenewablesGraphData>> {
    const link = version
      ? `${environment.apiDomain}/${subLink}/${version}`
      : `${environment.apiDomain}/${subLink}`;
    return this.http.get<ServerResponse<RenewablesGraphData>>(link);
  }

  public getTableRenewables(
    subLink: string,
    params?: TableProps
  ): Observable<ServerResponse<RenewablesResponse>> {
    const baseUrl = `${environment.apiDomain}/${subLink}`;

    return this.http.post<ServerResponse<RenewablesResponse>>(baseUrl, params);
  }

  public getTableRunDatesRenewables(): Observable<
    ServerResponse<RenewablesRunDates[]>
  > {
    const url = `${environment.apiDomain}/${apiEndpoints.getRenewablesTableRunDates}`;
    return this.http.get<ServerResponse<RenewablesRunDates[]>>(url);
  }
}
