<div class="units component">
  <div class="radio-options">
    <form
      [formGroup]="tempUnitsFormGroup"
      (change)="handleChange(tempUnitsFormGroup, 'tempUnit')"
    >
      <div class="units-group">
        <div class="unit-head" translate="temperature"></div>
        <label>
          <input
            type="radio"
            name="tempUnit"
            value="F"
            formControlName="tempUnit"
          />
          <span class="img"></span>
          <span class="text" translate="fahrenheit"></span>
        </label>
        <label>
          <input
            type="radio"
            name="tempUnit"
            value="C"
            formControlName="tempUnit"
          />
          <span class="img"></span>
          <span class="text" translate="celsius"></span>
        </label>
      </div>
    </form>

    <form
      [formGroup]="precipUnitsFormGroup"
      (change)="handleChange(precipUnitsFormGroup, 'precipUnit')"
    >
      <div class="units-group">
        <div class="unit-head" translate="precipitation"></div>
        <label>
          <input
            type="radio"
            name="precipUnit"
            formControlName="precipUnit"
            value="in"
          />
          <span class="img"></span>
          <span class="text" translate="inches"></span>
        </label>
        <label>
          <input
            type="radio"
            name="unit"
            name="precipUnit"
            formControlName="precipUnit"
            value="mm"
          />
          <span class="img"></span>
          <span class="text" translate="millimeters"></span>
        </label>
      </div>
    </form>

    <form
      [formGroup]="windUnitsFormGroup"
      (change)="handleChange(windUnitsFormGroup, 'windUnit')"
    >
      <div class="units-group">
        <div class="unit-head" translate="windSpeed"></div>
        <label>
          <input
            type="radio"
            name="windUnit"
            formControlName="windUnit"
            value="mph"
          />
          <span class="img"></span>
          <span class="text" translate="milesPerHour"></span>
        </label>
        <label>
          <input
            type="radio"
            name="windUnit"
            formControlName="windUnit"
            value="mps"
          />
          <span class="img"></span>
          <span class="text" translate="metersPerSecond"></span>
        </label>
        <label>
          <input
            type="radio"
            name="windUnit"
            formControlName="windUnit"
            value="kph"
          />
          <span class="img"></span>
          <span class="text" translate="kilometersPerHour"></span>
        </label>
      </div>
    </form>
  </div>
</div>
