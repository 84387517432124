import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '../../services/dashboard.service';
import { ImapService } from '../../services/imap.service';
@Component({
  selector: 'firebird-web-imap-panel',
  templateUrl: './imap-panel.component.html',
  styleUrls: ['./imap-panel.component.scss'],
})
export class ImapPanelComponent {
  constructor(
    private dialogRef: MatDialogRef<ImapPanelComponent>,
    private dashboard: DashboardService,
    private imapService: ImapService
  ) {}
  selectedSize: any = 'full';
  // don't thing we need this entire component will investigate more tomorrow
}
