import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IWidgetSummaryPanelData,
  WidgetTypes,
} from '../../constants/widget-types';
import { WidgetLabelConfig } from '../../constants';

@Component({
  selector: 'firebird-web-widget-config-panel',
  templateUrl: './widget-config-panel.component.html',
  styleUrls: ['./widget-config-panel.component.scss'],
})
export class WidgetConfigPanelComponent {
  public readonly widgetTypes = WidgetTypes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IWidgetSummaryPanelData,
    private dialogRef: MatDialogRef<WidgetConfigPanelComponent>
  ) {}

  public onClose(): void {
    this.dialogRef.close();
  }

  public getWidgetLabel(widgetType: string): string {
    return widgetType ? WidgetLabelConfig[widgetType] : '';
  }
}
