import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '@firebird-web/shared-config';
import { NA_RENEWABLES_HEAT_MAP_ENDPOINTS } from '../constants';
import {
  NaChartResponse,
  NaRenewablesCSVRequestBody,
  NaRenewablesForecastDTO,
  NaRenewablesHeatMap,
  NaRenewablesHeatMapRequestBody,
  WindfarmPools,
} from '../types';
import { RenewablesProducts } from '../../../models/renewables.models';

@Injectable()
export class NaRenewablesNetworkingService {
  private readonly baseUrl = `${environment.apiDomain}/api/v1/windcast`;

  constructor(private readonly http: HttpClient) {}

  public getWindFarmPools(
    region: string,
    product: RenewablesProducts
  ): Observable<WindfarmPools> {
    return this.http.get<WindfarmPools>(
      `${this.baseUrl}/pools/${region}/${product}`
    );
  }

  public getNaGraph(
    regionId: string,
    poolId: string,
    product: RenewablesProducts
  ): Observable<NaChartResponse> {
    return this.http.get<NaChartResponse>(
      `${environment.apiDomain}/api/v1/Windcast/graph/${regionId}/${poolId}/${product}`
    );
  }

  public fetchHeatMap(
    body: NaRenewablesHeatMapRequestBody
  ): Observable<NaRenewablesHeatMap> {
    return this.http
      .post<NaRenewablesHeatMap>(NA_RENEWABLES_HEAT_MAP_ENDPOINTS.heatMap, body)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public fetchForecast(
    siteId: string,
    product: RenewablesProducts
  ): Observable<NaRenewablesForecastDTO[]> {
    return this.http
      .get<NaRenewablesForecastDTO[]>(
        `${NA_RENEWABLES_HEAT_MAP_ENDPOINTS.forecast}/${siteId}/${product}`
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  public fetchCSV(
    body: NaRenewablesCSVRequestBody
  ): Observable<HttpResponse<Blob>> {
    return this.http
      .post(NA_RENEWABLES_HEAT_MAP_ENDPOINTS.csv, body, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<never> {
    return throwError(() => errorResponse);
  }
}
