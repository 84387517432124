<div class="container">
  <div class="header">
    <span class="title">{{ title }}</span>
    <div class="cancel" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="content">
    <span class="input">
      <div
        class="empty-title-error-message"
        *ngIf="
          submitButtonClicked &&
          (isTitleValid || newMonitorForm.get('title')?.hasError('emptyTitle'))
        "
      >
        <firebird-web-icon
          class="icon-duplicate"
          name="information-circle-red"
          height="20px"
          width="20px"
        ></firebird-web-icon>
        Please enter a unique name.
      </div>

      <form [formGroup]="newMonitorForm">
        <span class="name-field">
          <div class="dash-name">Name</div>
          <input
            class="title-input"
            type="text"
            placeholder="Enter a name for your dashboard"
            matInput
            formControlName="title"
            (keyup.enter)="submitClicked()"
          />
        </span>
        <span class="description-field">
          <div class="dash-description">Description</div>
          <textarea
            class="description-input"
            type="text"
            placeholder="Enter a description"
            matInput
            formControlName="description"
          ></textarea>
        </span>
      </form>
    </span>
  </div>
  <div class="cancel-submit-buttons">
    <span (click)="closeDialog()" class="cancel">X-Cancel</span>
    <span (click)="submitClicked()" class="submit">Create Dashboard</span>
  </div>
</div>
