import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { IOption } from '../../../../../shared/ui/src/lib/components/dropdown/dropdown.component';
import { SettingsApiService } from '../../services/settings-api.service';
import { map, Subject, takeUntil, tap } from 'rxjs';
import { first } from 'lodash';
import { switchMap } from 'rxjs/operators';
import { Notes } from '../../interfaces';
import { DomSanitizer } from '@angular/platform-browser';

//todo: rewrite Module structure, to avoid component providers
@Component({
  selector: 'firebird-web-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SettingsApiService],
})
export class NotesComponent implements OnInit, OnDestroy {
  public yearsDataSource: IOption[] | null;
  public selectedYear$: Subject<string> = new Subject();
  public notes: Notes[];

  private readonly destroyed$ = new Subject();

  constructor(
    private readonly settingsApiService: SettingsApiService,
    private readonly sanitizer: DomSanitizer,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getNotesYearsDataSource();
    this.watchForYearChanges();
  }

  public trackById(_: number, note: Notes): number {
    return note.id;
  }

  public trackByLabel(_: number, label: string): string {
    return label;
  }

  private watchForYearChanges(): void {
    this.selectedYear$
      .pipe(
        switchMap((year: string) => this.settingsApiService.getNotes(year)),
        map((notesList: Notes[]) => {
          return notesList.map((note: Notes) => {
            return {
              ...note,
              note: this.sanitizer.bypassSecurityTrustHtml(note.note as string),
            };
          });
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((notesList: Notes[]) => {
        this.notes = notesList;
        this.cdr.markForCheck();
      });
  }

  private getNotesYearsDataSource(): void {
    this.settingsApiService
      .getNotesYears()
      .pipe(
        map(({ years }) => {
          return years.map((year: string) => ({ labelKey: year, value: year }));
        }),
        tap((years: IOption[]) => {
          return this.selectedYear$.next(first(years)?.value || '');
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((years: IOption[]) => {
        this.yearsDataSource = years;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
