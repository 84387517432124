import moment from 'moment';

export const isSameDates = (
  firstDate: string,
  secondDate: string,
  format = 'YYYY-MM-DD'
): boolean => {
  const formattedFirstDate = moment(firstDate).format(format);
  const formattedSecondDate = moment(secondDate).format(format);

  return moment(formattedFirstDate).isSame(formattedSecondDate);
};
