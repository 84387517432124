import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as NotificationsStoreActions from './+state/notifications-store.actions';
import * as NotificationsStoreSelectors from './+state/notifications-store.selectors';

import { NotificationsStoreService } from './services/notifications-store.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsFacade {
  alerts$ = this.store.pipe(select(NotificationsStoreSelectors.getAlerts));
  notifications$ = this.store.pipe(
    select(NotificationsStoreSelectors.getNotifications)
  );
  alertState$ = this.store.pipe(
    select(NotificationsStoreSelectors.getAlertState)
  );
  notificationEmails$ = this.store.pipe(
    select(NotificationsStoreSelectors.getNotificationEmails)
  );

  constructor(
    private readonly store: Store,
    private readonly notificationsStoreService: NotificationsStoreService
  ) {}

  isAlertWindowOpen() {
    return this.store.pipe(
      select(NotificationsStoreSelectors.getAlertWindowOpen)
    );
  }

  changeAlertWindowOpen(isOpen: boolean) {
    this.store.dispatch(
      NotificationsStoreActions.changeAlertWindowOpen({ isOpen })
    );
  }

  getNewData() {
    this.store.dispatch(NotificationsStoreActions.loadAlerts());
    this.store.dispatch(NotificationsStoreActions.loadNotifications());
    this.store.dispatch(NotificationsStoreActions.loadEmailNotifications());
  }

  updateLastSeen() {
    return this.notificationsStoreService.updateLastSeen();
  }

  addNotificationEmail(email: string) {
    this.notificationsStoreService
      .postNotificationEmail(email)
      .subscribe(() =>
        this.store.dispatch(NotificationsStoreActions.loadEmailNotifications())
      );
  }

  updateNotificationEmail(oldEmail: string, newEmail: string) {
    this.notificationsStoreService
      .putNotificationEmail(oldEmail, newEmail)
      .subscribe(() =>
        this.store.dispatch(NotificationsStoreActions.loadEmailNotifications())
      );
  }

  deleteNotificationEmail(email: string) {
    this.notificationsStoreService
      .deleteNotificationEmail(email)
      .subscribe(() =>
        this.store.dispatch(NotificationsStoreActions.loadEmailNotifications())
      );
  }
}
