import { createAction, props } from '@ngrx/store';
import {
  ModelConfig,
  ModelFilterConfig,
  ModelMultiConfig,
  PanelData,
} from '../../models/model-page.types';

export const loadMultiPanelModels = createAction(
  '[ModelStore Page] Load Multi Panel',
  props<{ silentLoading: boolean }>()
);
export const loadMultiPanelModelsSuccess = createAction(
  '[ModelStore/API] Load Multi Panel Success',
  props<{ data: PanelData[] }>()
);
export const loadUpdatesForMultiPanelModelsSuccess = createAction(
  '[ModelStore/API] Load Updates For Multi Panel Success',
  props<{ data: PanelData[] }>()
);
export const loadMultiPanelModelsFailure = createAction(
  '[ModelStore/API] Load Multi Panel Failure',
  props<{ error: any }>()
);
// configs
export const loadMultiPanelConfigs = createAction(
  '[ModelStore Page] Load Multi Panel Configs'
);
export const loadMultiPanelConfigsSuccess = createAction(
  '[ModelStore/API] Load Multi Panel Success Configs',
  props<{ configs: ModelFilterConfig[] }>()
);
export const loadMultiPanelConfigsFailure = createAction(
  '[ModelStore/API] Load Multi Panel Failure Configs',
  props<{ error: any }>()
);
export const addConfigWithRunsLoading = createAction(
  '[ModelStore/API] Add Configs With Run Loading',
  props<{ config: ModelConfig }>()
);
export const addConfig = createAction(
  'ModelStore/API] Add Configs',
  props<{ config: ModelConfig }>()
);
export const editConfigWithRunsLoading = createAction(
  '[ModelStore/API] Edit Configs With Run Loading',
  props<{ config: ModelConfig; panelIndex: number }>()
);
export const editMultiConfigWithRunsLoading = createAction(
  '[ModelStore/API] Edit Multi Configs With Run Loading',
  props<{ config: ModelMultiConfig[] }>()
);
export const editConfig = createAction(
  'ModelStore/API] Edit Configs',
  props<{ config: ModelConfig; panelIndex: number }>()
);
export const editMultiConfig = createAction(
  'ModelStore/API] Edit Multi Configs',
  props<{ config: ModelConfig[] }>()
);
export const editMultiConfigSuccess = createAction(
  '[ModelStore/API] Load Multi Panel After Edit Success'
);
export const deleteConfig = createAction(
  '[ModelStore/API] Delete Configs',
  props<{ id: string }>()
);
export const deleteConfigs = createAction(
  '[ModelStore/API] Delete All Configs'
);
export const setMultiPanelInUse = createAction(
  '[ModelStore Page] Set Multi Panel In Use',
  props<{ inUse: boolean }>()
);
export const initAddingPersistedMultiConfigs = createAction(
  '[ModelStore/API] Init Adding Persisted Multi Configs',
  props<{ configs: ModelConfig[] }>()
);
export const addPersistedMultiConfigs = createAction(
  '[ModelStore/API] Add Persisted Multi Configs',
  props<{ configs: ModelConfig[] }>()
);
export const updateMultiPanelsPositions = createAction(
  '[ModelStore/API] Update Multi Panels Positions',
  props<{ oldIndex: number; newIndex: number }>()
);
