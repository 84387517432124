import { createAction, props } from '@ngrx/store';
import { UserSettingsState } from './user-settings.reducer';
import { ShareRouterPersistencePayload } from '../../models/user-settings.models';

export const initUserSettings = createAction('[UserSettings Page] Init');

export const loadUserSettingsSuccess = createAction(
  '[UserSettings/API] Load UserSettings Success',
  props<{ userSettings: Partial<UserSettingsState> }>()
);

export const loadUserSettingsFailure = createAction(
  '[UserSettings/API] Load UserSettings Failure',
  props<{ error: any }>()
);

export const changeTempUnit = createAction(
  '[UserSettings/SaveToServer] Change Temp Unit',
  props<{ tempUnit: string }>()
);

export const changePrecipUnit = createAction(
  '[UserSettings/SaveToServer] Change Precip Unit',
  props<{ precipUnit: string }>()
);

export const changeWindUnit = createAction(
  '[UserSettings/SaveToServer] Change Wind Unit',
  props<{ windUnit: string }>()
);

export const changeRouterPersistence = createAction(
  '[UserSettings/SaveToStore] Save User Routing Data',
  props<{ path: string; value: any; clearPrevState?: boolean }>()
);

export const shareRouterPersistence = createAction(
  '[UserSettings/SaveToStore] Share User Routing Data',
  props<ShareRouterPersistencePayload>()
);

export const changeQueryParamsPersistence = createAction(
  '[UserSettings/SaveToStore] Save User Query ParamsRouting Data',
  props<{ path: string; value: any }>()
);

export const changeActiveRoute = createAction(
  '[UserSettings/SaveToStore] Save User Routing State',
  props<{ path: string }>()
);

export const setHomepage = createAction(
  '[UserSettings/SaveToStore] Set User Homepage',
  props<{ path: string[] }>()
);

export const setHomepageNthLevel = createAction(
  '[UserSettings/SaveToStore] Sat User Homepage Nth Level',
  props<{ dedicatedRoutes: string[]; path: string; level: number }>()
);
