import { initial } from 'lodash';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ParamMap,
  Params,
  QueryParamsHandling,
  Router,
  UrlSerializer,
} from '@angular/router';
import { Location } from '@angular/common';
import { map, Observable } from 'rxjs';
import { CommonUtils } from '@firebird-web/shared-utils';
import { tabConfig } from '@firebird-web/shared-constants';
import { PermissionsService } from 'libs/acl/src/lib/permissions.service';
import { TableMapsToggle } from '../../../../../renewable/src/lib/enums';

@Injectable({
  providedIn: 'root',
})
export class RouteHelperService {
  public constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    private readonly urlSerializer: UrlSerializer,
    private readonly permissionService: PermissionsService
  ) {}

  /**
   * isAllRegionSelected
   * @returns
   */
  public isAllRegionSelected(): Observable<boolean> {
    return this.activatedRoute.queryParamMap?.pipe(
      map((params: ParamMap) => {
        return (
          params.get('region') === 'All Regions' && !params.get('customListId')
        );
      })
    );
  }
  public isWidgetView() {
    return this.location.path().includes('live-monitor');
  }
  /**
   * getSelectedRegion
   * @returns
   */
  public getSelectedRegion(): Observable<string> {
    return this.getValueFromURL('region');
  }
  public isWindowView() {
    return this.location.path().includes('window');
  }
  /**
   * getSelectedContinentId
   * @returns
   */
  public getSelectedContinentId(): Observable<string> {
    return this.getValueFromURL('continent');
  }

  public getSelectedWeightedContinentId(): Observable<string> {
    return this.getValueFromURL('weightedContinent');
  }
  /**
   * getForecastType
   * @returns
   */
  public getForecastType(): Observable<string> {
    return this.getValueFromURL('forecastType');
  }

  /**
   * getFilterType
   * @returns
   */
  public getFilterType(): Observable<string> {
    return this.getValueFromURL('filter');
  }

  /**
   * getPrimaryLens
   * @returns
   */
  public getPrimaryLens(): Observable<string> {
    return this.getValueFromURL('primaryLens');
  }

  /**
   * getTempUnit
   * @returns
   */
  public getTempUnit(): Observable<string> {
    return this.getValueFromURL('tempUnit');
  }

  /**
   * getPrecipUnit
   * @returns
   */
  public getPrecipUnit(): Observable<string> {
    return this.getValueFromURL('precipUnit');
  }

  /**
   * getWindUnit
   * @returns
   */
  public getWindUnit(): Observable<string> {
    return this.getValueFromURL('windUnit');
  }

  /**
   * getSelectedPrimaryLens
   * @returns
   */
  public getSelectedPrimaryLens(): Observable<string> {
    return this.getValueFromURL('primaryLens');
  }

  /**
   * getSelectedPrimaryLens
   * @returns
   */
  public getSelectedRunTime(): Observable<string> {
    return this.getValueFromURL('runTime');
  }

  /**
   * getSelectedSecondaryLens
   * @returns
   */
  public getSelectedSecondaryLens(): Observable<string> {
    return this.getValueFromURL('secondaryLens');
  }

  /**
   * getShowForecastDifference
   * @returns
   */
  public getShowForecastDifference(): Observable<boolean> {
    return this.getValueFromURL('showForecastDifference', 'false').pipe(
      map((isShowDifference: string) => {
        return (
          CommonUtils.pathContain('/1-15days/city-view/forecast-analysis') &&
          isShowDifference === 'true'
        );
      })
    );
  }

  /**
   * updateQueryParams
   * @param queryParams
   * @param queryParamsHandling
   */
  public updateQueryParams(
    params: any,
    queryParamsHandling: QueryParamsHandling = 'merge'
  ) {
    this.router.navigate([], {
      queryParams: { ...params },
      queryParamsHandling,
    });
  }

  /**
   * isOverviewPage
   * @returns true if url contains /overview
   */
  public isOverviewPage(): boolean {
    return this.location.path().includes('/overview');
  }

  /**
   * isLocationPage
   * @returns true if url contains /city-view
   */
  public isLocationPage(): boolean {
    return this.location.path().includes('/city-view');
  }

  /**
   * isForecastDifferencePage
   * @returns true if url contains /city-view/forecast-difference
   */
  public isForecastDifferencePage(): boolean {
    return this.location.path().includes('/city-view/forecast-difference');
  }

  public isForecastValuesPage(): boolean {
    return this.location.path().includes('/city-view/forecast-values');
  }

  public isProbalisticFriskPage(): boolean {
    return this.location.path().includes('/city-view/probalistic-frisk');
  }

  public isForecastAnalysisPage(): boolean {
    return this.location.path().includes('/city-view/forecast-analysis');
  }

  public isForecasstVerificationPage(): boolean {
    return this.location.path().includes('/city-view/forecast-verification');
  }

  /**
   * getValueFromURL
   * @param urlKey
   * @param defaultValue
   * @returns string
   */
  public getValueFromURL(
    urlKey: string,
    defaultValue = ''
  ): Observable<string> {
    return this.activatedRoute.queryParamMap?.pipe(
      map((params: ParamMap) => {
        return params.get(urlKey) || defaultValue;
      })
    );
  }

  /**
   * goToLoginPage
   */
  public goToLoginPage() {
    this.router.navigate(['login']);
  }

  /**
   * goToOverviewPage
   * @param queryParams
   */
  public goToOverviewPage(): void {
    const firstPermittedTab = tabConfig.find((tab) => {
      return this.permissionService.isPermitted(tab.permission);
    });
    this.router.navigate([`/1-15days/region-view/${firstPermittedTab?.value}`]);
  }

  public goHome(): void {
    this.router.navigate(['/']);
  }

  /**
   * goToForecastValuesPage
   * @param queryParams
   */
  public goToForecastValuesPage(queryParams: Params): void {
    this.router.navigate(['1-15days/city-view/forecast-values'], {
      queryParams: {
        ...queryParams,
        dateIndex: null,
        compareRunDate: null,
        showForecastDifference: null,
        forecastType: null,
        primaryLens: null,
        secondaryLens: null,
        filter: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * goToForecastDifferencesPage
   * @param queryParams
   */
  public goToForecastDifferencesPage(queryParams: Params): void {
    this.router.navigate(['1-15days/city-view/forecast-difference'], {
      queryParams: {
        ...queryParams,
        dateIndex: null,
        compareRunDate: null,
        showForecastDifference: null,
        forecastType: null,
        primaryLens: null,
        secondaryLens: null,
        filter: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * goToProbabilisticFriskPage
   * @param queryParams
   */
  public goToProbabilisticFriskPage(queryParams: Params): void {
    this.router.navigate(['1-15days/city-view/probabilistic-frisk'], {
      queryParams: {
        ...queryParams,
        freq: null,
        viewType: null,
        dateIndex: null,
        compareRunDate: null,
        showForecastDifference: null,
        forecastType: null,
        primaryLens: null,
        secondaryLens: null,
        filter: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * goToForecastAnalysisPage
   * @param queryParams
   */
  public goToForecastAnalysisPage(queryParams: Params): void {
    this.router.navigate(['1-15days/city-view/forecast-analysis'], {
      queryParams: {
        ...queryParams,
        freq: null,
        viewType: null,
        dateIndex: null,
        compareRunDate: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * goToForecastVerificationPage
   * @param queryParams
   */
  public goToForecastVerificationPage(queryParams: Params): void {
    this.router.navigate(['1-15days/city-view/forecast-verification'], {
      queryParams: {
        ...queryParams,
        freq: null,
        viewType: null,
        dateIndex: null,
        compareRunDate: null,
        showForecastDifference: null,
        forecastType: null,
        primaryLens: null,
        secondaryLens: null,
        filter: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  public goToOverviewMapsPage(): void {
    const url = this.getCurrentUrlSegments();
    this.router.navigate([url, 'maps'], {
      queryParamsHandling: 'merge',
    });
  }

  public goToSiblingPage(subRoute: string): void {
    const url = this.getCurrentUrlSegments();
    this.router.navigate([...initial(url), subRoute], {
      queryParamsHandling: 'merge',
    });
  }

  public redirectRenewablesPage(type: string, continent: string): void {
    const location =
      type === TableMapsToggle.Maps
        ? '1-15days/region-view/renewables/maps'
        : `1-15days/region-view/renewables/${continent}`;

    this.router.navigate([location]);
  }

  public returnFromOverviewMapsPage(): void {
    const returnTo = this.activatedRoute.snapshot.queryParams['returnTo'];
    this.router.navigate([`1-15days/region-view/${returnTo}`], {
      queryParamsHandling: 'merge',
    });
  }

  public goToLinkUrl(url: string): void {
    const parsedUrl = this.urlSerializer.parse(url);
    const queryParams = parsedUrl.queryParams;
    const path = parsedUrl.root.children['primary'].segments
      .map((it) => it.path)
      .join('/');

    this.router.navigate([path], {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * isEligibleForForeAnalysis
   * @param continentId
   * @returns true if continentId is not AUS or ASIA
   */
  public isEligibleForForeAnalysis(): Observable<boolean> {
    return this.getValueFromURL('continent').pipe(
      map((continentId: string) => {
        return continentId !== 'AUS' && continentId !== 'ASIA';
      })
    );
  }

  public getCurrentUrlSegments(): string[] {
    return this.router.url
      .split('?')[0]
      .split('/')
      .filter((path) => !!path);
  }
}
