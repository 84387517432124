<div class="weighted-widget-panel-container">
  <div class="labels-column">
    <div *ngFor="let label of labels" class="label">{{ label }}</div>
    <div class="label ens-spread-label">Ensemble Spread</div>
  </div>
  <div class="parameter-column">
    <div (click)="openWeightedLocationSelector()" class="location">
      {{ getContinent(selectedContinent) }} > {{ selectedSiteId }}
    </div>
    <div class="parameter">
      <firebird-web-model-weighted-forecast-details-dropdown
        [options]="modelWeightedForecastOptions"
        [modelWeightedForecast]="modelWeightedForecast"
        [isWidget]="true"
        (modelWeightedForecastChanged)="detailsChanged($event)"
      ></firebird-web-model-weighted-forecast-details-dropdown>
    </div>
    <div class="ensemble-spread">
      <firebird-web-dropdown
        class="multi-dd-start widget-panel"
        [value]="selectedEnsembleSpread?.value || ''"
        [options]="ensembleSpreads"
        (changeEvent)="ensSpreadChange($event)"
        type="variation-five"
      >
      </firebird-web-dropdown>
      <div class="show-bias">
        <firebird-web-checkbox
          class="bias-checkbox"
          [checked]="biasCorrect"
          labelKey="Bias Corrected"
          (changeEvent)="toggleBias($event)"
        ></firebird-web-checkbox>
      </div>
    </div>
    <!-- <div *ngIf="data.isNew" class="widget-size">
      <span>Widget Size</span>
      <firebird-web-dropdown
        labelKey="label"
        class="multi-dd-start widget-panel"
        [options]="widgetSizes"
        [value]="selectedSize"
        (changeEvent)="sizeChange($event)"
        type="variation-five"
      ></firebird-web-dropdown>
    </div> -->
  </div>
</div>
<div class="close-save">
  <span (click)="onSave()" class="save">Save</span>
  <span (click)="onClose()" class="cancel">X-Cancel</span>
</div>
