import { EventEmitter, Injectable } from '@angular/core';
import { Units } from '@firebird-web/user-settings-store';

@Injectable({
  providedIn: 'root',
})
export class WeightedModelForecastChartService {
  public emitToggleResp = new EventEmitter();

  /**
   * getModelsWithOutWsiAndNormals
   * @param legends
   * @returns
   */
  public getModelsWithOutWsiAndNormals(legends: any) {
    return Object.keys(legends).filter(
      (resp: string) => resp !== 'WSI' && resp !== 'normals'
    );
  }

  /**
   * getColor
   * @param modelType
   * @returns
   */
  public getColor(modelType: string) {
    switch (modelType) {
      case 'ECMWF_OP':
        return '#1063ED';
      case 'ECMWF_ENS':
        return '#F70F1B';
      case 'GFS_OP':
        return '#53B9EC';
      case 'GFS_ENS':
        return '#EE8031';
      case 'GEM_OP':
        return '#316E2F';
      case 'GEM_ENS':
        return '#704014';
      case 'MF_ECMWF_OP':
        return '#EED3ED';
      case 'MF_GFS_OP':
        return '#CDB882';
      case 'Normals':
        return '#CFD5D8';
      case 'observations':
        return '#157378';
      default:
        return '#000000';
    }
  }

  /**
   * setVisibilityAndSelection
   * @param obj
   * @param isShow
   */
  public setVisibilityAndSelection(obj: any, isShow: boolean) {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        this.setVisibilityAndSelection(obj[key], isShow);
        obj[key].isVisible = isShow;
        obj[key].isSelected = isShow;
      }
    }
  }

  /**
   * getHourlyKeys
   * @returns string
   */
  public getHourlyKeys(tempDetails: string) {
    // TODO: Add the rest of the cases
    switch (tempDetails) {
      case 'cloudCoverDetails': {
        return 'cloudCover';
      }
      case 'windSpeedDetails': {
        return 'windSpeed';
      }
      case 'precipitationDetails': {
        return 'precip';
      }
      default: {
        return 'temp';
      }
    }
  }

  /**
   * getAreaRangekeys
   * @param tempDetails
   * @returns
   */
  public getAreaRangekeys25_75(tempDetails: string) {
    switch (tempDetails) {
      case 'cloudCoverDetails': {
        return ['cloudCover25th', 'cloudCover75th', 'cloudCover'];
      }
      case 'windSpeedDetails': {
        return ['windSpeed25th', 'windSpeed75th', 'windSpeed'];
      }
      case 'precipitationDetails': {
        return ['precip25th', 'precip75th', 'precip'];
      }
      default: {
        return ['temp25th', 'temp75th', 'temp'];
      }
    }
  }
  /**
   *
   * @param tempDetails
   */
  public getUnit(tempDetails: string, tempUnits: Units) {
    switch (tempDetails) {
      case 'cloudCoverDetails': {
        return '%';
      }
      case 'windSpeedDetails': {
        return tempUnits.windUnit;
      }
      case 'precipitationDetails': {
        return tempUnits.precipUnit;
      }
      default: {
        return `°${tempUnits.tempUnit}`;
      }
    }
  }

  /**
   * getAreaRangekeys
   * @param tempDetails
   * @returns
   */
  public getAreaRangekeysmin_max(tempDetails: string) {
    switch (tempDetails) {
      case 'cloudCoverDetails': {
        return ['cloudCoverMax', 'cloudCoverMin', 'cloudCover'];
      }
      case 'windSpeedDetails': {
        return ['windSpeedMax', 'windSpeedMin', 'windSpeed'];
      }
      case 'precipitationDetails': {
        return ['precipMax', 'precipMin', 'precip'];
      }
      case 'temperatureDetails': {
        return ['tempMax', 'tempMin', 'temp'];
      }
      default: {
        return ['tempAvgMax', 'tempAvgMin', 'temp'];
      }
    }
  }
}
