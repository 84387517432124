import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromContinents from './+state/continents/continents.reducer';
import { ContinentsEffects } from './+state/continents/continents.effects';
import { ContinentsService } from './services';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromContinents.CONTINENTS_FEATURE_KEY,
      fromContinents.continentsReducer
    ),
    EffectsModule.forFeature([ContinentsEffects]),
  ],
  providers: [ContinentsService],
})
export class ContinentsStoreModule {}
