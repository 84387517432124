import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SETTINGS_FEATURE_KEY, SettingsState } from './settings.reducer';
import { FavoriteList } from '../../models/favorite-locations.types';
import { orderBy } from 'lodash';

type FavoriteListsData = SettingsState['favoriteLists'];

// Lookup the 'Settings' feature state managed by NgRx
export const getSettingsState =
  createFeatureSelector<SettingsState>(SETTINGS_FEATURE_KEY);

export const getFavoriteListsData = createSelector(
  getSettingsState,
  (state: SettingsState) => state.favoriteLists
);

export const getSettingsLoaded = createSelector(
  getFavoriteListsData,
  (state: FavoriteListsData) => state.loaded
);

export const getFavoriteLists = createSelector(
  getFavoriteListsData,
  getSettingsState,
  (favoriteDataState: FavoriteListsData, state: any) => {
    const cities = state.regions.flatMap(({ cities }: any) => cities);
    return favoriteDataState.data
      .map((list) => ({
        ...list,
        sites: list.sites.map((site) =>
          cities.find(({ siteId }: any) => site.siteId === siteId)
        ),
      }))
      .sort((siteA: FavoriteList, siteB: FavoriteList) =>
        siteA.listName.localeCompare(siteB.listName)
      );
  }
);

export const getLocationDetailsFavoriteLists = createSelector(
  getFavoriteListsData,
  ({ data }: FavoriteListsData): FavoriteList[] =>
    orderBy(data, 'listName').map((list) => ({
      ...list,
      sites: orderBy(list.sites, 'siteId'),
    }))
);

export const getContinentsRegions = createSelector(
  getSettingsState,
  (state: SettingsState) => {
    if (
      !state.selectedContinent ||
      state.selectedContinent.continent === 'All'
    ) {
      return state.regions;
    }
    return state.regions.filter(
      ({ continent }) => continent === state.selectedContinent.continent
    );
  }
);

export const getContinents = createSelector(
  getSettingsState,
  (state: SettingsState) => {
    return state.continents;
  }
);
