import { createReducer, on, Action } from '@ngrx/store';

import * as ModelStoreActions from '../model-store.actions';

import { MultiPanelData } from '../model-store.models';
import {
  modelConfigsAdapter,
  modelRunsAdapter,
  multiModelDataAdapter,
} from '../model-store.adapters';
import { ModelConfig } from '@firebird-web/model-store';
import { concat } from 'lodash';

export const initialModelStoreState: MultiPanelData = {
  selectedConfigs: [],
  runs: modelRunsAdapter.getInitialState({ loaded: false }),
  filterConfigs: modelConfigsAdapter.getInitialState({ loaded: false }),
  data: multiModelDataAdapter.getInitialState(),
  loaded: false,
  inUse: false,
};

const reducer = createReducer(
  initialModelStoreState,
  on(ModelStoreActions.loadMultiPanelConfigs, (state) => ({
    ...state,
    filterConfigs: { ...state.filterConfigs, loaded: false },
  })),
  on(ModelStoreActions.loadMultiPanelConfigsSuccess, (state, { configs }) => {
    return {
      ...state,
      filterConfigs: modelConfigsAdapter.setAll(configs, {
        ...state.filterConfigs,
        loaded: true,
        error: null,
      }),
    };
  }),
  on(ModelStoreActions.addConfig, (state, { config }) => {
    const configIndex = state.selectedConfigs.length;
    return {
      ...state,
      loaded: false,
      selectedConfigs: [
        ...state.selectedConfigs,
        { ...config, index: configIndex },
      ],
    };
  }),
  on(ModelStoreActions.addPersistedMultiConfigs, (state, { configs }) => {
    return {
      ...state,
      selectedConfigs: [
        ...configs.map((config, index) => ({ ...config, index })),
      ],
    };
  }),
  on(
    ModelStoreActions.updateMultiPanelsPositions,
    (state, { oldIndex, newIndex }) => {
      const selectedConfigs = concat(state.selectedConfigs);
      const selectors = multiModelDataAdapter.getSelectors();
      const allEntities = selectors.selectAll(state.data);
      const selectedConfigsLength = selectedConfigs.length;

      if (
        oldIndex < 0 ||
        oldIndex >= selectedConfigsLength ||
        newIndex < 0 ||
        newIndex >= selectedConfigsLength
      ) {
        return {
          ...state,
        };
      }

      const modelIds = allEntities.map((item) => item?.trackId);
      const selectedId = modelIds[oldIndex];
      modelIds.splice(oldIndex, 1);
      modelIds.splice(newIndex, 0, selectedId);

      const sortedEntities = allEntities.sort((a, b) => {
        return modelIds.indexOf(a.trackId) - modelIds.indexOf(b.trackId);
      });
      const mappedEntriesByIndex = sortedEntities.map((entity, index) => {
        return {
          ...entity,
          index,
        };
      });
      const models = multiModelDataAdapter.setMany(
        mappedEntriesByIndex,
        multiModelDataAdapter.getInitialState()
      );

      const sortedConfigs = selectedConfigs.sort((a, b) => {
        return modelIds.indexOf(a.trackId) - modelIds.indexOf(b.trackId);
      });
      const mappedConfigsByIndex = sortedConfigs.map((config, index) => {
        return {
          ...config,
          index,
        };
      });

      return {
        ...state,
        data: models,
        selectedConfigs: concat(mappedConfigsByIndex),
      };
    }
  ),
  on(ModelStoreActions.editMultiConfig, (state, { config }) => {
    return {
      ...state,
      loaded: false,
      selectedConfigs: state.selectedConfigs.reduce<ModelConfig[]>(
        (acc, item, index) => {
          if (item.index !== config[index].index) {
            return [...acc, item];
          }
          return [...acc, config[index]];
        },
        []
      ),
    };
  }),
  on(ModelStoreActions.editConfig, (state, { config, panelIndex }) => {
    return {
      ...state,
      loaded: false,
      selectedConfigs: state.selectedConfigs.reduce<ModelConfig[]>(
        (acc, item, index) => {
          if (panelIndex !== index) {
            return [...acc, item];
          }
          return [...acc, { ...config, index: panelIndex }];
        },
        []
      ),
    };
  }),
  on(ModelStoreActions.deleteConfig, (state, { id }) => {
    return {
      ...state,
      loaded: false,
      data: multiModelDataAdapter.removeOne(id, state.data),
      selectedConfigs: state.selectedConfigs.filter(
        (config) => config.trackId !== id
      ),
    };
  }),
  on(ModelStoreActions.deleteConfigs, (state) => {
    return {
      ...state,
      data: multiModelDataAdapter.removeAll(state.data),
      loaded: false,
      selectedConfigs: [],
    };
  }),
  on(ModelStoreActions.loadMultiPanelModels, (state, { silentLoading }) => ({
    ...state,
    loaded: silentLoading ? state.loaded : false,
  })),
  on(ModelStoreActions.loadMultiPanelModelsSuccess, (state, { data }) => {
    const models = multiModelDataAdapter.setMany(data, state.data);
    return {
      ...state,
      data: models,
      loaded: true,
    };
  }),
  on(
    ModelStoreActions.loadUpdatesForMultiPanelModelsSuccess,
    (state, { data }) => {
      const models = multiModelDataAdapter.setMany(data, state.data);
      return {
        ...state,
        data: models,
        loaded: true,
      };
    }
  ),
  on(ModelStoreActions.setMultiPanelInUse, (state, { inUse }) => ({
    ...state,
    inUse,
  }))
);

export function multiPanelReducer(
  state: MultiPanelData | undefined,
  action: Action
) {
  return reducer(state, action);
}
