import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { environment } from '@firebird-web/shared-config';
import { map, Observable } from 'rxjs';
import { RegionsEntity } from '../+state/regions/regions.models';
import { CommonUtils } from '@firebird-web/shared-utils';
@Injectable({
  providedIn: 'root',
})
export class RegionsService {
  constructor(private http: HttpClient) {}

  getRegionsList(continentId: string): Observable<RegionsEntity[]> {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getRegionsList}`,
      { continentId }
    );
    return this.http.get(endPointUrl).pipe(map((data: any) => data.data));
  }
}
