<!-- VARIATION ONE -->
<mat-form-field
  [ngStyle]="{ width: width }"
  *ngIf="type === 'variation-one'"
  class="variation-one"
  [ngClass]="{ 'mat-focused': isDropdownOpen }"
>
  <mat-select
    disableOptionCentering
    [value]="value"
    (selectionChange)="onChangeEvent($event)"
    (opened)="isDropdownOpen = true"
    (closed)="isDropdownOpen = false"
  >
    <mat-option
      *ngFor="let item of options; trackBy: trackByFnGroup"
      [value]="item.value"
    >
      <ng-container *ngIf="item.labelKey">{{
        item.labelKey | translate
      }}</ng-container>
    </mat-option>
  </mat-select>
</mat-form-field>

<!-- VARIATION TWO -->
<mat-form-field
  [ngStyle]="{ width: width }"
  *ngIf="type === 'variation-two'"
  class="variation-two"
  [ngClass]="{ 'mat-focused': isDropdownOpen }"
>
  <span class="icon" *ngIf="icon">
    <firebird-web-icon [width]="'32px'" [name]="icon"> </firebird-web-icon>
  </span>

  <mat-select
    #selector
    [(value)]="value"
    disableOptionCentering
    panelClass="variation-two-panelClass"
    (selectionChange)="onChangeEvent($event)"
    (opened)="isDropdownOpen = true"
    (closed)="isDropdownOpen = false"
  >
    <mat-option
      *ngFor="let item of options; trackBy: trackByFnGroup"
      [value]="item.value"
    >
      <ng-container *ngIf="item.labelKey">{{
        item.labelKey | translate
      }}</ng-container>
    </mat-option>
  </mat-select>
</mat-form-field>

<!-- VARIATION THREE -->
<mat-form-field
  [ngStyle]="{ width: width }"
  *ngIf="type === 'variation-three'"
  class="variation-three"
  [ngClass]="{ 'mat-focused': isDropdownOpen }"
>
  <span class="icon">
    <firebird-web-icon [width]="'32px'" [height]="'32px'" [name]="icon">
    </firebird-web-icon>
  </span>
  <div class="cities-dropdown" *ngIf="value && value !== null">
    <mat-select
      disableOptionCentering
      [value]="value"
      panelClass="variation-two-panelClass"
      (opened)="isDropdownOpen = true"
      (closed)="isDropdownOpen = false"
    >
      <mat-option
        *ngFor="let item of options; trackBy: trackByFnGroup"
        [value]="item.value"
      >
        <ng-container *ngIf="item.labelKey">{{
          item.labelKey | translate
        }}</ng-container>
      </mat-option>
    </mat-select>
    <div class="region-cities" *ngIf="cityName">{{ cityName | translate }}</div>
  </div>
</mat-form-field>

<mat-form-field
  class="variation-four"
  *ngIf="type === 'variation-four' && options?.length > 0"
  [ngClass]="{ 'mat-focused': isDropdownOpen }"
  [ngStyle]="{ width: width }"
>
  <mat-select
    [panelClass]="panelClass || 'variation-four-panelClass'"
    [value]="value"
    disableOptionCentering
    #selector
    (selectionChange)="onChangeEvent($event)"
    (opened)="isDropdownOpen = true"
    (closed)="isDropdownOpen = false"
  >
    <mat-select-trigger>
      <ng-container
        *ngIf="
          !isMultiContinentInCustomList && !customListRunDateConfig;
          else customListSelectTriggerContent
        "
      >
        {{ getRunDateLabel(value) | dateWithoutTimezone: dateFormat }}
        <span class="run-date-type">
          {{ getRunDateType(value) | translate }}
        </span>
      </ng-container>
      <ng-template #customListSelectTriggerContent>
        {{ customListRunDateConfig?.label }}
        <span
          class="run-date-type"
          *ngIf="!customListRunDateConfig?.isLatestForecast"
        >
          {{ customListRunDateConfig?.type }}
        </span>
      </ng-template>
    </mat-select-trigger>
    <div class="variation-four">
      <ng-container *ngFor="let runDate of options; let idx = index">
        <mat-option
          [value]="runDate.id"
          [ngStyle]="{ height: '52px', padding: '0' }"
          *ngIf="idx === 0 && !isMultiContinentInCustomList"
        >
          <div class="radio-options">
            <label class="latest-label">
              <span class="text">
                <span class="latest" translate="latestForecast"></span>
                <span>
                  {{
                    runDate.label
                      ? (runDate.label | dateWithoutTimezone: dateFormat)
                      : ''
                  }}
                  <span class="run-date-type">
                    {{
                      (runDate.type ? runDate.type.toLowerCase() : '')
                        | translate
                    }}
                  </span>
                </span>
              </span>
            </label>
          </div>
        </mat-option>
        <mat-option
          class="custom-list-latest-mat-option"
          [value]="runDate.id"
          *ngIf="idx === 0 && isMultiContinentInCustomList"
        >
          <div class="radio-options">
            <label class="latest-label">
              <span class="text">
                <span class="latest" translate="latestForecast"></span>
              </span>
            </label>
          </div>
        </mat-option>
        <mat-option
          [value]="runDate.id"
          [ngStyle]="{ padding: '0' }"
          *ngIf="idx !== 0"
        >
          <div class="radio-options">
            <label>
              <span class="text">
                {{ runDate.label | dateWithoutTimezone: dateFormat }}
                <span class="run-date-type">{{
                  (runDate.type ? runDate.type.toLowerCase() : '') | translate
                }}</span>
              </span>
            </label>
          </div>
        </mat-option>
      </ng-container>
    </div>
  </mat-select>
</mat-form-field>

<!-- VARIATION FIVE -->
<mat-form-field
  [ngStyle]="{ width: width }"
  *ngIf="type === 'variation-five'"
  class="variation-five"
  floatLabel="never"
  [ngClass]="{
    'mat-focused': isDropdownOpen,
    'short-dropdown': isShort
  }"
>
  <mat-label *ngIf="triggerLabel" class="trigger-label">
    <span [translate]="triggerLabel"></span>
  </mat-label>

  <mat-select
    [panelClass]="panelClass || 'variation-five-panelClass'"
    #variationFiveSelector
    disableOptionCentering
    [(ngModel)]="value"
    (selectionChange)="onChangeEvent($event)"
    (opened)="isDropdownOpen = true"
    (closed)="isDropdownOpen = false"
    [disabled]="disabled"
  >
    <mat-option
      *ngFor="let item of options; trackBy: trackByFnGroup"
      [value]="item.value"
      (click)="onOptionClick($event, item.isPermitted)"
      class="variation-five__option"
      [disabled]="!item.isPermitted && item.isPermitted !== undefined"
    >
      <span *ngIf="item.labelKey; else label">{{
        item.labelKey | translate
      }}</span>
      <ng-template #label>
        <span>{{ item.label }}</span>
      </ng-template>
      <firebird-web-icon
        class="icons"
        *ngIf="!item.isPermitted && item.isPermitted !== undefined"
        name="lock"
        width="16px"
        height="14px"
      >
      </firebird-web-icon>
    </mat-option>
  </mat-select>

  <mat-select *ngIf="group_option" [value]="value" disableOptionCentering>
    <mat-optgroup
      *ngFor="let group of group_option; trackBy: trackByFnGroup"
      [label]="group.name | translate"
    >
      <mat-option
        *ngFor="let item of group.option; trackBy: trackByFnGroup"
        [value]="item.value"
      >
        <ng-container *ngIf="item.labelKey">{{
          item.labelKey | translate
        }}</ng-container>
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>

<!-- TODO: All previous variations are incorrect and should be rewritten. -->
<!-- TODO: That is temporary workaround to make firebird-web-dropdown as a shared component-->
<mat-form-field
  class="variation-four"
  *ngIf="type === 'variation-four-shared'"
  [ngClass]="{ 'mat-focused': isDropdownOpen }"
  [ngStyle]="{ width: width }"
>
  <mat-select
    panelClass="variation-four-panelClass"
    [value]="value"
    disableOptionCentering
    #selector
    (selectionChange)="onChangeEvent($event)"
    (opened)="isDropdownOpen = true"
    (closed)="isDropdownOpen = false"
  >
    <mat-select-trigger>
      {{ getLabel(value) }}
    </mat-select-trigger>
    <mat-radio-group>
      <ng-container *ngFor="let option of options">
        <mat-option [value]="option.value" [disabled]="!option.isPermitted">
          <div class="radio-options">
            <label>
              <input
                type="radio"
                [checked]="value === option.value"
                name="dropdown"
                [value]="option.value"
                [disabled]="!option.isPermitted"
              />
              <span class="img"></span>
              <span
                [ngClass]="{
                  text: option.isPermitted,
                  'disabled-text': !option.isPermitted
                }"
              >
                {{ option.labelKey }}
              </span>
              <firebird-web-icon
                class="icons"
                *ngIf="!option.isPermitted"
                name="lock"
                width="16px"
                height="14px"
              >
              </firebird-web-icon>
            </label>
          </div>
        </mat-option>
      </ng-container>
    </mat-radio-group>
  </mat-select>
</mat-form-field>
