import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
export function dashTitleValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const title = control.value as string;
    if (!title.trim()) {
      return { emptyTitle: true };
    }
    return null;
  };
}
