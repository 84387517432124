import { ForecastDataSet } from '../models';

export const footerDataLabelMapping: {
  key: keyof ForecastDataSet;
  label: string;
}[] = [
  { key: 'max_Forecast', label: 'Peak' },
  { key: 'avg_Forecast', label: 'Avg' },
  { key: 'offPeak_Forecast', label: 'Off-Peak Avg' },
  { key: 'peak_Forecast', label: 'On-Peak Avg' },
];
