import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocationService } from '../../../../../shared/services/src/lib/location.service';
import { filter, first, map, switchMap, tap } from 'rxjs';
import { WidgetPanelService } from '../../services';
import { DataSource } from '../../../../../shared/interfaces/src/lib';
import { DashboardService } from '../../services/dashboard.service';
import { WidgetPanelState } from '../../interfaces';
import { CalendarService } from '@firebird-web/shared-services';
import { buildWidgetDetails, buildWidgetPanelState } from '../../helpers';
import { MatSelectChange } from '@angular/material/select';
import { UnitType } from '../../enums';
import {
  DeFaultWidgetPanelState,
  IWidgetLocationData,
  IWidgetSummaryPanelData,
  WidgetTypes,
  sumTableSizes,
} from '../../constants';
import { Store } from '@ngrx/store';
import { UserSettingsFacade } from '@firebird-web/user-settings-store';
import { WidgetService } from '../../services/widget.service';

@Component({
  selector: 'firebird-web-analisys-panel',
  templateUrl: './analisys-panel.component.html',
  styleUrls: ['./analisys-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalisysPanelComponent implements OnInit {
  public readonly cUnit: string = UnitType.C;
  public secondaryLensDataSource: DataSource[];
  public sumTableSizes = sumTableSizes;
  public panelState: WidgetPanelState = DeFaultWidgetPanelState;
  public tempUnit: string;

  private currentDate: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IWidgetSummaryPanelData,
    private locationService: LocationService,
    private widgetPanelService: WidgetPanelService,
    private userSettingsFacade: UserSettingsFacade,
    public store: Store,
    private widgetService: WidgetService,
    private dashboardService: DashboardService,
    private dialogRef: MatDialogRef<AnalisysPanelComponent>,
    private calendarService: CalendarService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getRouteSettings();
    this.initPanelData();
    this.initCurrentDate();
  }

  public changeLocation(): void {
    const selectedContinentId = this.widgetPanelService.getContinentByName(
      this.data.widgetConfig.continent
    );

    this.locationService
      .openWidgetCityLocation({
        region: this.panelState.selectedRegion ?? this.data.widgetConfig.region,
        continent:
          this.widgetPanelService.getContinentByName(
            this.panelState.selectedContinent
          )?.id ??
          (selectedContinentId?.id || 'NA'),
      })
      .pipe(
        tap((response) => {
          this.setPreviousContinentRegion(response);
          return response;
        })
      )
      .subscribe((resp: IWidgetLocationData) => {
        this.panelState = {
          ...this.panelState,
          selectedContinent: this.widgetPanelService.getContinentByName(
            resp.continent
          )?.name as string,
          selectedRegion: resp.region,
          selectedCity: resp.name,
          selectedSiteId: resp.siteId,
        };
        this.cdr.markForCheck();
      });
  }

  public changeSecondaryLens(event: MatSelectChange): void {
    this.panelState.selectedSecondaryLens = event.value;
  }

  public changeSize(event: MatSelectChange): void {
    this.panelState.selectedSumTableSize = event.value;
  }

  public changeDiffPrior(event: Event): void {
    this.panelState.diffPriorValue = (event.target as HTMLInputElement).checked;
  }

  public showDecimalValues(event: Event): void {
    this.panelState.showDecimalValue = (
      event.target as HTMLInputElement
    ).checked;
  }

  public saveWidget(): void {
    const selectedContinentId = this.widgetPanelService.getContinentByName(
      this.panelState.selectedContinent ?? this.data.widgetConfig.continent
    )?.id;

    const widgetDetails = buildWidgetDetails(
      selectedContinentId as string,
      this.currentDate,
      this.panelState,
      this.tempUnit
    );
    if (this.data.isNew) {
      this.widgetService.updateWidgetLocationState({
        type: WidgetTypes.analysis,
        continent: selectedContinentId as string,
        region: this.panelState.selectedRegion,
        siteId: this.panelState.selectedCity,
      });
      this.dashboardService.createWidget(widgetDetails, WidgetTypes.analysis);
    }

    return this.dialogRef.close(widgetDetails);
  }

  public closeWidget(): void {
    this.dialogRef.close();
  }

  private setPreviousContinentRegion(response: IWidgetLocationData): void {
    if (!response) {
      this.panelState.selectedRegion = this.data.widgetConfig.region;
      this.panelState.selectedContinent = this.data.widgetConfig.continent;
    }
  }

  private initPanelData(): void {
    this.secondaryLensDataSource =
      this.widgetPanelService.adaptSecondaryLensDataSource();

    this.panelState = buildWidgetPanelState(
      this.data.isNew,
      this.panelState,
      this.data.widgetConfig,
      this.secondaryLensDataSource
    );
    if (this.data.isNew) {
      this.updateLocationByState();
    }

    this.cdr.markForCheck();
  }

  private initCurrentDate(): void {
    this.currentDate = this.calendarService.getFullDate(
      new Date().setHours(0, 0, 0)
    );
  }
  updateLocationByState() {
    const { region, continent, siteId } =
      this.widgetService.widgetLocationState || {};
    if (this.widgetService.isSameWidgetType(WidgetTypes.analysis)) {
      this.panelState.selectedRegion = region || '';
      this.panelState.selectedContinent =
        this.widgetPanelService.getContinentName(continent) || '';
      this.panelState.selectedCity = siteId || '';
      return;
    }
    if (!['MEX', 'ASIA', 'NA', 'EUR'].includes(continent)) {
      return;
    }
    this.panelState.selectedContinent =
      this.widgetPanelService.getContinentName(continent);
    this.getRegionsList(continent)
      .pipe(
        map((regions) => {
          const foundRegion = regions.find(
            (regions) => regions.regionName === region
          );
          return foundRegion;
        }),
        filter((region) => !!region),
        switchMap((region) => {
          return this.getCitiesBasedOnRegionAndContient(
            continent,
            region?.siteId || ''
          );
        }),
        first()
      )
      .subscribe((cities) => {
        this.panelState.selectedRegion = region || '';
        this.panelState.selectedCity = cities[0].siteId || '';
        this.panelState.selectedSiteId = cities[0].siteId || '';
        this.cdr.markForCheck();
      });
  }
  private getRouteSettings(): void {
    this.userSettingsFacade.selectedTempUnit$

      .pipe(first())
      .subscribe((units) => {
        this.tempUnit = units;
      });
  }
  private getRegionsList(continentId: string | null) {
    return this.locationService.getRegionsList(continentId);
  }
  private getCitiesBasedOnRegionAndContient(continent: string, region: string) {
    return this.locationService.getCitiesByRegionAndContinent(
      region,
      continent
    );
  }
}
