import { LOCATION_PICKER_FEATURE_KEY } from '../constants/location-picker.constant';

export interface LocationPickerState {
  [LocationPickers.REGION]: RegionLocationPicker;
  [LocationPickers.CITY]: CityLocationPicker;
  isInitialized: boolean;
}

export interface LocationPickerPartialState {
  readonly [LOCATION_PICKER_FEATURE_KEY]: LocationPickerState;
}

export type RegionLocationPicker = Omit<LocationPickerProperties, 'siteId'> &
  Partial<{ customList: LocationPickerCustomList }>;

export type CityLocationPicker = LocationPickerProperties & {
  customList?: LocationPickerCustomList;
};

export enum LocationPickers {
  REGION = 'region-view',
  CITY = 'city-view',
}

export interface LocationPickerProperties {
  continent: string | null;
  region: string | null;
  siteId: string | null;
}

export interface LocationPickerCustomList {
  id: string | number;
  name: string;
  continent: string;
  continents: string[];
}

export interface LocationPickerConfig {
  redirect?: boolean;
  path?: string;
  queryParams?: {
    [key: string]: unknown;
  };
}

export interface InitDataSuccessPayload {
  persistedState: LocationPickerState;
}

export interface InitDataActionPayload {
  path: string;
  queryParams: Partial<LocationPickerProperties>;
}

export type Location = Omit<LocationPickerProperties, 'siteId'>;

export interface SaveRegionDataActionPayload {
  location?: Location;
  customList?: LocationPickerCustomList;
  config?: LocationPickerConfig;
}

export type SaveCustomListDataActionPayload = Pick<
  SaveRegionDataActionPayload,
  'customList'
>;

export interface SaveCityDataActionPayload {
  location: Pick<LocationPickerProperties, 'siteId'> &
    Partial<LocationPickerProperties>;
  customList?: LocationPickerCustomList;
  config?: LocationPickerConfig;
}

export interface RegionLocation {
  region: string | null;
  continent: string | null;
  continentName: string;
  customList?: LocationPickerCustomList;
}
