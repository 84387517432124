import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@firebird-web/shared-config';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { CommonUtils } from '@firebird-web/shared-utils';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WeightedSinglerunDropdownService {
  /**
   * getUsGasBcfModelsRun
   * @returns
   */
  public getUsGasBcfModelsRun() {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getUsGasBcfModelsRun}`
    );
    return this.http.get(endPointUrl).pipe(map((resp: any) => resp?.data));
  }

  /**
   * geWeightedModelsRun
   * @returns
   */
  public geWeightedModelsRun() {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.geWeightedModelsRun}`
    );
    return this.http.get(endPointUrl).pipe(map((resp: any) => resp?.data));
  }

  // constructor
  public constructor(private http: HttpClient) {}
}
