import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FavoriteList } from 'libs/custom-locations-store/src/lib/models/favorite-locations.types';
import { CustomLocationsService } from 'libs/custom-locations-store/src/lib/services/custom-locations.service';
import { first } from 'rxjs';

@Component({
  selector: 'firebird-web-settings-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ActionButtonsComponent implements OnInit {
  @Input() isEditVisible = false;
  @Input() list: FavoriteList | undefined;
  @Output() edit = new EventEmitter();
  @Output() deleteList = new EventEmitter();
  @Output() copyList = new EventEmitter<Partial<FavoriteList>>();
  @ViewChild('copyTrigger', { static: false }) copyTrigger!: MatMenuTrigger;

  constructor(
    private readonly customLocationsService: CustomLocationsService,
    private readonly cd: ChangeDetectorRef
  ) {}

  public copyListName = '';
  public isNameAlreadyExists = false;

  ngOnInit(): void {
    this.copyListName = (this.list?.listName || '') + ' Copy';
  }

  copyListNameChange() {
    this.isNameAlreadyExists = false;
  }

  getDuplicateClass() {
    return this.isNameAlreadyExists
      ? 'favorite-location-copy-panel duplicate-error'
      : 'favorite-location-copy-panel';
  }

  copyListHandler(list: Partial<FavoriteList>) {
    if (!list.listName || !list.id) {
      return;
    }
    this.customLocationsService
      .isFavoriteListUnique(list.listName)
      .pipe(first())
      .subscribe({
        next: (isNotUnique) => {
          this.isNameAlreadyExists = isNotUnique;
          this.cd.markForCheck();
          if (!isNotUnique) {
            this.copyList.emit(list);
            this.copyTrigger.closeMenu();
          }
        },
      });
  }
}
