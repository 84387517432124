export const startLegendState = {
  '3km_GRAF': true,
  ECMWF_OP_00Z_CURRENT: true,
  ECMWF_OP_12Z_CURRENT: true,
  ECMWF_OP_00Z_PREVIOUS: true,
  ECMWF_OP_12Z_PREVIOUS: true,
  ECMWF_ENS_00Z_CURRENT: true,
  ECMWF_ENS_12Z_CURRENT: true,
  ECMWF_ENS_00Z_PREVIOUS: true,
  ECMWF_ENS_12Z_PREVIOUS: true,
  GFS_OP_00Z: true,
  GFS_OP_06Z: true,
  GFS_OP_12Z: true,
  GFS_OP_18Z: true,
  GFS_ENS_00Z: true,
  GFS_ENS_06Z: true,
  GFS_ENS_12Z: true,
  GFS_ENS_18Z: true,
  OBS: true,
  ECMWF_ENS_06Z_PREVIOUS: true,
  ECMWF_ENS_18Z_PREVIOUS: true,
  ECMWF_ENS_06Z_CURRENT: true,
  ECMWF_ENS_18Z_CURRENT: true,
  ECMWF_OP_06Z_PREVIOUS: true,
  ECMWF_OP_18Z_PREVIOUS: true,
  ECMWF_OP_06Z_CURRENT: true,
  ECMWF_OP_18Z_CURRENT: true,
} as const;

export const startEurWidgetLegendState = {
  '3km_GRAF': false,
  ECMWF_OP_00Z_CURRENT: false,
  ECMWF_OP_12Z_CURRENT: false,
  ECMWF_OP_00Z_PREVIOUS: false,
  ECMWF_OP_12Z_PREVIOUS: false,
  ECMWF_ENS_00Z_CURRENT: false,
  ECMWF_ENS_12Z_CURRENT: false,
  ECMWF_ENS_00Z_PREVIOUS: false,
  ECMWF_ENS_12Z_PREVIOUS: false,
  GFS_OP_00Z: false,
  GFS_OP_06Z: false,
  GFS_OP_12Z: false,
  GFS_OP_18Z: false,
  GFS_ENS_00Z: false,
  GFS_ENS_06Z: false,
  GFS_ENS_12Z: false,
  GFS_ENS_18Z: false,
  OBS: true,
  ECMWF_ENS_06Z_PREVIOUS: false,
  ECMWF_ENS_18Z_PREVIOUS: false,
  ECMWF_ENS_06Z_CURRENT: false,
  ECMWF_ENS_18Z_CURRENT: false,
  ECMWF_OP_06Z_PREVIOUS: false,
  ECMWF_OP_18Z_PREVIOUS: false,
  ECMWF_OP_06Z_CURRENT: false,
  ECMWF_OP_18Z_CURRENT: false,
} as const;

export const StartEurWidgetLegendState = {
  ECMWF_OP_00Z: true,
  ECMWF_OP_06Z: true,
  ECMWF_OP_12Z: true,
  ECMWF_ENS_00Z: true,
  ECMWF_ENS_06Z: true,
  ECMWF_ENS_12Z: true,
  GFS_ENS_00Z: true,
  GFS_ENS_06Z: true,
  GFS_ENS_12Z: true,
  GFS_OP_00Z: true,
  GFS_OP_06Z: true,
  GFS_OP_12Z: true,
  OBS: true,
};
