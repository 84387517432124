export enum MapsForecastType {
  Wind = 'wind',
  Solar = 'solar',
}

export enum MapsUnitsType {
  Mph = 'mph',
  Mps = 'ms',
}

export enum MapsHeightType {
  SixM = '60m',
  EightM = '80m',
  TenM = '100m',
}

export enum AvailableMapsContinent {
  NA = 'NA',
  EUR = 'EUR',
  MEX = 'MEX',
}
