import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  LocationData,
  RenewableRegionData,
  RenewablesProducts,
} from 'libs/renewable/src/lib/models';
import { WINDFARM_AGGREGATES_PICKER_TABLE_COLUMNS } from 'libs/renewable/src/lib/modules/na-renewables/constants/picker-table-columns.constants';
import { NaRenewablesNetworkingService } from 'libs/renewable/src/lib/modules/na-renewables/services/na-renewables-networking.service';
import {
  PickerTableCol,
  WindfarmPool,
  WindfarmPools,
  WindfarmSubPool,
} from 'libs/renewable/src/lib/modules/na-renewables/types';
import { DropdownOption } from 'libs/shared/interfaces/src/lib';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { isEqual } from 'lodash';
import { RenewablesProductsEnum } from 'libs/renewable/src/lib/enums';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ContinentData } from 'libs/continent-picker/src/lib/types';
import { IOption } from 'libs/shared/ui/src/lib/components/dropdown/dropdown.component';
import { Continents, contMapping } from './renewables-location-popup.models';

@Component({
  selector: 'firebird-web-renewables-location-popup',
  templateUrl: './renewables-location-popup.component.html',
  styleUrls: ['./renewables-location-popup.component.scss'],
  providers: [NaRenewablesNetworkingService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenewablesLocationPopupComponent implements OnInit, OnDestroy {
  public filteredFarms: string[];
  public product: RenewablesProducts = 'wind';
  public windProductType = RenewablesProductsEnum.WIND;
  public aggregatesDataSource = new MatTableDataSource<WindfarmPool>([]);
  public subPoolsDataSource: WindfarmSubPool[] = [];
  public aggregatesColumns: PickerTableCol[] =
    WINDFARM_AGGREGATES_PICKER_TABLE_COLUMNS;
  public selectedPool: WindfarmPool | null = null;
  public continents$: Observable<DropdownOption[]>;
  public selectedContinent$ = new BehaviorSubject<string>('');
  public regions$: Observable<RenewableRegionData[]>;
  public pools$: Observable<WindfarmPools>;
  public selectedRegion$ = new BehaviorSubject<string>('');

  public farms: string[];
  private region: string | null;
  private pool: string | null;
  private locations: ContinentData[];
  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public locationData: any,
    private readonly dataService: NaRenewablesNetworkingService,
    private dialogRef: MatDialogRef<RenewablesLocationPopupComponent>
  ) {}

  ngOnInit(): void {
    this.initDialogData();
    this.manageRegionAndPoolSelection();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public selectRegion(region: RenewableRegionData) {
    this.dialogRef.close({
      continent: this.selectedContinent$.getValue(),
      region,
    });
  }

  public setContinent(value: string) {
    this.selectedContinent$.next(value);
  }

  public onPoolChange(row: WindfarmPool): void {
    this.selectedPool = row as WindfarmPool;
    this.dialogRef.close({
      continent: this.selectedContinent$.getValue(),
      pool: row,
      region: this.getRegionData(this.selectedRegion$.getValue()),
      isAggregate: true,
    });
  }

  public onOptionPoolSelect({
    option: { value },
  }: MatAutocompleteSelectedEvent): void {
    const selectedPool =
      this.subPoolsDataSource.find((farm) => farm.name === value) ||
      this.subPoolsDataSource[0];
    this.dialogRef.close({
      continent: this.selectedContinent$.getValue(),
      pool: selectedPool,
      region: this.getRegionData(selectedPool.poolId),
      isAggregate: false,
    });
  }

  public onSearchChange(input: Event) {
    const newValue = (input.target as HTMLInputElement).value;
    this.filteredFarms = this.farms.filter((farm) =>
      farm.toLocaleUpperCase().includes(newValue.toLocaleUpperCase())
    );
  }

  public isContinentPermitted(continent: string): boolean {
    //TODO remove when total would be available for NA
    if (this.product === 'total' && continent === 'NA') {
      return false;
    }
    return (
      this.locationData.data.locationData
        .find((location: ContinentData) => location.continent === continent)
        ?.regions.some((region: IOption) => region.isPermitted) || false
    );
  }

  public trackByFn(index: number, item: string): string {
    return item;
  }

  private getRegionData(region: string) {
    const regions = this.locations.find(
      (location) => location.continent === this.selectedContinent$.getValue()
    )?.regions as IOption[];
    return regions.find(({ value }: IOption) => value === region) as IOption;
  }

  private manageRegionAndPoolSelection(): void {
    this.regions$ = this.selectedContinent$.pipe(
      takeUntil(this.destroyed$),
      distinctUntilChanged((prevCont, currCont) => prevCont == currCont),
      switchMap((continent) => {
        const regions = this.locationData.data.locationData.find(
          (x: LocationData<RenewableRegionData>) => x.continent === continent
        ).regions;
        regions.map(
          (region: RenewableRegionData) =>
            (region.isPermitted = this.isRegionPermitted(
              continent,
              this.product,
              region
            ))
        );
        if (this.region) {
          this.selectedRegion$.next(this.region);
          this.region = null;
        } else {
          this.selectedRegion$.next(
            regions.find((region: RenewableRegionData) => region.isPermitted)
              .value
          );
        }
        return of(regions);
      }),
      shareReplay()
    );

    this.pools$ = combineLatest([
      this.selectedContinent$,
      this.selectedRegion$,
    ]).pipe(
      takeUntil(this.destroyed$),
      filter(([continent]) => continent === 'NA'),
      distinctUntilChanged(([, prevRegion], [, currRegion]) =>
        isEqual(prevRegion, currRegion)
      ),
      switchMap(([, region]) => {
        return this.dataService.getWindFarmPools(region, this.product);
      })
    );

    this.pools$.pipe(takeUntil(this.destroyed$)).subscribe((windfarmPools) => {
      this.aggregatesDataSource.data = windfarmPools.aggregates;
      this.subPoolsDataSource = windfarmPools.subPools;
      this.farms = windfarmPools.subPools.map((pool) => pool.name);
      this.filteredFarms = this.farms;
      if (this.pool) {
        this.selectedPool =
          windfarmPools.aggregates.find((pool) => pool.name === this.pool) ||
          null;
        this.pool = null;
      }
    });
  }

  private initDialogData(): void {
    this.locations = this.locationData.data.locationData;
    this.product = this.locationData.data.product;
    const continent =
      contMapping[this.locationData.data.continent as Continents];
    const continents = this.locations.map((data: ContinentData) => {
      return {
        value: data.continent,
        labelKey: data.continent,
        isPermitted: this.isContinentPermitted(data.continent),
      };
    });
    this.continents$ = of(continents);

    this.region = this.locationData.data.region;
    this.pool = this.locationData.data.pool;

    this.selectedContinent$.next(
      continents.find((x: IOption) => x.isPermitted && x.value === continent)
        ?.value || continents[0].value
    );
  }

  private isRegionPermitted(
    continent: string,
    product: string,
    region: RenewableRegionData
  ): boolean {
    if (product === 'total') {
      if (continent === 'EUR') {
        return region.isSolarAvailable && region.isSolarAvailable;
      }
      if (continent === 'NA') {
        return false;
      }
    }

    return this.product === 'solar'
      ? region.isSolarAvailable && !region.value.includes('CAISO') //TODO remove then CAISO would be available
      : region.isWindAvailable;
  }
}
