export enum NaChartSeriesModelRun {
  AG2_LATEST = 'AG2_LATEST',
  AG2_PREVIOUS = 'AG2_PREVIOUS',
  AG2_PRIMARY = 'AG2_PRIMARY',
  AG2_MORNING = 'AG2_MORNING',
  AG2_PREVIOUS_MORNING = 'AG2_PREVIOUS_MORNING',
  AG2_EVENING = 'AG2_EVENING',
  AG2_PREVIOUS_EVENING = 'AG2_PREVIOUS_EVENING',
  AG2_PRIMARY_YESTERDAY = 'AG2_PRIMARY_YESTERDAY',
  AG2_EVENING_YESTERDAY = 'AG2_EVENING_YESTERDAY',
  AG2_PREVIOUS_MORNING_MID_MORNING = 'AG2_PREVIOUS_MORNING_MID_MORNING',
  LOCATION_MORNING = 'LOCATION_LATEST_MORNING',
  LOCATION_LATEST = 'LOCATION_LATEST',
  LOCATION_PREVIOUS = 'LOCATION_PREVIOUS',
  LOCATION_PREVIOUS_MORNING = 'LOCATION_PREVIOUS_MORNING',
  ERCOT_WGRPP_LATEST_MORNING = 'ERCOT_WGRPP_LATEST_MORNING',
  ERCOT_STWPF_LATEST_MORNING = 'ERCOT_STWPF_LATEST_MORNING',
  ERCOT_WGRPP_LATEST = 'ERCOT_WGRPP_LATEST',
  ERCOT_STWPF_LATEST = 'ERCOT_STWPF_LATEST',
  ERCOT_WGRPP_PREVIOUS = 'ERCOT_WGRPP_PREVIOUS',
  ERCOT_STWPF_PREVIOUS = 'ERCOT_STWPF_PREVIOUS',
  ERCOT_WGRPP_MORNING = 'ERCOT_WGRPP_PREVIOUS_MORNING',
  ERCOT_STWPF_MORNING = 'ERCOT_STWPF_PREVIOUS_MORNING',
  OBS = 'OBS',
  FARM_CAPACITY = 'FARM_CAPACITY',
  CONFIDENCE_BANDS = 'CONFIDENCE_BANDS',
}
