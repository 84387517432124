<div class="location-selection" (click)="openLocationPopup()">
  <div class="picker">
    <span>{{ continent$ | async }}</span>
    <firebird-web-icon
      height="10px"
      width="10px"
      name="chevron-right"
    ></firebird-web-icon>
    <span>{{ region$ | async }}</span>
    <firebird-web-icon
      height="10px"
      width="10px"
      name="chevron-down"
    ></firebird-web-icon>
  </div>
  <span class="pool-text" *ngIf="(continent$ | async) === 'North America'">
    {{ getLocationText() | translate }} : {{ pool$ | async }}
  </span>
</div>
