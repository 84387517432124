import { createAction, props } from '@ngrx/store';
import { SettingsEntity } from './settings.models';
import { FavoriteList } from '../../models/favorite-locations.types';

export const initSettings = createAction('[Settings Page] Init');

export const loadSettingsSuccess = createAction(
  '[Settings/API] Load Settings Success',
  props<{ settings: SettingsEntity[] }>()
);

export const deleteList = createAction(
  '[Settings/API] Delete List',
  props<{ list: FavoriteList }>()
);
export const deleteListSuccess = createAction(
  '[Settings/API] Delete List Success',
  props<{ id: number }>()
);
export const deleteListFailure = createAction(
  '[Settings/API] Delete Lists Failure',
  props<{ error: any }>()
);

export const copyList = createAction(
  '[Settings/API] Copy List',
  props<{ list: Partial<FavoriteList> }>()
);

export const copyListSuccess = createAction(
  '[Settings/API] Copy List Success',
  props<{ lists: FavoriteList[] }>()
);

export const changeList = createAction(
  '[Settings/API] Change List',
  props<{ list: FavoriteList }>()
);

export const setSelectedContinent = createAction(
  '[Settings/API] Set Selected Continent',
  props<{ selectedContinent: any }>()
);

export const loadLists = createAction('[Settings/API] Load Lists');
export const loadListsSuccess = createAction(
  '[Settings/API] Load Lists Success',
  props<{ favoriteLists: FavoriteList[] }>()
);
export const loadListsFailure = createAction(
  '[Settings/API] Load Lists Failure',
  props<{ error: any }>()
);

export const loadCities = createAction('[Settings/API] Load Cities');
export const loadCitiesSuccess = createAction(
  '[Settings/API] Load Cities Success',
  props<{ continents: any; regions: any[] }>()
);
export const loadCitiesFailure = createAction(
  '[Settings/API] Load Regions Cities',
  props<{ error: any }>()
);

export const createList = createAction(
  '[Settings/API] Create list',
  props<{ list: any }>()
);

export const createListSuccess = createAction(
  '[Settings/API] Create list Success',
  props<{ list: any }>()
);

export const saveList = createAction(
  '[Settings/API] Save list',
  props<{ list: any }>()
);

export const saveListSuccess = createAction(
  '[Settings/API] Save list Success',
  props<{ list: any }>()
);
