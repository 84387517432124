import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ImapService {
  private potentialLayers: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  public selectedLayers$ = this.potentialLayers.asObservable();

  public updateSharedData(data: string): void {
    this.potentialLayers.next(data);
  }
}
