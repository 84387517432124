import moment from 'moment/moment';
import { DropdownOption } from '../../../../../../shared/interfaces/src/lib';
import { NaRenewablesForecast } from '../types';

export const createNaRenewablesForecastDropdown = (
  forecastList: NaRenewablesForecast[]
): DropdownOption<NaRenewablesForecast>[] =>
  forecastList.map((forecast) => ({
    labelKey: forecast.label,
    value: forecast,
  }));

export const getShortMonthName = (date: string): string =>
  moment(date).format('MMM');
