import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, map, Subject, takeUntil } from 'rxjs';
import { mexRegions } from './location.constants';
import {
  LocationSelectionDialogData,
  LocationSelectionDialogResult,
} from './location.interface';
import { CommonUtils } from '@firebird-web/shared-utils';
import { getFavoriteLists } from '@firebird-web/custom-locations-store';
import { FavoriteList } from '../../../../../custom-locations-store/src/lib/models/favorite-locations.types';
import { isEmpty } from 'lodash';
import {
  getAllRegions,
  loadRegions,
  RegionsEntity,
} from '@firebird-web/regions-store';
import { continents } from '@firebird-web/shared-constants';
import {
  ContinentsEntity,
  getAllContinents,
} from '@firebird-web/continents-store';
import { SettingsService } from '../../../../../settings/src/lib/services/settings.service';
import { PermissionDialogComponent } from 'libs/shared/ui/src/lib/components/permission-dialog/permission-dialog.component';

@Component({
  selector: 'firebird-web-location-selection',
  templateUrl: './location-selection.component.html',
  styleUrls: ['./location-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [SettingsService],
})
export class LocationSelectionComponent implements OnInit, OnDestroy {
  public customList$ = this.store
    .select(getFavoriteLists)
    .pipe(map((list) => CommonUtils.toChunksByColumnsQty(list, 3)));
  public continents$ = this.store.select(getAllContinents);
  public continent$ = new BehaviorSubject<ContinentsEntity>(
    {} as ContinentsEntity
  );
  public selectedISORegions: RegionsEntity[] = [];
  public selectedISORegionsByChunks: RegionsEntity[][] = [];
  public selectedEIARegions: RegionsEntity[] = [];
  public mexRegions = mexRegions;
  public isLoaded = false;
  private destroy$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: LocationSelectionDialogData,
    private readonly dialogRef: MatDialogRef<
      LocationSelectionComponent,
      LocationSelectionDialogResult
    >,
    private readonly store: Store,
    private readonly cdRef: ChangeDetectorRef,
    private readonly settingsService: SettingsService,
    private matDialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.changeContinent(this.initialContinent);
    this.store
      .select(getAllRegions)
      .pipe(
        filter((regionList) => !isEmpty(regionList)),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (regionList) => {
          this.selectedEIARegions = regionList.filter(({ isEia }) => isEia);
          this.selectedISORegions = regionList.filter(
            ({ siteId, regionName, isEia }) =>
              !isEia && siteId !== 'All Regions' && regionName !== 'All Cities'
          );

          if (
            [continents.EUROPE, continents.ASIA].includes(this.continent.id)
          ) {
            this.selectedISORegionsByChunks = CommonUtils.toChunksByColumnsQty(
              this.selectedISORegions,
              2
            );
          }
          this.isLoaded = true;

          // Since we have to display regions as chunks,
          // change detection does not work with nested ngFor (matrix array)
          this.cdRef.markForCheck();
        },
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.continent$.complete();
  }

  public showManageList(): void {
    this.closeDialog();
    this.settingsService.openManageCustomList();
  }

  public selectCustomList({
    id,
    listName: name,
    continent,
    continents,
  }: FavoriteList): void {
    this.closeDialog({
      isCustomList: true,
      customList: {
        id,
        name,
        continent,
        continents,
      },
    });
  }

  public closeDialog(result?: LocationSelectionDialogResult): void {
    this.dialogRef.close(result);
  }

  public changeContinent(continent: ContinentsEntity): void {
    this.isLoaded = false;
    this.continent$.next(continent);
    this.store.dispatch(
      loadRegions({
        selectedContinentId: continent.id,
      })
    );
  }

  public selectRegion(
    {
      regionName = 'All Regions',
      siteId,
    }: Partial<Omit<RegionsEntity, 'regionName'>> &
      Pick<RegionsEntity, 'regionName'>,
    isNotPermitted = false
  ): void {
    if (isNotPermitted) {
      this.showPermissionDeniedDialog();
      return;
    }
    this.closeDialog({
      continent: this.continent,
      region: regionName ?? siteId,
    });
  }

  private get initialContinent(): ContinentsEntity {
    return this.data.continent;
  }

  public get continent(): ContinentsEntity {
    return this.continent$.getValue();
  }

  private showPermissionDeniedDialog() {
    this.matDialog.open(PermissionDialogComponent, {
      width: '500px',
      panelClass: ['firebird-dialog-panel'],
      autoFocus: false,
    });
  }
}
