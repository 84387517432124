import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { GenerateFileNameReq } from '@firebird-web/shared-interfaces';
import { CommonUtils } from '@firebird-web/shared-utils';
import { environment } from '@firebird-web/shared-config';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tap, Observable } from 'rxjs';
import { CalendarService } from '../calendar/calendar.service';
import {
  ForecastRequestParams,
  ForecastRequestParamsCsv,
} from '../../../../../heat-map-overview/src/lib/types/forecast.interface';
import { Units } from '@firebird-web/user-settings-store';

@Injectable({
  providedIn: 'root',
})
export class ExportDataService {
  private renderer!: Renderer2;

  // constructor
  public constructor(
    private http: HttpClient,
    private rendererFactory: RendererFactory2,
    private calendarService: CalendarService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  /**
   * downloadFile
   * @param blob
   */
  public downloadFile(blob: Blob, fileName: string): void {
    const blobUrl = URL.createObjectURL(blob);
    const link = this.renderer.createElement('a');
    link.setAttribute('href', blobUrl);
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
    );
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(blobUrl);
      link.remove();
    }, 100);
  }

  /**
   * exportByUrlAsCSV
   * @url url
   * @param params
   */
  public exportAsCSV(
    url: string,
    params: any,
    fileName: string
  ): Observable<Blob> {
    const endpointUrl = `${environment.apiDomain}/${url}`;
    return this.http
      .post(endpointUrl, params, {
        responseType: 'blob',
      })
      .pipe(
        tap((blob: Blob) => {
          this.downloadFile(blob, fileName);
        })
      );
  }

  /**
   * weightedExportAsCSV
   * @param url
   * @param params
   * @returns
   */
  public weightedExportAsCSV(url: string, params: any): Observable<Blob> {
    const endpointUrl = `${environment.apiDomain}/${url}`;
    return this.http
      .post(endpointUrl, params, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        tap((response: any) => {
          const input = response.headers.get('Content-Disposition');
          const fileName: string = this.getFileName(input);
          this.downloadFile(response.body, fileName);
        })
      );
  }

  /**
   * generateFileName
   * @param params
   */
  public generateFileName({
    runDate,
    applicationName,
    cityName,
  }: GenerateFileNameReq): string {
    const filNamePattern = '{applicationName}_{cityName}_INIT{formattedDate}';
    const formattedDate = this.calendarService.formatDateString(
      runDate,
      "yyyy_MM_dd_HHmm'Z'"
    );
    const fileName = CommonUtils.populateURLTemplate(filNamePattern, {
      applicationName,
      cityName,
      formattedDate,
    });
    return fileName;
  }

  /**
   * getFileName
   * @param input
   * @returns
   */
  public getFileName(input: string) {
    const matches = input.match(/filename\*=UTF-8''([^;]+)/) || '';
    const filename = matches[1];
    return filename;
  }

  public getContentDisposition<R = unknown>(
    response: HttpResponse<R>
  ): string | null {
    return response.headers.get('Content-Disposition');
  }

  public renewablesExportAsCSV(
    region: string,
    model: string,
    renewableType: string,
    initTime: string,
    comparisonModel: string | null,
    comparisonInitTime?: string | null,
    forecastType = 'Megawatts'
  ): Observable<Blob> {
    let link = `${environment.apiDomain}/api/v1/renewables/download/${region}/${model}/${renewableType}/${forecastType}/null/${initTime}`;
    if (comparisonModel && comparisonInitTime) {
      link += `/${comparisonModel}/${comparisonInitTime}`;
    }
    return this.http
      .get<Blob>(link, {
        responseType: 'blob' as 'json',
        observe: 'response',
      })
      .pipe(
        tap((response: any) => {
          const input = response.headers.get('Content-Disposition');
          const fileName: string = this.getFileName(input);
          this.downloadFile(response.body, fileName);
        })
      );
  }

  public summaryExportAsCSV({
    customList,
    primaryLens,
    compareRunDate,
    ...rest
  }: ForecastRequestParams & ForecastRequestParamsCsv & Units): Observable<
    HttpResponse<Blob>
  > {
    const link = `${environment.apiDomain}/api/v1/CityTable/summary/download`;
    const isCustom = !!customList;
    const body = {
      ...rest,
      isCustom,
      primaryLens,
      ...(isCustom && {
        ProfileCustomId: +customList.id,
        continent: 'NA',
        region: customList.name,
      }),
      ...(primaryLens === 'ForecastDifference' && { compareRunDate }),
    };
    return this.http
      .post<Blob>(link, body, {
        responseType: 'blob' as 'json',
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponse<Blob>) => {
          const input = response.headers.get('Content-Disposition') || '';
          const fileName: string = this.getFileName(input);
          if (response.body) {
            this.downloadFile(response.body, fileName);
          }
        })
      );
  }
}
