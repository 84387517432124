import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardDataService } from '../../../services/data/dashboard/dashboard-data.service';
import { dashTitleValidator } from 'libs/shared/validators/validators';
import { first } from 'rxjs';
import { DashboardService } from '../../../services/dashboard.service';

@Component({
  selector: 'firebird-web-dash-edit-panel',
  templateUrl: './dash-edit-panel.component.html',
  styleUrls: ['./dash-edit-panel.component.scss'],
})
export class DashEditPanelComponent {
  editMonitorForm: FormGroup;
  submitButtonClicked = false;
  isTitleValid = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DashEditPanelComponent>,
    private dashboardService: DashboardService,
    private dashData: DashboardDataService
  ) {
    this.editMonitorForm = this.fb.group({
      title: new FormControl(this.data.title || '', [
        Validators.required,
        dashTitleValidator(),
      ]),
      description: new FormControl(this.data.description || ''),
    });
  }
  closeDialog() {
    this.dialogRef.close({
      updated: false,
    });
  }
  updateDashboard() {
    this.submitButtonClicked = true;
    this.isTitleValid = this.dashData.checkDuplicateTitle(
      this.editMonitorForm.get('title')?.value,
      true,
      this.data.id,
      this.data.title
    );
    this.editMonitorForm.updateValueAndValidity();

    const id = this.data.id;
    if (this.editMonitorForm.valid && !this.isTitleValid) {
      this.dashData
        .updateDashboard(
          id,
          this.editMonitorForm?.get('title')?.value,
          this.editMonitorForm.get('description')?.value,
          this.data.widgetConfig
        )
        .pipe(first())
        .subscribe(() => {
          this.dashData.getAllDashboards();
        });
      this.dialogRef.close({
        updated: true,
        editedTitle: this.editMonitorForm.get('title')?.value,
        editedDescription: this.editMonitorForm.get('description')?.value,
      });
    }
  }
}
