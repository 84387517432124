<div class="notification-email-dialog component">
  <h2 mat-dialog-title>{{ data.type }} Email Address</h2>
  <div *ngIf="!emailControl.valid" class="error-wrapper">
    <mat-icon>error_outline</mat-icon>
    <p>Please enter a valid email address.</p>
  </div>
  <mat-dialog-content>
    <div class="email-wrapper">
      <p class="input-label">Email</p>
      <mat-form-field>
        <input matInput [formControl]="emailControl" />
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: flex-end">
    <firebird-web-button
      type="button"
      name="Cancel"
      [style]="'basic'"
      [labelKey]="'xcancel' | translate"
      (clickEvent)="onNoClick()"
    ></firebird-web-button>
    <firebird-web-button
      type="submit"
      name="Save"
      [style]="'additional'"
      [labelKey]="saveLabel | translate"
      (clickEvent)="onSave()"
    ></firebird-web-button>
  </mat-dialog-actions>
</div>
