import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from 'libs/acl/src/lib/auth-callback/auth-callback.component';
import { NoPermissionComponent } from '../../../../libs/acl/src/lib/no-permission/no-permission.component';

const routes: Routes = [
  {
    path: 'auth/callback/signin',
    component: AuthCallbackComponent,
  },
  {
    path: '/auth/callback/logout',
    component: AuthCallbackComponent,
  },
  {
    path: 'auth/no-permission',
    component: NoPermissionComponent,
  },
  {
    path: '',
    loadChildren: () => import('./base/base.module').then((m) => m.BaseModule),
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
