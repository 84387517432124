import moment, { Moment } from 'moment';
import { isSameDates } from './heat-map-summary.helper';
import { isNull, slice, first } from 'lodash';
import { RunDatesEntity } from '@firebird-web/run-dates-store';

export const subtractDaysFromDate = (
  date: string | Moment,
  amount: number,
  format = 'YYYY-MM-DD'
): string => moment(date).subtract(amount, 'days').format(format);

export const isDateOlder = (
  runDate: string | number,
  dateToCompare: string | number
): boolean => moment(runDate).isAfter(moment(dateToCompare));

export const getCompareRunDate = (
  runDates: RunDatesEntity[],
  runDate: string | number
): string | number =>
  runDates.find(({ type, id }) => {
    return (
      type === 'PRIMARY' &&
      isSameDates(id as string, subtractDaysFromDate(runDate as string, 1))
    );
  })?.id ?? '';

export const getLatestRunDateAndCompareRunDate = (
  runDates: RunDatesEntity[]
): [string, string] =>
  slice(runDates, 0, 2).map(({ id }) => id.toString()) as [string, string];

export const getCompareRunDates = (
  runDates: RunDatesEntity[],
  runDate: string | number
): RunDatesEntity[] =>
  runDates
    .filter(({ id }) => isDateOlder(runDate, id))
    .filter(({ type }) => type === 'PRIMARY');

export const getLatestRunDate = (
  runDates: RunDatesEntity[]
): string | number | undefined => first(runDates)?.id;

export const getLatestRunDateStorageTime = (
  runDates: RunDatesEntity[]
): string => (first(runDates) as RunDatesEntity).storageTime;

export const isSameRunDates = (
  runDate: string | number | undefined,
  compareRunDate: string | number | undefined
): boolean => runDate === compareRunDate;

export const isPrimaryLensForecastDifference = (
  primaryLens: string | null
): boolean => !isNull(primaryLens) && primaryLens === 'ForecastDifference';

export const isLastRunDate = (
  runDates: RunDatesEntity[],
  runDate: string
): boolean => {
  const { id: lastRunDateId = '' } = runDates.at(-1) ?? ({} as RunDatesEntity);

  return lastRunDateId === runDate;
};
