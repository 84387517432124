import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '../../services/dashboard.service';
import {
  IRenewableWidgetPanelData,
  WidgetTypes,
} from '../../constants/widget-types';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  LocationData,
  RenewableRegionData,
  RenewablesEnsSpread,
  RenewablesProducts,
  WIDGET_RANGE_SELECTOR_OPTIONS,
} from 'libs/renewable/src/lib/models/renewables.models';
import { IRenewablesParams } from '../../interfaces';
import { WidgetService } from '../../services/widget.service';
import { RenewablesService } from 'libs/renewable/src/lib/services/renewables.service';
import { EurRenewablesService } from '../../../../../renewable/src/lib/modules/eur-renewables/services/eur-renewables.service';
import { continents } from '@firebird-web/shared-constants';
import { StartEurWidgetLegendState } from '../../../../../renewable/src/lib/constants';
import {
  EUR_LABELS,
  NA_LABELS,
  NA_PRODUCTS,
  PRODUCT_TYPES,
  SOLAR_PRODUCT,
  UNIT_TYPES,
  WIND_PRODUCT,
} from './renewables-panel.constants';
import {
  RenewablesNaDefaultLegendState,
  RenewablesNaErcotLegendState,
} from '../../constants';
import { MatSelectChange } from '@angular/material/select';
import { DropdownOption } from 'libs/shared/interfaces/src/lib';
import { isProductAvailable } from 'libs/renewable/src/lib/types';
import { IOption } from 'libs/shared/ui/src/lib/components/dropdown/dropdown.component';
import { WindfarmPool } from 'libs/renewable/src/lib/modules/na-renewables/types';
import { NaRenewablesNetworkingService } from 'libs/renewable/src/lib/modules/na-renewables/services/na-renewables-networking.service';
import { first as lodashFirst } from 'lodash';

@Component({
  selector: 'firebird-web-renewables-panel',
  templateUrl: './renewables-panel.component.html',
  styleUrls: ['./renewables-panel.component.scss'],
  providers: [RenewablesService, DatePipe, DecimalPipe],
})
export class RenewablesPanelComponent implements OnInit, OnDestroy {
  public selectedGraphUnit = 'megawatts';
  public selectedRenewablesType$ = new BehaviorSubject<RenewablesProducts>(
    'wind'
  );
  public continent$ = new BehaviorSubject<string>('');
  public pool = '';
  public poolId = '';
  public selectedRange!: {
    labelKey: string;
    value: { min: number; max: number };
  };
  public labels = EUR_LABELS;
  public graphUnit = UNIT_TYPES;
  public renewableTypes = PRODUCT_TYPES;
  public rangeOptions = WIDGET_RANGE_SELECTOR_OPTIONS;
  public locationData$: Observable<LocationData<RenewableRegionData[]>[]>;
  public region$ = new BehaviorSubject<string>('');
  public ensembleSpreadDropdownOptions: DropdownOption[] = [];
  public selectedEnsembleSpread!: RenewablesEnsSpread;
  public isAggregate = false;

  private selectedRenewableSize = 'full';
  private ensembleSpreadList: RenewablesEnsSpread[] = [];
  private locationData: LocationData<RenewableRegionData[]>[];
  private readonly destroyed$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: IRenewableWidgetPanelData,
    private readonly dialogRef: MatDialogRef<RenewablesPanelComponent>,
    private readonly dashboard: DashboardService,
    private readonly widgetService: WidgetService,
    private readonly eurRenewablesService: EurRenewablesService,
    private readonly renewableService: RenewablesService,
    private readonly renewablesNetworkService: NaRenewablesNetworkingService
  ) {}

  public ngOnInit(): void {
    this.locationData$ = this.initLocationData();
    this.getRenewablesGraphEnsembleSpreads();
    this.validateProduct$();

    if (this.data.isNew) {
      this.configureNewWidget();
    } else {
      this.configureExistingWidget();
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public regionChange({ value }: MatSelectChange): void {
    this.region$.next(value);
  }

  public graphUnitChange({ value }: MatSelectChange): void {
    this.selectedGraphUnit = value;
  }

  public ensSpreadChange({ value }: MatSelectChange): void {
    this.selectedEnsembleSpread = { label: value, value };
  }

  public handleLocationChange(event: {
    continent: string;
    region: IOption & isProductAvailable;
    pool: WindfarmPool & { value: string };
    isAggregate: boolean;
  }): void {
    this.isAggregate = event.isAggregate;
    this.continent$.next(event.continent);
    this.labels = this.isNa(this.continent$.getValue())
      ? NA_LABELS
      : EUR_LABELS;
    this.region$.next(event.region.value);
    this.pool = event?.pool?.name;
    this.poolId = event?.pool?.id;
    this.renewableTypes = this.getRenewablesTypesBasedOnLocation(
      event.region,
      this.continent$.getValue()
    );

    this.validateSelectedRenewableType();
  }

  private validateSelectedRenewableType() {
    if (
      !this.renewableTypes.some(
        (type) => type.value === this.selectedRenewablesType$.getValue()
      )
    ) {
      this.selectedRenewablesType$.next(
        lodashFirst(this.renewableTypes)?.value as RenewablesProducts
      );
    }
  }

  private getRenewablesTypesBasedOnLocation(
    region: IOption & isProductAvailable,
    continent: string
  ): DropdownOption<RenewablesProducts>[] {
    const isEurope = continent === 'EUR';
    const isNAExcludingCAISO =
      continent !== 'EUR' && !region.value.includes('CAISO');

    if (isEurope) {
      if (region.isWindAvailable && region.isSolarAvailable) {
        return PRODUCT_TYPES;
      }
      return region.isWindAvailable ? WIND_PRODUCT : SOLAR_PRODUCT;
    }

    if (
      isNAExcludingCAISO &&
      region.isWindAvailable &&
      region.isSolarAvailable
    ) {
      return NA_PRODUCTS;
    }

    return WIND_PRODUCT;
  }

  public renewablesTypeChange({ value }: MatSelectChange): void {
    this.selectedRenewablesType$.next(value);
  }

  public rangeChange({ value }: MatSelectChange): void {
    this.selectedRange = { labelKey: value, value };
  }

  public onSave(): void {
    const widgetType = this.isNa(this.continent$.getValue())
      ? WidgetTypes.naRenewables
      : WidgetTypes.renewables;
    if (this.data.isNew) {
      const widgetObj: IRenewablesParams = {
        ensSpread: this.selectedEnsembleSpread,
        legend: this.getDefaultLegendState(),
        region: this.region$.getValue(),
        continent: this.continent$.getValue(),
        product: this.selectedRenewablesType$.getValue(),
        pool: this.pool,
        poolId: this.poolId,
        isAggregate: this.isAggregate,
        ensSpreads: this.ensembleSpreadList,
        scale: this.selectedGraphUnit,
        range: {
          label: this.selectedRange.labelKey,
          value: this.selectedRange.value,
        },
        size: this.selectedRenewableSize === 'full' ? 4 : 2,
        type: widgetType,
        gridstack: {},
      };
      this.dashboard.createWidget(widgetObj, widgetType);
      this.widgetService.updateWidgetLocationState({
        type: widgetType,
        region: this.region$.getValue(),
        continent: this.continent$.getValue(),
      });
      this.dashboard.saveDashboard();
      this.dialogRef.close();
    } else {
      this.data.widgetConfig.ensSpread = this.selectedEnsembleSpread;
      this.data.widgetConfig.continent = this.continent$.getValue();
      this.data.widgetConfig.legend = this.getSavedLegendState();
      this.data.widgetConfig.region = this.region$.getValue();
      this.data.widgetConfig.pool = this.pool;
      this.data.widgetConfig.poolId = this.poolId;
      this.data.widgetConfig.isAggregate = this.isAggregate;
      this.data.widgetConfig.continent =
        this.continent$.getValue() ?? continents.EUROPE;
      this.data.widgetConfig.range = {
        label: this.selectedRange.labelKey,
        value: this.selectedRange.value,
      };
      this.dashboard.updateWidgetType(this.data.widgetId, widgetType);
      this.data.widgetConfig.product = this.selectedRenewablesType$.getValue();
      this.data.widgetConfig.scale = this.selectedGraphUnit;
      this.dialogRef.close(this.data.widgetConfig);
    }
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  private configureNewWidget(): void {
    this.getFirstPermittedContinent();
    this.getFirstPermittedRegion();
    this.selectedRange = WIDGET_RANGE_SELECTOR_OPTIONS[1];
    this.labels = EUR_LABELS;
    this.updateLocationBasedOnState();
  }

  private getDefaultLegendState(): Record<string, boolean> {
    if (!this.isNa(this.continent$.getValue())) {
      return StartEurWidgetLegendState;
    }

    return this.region$.getValue().includes('ERCOT')
      ? RenewablesNaErcotLegendState
      : RenewablesNaDefaultLegendState;
  }

  private getSavedLegendState(): Record<string, boolean> {
    if (
      this.data.widgetConfig.region !== this.region$.getValue() ||
      this.data.widgetConfig.pool !== this.pool
    ) {
      return this.getDefaultLegendState();
    }

    return this.data.widgetConfig.legend;
  }

  private getFirstPermittedContinent(): void {
    this.locationData$
      .pipe(
        first(),
        map((locationData) =>
          locationData.map((location) => {
            return {
              continent: location.continent,
              isPermitted: location.regions.some(
                (region) => region.isPermitted
              ),
            };
          })
        )
      )
      .subscribe((continents) => {
        this.continent$.next(
          continents.find((continent) => continent.isPermitted)?.continent ||
            continents[0].continent
        );
      });
  }

  private getFirstPermittedRegion(): void {
    this.locationData$
      .pipe(
        first(),
        map(
          (locations) =>
            locations.find(
              (location) => location.continent === this.continent$.getValue()
            )?.regions || locations[0].regions
        )
      )
      .subscribe((regions) => {
        this.region$.next(
          regions.find((region) => region.isPermitted)?.value ||
            regions[0].value
        );
      });
  }

  private initLocationData(): Observable<
    LocationData<RenewableRegionData[]>[]
  > {
    return this.renewableService.prepareGetLocationsData().pipe(
      first(),
      shareReplay(1),
      tap((locationData) => (this.locationData = locationData))
    );
  }

  private updateLocationBasedOnState(): void {
    if (this.widgetService.isSameWidgetType(WidgetTypes.renewables)) {
      this.region$.next(this.widgetService.widgetLocationState?.region || 'AT');
    }
  }

  private configureExistingWidget(): void {
    if (this.data) {
      this.selectedRenewablesType$.next(this.data.widgetConfig.product);
      this.selectedGraphUnit = this.data.widgetConfig.scale;
      this.region$.next(this.data.widgetConfig.region);
      this.continent$.next(
        this.data.widgetConfig.continent ?? continents.EUROPE
      );
      this.pool = this.data.widgetConfig.pool;
      this.poolId = this.data.widgetConfig.poolId;
      this.isAggregate = this.data.widgetConfig?.isAggregate ?? false;
      this.selectedRange = WIDGET_RANGE_SELECTOR_OPTIONS.filter(
        (rangeOption) =>
          rangeOption.value.max === this.data.widgetConfig.range.value.max &&
          rangeOption.value.min === this.data.widgetConfig.range.value.min
      )[0];
      this.labels =
        this.continent$.getValue() === continents.NORTH_AMERICA
          ? NA_LABELS
          : EUR_LABELS;

      this.locationData$.pipe(first()).subscribe((locationData) => {
        const location =
          locationData.find(
            (location) => location.continent === this.continent$.getValue()
          ) || locationData[0];
        const region =
          location.regions.find(
            (region) => region.value === this.region$.getValue()
          ) || location.regions[0];
        this.renewableTypes = this.getRenewablesTypesBasedOnLocation(
          region,
          this.continent$.getValue()
        );
      });
      this.validateSelectedRenewableType();
    }
  }

  private getRenewablesGraphEnsembleSpreads(): void {
    combineLatest([this.continent$, this.region$])
      .pipe(
        filter(
          ([continent, region]) =>
            !!region && !!continent && !this.isNa(continent)
        ),
        switchMap(([, region]) =>
          this.eurRenewablesService.getGraphRenewables$(region)
        ),
        map(({ data }) => this.eurRenewablesService.buildEnsembleSpreads(data)),
        takeUntil(this.destroyed$)
      )
      .subscribe((ensembleSpreads) => {
        this.ensembleSpreadList = ensembleSpreads;
        this.ensembleSpreadDropdownOptions =
          this.eurRenewablesService.buildEnsembleSpreadDropdownOptions(
            ensembleSpreads
          );
        this.defineSelectedEnsembleSpread(ensembleSpreads);
      });
  }

  private defineSelectedEnsembleSpread(
    ensembleSpreads: RenewablesEnsSpread[]
  ): void {
    let ensembleSpread = this.data?.isNew
      ? this.selectedEnsembleSpread
      : this.data?.widgetConfig?.ensSpread;

    if (
      !ensembleSpread ||
      !ensembleSpreads.map(({ value }) => value).includes(ensembleSpread.value)
    ) {
      ensembleSpread =
        this.eurRenewablesService.renewablesDefaultEnsembleSpreads;
    }

    this.selectedEnsembleSpread = ensembleSpread;
  }

  private isNa(continent: string): boolean {
    return continent === continents.NORTH_AMERICA;
  }

  private validateProduct$() {
    combineLatest([this.continent$, this.selectedRenewablesType$, this.region$])
      .pipe(
        distinctUntilChanged(
          ([, prevProduct], [, currProduct]) => prevProduct === currProduct
        ),
        filter(([continent]) => continent === continents.NORTH_AMERICA),
        switchMap(([, product, region]) =>
          this.renewablesNetworkService.getWindFarmPools(region, product)
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((data) => {
        if (!data || !data.aggregates?.length) return;

        const firstAggregate = lodashFirst(data.aggregates) as WindfarmPool;
        const poolIdPrefix = lodashFirst(
          firstAggregate.poolId.split('_')
        ) as string;
        const region = this.getRegionData(poolIdPrefix);
        const isSolar = this.selectedRenewablesType$.getValue() === 'solar';
        this.handleLocationChange({
          continent: continents.NORTH_AMERICA,
          region: {
            ...firstAggregate,
            value: poolIdPrefix,
            labelKey: poolIdPrefix,
            isWindAvailable: isSolar || !!region?.isSolarAvailable,
            isSolarAvailable: isSolar || !!region?.isWindAvailable,
          },
          pool: {
            ...firstAggregate,
            value: poolIdPrefix,
          },
          isAggregate: true,
        });
      });
  }

  private getRegionData(name: string) {
    if (this.locationData) {
      const regions =
        this.locationData.find(
          (location) => location.continent === continents.NORTH_AMERICA
        )?.regions || this.locationData[0].regions;
      return regions.find(
        (region: RenewableRegionData) => region.value === name
      );
    }
    return null;
  }
}
