import {
  SAVE_TO_PARENT_GENERICS,
  SAVE_TO_PARENT_URLS,
} from './../../constants/save-to-parent-urls.constant';
import { forkJoin, switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserSettingsState } from './user-settings.reducer';
import * as UserSettingsActions from './user-settings.actions';
import { Router } from '@angular/router';
import { UserSettingService } from '../../user-setting.service';
import { UserSettingApiService } from '../../user-settings-api.service';
import { Units } from '@firebird-web/user-settings-store';
import { AuthService } from 'libs/acl/src/lib/auth.service';

@Injectable()
export class UserSettingsEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsActions.initUserSettings),
      switchMap(() =>
        forkJoin([
          this.userSettingApiService.getUnitSettings(),
          this.userSettingApiService.getUserSettings(),
        ])
      ),
      switchMap(
        ([units, userSettings]: [Units, Partial<UserSettingsState>]) => {
          const url = this.userSettingsService
            .getCurrentLocationUrlArray()
            .join('/');
          userSettings = {
            homePage: userSettings?.homePage || [],
            units: units || {},
            ...userSettings,
          };
          const genericUrlKey = Object.keys(SAVE_TO_PARENT_GENERICS).find(
            (key) => `/${url}`.includes(key)
          ) as keyof typeof SAVE_TO_PARENT_GENERICS;
          const saveToParentUrl = SAVE_TO_PARENT_URLS.get(
            genericUrlKey ? SAVE_TO_PARENT_GENERICS[genericUrlKey] : url
          );
          return [
            UserSettingsActions.loadUserSettingsSuccess({ userSettings }),
            UserSettingsActions.changeRouterPersistence({
              path: saveToParentUrl ? saveToParentUrl : url,
              value: this.userSettingsService.getCurrentLocationQueryParams(),
            }),
          ];
        }
      )
    )
  );

  changeQueryParamsPersistence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsActions.changeQueryParamsPersistence),
      tap(({ value }) => {
        this.router.navigate([], {
          queryParamsHandling: 'merge',
          queryParams: value,
          replaceUrl: true,
        });
      }),
      switchMap(({ path, value }) => [
        UserSettingsActions.changeRouterPersistence({ path, value }),
      ])
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly userSettingsService: UserSettingService,
    private readonly userSettingApiService: UserSettingApiService,
    private readonly authService: AuthService
  ) {}
}
