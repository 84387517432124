import { createAction, props } from '@ngrx/store';
import { RegionsEntity } from './regions.models';

export const loadRegions = createAction(
  '[Regions Store] Load Regions',
  props<{ selectedContinentId: string }>()
);

export const loadRegionsSuccess = createAction(
  '[Regions Store] Load Regions Success',
  props<{ regions: RegionsEntity[] }>()
);

export const loadRegionsFailure = createAction(
  '[Regions Store] Load Regions Failure',
  props<{ error: any }>()
);
