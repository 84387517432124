import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MODEL_STORE_FEATURE_KEY } from '../model-store.reducer';
import {
  modelConfigsAdapter,
  modelDataAdapter,
  modelRunsAdapter,
} from '../model-store.adapters';
import { ModelRun } from '../../models/model-page.types';
import { PeriodMatrixData, ModelStoreState } from '../model-store.models';
import { getConfigs } from './helpers';

export const getModelStoreState = createFeatureSelector<ModelStoreState>(
  MODEL_STORE_FEATURE_KEY
);

const { selectAll: selectAllModels } = modelDataAdapter.getSelectors();
const { selectAll: selectAllConfigs } = modelConfigsAdapter.getSelectors();
const { selectAll: selectAllRuns } = modelRunsAdapter.getSelectors();

const getModelDataState = createSelector(
  getModelStoreState,
  (state: ModelStoreState) => state.periodMatrixData
);

export const getModelDataLoaded = createSelector(
  getModelDataState,
  (state: PeriodMatrixData) => state.loaded
);

export const getPeriodMatrixInUse = createSelector(
  getModelDataState,
  (state: PeriodMatrixData) => state.inUse
);

export const getModelStoreError = createSelector(
  getModelDataState,
  (state: PeriodMatrixData) => state.error
);

export const getAllModelStore = createSelector(
  getModelDataState,
  (state: PeriodMatrixData) => ({
    main: selectAllModels(state.main),
    diff: selectAllModels(state.diff),
  })
);

export const getPeriodMatrixTimeDiffStore = createSelector(
  getModelDataState,
  (state: PeriodMatrixData) => state.selectedTimeDiff
);

export const getPeriodMatrixConfigs = createSelector(
  getModelDataState,
  (state: PeriodMatrixData) => selectAllConfigs(state.configs)
);

export const getPeriodMatrixRuns = createSelector(
  getModelDataState,
  (state: PeriodMatrixData) => selectAllRuns(state.runs)
);

export const getPeriodMatrixSelectedConfigArray = createSelector(
  getModelDataState,
  (state: PeriodMatrixData) => getConfigs(state.selectedConfigs)
);

export const getPeriodMatrixSelectedConfigs = createSelector(
  getModelDataState,
  (state: PeriodMatrixData) => state.selectedConfigs
);

const getPeriodMatrixRunIndex = createSelector(
  getModelDataState,
  getPeriodMatrixRuns,
  (state: PeriodMatrixData, allRuns: ModelRun[]) => {
    const selectedRun = state.selectedConfigs?.run;
    if (!selectedRun) {
      return null;
    }
    return allRuns.findIndex(({ id }) => id === selectedRun.id);
  }
);

export const getPeriodMatrixNext5DaysRun = createSelector(
  getModelDataState,
  getPeriodMatrixRuns,
  (state: PeriodMatrixData, allRuns: ModelRun[]) => {
    const selectedRun = state.selectedConfigs?.run;
    if (!selectedRun) {
      return null;
    }
    const runDate = new Date(selectedRun.id);
    runDate.setDate(runDate.getDate() + 5);
    return allRuns.find(
      ({ id }) => new Date(id).toISOString() === runDate.toISOString()
    );
  }
);

export const getPeriodMatrixPrevious5DaysRun = createSelector(
  getModelDataState,
  getPeriodMatrixRuns,
  (state: PeriodMatrixData, allRuns: ModelRun[]) => {
    const selectedRun = state.selectedConfigs?.run;
    if (!selectedRun) {
      return null;
    }
    const runDate = new Date(selectedRun.id);
    runDate.setDate(runDate.getDate() - 5);
    return allRuns.find(
      ({ id }) => new Date(id).toISOString() === runDate.toISOString()
    );
  }
);

export const getPeriodMatrixNextRun = createSelector(
  getPeriodMatrixRunIndex,
  getPeriodMatrixRuns,
  (currentRunIndex: number | null, allRuns: ModelRun[]) => {
    return typeof currentRunIndex === 'number' && currentRunIndex > 0
      ? allRuns[currentRunIndex - 1]
      : null;
  }
);

export const getPeriodMatrixPreviousRun = createSelector(
  getPeriodMatrixRunIndex,
  getPeriodMatrixRuns,
  (currentRunIndex: number | null, allRuns: ModelRun[]) => {
    return typeof currentRunIndex === 'number' &&
      currentRunIndex < allRuns.length
      ? allRuns[currentRunIndex + 1]
      : null;
  }
);
