<div class="location-selector">
  <header>
    <h3>{{ 'selectLocation' | translate }}</h3>
    <button
      mat-icon-button
      mat-dialog-close
      type="reset"
      aria-label="Close dialog"
    >
      <firebird-web-icon
        class="close-icon"
        name="cross"
        width="12px"
      ></firebird-web-icon>
    </button>
  </header>
  <mat-divider></mat-divider>
  <div class="selection-section">
    <firebird-web-dropdown
      type="variation-five"
      class="multi-dd-start widget-panel-smaller"
      [options]="continents$ | async"
      [value]="selectedContinent$ | async"
      (changeEvent)="setContinent($event.value)"
    >
    </firebird-web-dropdown>
    <ng-container *ngIf="(selectedContinent$ | async) === 'NA'">
      <p>{{ 'region' | translate }}</p>
      <firebird-web-dropdown
        type="variation-five"
        class="multi-dd-start widget-panel-smaller"
        [options]="regions$ | async"
        [value]="selectedRegion$ | async"
        (changeEvent)="selectedRegion$.next($event.value)"
      >
      </firebird-web-dropdown>
    </ng-container>
  </div>
  <mat-divider></mat-divider>
  <section>
    <div *ngIf="(selectedContinent$ | async) === 'EUR'" class="regions-section">
      <div *ngIf="regions$ | async as regions">
        <ul>
          <li
            *ngFor="let region of regions; let i = index"
            role="button"
            tabindex="0"
            (click)="selectRegion(region)"
            (keydown.enter)="selectRegion(region)"
            (keydown.space)="selectRegion(region)"
            [hasViewPermission]="region.isPermitted"
          >
            {{ region.labelKey }}
          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="(selectedContinent$ | async) === 'NA'" class="regions-section">
      <p>{{ 'selectAggregateOrEnter' | translate }}</p>
      <div *ngIf="regions$ | async as regions">
        <p class="regions-section__section-title">
          {{
            (product || '' | translate) +
              ' ' +
              ((product === windProductType
                ? 'renewablesFarmAggregates'
                : 'renewablesAggregates'
              ) | translate)
          }}
          ({{ aggregatesDataSource.data.length }})
        </p>
        <div class="picker-wrapper">
          <firebird-web-picker-table
            [dataSource]="aggregatesDataSource"
            [colDefs]="aggregatesColumns"
            [isSort]="true"
            [activeSort]="aggregatesColumns[0].name"
            [sortDirection]="'desc'"
            [sortDisableClear]="true"
            [selectedRow]="selectedPool"
            (rowSelected)="onPoolChange($event)"
          ></firebird-web-picker-table>
        </div>
        <div class="autocomplete-container" *ngIf="!!this.farms?.length">
          <p class="regions-section__section-title">
            {{ 'renewablesWindFarms' | translate }} ({{
              subPoolsDataSource.length
            }})
          </p>
          <mat-form-field floatLabel="never" class="autocomplete-searchbox">
            <mat-label>Search Farms</mat-label>
            <input
              type="text"
              matInput
              [matAutocomplete]="auto"
              (keyup)="onSearchChange($event)"
            />
            <firebird-web-icon
              class="search-icon"
              name="search"
              width="12px"
              height="12px"
            >
            </firebird-web-icon>
          </mat-form-field>

          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="onOptionPoolSelect($event)"
          >
            <mat-option
              *ngFor="let farm of filteredFarms; trackBy: trackByFn"
              [value]="farm"
            >
              {{ farm }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </div>
  </section>
</div>
