export const constants = {
  NOT_AVAILABLE: 'n/a',
  FRISK_NOT_AVAILABLE: '--',
  NOT_AVAILABLE_YET: '-',
  NULL: '!!!',
};

export const continents = {
  NORTH_AMERICA: 'NA',
  EUROPE: 'EUR',
  MEXICO: 'MEX',
  AUSTRALIA: 'AUS',
  ASIA: 'ASIA',
};

export const continentsNames = {
  NORTH_AMERICA: 'North America',
  EUROPE: 'Europe',
  MEXICO: 'Mexico',
  AUSTRALIA: 'Australia',
  ASIA: 'Asia',
};

export const diffTags = {
  minFeelsLike: 'minFeelsLikeDiff',
  maxFeelsLike: 'maxFeelsLikeDiff',
  peakMin: 'offPeakMinDiff',
  peakMax: 'onPeakMaxDiff',
  minTemp: 'minTempDiff',
  maxTemp: 'maxTempDiff',
  hdd: 'hddDiff',
  cdd: 'cddDiff',
  gasDayMin: 'gasDayMinTempDiff',
  gasDayMax: 'gasDayMaxTempDiff',
};
export const dfnTags = {
  aggMinTemp: 'aggMinTemp',
  aggMaxTemp: 'aggMaxTemp',
  minTemp: 'minTemp',
  maxTemp: 'maxTemp',
  offPeakMin: 'offPeakMin',
  onPeakMax: 'onPeakMax',
  gasDayMinTemp: 'gasDayMinTemp',
  gasDayMaxTemp: 'gasDayMaxTemp',
};

export const cellTags = {
  aggMinTemp: 'aggMinTemp',
  aggMaxTemp: 'aggMaxTemp',
  minTemp: 'minTemp',
  maxTemp: 'maxTemp',
  onPeakMax: 'onPeakMax',
  offPeakMin: 'offPeakMin',
  minFeelsLike: 'minFeelsLike',
  maxFeelsLike: 'maxFeelsLike',
  gasDayMinTemp: 'gasDayMinTemp',
  gasDayMaxTemp: 'gasDayMaxTemp',
};
export const MIN_REQUIRED_HEIGHT = 1000;
