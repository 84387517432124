<div class="container">
  <div class="item">
    <div class="item-title">Locations</div>
    <div class="item-value" (click)="changeLocation()">
      <span
        >{{ panelState.selectedContinent | titlecase }}
        <firebird-web-icon name="chevron-right" height="11px" width="11px">
        </firebird-web-icon>
        {{ panelState.selectedRegion }}
        <firebird-web-icon name="chevron-right" height="11px" width="11px">
        </firebird-web-icon>
        {{ panelState.selectedCity }}
        <firebird-web-icon
          name="chevron-down"
          height="11px"
          width="11px"
        ></firebird-web-icon
      ></span>
    </div>
  </div>

  <div class="item">
    <div class="item-title">Climatology</div>
    <div class="item-value">
      <firebird-web-dropdown
        labelKey="label"
        class="multi-dd-start widget-panel"
        [options]="secondaryLensDataSource"
        [value]="panelState.selectedSecondaryLens"
        (changeEvent)="changeSecondaryLens($event)"
        type="variation-five"
      ></firebird-web-dropdown>
    </div>
  </div>

  <div class="item">
    <div class="item-title"></div>
    <div class="item-value">
      <firebird-web-checkbox
        (changeEvent)="changeDiffPrior($event)"
        labelKey="Differences from prior forecast"
        [checked]="panelState.diffPriorValue"
      >
      </firebird-web-checkbox>
    </div>
  </div>
  <div class="item" *ngIf="tempUnit === cUnit">
    <div class="item-title"></div>
    <div class="item-value">
      <firebird-web-checkbox
        (changeEvent)="showDecimalValues($event)"
        labelKey="Decimals"
        [checked]="panelState.showDecimalValue"
      >
      </firebird-web-checkbox>
    </div>
  </div>

  <!-- <div class="item" *ngIf="data.isNew">
    <div class="item-title">Widget Size</div>
    <div class="item-value">
      <firebird-web-dropdown
        labelKey="label"
        class="multi-dd-start widget-panel"
        [options]="sumTableSizes"
        [value]="panelState.selectedSumTableSize"
        (changeEvent)="changeSize($event)"
        type="variation-five"
      ></firebird-web-dropdown>
    </div>
  </div> -->

  <div class="close-save">
    <span class="save" (click)="saveWidget()">Save</span>
    <span class="cancel" (click)="closeWidget()">X-Cancel</span>
  </div>
</div>
