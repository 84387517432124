import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as NotificationsStoreActions from './notifications-store.actions';
import {
  INotificationEmail,
  NotificationsStoreEntity,
} from './notifications-store.models';
import { IAlert } from 'libs/alerts-notifications/src/lib/interfaces/alert.interface';
import { INotification } from 'libs/alerts-notifications/src/lib/interfaces/notification.interface';

export const NOTIFICATIONS_STORE_FEATURE_KEY = 'notificationsStore';

export interface NotificationsStoreState
  extends EntityState<NotificationsStoreEntity> {
  selectedId?: string | number; // which NotificationsStore record has been selected
  loaded: boolean; // has the NotificationsStore list been loaded
  error?: string | null; // last known error (if any)
  emails: INotificationEmail[];
  notifications: INotification[];
  alerts: IAlert[];
  isAlertWindowOpen: boolean;
}

export interface NotificationsStorePartialState {
  readonly [NOTIFICATIONS_STORE_FEATURE_KEY]: NotificationsStoreState;
}

export const notificationsStoreAdapter: EntityAdapter<NotificationsStoreEntity> =
  createEntityAdapter<NotificationsStoreEntity>();

export const initialNotificationsStoreState: NotificationsStoreState =
  notificationsStoreAdapter.getInitialState({
    emails: [],
    notifications: [],
    alerts: [],
    loaded: false,
    isAlertWindowOpen: false,
  });

const reducer = createReducer(
  initialNotificationsStoreState,
  on(
    NotificationsStoreActions.loadNotificationsStoreFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(
    NotificationsStoreActions.loadEmailNotificationsSuccess,
    (state, { emails }) => ({
      ...state,
      emails,
    })
  ),
  on(
    NotificationsStoreActions.loadNotificationsSuccess,
    (state, { notifications }) => ({
      ...state,
      notifications,
    })
  ),
  on(NotificationsStoreActions.loadAlertsSuccess, (state, { alerts }) => ({
    ...state,
    alerts,
  })),
  on(NotificationsStoreActions.changeAlertWindowOpen, (state, { isOpen }) => ({
    ...state,
    isAlertWindowOpen: isOpen,
  }))
);

export function notificationsStoreReducer(
  state: NotificationsStoreState | undefined,
  action: Action
) {
  return reducer(state, action);
}
