import { EventEmitter, Injectable } from '@angular/core';
import {
  IPopOverPosition,
  WindowSpaceList,
} from '@firebird-web/shared-interfaces';
import { BehaviorSubject } from 'rxjs';
import { CommonUtils } from '@firebird-web/shared-utils';
import { CreateWindowSpaceList, DefaultPopupPosition } from './helpers';

@Injectable({
  providedIn: 'root',
})
export class AgGridService {
  public removeRelativeStyle = new EventEmitter();
  public closePopup = new EventEmitter();
  public clickTriggered = new EventEmitter();
  public showDecimalsCelsius = new BehaviorSubject(true);
  public tabDailyForecastsData = new EventEmitter();
  public emitRunDateCheckbox = new EventEmitter();
  private popOverPositions: IPopOverPosition = DefaultPopupPosition;

  /**
   * initialPopOverPosition
   */
  get initialPopOverPosition(): IPopOverPosition {
    return this.popOverPositions;
  }

  public getDecimalValue(value: number): string | number {
    return CommonUtils.transformToDecimalValue(
      this.getCelsiusDecimals(),
      value
    );
  }
  public getWidgetDecimalValue(
    value: number,
    showDecimal: boolean
  ): string | number {
    return CommonUtils.transformToDecimalValue(showDecimal, value);
  }

  public getPopOverPosition(
    elementPositionX: number,
    elementPositionY: number,
    popupWidth: number,
    popupHeight: number
  ): IPopOverPosition {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const position = DefaultPopupPosition;
    const windowSpaceList: WindowSpaceList = CreateWindowSpaceList(
      windowWidth,
      windowHeight
    );

    const isLeftPosition =
      elementPositionX >= windowSpaceList.leftSpace.startPosition &&
      elementPositionX <= windowSpaceList.leftSpace.endPosition;
    const isRightPosition =
      elementPositionX >= windowSpaceList.rightSpace.startPosition &&
      elementPositionX <= windowSpaceList.rightSpace.endPosition;
    const isTopPosition =
      elementPositionY >= windowSpaceList.topSpace.startPosition &&
      elementPositionY <= windowSpaceList.topSpace.endPosition;
    const isBottomPosition =
      elementPositionY >= windowSpaceList.bottomSpace.startPosition &&
      elementPositionY <= windowSpaceList.bottomSpace.endPosition;

    position.left = isLeftPosition ? elementPositionX + 20 : 0;
    position.right = isRightPosition
      ? windowSpaceList.rightSpace.endPosition - elementPositionX + 20
      : 0;
    position.top = isTopPosition
      ? windowSpaceList.topSpace.endPosition - elementPositionY <= popupHeight
        ? Math.abs(popupHeight - elementPositionY) + 40
        : elementPositionY
      : 0;
    position.bottom = isBottomPosition
      ? windowSpaceList.bottomSpace.endPosition - elementPositionY <=
        popupHeight
        ? (windowSpaceList.bottomSpace.endPosition - elementPositionY) / 2 + 20
        : windowSpaceList.bottomSpace.endPosition - elementPositionY
      : 0;

    return position;
  }
  /**
   * getCalcPopOverPosition
   * @param pageClickEvent
   * @returns
   */
  getCalcPopOverPosition(
    pageX: number,
    pageY: number,
    width: number,
    height: number,
    isProbFriskIndex?: boolean
  ): IPopOverPosition {
    const x = pageX;
    const y = pageY;
    const totalWidth = x + width;
    const totalHeight = y + height;

    let rightVal =
      totalWidth > window.innerWidth ? window.innerWidth - x + 20 : 0;
    let leftVal = totalWidth < window.innerWidth ? x + 20 : 0;
    const bottomVal =
      totalHeight > window.innerHeight ? window.innerHeight - y : 0;
    const topVal = totalHeight < window.innerHeight ? y : 0;

    if (isProbFriskIndex) {
      //Set default left and right value for center the popup
      const defaultCenterValue = 109;
      rightVal =
        totalWidth > window.innerWidth
          ? window.innerWidth - x - defaultCenterValue
          : 0;
      leftVal = x - defaultCenterValue;
    }
    this.popOverPositions = {
      top: topVal,
      left: leftVal,
      right: rightVal,
      bottom: bottomVal,
    };

    return this.popOverPositions;
  }

  getCelsiusDecimals() {
    return this.showDecimalsCelsius.value;
  }
  /**
   * getExceedanceValue
   * @param value
   * @returns number
   */
  getExceedanceValue(value: number): number {
    switch (value) {
      case 0.1: {
        return 0;
      }
      case 99: {
        return 100;
      }
      default: {
        return value;
      }
    }
  }
}
