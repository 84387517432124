import { UserSettingApiService } from '../user-settings-api.service';
import { MetaReducer } from '@ngrx/store';
import {
  saveToServerWithDebounce,
  saveToServer,
} from '@firebird-web/user-settings-store';

export const getMetaReducers = (
  service: UserSettingApiService
): MetaReducer<any> => {
  return saveToServer(service);
};

export const getSettingsMetaReducers = (
  service: UserSettingApiService
): MetaReducer<any> => {
  return saveToServerWithDebounce(service);
};
