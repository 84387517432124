import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@firebird-web/shared-config';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { map, Observable } from 'rxjs';
import { RunDatesEntity } from '@firebird-web/run-dates-store';
import { ModelRun, ModelRunResponse } from '@firebird-web/model-store';
import { ServerResponse } from '@firebird-web/shared-interfaces';

@Injectable({
  providedIn: 'root',
})
export class WidgetApiService {
  constructor(private readonly http: HttpClient) {}

  public getRunDates(
    continent: string
  ): Observable<Array<Pick<RunDatesEntity, 'initTime' | 'type'>>> {
    return this.http
      .get<{
        data: Array<Pick<RunDatesEntity, 'initTime' | 'type'>>;
        status: string;
        version: string;
      }>(
        `${environment.apiDomain}/${apiEndpoints.getSummaryRunDates}/${continent}?sorting=business`
      )
      .pipe(map(({ data }) => data));
  }

  public getMostUpToDateModelRun(
    modelId: string,
    parameter: string,
    period: string
  ): Observable<ModelRun> {
    const subLink = `api/v1/ModelPage/single-panel-runs/${modelId}/${parameter}/${period}`;
    const link = `${environment.apiDomain}/${subLink}`;

    return this.http.get<ServerResponse<ModelRunResponse[]>>(link).pipe(
      map(
        ({
          data: [{ display_name: title, ...rest } = {} as ModelRunResponse],
        }) =>
          ({
            ...rest,
            title,
          } as ModelRun)
      )
    );
  }
}
