<div class="notification-email component">
  <p class="email-label">Email Address {{ emailNumber + 1 }}</p>
  <span class="email">{{ email }}</span>
  <span class="icon-button" (click)="emailDialog(email, 'Edit')">
    <mat-icon>edit_outline</mat-icon>
  </span>
  <span
    class="icon-button"
    *ngIf="isAddAllowed"
    (click)="emailDialog('', 'Add')"
  >
    <mat-icon>add_circle_outline</mat-icon>
  </span>
  <span class="icon-button" *ngIf="!isAddAllowed" (click)="deleteEmail(email)">
    <mat-icon>delete_outline</mat-icon>
  </span>
</div>
