import { createReducer, on, Action } from '@ngrx/store';

import * as SettingsActions from './settings.actions';
import { FavoriteList } from '../../models/favorite-locations.types';

export const SETTINGS_FEATURE_KEY = 'settings';

type Continent = any;

export interface SettingsState {
  favoriteLists: {
    loaded: boolean;
    error?: string | null;
    data: FavoriteList[];
  };
  continents: Continent[];
  selectedContinent: Continent;
  regions: any[];
}

export interface SettingsPartialState {
  readonly [SETTINGS_FEATURE_KEY]: SettingsState;
}

export const initialSettingsState: SettingsState = {
  favoriteLists: {
    loaded: false,
    data: [],
  },
  continents: [],
  regions: [],
  selectedContinent: undefined,
};

const reducer = createReducer(
  initialSettingsState,
  on(SettingsActions.initSettings, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),

  on(SettingsActions.loadCitiesSuccess, (state, { regions, continents }) => ({
    ...state,
    continents,
    regions,
  })),
  on(SettingsActions.loadListsSuccess, (state, { favoriteLists }) => ({
    ...state,
    favoriteLists: {
      loaded: true,
      data: favoriteLists,
    },
  })),
  on(SettingsActions.createListSuccess, (state, { list }) => ({
    ...state,
    favoriteLists: {
      loaded: true,
      data: [...state.favoriteLists.data, list],
    },
  })),

  on(SettingsActions.saveListSuccess, (state, { list }) => ({
    ...state,
    favoriteLists: {
      ...state.favoriteLists,
      data: state.favoriteLists.data.map((existedList) => {
        if (list.id !== existedList.id) {
          return existedList;
        }
        return list;
      }),
    },
  })),

  on(SettingsActions.setSelectedContinent, (state, { selectedContinent }) => ({
    ...state,
    selectedContinent,
  })),

  on(SettingsActions.deleteListSuccess, (state, { id }) => ({
    ...state,
    favoriteLists: {
      ...state.favoriteLists,
      data: state.favoriteLists.data.filter(
        ({ id: existedId }) => id !== existedId
      ),
    },
  })),
  on(SettingsActions.copyListSuccess, (state, { lists }) => ({
    ...state,
    favoriteLists: {
      ...state.favoriteLists,
      data: [...lists],
    },
  }))
);

export function settingsReducer(
  state: SettingsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
