import { get } from 'lodash';
import { LocationSelectionDialogResult } from './location.interface';
import { ContinentsEntity } from '@firebird-web/continents-store';

export const extractLocationSelectionDialogResult = (
  result: LocationSelectionDialogResult
): Required<Omit<LocationSelectionDialogResult, 'customList'>> &
  Pick<LocationSelectionDialogResult, 'customList'> => ({
  isCustomList: get(result, 'isCustomList', false),
  continent: get(result, 'continent', {} as ContinentsEntity),
  region: get(result, 'region', ''),
  customList: get(result, 'customList', undefined),
});
