import {
  IPopOverPosition,
  WindowSpaceList,
} from '@firebird-web/shared-interfaces';

export const DefaultPopupPosition: IPopOverPosition = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export const CreateWindowSpaceList = (
  windowWidth: number,
  windowHeight: number
): WindowSpaceList => {
  return {
    topSpace: {
      startPosition: 0,
      endPosition: windowHeight / 2,
    },
    rightSpace: {
      startPosition: windowWidth / 2,
      endPosition: windowWidth,
    },
    leftSpace: {
      startPosition: 0,
      endPosition: windowWidth / 2,
    },
    bottomSpace: {
      startPosition: windowHeight / 2,
      endPosition: windowHeight,
    },
  };
};
