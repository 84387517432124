export enum LocationPickerActionTypes {
  INIT = '[LocationPicker] Init',
  REINIT = '[LocationPicker] ReInit',
  INIT_SUCCESS = '[LocationPicker] Init State Success',
  INIT_FAILURE = '[LocationPicker] Init State Failure',
  INIT_DATA_FOR_CURRENT_PAGE = '[LocationPicker/Hydrate] Init Location Picker For Current Page',
  SAVE_DATA_FOR_REGION = '[LocationPicker/Hydrate] Save Data For Region Location Picker',
  SAVE_DATA_FOR_CUSTOM_LIST = '[LocationPicker/Hydrate] Save Data For Custom List Location Picker',
  SAVE_DATA_FOR_CITY = '[LocationPicker/Hydrate] Save Data For City Location Picker',
  INIT_CITY_DATA = '[LocationPicker/Hydrate] Initialize city data',
}
