import { TitleCasePipe } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { IDailyForecastDifference } from 'libs/forecast-difference/src/lib/interfaces/daily-forecast-difference.interface';
import { IHourlyForecastDifference } from 'libs/forecast-difference/src/lib/interfaces/hourly-forecast-difference.interface';
import { Subject } from 'rxjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root',
})
export class ChartsService {
  private chartsRespSource = new Subject<any>();

  public chartsResp$ = this.chartsRespSource.asObservable();

  public emitChartsResp = new EventEmitter();
  //Default bar Config
  public defaultBarMainConfig = {
    type: 'line',
    color: 'transparent',
    pointWidth: 8,
    marker: {
      lineColor: 'transparent',
    },
    states: {
      hover: {
        enabled: false,
      },
    },
  };

  //Default bar datalabel config
  public defaultBarDataLabelConfig = {
    enabled: true,
    useHTML: true,
    point: {
      index: 0,
      series: {
        data: [{ plotY: 0 }],
      },
    },
  };

  /**
   * sendChartResp
   * @param message
   */
  sendChartResp(message: any) {
    this.chartsRespSource.next(message);
  }

  /**
   * windbarb icons
   * @param value
   * @returns windbarb icons name as a string
   */
  windBarbIcons(value: number) {
    switch (true) {
      case value >= 0 && value <= 2: {
        return 'windbarb-0';
      }
      case value >= 3 && value < 9: {
        return 'windbarb-5';
      }
      case value >= 9 && value < 15: {
        return 'windbarb-10';
      }
      case value >= 15 && value < 21: {
        return 'windbarb-15';
      }
      case value >= 21 && value < 26: {
        return 'windbarb-20';
      }
      case value >= 26 && value < 32: {
        return 'windbarb-25';
      }
      case value >= 32 && value < 38: {
        return 'windbarb-30';
      }
      case value >= 38 && value < 55: {
        return 'windbarb-35';
      }
      case value >= 55 && value < 61: {
        return 'windbarb-50';
      }
      case value >= 61 && value < 67: {
        return 'windbarb-55';
      }
      case value >= 67 && value < 73: {
        return 'windbarb-60';
      }
      case value >= 73 && value < 113: {
        return 'windbarb-65';
      }
      case value >= 113 && value < 119: {
        return 'windbarb-100';
      }
      case value >= 119 && value <= 123: {
        return 'windbarb-105';
      }
      default: {
        return 'windbarb-0';
      }
    }
  }

  /**
   * percipCustomBarConfig
   * @param color
   * @param left
   */
  percipCustomBarConfig(color: string, left = -5, width = '8px') {
    const config = {
      ...this.defaultBarMainConfig,
      dataLabels: {
        ...this.defaultBarDataLabelConfig,
        formatter: function () {
          const index = this.point.index;
          const chartWidth = 180;
          const positionTop = this.point.series.data[index].plotY;
          const getHeight = chartWidth - positionTop;
          return `<div style='width:${width}; background-color:${color}; position: absolute; left: ${left}px;
              top: 6px;  bottom:-${getHeight}px;  height:${getHeight}px;'></div>`;
        },
      },
    };
    return config;
  }

  /**
   * foreacstDiffCustombarConfig
   * @param color1
   * @param color2
   * @returns
   */
  foreacstDiffCustombarConfig(color1: string, color2: string) {
    const config = {
      ...this.defaultBarMainConfig,
      dataLabels: {
        ...this.defaultBarDataLabelConfig,
        className: 'forecast-charts-labels',
        y: 0,
        formatter: function () {
          const index = this.point.index;
          const chartWidth = 33;
          const positionTop = this.point.series.data[index].plotY;
          const getHeight = Math.abs(chartWidth - positionTop);
          const defaultCss =
            'width: 12px; position: absolute; transform: translateX(-50%);';
          if (this.y > 0) {
            return `<div style='background-color:${color1}; top:${positionTop}px; height:${getHeight}px; ${defaultCss}'></div>`;
          } else if (this.y < 0) {
            return `<div style='background-color:${color2}; top:32px; height:${getHeight}px; ${defaultCss}'></div>`;
          } else {
            return '';
          }
        },
      },
    };

    return config;
  }

  /**
   * getForecastDifferenceDateType
   * @param data
   * @returns
   */
  public getForecastDifferenceDateType(
    data: IDailyForecastDifference | IHourlyForecastDifference
  ): string {
    return this.titlecase.transform(data?.type || '');
  }

  /**
   * getMaxValue
   * @param titlecase
   */
  getMaxValue(arr: number[]) {
    return Math.max(...arr);
  }

  /**
   * getClosestValue
   * @param value
   * @param arr
   * @returns
   */
  public getClosestValue(value: number, arr: number[]): number {
    const closest = arr.reduce(function (prev: number, curr: number) {
      return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
    });
    return closest;
  }

  /**
   * downloadChartsData
   * @param value
   * @param id
   * @param filename
   * @param ext
   */
  public downloadChartsData(
    value: string,
    id: string,
    filename = '',
    ext = ''
  ) {
    const getId: any = document.querySelector(id);
    const width = getId.offsetWidth;
    const height = getId.offsetHeight;
    html2canvas(getId).then((canvas) => {
      const pdf = new jsPDF('landscape', 'mm', [width, height]);
      const imageData = canvas.toDataURL('image/png');
      switch (value) {
        case 'print': {
          const printWindow: any = window.open('', '_blank');
          printWindow.document.open();
          printWindow.document.write(
            '<img src="' + imageData + '" style="width:100%;">'
          );
          printWindow.document.close();
          setTimeout(() => {
            printWindow.print();
            printWindow.close();
          });
          break;
        }
        case 'image': {
          const screenshot = canvas.toDataURL(`image/${ext}`);
          const link = document.createElement('a');
          link.href = screenshot;
          link.download = filename;
          link.click();
          break;
        }
        case 'pdf': {
          pdf.addImage(imageData, 'PNG', 0, 0, width, height);
          pdf.save(filename);
          break;
        }
        case 'svg': {
          const finalUrl = this.svgConvert(
            canvas.width,
            canvas.height,
            imageData
          );
          const type = 'image/svg+xml;charset=utf-8';
          const blob = new Blob([finalUrl], { type: type });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          link.click();
        }
      }
    });
  }

  /**
   * svgConvert
   * @param width
   * @param height
   * @param imageData
   * @returns
   */
  public svgConvert(width: number, height: number, imageData: string) {
    const xmlDeclaration =
      '<?xml version="1.0" encoding="UTF-8" standalone="no"?>';
    const svgStart = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">`;
    const svgEnd = '</svg>';
    const imageTag = `<image x="0" y="0" width="${width}" height="${height}" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${imageData}" />`;
    return xmlDeclaration + svgStart + imageTag + svgEnd;
  }

  // constructor
  public constructor(private titlecase: TitleCasePipe) {}
}
