<mat-table
  #table
  [dataSource]="dataSource"
  matSort
  [matSortDisableClear]="sortDisableClear"
  [matSortActive]="activeSort"
  [matSortDirection]="sortDirection"
  class="picker-table"
>
  <ng-container *ngFor="let col of colDefs" [matColumnDef]="col.name">
    <mat-header-cell
      mat-header-cell
      [mat-sort-header]="col.name"
      [disabled]="!col.isSortable || !isSort"
      *matHeaderCellDef
      class="picker-table__table-cell picker-table__table-cell--header"
      [ngClass]="{
        'picker-table__table-cell--right-align': col.name === 'capacity',
        'picker-table__table-cell--left-align': col.name !== 'capacity'
      }"
    >
      {{ col.labelKey | translate }}
    </mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      class="picker-table__table-cell"
      [ngClass]="{
        'picker-table__table-cell--right-align': col.name === 'capacity',
        'picker-table__table-cell--left-align': col.name !== 'capacity'
      }"
    >
      {{ formatCellData(element, col.name) }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="symbol">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      class="picker-table__table-cell picker-table__table-cell--header picker-table__table-cell--symbol"
    >
    </mat-header-cell>
    <mat-cell
      mat-cell
      *matCellDef="let element"
      class="picker-table__table-cell picker-table__table-cell--symbol"
    >
      <mat-icon
        *ngIf="element.id === selectedRow?.id"
        class="picker-table__icon-wrapper"
      >
        <firebird-web-icon
          name="tick"
          class="picker-table__icon"
        ></firebird-web-icon>
      </mat-icon>
    </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="displayedColumns"
    class="picker-table__table-row picker-table__table-header-row"
  ></mat-header-row>
  <mat-row
    matRipple
    *matRowDef="let row; columns: displayedColumns"
    class="picker-table__table-row"
    [ngClass]="{
      'picker-table__table-row--selected': row.id === selectedRow?.id
    }"
    (click)="poolChange(row)"
  ></mat-row>
  <div
    class="picker-table__table-row picker-table__table-row--no-data"
    *matNoDataRow
  >
    <div class="picker-table__table-cell picker-table__table-cell--no-data">
      No data
    </div>
  </div>
</mat-table>
