import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { IDemandPanelState, LoadRegion } from '../../interfaces';
import {
  defaultDemandLocation,
  IWidgetSummaryPanelData,
  WidgetTypes,
} from '../../constants';
import { first as rxjsFirst } from 'rxjs';
import { first as lodashFirst } from 'lodash';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '../../services/dashboard.service';
import { MatSelectChange } from '@angular/material/select';
import { LoadDataService } from 'libs/load/src/lib/services/load-data.service';
import { PermissionsService } from 'libs/acl/src/lib/permissions.service';
@Component({
  selector: 'firebird-web-demand-panel',
  templateUrl: './demand-panel.component.html',
  styleUrls: ['./demand-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemandPanelComponent implements OnInit {
  public demandPanelState: IDemandPanelState = {
    selectedRegion: defaultDemandLocation,
    selectedSubzone: defaultDemandLocation.subZones[0],
    regions: [],
    selectedSubZones: defaultDemandLocation.subZones,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IWidgetSummaryPanelData,
    private dataService: LoadDataService,
    private dialogRef: MatDialogRef<DemandPanelComponent>,
    private dashboardService: DashboardService,
    private cdr: ChangeDetectorRef,
    private permissionService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.populateRegions();
  }

  public regionChange(region: MatSelectChange): void {
    const selectedRegion = this.findSelectedRegion(region.value);
    const { subZones: selectedSubZones } = selectedRegion;

    this.demandPanelState = {
      ...this.demandPanelState,
      selectedRegion,
      selectedSubZones,
      selectedSubzone: lodashFirst(selectedSubZones) as LoadRegion,
    };
  }

  public subZoneChange(subzone: MatSelectChange): void {
    this.demandPanelState.selectedSubzone =
      this.demandPanelState.selectedSubZones.find(
        (subZone) => subZone.locationId === subzone.value
      ) ?? (lodashFirst(this.demandPanelState.selectedSubZones) as LoadRegion);
  }

  public onSave(): void {
    this.dialogRef.close({
      region: this.demandPanelState.selectedRegion,
      subzone: this.demandPanelState.selectedSubzone,
    });
    if (this.data.isNew) {
      this.dashboardService.createWidget(
        {
          region: this.demandPanelState.selectedRegion,
          subzone: this.demandPanelState.selectedSubzone,
          w: 8,
          h: 30,
          isNew: this.data.isNew,
        },
        WidgetTypes.demand
      );
    }
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public getPermission(locationId: string): string[] | undefined {
    return ['Load_Forecasts', locationId.split('_')[0]];
  }

  private populateRegions(): void {
    this.dataService
      .getLocations$()
      .pipe(rxjsFirst())
      .subscribe((data: LoadRegion[]) => {
        this.demandPanelState.regions = data;
        this.assignProperties(data);
        this.cdr.markForCheck();
      });
  }

  private getFirstAllowedRegion(regions: LoadRegion[]): LoadRegion {
    const loadPermissions =
      this.permissionService.getPermissions().View.Load_Forecasts;

    const firstRegion = loadPermissions.ERCOT
      ? 'ERCOT'
      : this.getFirstValidPermissions(loadPermissions);

    return (
      regions.find(
        (region) => region.locationId.split('_')[0] === firstRegion
      ) ?? (lodashFirst(regions) as LoadRegion)
    );
  }

  private assignProperties(data: LoadRegion[]): void {
    const firstRegion = this.getFirstAllowedRegion(data);
    this.demandPanelState.selectedRegion = this.data.isNew
      ? firstRegion
      : (this.data.widgetConfig.region as unknown as LoadRegion);
    this.demandPanelState.selectedSubZones = this.data.isNew
      ? firstRegion.subZones
      : this.demandPanelState.selectedRegion.subZones;
    this.demandPanelState.selectedSubzone = this.data.isNew
      ? firstRegion.subZones[0]
      : this.data.widgetConfig.subzone;
  }

  private getFirstValidPermissions(permissions: {
    [key: string]: boolean;
  }): string {
    const firstEntry = Object.entries(permissions).find(([, value]) => !!value);

    return firstEntry ? firstEntry[0] : '';
  }

  private findSelectedRegion(regionId: string): LoadRegion {
    return (
      this.demandPanelState.regions.find(
        (region: LoadRegion) => region.locationId === regionId
      ) ?? this.demandPanelState.regions[0]
    );
  }
}
