import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MODEL_STORE_FEATURE_KEY } from '../model-store.reducer';
import { modelConfigsAdapter, modelDataAdapter } from '../model-store.adapters';
import { ModelStoreState, MultiPanelData } from '../model-store.models';

const getModelStoreState = createFeatureSelector<ModelStoreState>(
  MODEL_STORE_FEATURE_KEY
);

const { selectAll: selectAllModels } = modelDataAdapter.getSelectors();
const { selectAll: selectAllConfigs } = modelConfigsAdapter.getSelectors();

const getModelDataState = createSelector(
  getModelStoreState,
  (state: ModelStoreState) => state.multiPanelData
);

export const getModelMultiPanelDataLoaded = createSelector(
  getModelDataState,
  (state: MultiPanelData) => state.loaded
);

export const getMultiPanelError = createSelector(
  getModelDataState,
  (state: MultiPanelData) => state.error
);

export const getModelMultiPanelData = createSelector(
  getModelDataState,
  (state: MultiPanelData) => selectAllModels(state.data)
);

export const getMultiPanelConfigs = createSelector(
  getModelDataState,
  (state: MultiPanelData) => selectAllConfigs(state.filterConfigs)
);

export const getMultiPanelSelectedConfigArray = createSelector(
  getModelDataState,
  (state: MultiPanelData) => state.selectedConfigs
);

export const getMultiPanelChartQty = createSelector(
  getModelDataState,
  (state: MultiPanelData) => state.selectedConfigs.length
);

export const getMultiPanelInUse = createSelector(
  getModelDataState,
  (state: MultiPanelData) => state.inUse
);
