import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromNotificationsStore from './+state/notifications-store.reducer';
import { NotificationsStoreEffects } from './+state/notifications-store.effects';
import { NotificationsStoreService } from './services/notifications-store.service';
import { WsService } from 'libs/ws/src/lib/ws.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromNotificationsStore.NOTIFICATIONS_STORE_FEATURE_KEY,
      fromNotificationsStore.notificationsStoreReducer
    ),
    EffectsModule.forFeature([NotificationsStoreEffects]),
  ],
  providers: [NotificationsStoreService, WsService],
})
export class NotificationsStoreModule {}
