import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '../../services/notifications.service';
import { NotificationsFacade } from '@firebird-web/notifications-store';
import { FormControl, Validators } from '@angular/forms';

export interface EmailData {
  email: string;
  type: string;
}

@Component({
  selector: 'firebird-web-notification-email-dialog',
  templateUrl: './notification-email-dialog.component.html',
  styleUrls: ['./notification-email-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NotificationsService],
})
export class NotificationEmailDialogComponent implements OnInit {
  status_code!: string;
  oldEmail!: string;
  saveLabel: string;

  emailControl = new FormControl(this.data.email, [Validators.email]);

  constructor(
    public dialogRef: MatDialogRef<NotificationEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: EmailData,
    private readonly notificationsFacade: NotificationsFacade
  ) {}

  ngOnInit(): void {
    this.oldEmail = this.data.email;
    this.saveLabel = this.data.type === 'Edit' ? 'Save' : this.data.type;
  }

  onSave(): void {
    if (!this.emailControl.valid) {
      return;
    }

    if (this.data.type === 'Edit') {
      this.notificationsFacade.updateNotificationEmail(
        this.oldEmail,
        this.emailControl.value || this.oldEmail
      );
    } else {
      this.notificationsFacade.addNotificationEmail(
        this.emailControl.value || this.oldEmail
      );
    }
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
