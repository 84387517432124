import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { format } from 'date-fns';
import { UtcDatePipe } from '@firebird-web/shared/pipes';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  public constructor(
    private utcDatePipe: UtcDatePipe,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /**
   * parse
   * @desc Parses string date and returns date object
   * @param date
   * @returns
   */
  public parse(date: string) {
    return new Date(date);
  }

  public getMonth(date: string): number {
    const parsedDate: Date = new Date(date);
    return parsedDate.getMonth() + 1;
  }

  public subtractDays(date: string, daysToSubtract: number): number {
    const parsedDate: Date = new Date(date);
    return parsedDate.setDate(parsedDate.getDate() - daysToSubtract);
  }

  public getDate(date: string): number {
    const parsedDate: Date = new Date(date);
    return parsedDate.getDate();
  }

  public getDay(dateString: string): string {
    const day: string = this.utcDatePipe.transform(dateString, 'EE');
    return day?.toUpperCase();
  }

  public addDays(date: string, daysToAdd: number): string {
    const parsedDate = new Date(date);
    parsedDate.setDate(parsedDate.getDate() + daysToAdd);
    return parsedDate.toISOString();
  }

  /**
   * formatDateString
   * @param dateString
   * @param format
   * @returns
   */
  public formatDateString(dateString: string, format: string): string {
    return dateString ? formatDate(dateString, format, this.locale) : '';
  }

  public formatDateStringUTC(dateString: string, format: string): string {
    return dateString ? formatDate(dateString, format, this.locale, 'UTC') : '';
  }

  /**
   * isWeekend
   * @param displayDate
   * @returns
   */
  public isWeekend(displayDate: string): boolean {
    const day = this.getDay(displayDate);
    return day === 'SAT' || day === 'SUN';
  }

  /**
   * getCurrentDate
   * @param dateFormat
   * @returns
   */
  public getFullDate(
    timeStamp: number | string,
    dateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'"
  ) {
    return format(new Date(timeStamp), dateFormat);
  }

  public parseDate(dateString: string): Date {
    const timezoneOffset = new Date().getTimezoneOffset() * 60000; // timezone offset in milliseconds
    return new Date(Date.parse(dateString) + timezoneOffset);
  }

  public parseTime(date: string) {
    return new Date(date).getTime();
  }

  /**
   * getCalucatedDateTimeAndRunValue
   * @param clickType
   * @param operatorType
   * @param dateString
   * @param run
   * @returns
   */
  public getCalucatedDateTimeAndRunValue(
    clickType: string,
    operatorType: string,
    dateTimeString: string,
    run = '00'
  ): { run: string; dateTime: string } {
    const parsedDate =
      run === '00'
        ? new Date(this.parseDate(dateTimeString).setHours(0, 0, 0))
        : new Date(this.parseDate(dateTimeString).setHours(12, 0, 0));
    let newDate = 0;
    if (clickType === 'day') {
      newDate =
        operatorType === 'plus'
          ? parsedDate.setDate(parsedDate.getDate() + 1)
          : parsedDate.setDate(parsedDate.getDate() - 1);
    } else if (clickType === 'twelve') {
      newDate =
        operatorType === 'plus'
          ? parsedDate.setHours(parsedDate.getHours() + 12)
          : parsedDate.setHours(parsedDate.getHours() - 12);
    }
    const hrs = parsedDate.getHours().toString();
    return {
      run: hrs === '0' ? '00' : hrs,
      dateTime: this.getFullDate(newDate),
    };
  }

  /**
   * getMonthDay
   * @param date
   * @returns
   */
  public getMonthDay(date: string, format = 'EE MMM dd') {
    return this.getFullDateUTC(date, format);
  }

  /**
   * getMonthDateRange
   * @param startDate
   * @param endDate
   * @returns
   */
  public getMonthDateRange(startDate: string, endDate: string) {
    if (startDate === endDate) {
      return this.getMonthDay(startDate).toUpperCase();
    } else {
      return `${this.getMonthDay(
        startDate,
        'MMM dd'
      ).toUpperCase()}-${this.getMonthDay(endDate, 'MMM dd').toUpperCase()}`;
    }
  }

  private getFullDateUTC(
    timeStamp: number | string,
    dateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'"
  ) {
    return this.utcDatePipe.transform(new Date(timeStamp), dateFormat);
  }
}
