import {
  CategoryValues,
  DailyPeriodValues,
  ForecastValues,
  RunTimeModelValues,
} from '../enums';
import { DropdownOption } from '../../../../shared/interfaces/src/lib';
import { NaChartSeriesModelRun } from '../../../../renewable/src/lib/modules/na-renewables/enums';

export const filterDropDownValues = [
  {
    labelKey: 'Min/Max Temp',
    value: 'MinMax',
  },
  {
    labelKey: 'Off-Peak Min/On-Peak Max Temp',
    value: 'Peak',
  },
  {
    labelKey: 'Average Temp',
    value: 'AverageTemp',
  },
  {
    labelKey: 'HDD/CDD',
    value: 'DegreeDays',
  },
  {
    labelKey: 'Gas Day',
    value: 'GasDay',
  },
  {
    labelKey: 'Min/Max Feels Like Temp',
    value: 'MinMaxFeelsLike',
  },
  {
    labelKey: 'Max Dewpoint',
    value: 'MaxDewpoint',
  },
  { labelKey: 'Precip Amount', value: 'precipAmount' },
  { labelKey: 'Probability of Precip', value: 'probabilityOfPrecip' },
];

export const primaryLensDropdown = [
  {
    labelKey: 'Forecast',
    value: 'Forecast',
  },
  {
    labelKey: 'Departure From Norms',
    value: 'DepartureFromNorms',
  },
  {
    labelKey: 'Forecast Differences',
    value: 'ForecastDifference',
  },
];

export const primaryLensAvgTempDropdown = [
  {
    labelKey: 'Forecast',
    value: 'Forecast',
  },
  {
    labelKey: 'Departure From Norms',
    value: 'DepartureFromNorms',
  },
  {
    labelKey: 'Forecast Differences',
    value: 'ForecastDifference',
  },
  {
    labelKey: 'Frisk Index',
    value: 'FriskIndex',
  },
];
export const primaryLensPrecipDropdown = [
  {
    labelKey: 'Forecast',
    value: 'Forecast',
  },
  {
    labelKey: 'Forecast Differences',
    value: 'ForecastDifference',
  },
];

export const CUSTOM_LIST = 'Custom List';

export const sumTableSizes = [
  { labelKey: 'Full Width', value: 'full' },
  { labelKey: 'Half Width', value: 'half' },
];
export const secondaryLensOptions = [
  { value: 'AvgYrThirty', labelKey: 'vs 30yr Avg' },
  { value: 'AvgYrTen', labelKey: 'vs 10yr Avg' },
  { value: 'LastYear', labelKey: 'vs Last Year Obs' },
];

export const CategoryDataSourceList: DropdownOption[] = [
  { value: CategoryValues.iso, labelKey: 'ISO Country' },
  { value: CategoryValues.degree, labelKey: 'Weighted Degree Days' },
];

export const DailyPeriodDataSourceList: DropdownOption[] = [
  { value: DailyPeriodValues.daily, labelKey: 'Daily' },
  { value: DailyPeriodValues.period, labelKey: 'Period' },
];

export const ForecastDataSourceList: DropdownOption[] = [
  { value: ForecastValues.ag2, labelKey: 'AG2' },
  { value: ForecastValues.model, labelKey: 'Model' },
];

export const RunTimeModelDataSourceList: DropdownOption[] = [
  { value: RunTimeModelValues.runTime, labelKey: 'Model Run Time' },
  { value: RunTimeModelValues.single, labelKey: 'Single Model' },
];

export const ParameterDataSourceList: DropdownOption[] = [
  { value: 'minMaxTemp', labelKey: 'Min/Max Temp' },
  { value: 'populationWeightedDd', labelKey: 'Population Weighted DD' },
];

export const EURParameterDataSourceList: DropdownOption[] = [
  { value: 'minMaxTemp', labelKey: 'Min/Max Temp' },
];

export const SUMMARY_TABLE_WIDGET_CUSTOM_LIST_CONTINENT = 'NA';

export const SUMMARY_TABLE_WIDGET_DEFAULT_CONTINENT = 'NA';

export const SUMMARY_TABLE_WIDGET_DEFAULT_REGION = 'All Regions';

export const WEIGHTED_GRAPH_WIDGET_DEFAULT_NA_REGION = 'MISO';

export const WEIGHTED_GRAPH_WIDGET_DEFAULT_EUR_REGION = 'AUSTRIA';

export const MaxCapacityLabelMapping: Record<string, string> = {
  wind: 'Wind Capacity:',
  solar: 'Solar Capacity:',
  total: 'Total Wind and Solar Capacity:',
};

export const RenewablesNaErcotLegendState = {
  [NaChartSeriesModelRun.AG2_LATEST]: true,
  [NaChartSeriesModelRun.AG2_MORNING]: true,
  [NaChartSeriesModelRun.AG2_PREVIOUS]: false,
  [NaChartSeriesModelRun.AG2_PRIMARY]: false,
  [NaChartSeriesModelRun.AG2_EVENING]: false,
  [NaChartSeriesModelRun.AG2_PRIMARY_YESTERDAY]: true,
  [NaChartSeriesModelRun.AG2_EVENING_YESTERDAY]: false,
  [NaChartSeriesModelRun.ERCOT_WGRPP_LATEST]: false,
  [NaChartSeriesModelRun.ERCOT_WGRPP_LATEST_MORNING]: false,
  [NaChartSeriesModelRun.ERCOT_WGRPP_PREVIOUS]: false,
  [NaChartSeriesModelRun.ERCOT_WGRPP_MORNING]: false,
  [NaChartSeriesModelRun.ERCOT_STWPF_LATEST_MORNING]: true,
  [NaChartSeriesModelRun.ERCOT_STWPF_LATEST]: true,
  [NaChartSeriesModelRun.ERCOT_STWPF_PREVIOUS]: false,
  [NaChartSeriesModelRun.ERCOT_STWPF_MORNING]: true,
  [NaChartSeriesModelRun.OBS]: true,
  [NaChartSeriesModelRun.FARM_CAPACITY]: true,
  [NaChartSeriesModelRun.CONFIDENCE_BANDS]: false,
};

export const RenewablesNaDefaultLegendState = {
  [NaChartSeriesModelRun.AG2_LATEST]: true,
  [NaChartSeriesModelRun.AG2_MORNING]: true,
  [NaChartSeriesModelRun.AG2_PREVIOUS]: false,
  [NaChartSeriesModelRun.AG2_PRIMARY]: false,
  [NaChartSeriesModelRun.AG2_EVENING]: false,
  [NaChartSeriesModelRun.AG2_PRIMARY_YESTERDAY]: true,
  [NaChartSeriesModelRun.AG2_EVENING_YESTERDAY]: false,
  [NaChartSeriesModelRun.LOCATION_MORNING]: true,
  [NaChartSeriesModelRun.LOCATION_LATEST]: true,
  [NaChartSeriesModelRun.LOCATION_PREVIOUS]: false,
  [NaChartSeriesModelRun.LOCATION_PREVIOUS_MORNING]: true,
  [NaChartSeriesModelRun.OBS]: true,
  [NaChartSeriesModelRun.FARM_CAPACITY]: true,
  [NaChartSeriesModelRun.CONFIDENCE_BANDS]: false,
};
