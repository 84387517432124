import { Component, OnInit } from '@angular/core';
import { DialogService } from '@firebird-web/shared-ui';
import { CreateListComponent } from '../create-list/create-list.component';
import { FavoriteList } from '../../../../../custom-locations-store/src/lib/models/favorite-locations.types';
import { Store } from '@ngrx/store';
import {
  changeList,
  copyList,
  deleteList,
  getFavoriteLists,
  loadCities,
} from '@firebird-web/custom-locations-store';

@Component({
  selector: 'firebird-web-favorite-locations',
  templateUrl: './favorite-locations.component.html',
  styleUrls: ['./favorite-locations.component.scss'],
})
export class FavoriteLocationsComponent implements OnInit {
  public favoriteLists$ = this.store.select(getFavoriteLists);
  public selectedList: FavoriteList | undefined;

  constructor(
    private readonly dialogService: DialogService,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(loadCities());
  }

  onListDelete($event: FavoriteList) {
    if (!$event.id) {
      return;
    }
    this.store.dispatch(deleteList({ list: $event }));
  }

  onListCopy($event: Partial<FavoriteList>) {
    if (!$event.id) {
      return;
    }
    this.store.dispatch(
      copyList({
        list: $event,
      })
    );
  }

  onListEdit(isNew = false) {
    if (!this.selectedList && !isNew) {
      return;
    }
    const list = isNew ? { listName: '' } : this.selectedList;
    this.dialogService
      .openDialog({
        component: CreateListComponent,
        config: {
          width: '1000px',
          height: 'calc(100vh - 100px)',
          maxHeight: '845px',
        },
        context: {
          list,
          deleteList: this.onListDelete.bind(this),
          copyList: this.onListCopy.bind(this),
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (!data) {
          return;
        }

        this.store.dispatch(changeList({ list: data }));
      });
  }

  onHover(list: FavoriteList) {
    this.selectedList = list;
  }

  onLeave() {
    this.selectedList = undefined;
  }

  public trackByFnGroup(index: number) {
    return index;
  }
}
