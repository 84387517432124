import {
  ModelIntervalResponse,
  ModelData,
  BaseInterval,
} from './models/model-page.types';

export const periodStrategies = {
  PERIOD: {
    selectProperty: 'order',
    withDots: false,
    getIntervalData: (data: ModelData[]) => {
      // , baseInterval: Interval
      return data.map(({ validPeriod, order, isModelInvalid }) => ({
        id: order,
        label: validPeriod,
        disabledLabel: false,
        disabledInterval: false,
        isModelInvalid,
        validTime: order,
      }));
    },
  },
  WEEK: {
    selectProperty: 'interval',
    withDots: false,
    getIntervalData: (data: ModelData[], baseInterval: BaseInterval) => {
      const length =
        (baseInterval.stop - baseInterval.start) / baseInterval.interval + 1;
      return Array.from(
        {
          length,
        },
        (_, i) => {
          const existedIntervals: number[] =
            data?.map(({ order }) => order) || [];

          const interval: number =
            (<ModelIntervalResponse>baseInterval).start +
            (<ModelIntervalResponse>baseInterval).interval * i;
          const disabledLabel = !existedIntervals.includes(interval);
          const disabledInterval =
            disabledLabel ||
            !existedIntervals.includes(
              interval + (<ModelIntervalResponse>baseInterval).interval
            );
          return {
            id: interval,
            label: `Week ${interval}`,
            disabledLabel,
            disabledInterval,
            isModelInvalid: data?.find(
              ({ interval: dataInterval }) => dataInterval === interval
            )?.isModelInvalid,
            validTime: data?.find(
              ({ interval: dataInterval }) => dataInterval === interval
            )?.validTime,
          };
        }
      );
    },
  },
  HOUR: {
    selectProperty: 'interval',
    withDots: true,
    getIntervalData: (data: ModelData[], baseInterval: BaseInterval) => {
      const existedIntervals: number[] =
        data
          ?.filter(({ validPeriod }) => !!validPeriod)
          .map(({ interval }) => interval) || [];
      const length =
        (baseInterval.stop - baseInterval.start) / baseInterval.interval + 1;
      return Array.from(
        {
          length,
        },
        (_, i) => {
          const interval: number =
            (<ModelIntervalResponse>baseInterval).start +
            (<ModelIntervalResponse>baseInterval).interval * i;
          const disabledLabel = !existedIntervals.includes(interval);
          const disabledInterval =
            disabledLabel ||
            !existedIntervals.includes(
              interval + (<ModelIntervalResponse>baseInterval).interval
            );
          return {
            id: interval,
            label: interval,
            disabledLabel,
            isModelInvalid: data?.find(
              ({ interval: dataInterval }) => dataInterval === interval
            )?.isModelInvalid,
            disabledInterval,
            validTime: data?.find(
              ({ interval: dataInterval }) => dataInterval === interval
            )?.validTime,
          };
        }
      );
    },
  },
} as const;

export type PeriodStrategies = typeof periodStrategies;
