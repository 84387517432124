import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiEndpoints, continents } from '@firebird-web/shared-constants';
import { environment } from '@firebird-web/shared-config';
import { map, Observable } from 'rxjs';
import { ContinentsEntity } from '../+state/continents/continents.models';

@Injectable()
export class ContinentsService {
  constructor(private readonly http: HttpClient) {}

  getContinentsList(): Observable<ContinentsEntity[]> {
    return this.http
      .get(`${environment.apiDomain}/${apiEndpoints.getContinentsList}`)
      .pipe(
        map((resp: any) =>
          resp.data.filter(
            (continent: ContinentsEntity) =>
              continent.id != continents.AUSTRALIA
          )
        )
      ); //Currently we don't support Australia
  }
}
