import { IRegion } from './location.interface';

export const mexRegions: IRegion[] = [
  {
    siteId: 'NORTE/NORESTE',
    isEia: false,
    regionName: 'NORTE/NORESTE',
  },
  {
    siteId: 'BAJA/NOROESTE',
    isEia: false,
    regionName: 'BAJA/NOROESTE',
  },
  {
    siteId: 'CENTRAL/OCCIDENTAL',
    isEia: false,
    regionName: 'CENTRAL/OCCIDENTAL',
  },
  {
    siteId: 'ORIENTAL',
    isEia: false,
    regionName: 'ORIENTAL',
  },
  {
    siteId: 'PENINSULAR',
    isEia: false,
    regionName: 'PENINSULAR',
  },
];
