import { ModelConfigExtraParams } from '@firebird-web/model-store';

export const loopDelays: Record<number, number> = {
  [2]: 250,
  [1]: 500,
  [0]: 1000,
};

export const categoryNames = {
  ['sfc-precip']: 'Surface/Precip',
  ['low-mid-trop']: 'Low/Mid Trop',
  ['upper-trop-strat']: 'Upper Trop/Strat',
} as const;

export const refreshTimeout = 1000;
export const defaultLoopDelay = 1;

//todo: move this logic to BE side
export const DefaultModelImage: any = {
  interval: 0,
  order: 0,
  pathURL: '/assets/img/missing_gfx/NotYetArrived.png',
  thumbPathURL: '',
  periodType: '',
  validPeriod: 'period',
  validTime: 0,
};

export const modelComparisonModelConfigExtraParams: ModelConfigExtraParams = {
  controlId: 'Model-Comparison',
};
