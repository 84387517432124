import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MODEL_STORE_FEATURE_KEY } from '../model-store.reducer';
import {
  modelConfigsAdapter,
  modelDataAdapter,
  modelRunsAdapter,
} from '../model-store.adapters';
import { ComparisonData, ModelStoreState } from '../model-store.models';
import { getConfigs } from './helpers';

const getModelStoreState = createFeatureSelector<ModelStoreState>(
  MODEL_STORE_FEATURE_KEY
);

const { selectAll: selectAllModels } = modelDataAdapter.getSelectors();
const { selectAll: selectAllConfigs } = modelConfigsAdapter.getSelectors();
const { selectAll: selectAllRuns } = modelRunsAdapter.getSelectors();

const getModelDataState = createSelector(
  getModelStoreState,
  (state: ModelStoreState) => state.comparisonData
);

export const getModelComparisonDataLoaded = createSelector(
  getModelDataState,
  (state: ComparisonData) => state.loaded
);

export const getModelComparisonError = createSelector(
  getModelDataState,
  (state: ComparisonData) => state.error
);

export const getModelComparisonData = createSelector(
  getModelDataState,
  (state: ComparisonData) => selectAllModels(state.main)
);

export const getModelComparisonConfigs = createSelector(
  getModelDataState,
  (state: ComparisonData) => selectAllConfigs(state.configs)
);

export const getModelComparisonRuns = createSelector(
  getModelDataState,
  (state: ComparisonData) => selectAllRuns(state.runs)
);

export const getModelComparisonSelectedConfigArray = createSelector(
  getModelDataState,
  (state: ComparisonData) => getConfigs(state.selectedConfigs)
);

export const getModelComparisonSelectedConfigs = createSelector(
  getModelDataState,
  (state: ComparisonData) => state.selectedConfigs
);
export const getModelComparisonInUse = createSelector(
  getModelDataState,
  (state: ComparisonData) => state.inUse
);
export const getModelComparisonAlwaysShowLatest = createSelector(
  getModelDataState,
  ({ alwaysShowLatest }) => alwaysShowLatest
);
