import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as ContinentsActions from './continents.actions';
import { ContinentsEntity } from './continents.models';

export const CONTINENTS_FEATURE_KEY = 'continents';

export interface ContinentsState extends EntityState<ContinentsEntity> {
  selectedId?: string | number; // which Continents record has been selected
  loaded: boolean; // has the Continents list been loaded
  error?: string | null; // last known error (if any)
}

export interface ContinentsPartialState {
  readonly [CONTINENTS_FEATURE_KEY]: ContinentsState;
}

export const continentsAdapter: EntityAdapter<ContinentsEntity> =
  createEntityAdapter<ContinentsEntity>();

export const initialContinentsState: ContinentsState =
  continentsAdapter.getInitialState({
    // set initial required properties
    loaded: false,
  });

const reducer = createReducer(
  initialContinentsState,
  on(ContinentsActions.initContinents, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(ContinentsActions.loadContinentsSuccess, (state, { continents }) =>
    continentsAdapter.setAll(continents, { ...state, loaded: true })
  ),
  on(ContinentsActions.loadContinentsFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function continentsReducer(
  state: ContinentsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
