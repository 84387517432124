<div class="location-selection-panel">
  <div class="universal">
    <span>Select a Location</span>

    <div class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </div>
  </div>

  <span class="custom-loc-header"
    ><span class="custom-loc-title">Custom Location List</span>
    <span class="manage-lists" (click)="showManageList()"
      >Manage Lists...</span
    ></span
  >
  <div class="custom-loc-list">
    <div *ngFor="let listGroup of customList$ | async" class="custom-loc">
      <div *ngFor="let list of listGroup" (click)="selectCustomList(list)">
        {{ list.listName }}
      </div>
    </div>
  </div>
  <div class="continent-location">
    <div>Locations by Continent</div>
    <span [matMenuTriggerFor]="contMenu" class="continent-menu"
      >{{ (continent$ | async)?.text | titlecase }}
      <mat-icon>expand_more</mat-icon></span
    >
  </div>
  <span class="regions">
    <span *ngIf="(continent$ | async)?.id === 'NA' && isLoaded">
      <span
        class="na-regions"
        [ngClass]="{
          'restricted-regions':
            !selectedISORegions.length || !selectedEIARegions.length
        }"
      >
        <span class="agg">
          <span (click)="selectRegion({ regionName: 'All Regions' })">
            All Aggregates
          </span>
          <span
            (click)="
              selectRegion(
                { regionName: 'ISO Regions' },
                !selectedISORegions.length
              )
            "
            [ngClass]="{
              disabled: !selectedISORegions.length
            }"
          >
            ISO Aggregates
            <firebird-web-icon
              class="lock-icon"
              *ngIf="!selectedISORegions.length"
              name="lock"
              width="18px"
              height="16px"
            >
            </firebird-web-icon>
          </span>
          <span
            (click)="
              selectRegion(
                { regionName: 'EIA Regions' },
                !selectedEIARegions.length
              )
            "
            [ngClass]="{
              disabled: !selectedEIARegions.length
            }"
          >
            EIA Aggregates
            <firebird-web-icon
              class="lock-icon"
              *ngIf="!selectedEIARegions.length"
              name="lock"
              width="18px"
              height="16px"
            >
            </firebird-web-icon>
          </span>
          <span (click)="selectRegion({ regionName: 'All Cities' })">
            All Cities
          </span>
        </span>

        <span *ngIf="selectedISORegions?.length">
          <div class="iso-title">ISO Cities by Region</div>
          <span class="iso-regions">
            <span
              (click)="selectRegion(region)"
              *ngFor="let region of selectedISORegions"
            >
              {{ region.regionName }}
            </span>
          </span>
        </span>

        <span *ngIf="selectedEIARegions?.length">
          <div class="iso-title">EIA Cities By Region</div>
          <span class="eia-cities">
            <span
              (click)="selectRegion(region)"
              *ngFor="let region of selectedEIARegions"
            >
              {{ region.regionName }}
            </span>
          </span>
        </span>
      </span>
    </span>

    <span *ngIf="(continent$ | async)?.id === 'EUR'">
      <div class="eur-regions">
        <span class="agg">
          <span (click)="selectRegion({ regionName: 'All Regions' })"
            >All Aggregates</span
          >
          <span (click)="selectRegion({ regionName: 'All Cities' })"
            >All Cities</span
          >
        </span>

        <span
          class="non-agg-eur-regions"
          *ngFor="let regionList of selectedISORegionsByChunks"
        >
          <span
            (click)="selectRegion(region)"
            class="region-item"
            *ngFor="let region of regionList"
          >
            {{ region.regionName }}
          </span>
        </span>
      </div>
    </span>

    <span *ngIf="(continent$ | async)?.id === 'ASIA'">
      <div class="asia-regions">
        <span class="agg">
          <span (click)="selectRegion({ regionName: 'All Regions' })"
            >All Aggregates</span
          >
          <span (click)="selectRegion({ regionName: 'All Cities' })"
            >All Cities</span
          >
        </span>
        <span
          class="asia-non-agg-regions"
          *ngFor="let regionList of selectedISORegionsByChunks"
        >
          <span
            (click)="selectRegion(region)"
            class="asia-region-item"
            *ngFor="let region of regionList"
          >
            {{ region.regionName }}
          </span>
        </span>
      </div>
    </span>

    <span *ngIf="(continent$ | async)?.id === 'MEX'">
      <span class="na-regions">
        <span class="agg">
          <span (click)="selectRegion({ regionName: 'All Regions' })">
            All Aggregates
          </span>
          <span (click)="selectRegion({ regionName: 'All Cities' })">
            All Cities
          </span>
        </span>
        <span>
          <span class="mex-city">
            <span
              (click)="selectRegion(region)"
              class="city"
              *ngFor="let region of mexRegions"
              >{{ region.regionName }}</span
            >
          </span>
        </span>
      </span>
    </span>
  </span>

  <mat-menu class="locations-menu-panel" #contMenu="matMenu">
    <ng-container *ngFor="let continent of continents$ | async">
      <div mat-menu-item>
        <div
          [hasViewPermission]="[continent.id || '']"
          (click)="changeContinent(continent)"
          [alignRight]="true"
          key="PoolSet"
        >
          {{ continent.text | titlecase }}
        </div>
      </div>
    </ng-container>
  </mat-menu>
</div>
