<div class="container">
  <div class="header">
    <span class="title">Update {{ data.title }}</span>
    <div (click)="closeDialog()" class="cancel">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="content">
    <span class="input"
      ><div
        *ngIf="
          submitButtonClicked &&
          (editMonitorForm.get('title')?.hasError('emptyTitle') || isTitleValid)
        "
        class="empty-title-error-message"
      >
        <firebird-web-icon
          class="icon"
          name="information-circle-red"
          height="20px"
          width="20px"
        ></firebird-web-icon>
        Please enter a unique name.
      </div>
      <form [formGroup]="editMonitorForm">
        <span class="name-field">
          <div class="dash-name">Name</div>
          <input
            class="title-input"
            type="text"
            placeholder="Edit Dashboard Name"
            matInput
            formControlName="title"
            (keyup.enter)="updateDashboard()"
          />
        </span>
        <span class="description-field">
          <div class="dash-description">Description</div>
          <textarea
            class="description-input"
            type="text"
            placeholder="Edit description"
            matInput
            formControlName="description"
          ></textarea>
        </span>
      </form>
    </span>
  </div>

  <div class="cancel-submit-buttons">
    <span (click)="closeDialog()" class="cancel">X-Cancel</span>
    <span (click)="updateDashboard()" class="submit">Update Dashboard</span>
  </div>
</div>
