import { EurRenewableWidgetLegend } from '../models';

export const EurRenewableWidgetLegends: EurRenewableWidgetLegend[] = [
  {
    key: 'ECMWF_OP_00Z',
    label: 'ECMWF OP Latest',
    icon: 'thick-line',
    fillColor: '#1063ED',
    strokeColor: '#1063ED',
  },
  {
    key: 'ECMWF_OP_06Z',
    label: 'ECMWF OP (-6Hrs)',
    icon: 'medium-line',
    fillColor: '#1063ED',
    strokeColor: '#1063ED',
  },
  {
    key: 'ECMWF_OP_12Z',
    label: 'ECMWF OP (-12Hrs)',
    icon: 'line',
    fillColor: '#1063ED',
    strokeColor: '#1063ED',
  },
  {
    key: 'ECMWF_ENS_00Z',
    label: 'ECMWF ENS Latest',
    icon: 'thick-line',
    fillColor: '#F70F1B',
    strokeColor: '#F70F1B',
  },
  {
    key: 'ECMWF_ENS_06Z',
    label: 'ECMWF ENS (-6Hrs)',
    icon: 'medium-line',
    fillColor: '#F70F1B',
    strokeColor: '#F70F1B',
  },
  {
    key: 'ECMWF_ENS_12Z',
    label: 'ECMWF ENS (-12Hrs)',
    icon: 'line',
    fillColor: '#F70F1B',
    strokeColor: '#F70F1B',
  },
  {
    key: 'GFS_ENS_00Z',
    label: 'GFS ENS Latest',
    icon: 'thick-line',
    fillColor: '#EE8031',
    strokeColor: '#EE8031',
  },
  {
    key: 'GFS_ENS_06Z',
    label: 'GFS ENS (-6Hrs)',
    icon: 'medium-line',
    fillColor: '#EE8031',
    strokeColor: '#EE8031',
  },
  {
    key: 'GFS_ENS_12Z',
    label: 'GFS ENS Jun (-12Hrs)',
    icon: 'line',
    fillColor: '#EE8031',
    strokeColor: '#EE8031',
  },
  {
    key: 'GFS_OP_00Z',
    label: 'GFS OP Latest',
    icon: 'thick-line',
    fillColor: '#53B9EC',
    strokeColor: '#53B9EC',
  },
  {
    key: 'GFS_OP_06Z',
    label: 'GFS OP (-6Hrs)',
    icon: 'medium-line',
    fillColor: '#53B9EC',
    strokeColor: '#53B9EC',
  },
  {
    key: 'GFS_OP_12Z',
    label: 'GFS OP (-12Hrs)',
    icon: 'line',
    fillColor: '#53B9EC',
    strokeColor: '#53B9EC',
  },
];

export const EurRenewableWidgetLegendsOrder: string[] = [
  'ECMWF_OP',
  'GFS_OP',
  'ECMWF_ENS',
  'GFS_ENS',
];

export const EurRenewableWidgetRuns: Record<number, string> = {
  0: '00Z',
  1: '06Z',
  2: '12Z',
};
