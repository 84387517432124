import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'firebird-web-delete-panel',
  templateUrl: './delete-panel.component.html',
  styleUrls: ['./delete-panel.component.scss'],
})
export class DeletePanelComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeletePanelComponent>
  ) {}
  deleteSelected() {
    this.dialogRef.close({ delete: true });
  }
  cancelSelected() {
    this.dialogRef.close({ delete: false });
  }
  ngOnInit(): void {}
}
