import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationEmailDialogComponent } from '../notification-email-dialog/notification-email-dialog.component';
import { NotificationsFacade } from '@firebird-web/notifications-store';

@Component({
  selector: 'firebird-web-notification-email',
  templateUrl: './notification-email.component.html',
  styleUrls: ['./notification-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationEmailComponent {
  @Input() email!: string;
  @Input() emailNumber: number;
  @Input() isAddAllowed: boolean;

  status_code!: string;

  constructor(
    private readonly dialog: MatDialog,
    private readonly notificationsFacade: NotificationsFacade
  ) {}

  emailDialog(email: string, type: string) {
    this.dialog.open(NotificationEmailDialogComponent, {
      data: { email: email, type: type },
    });
  }

  deleteEmail(email: string): any {
    this.notificationsFacade.deleteNotificationEmail(email);
  }
}
