import { PickerTableCol } from '../types';

export const WINDFARM_AGGREGATES_PICKER_TABLE_COLUMNS: PickerTableCol[] = [
  {
    name: 'capacity',
    labelKey: 'capacity',
    isSortable: true,
  },
  {
    name: 'name',
    labelKey: 'aggregate',
    isSortable: true,
  },
];

export const WINDFARM_SUBPOOLS_PICKER_TABLE_COLUMNS: PickerTableCol[] = [
  {
    name: 'capacity',
    labelKey: 'capacity',
    isSortable: true,
  },
  {
    name: 'name',
    labelKey: 'farm',
    isSortable: true,
  },
];
