import { CustomSeries } from '../models';

export const baseChartOptions = {
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false,
      },
    },
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  plotOptions: {
    series: {
      stickyTracking: false,
    },
  },
  rangeSelector: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  navigator: {
    enabled: true,
    maskInside: false,
    maskFill: 'rgba(255, 255, 255, 0.7)',
    series: {
      type: 'areaspline',
      fillOpacity: 0,
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1.5 },
        stops: [
          [0, 'rgba(117, 189, 188, 1)'],
          [1, 'rgba(117, 189, 188, 0)'],
        ],
      },
      lineWidth: 0,
    },
    handles: {},
    xAxis: {
      tickInterval: 86400000,
      style: {
        color: 'rgba(108, 124, 132, 1)',
        'font-family': 'Nunito',
        'font-size': '10px',
        cursor: 'default',
      },
    },
    adaptToUpdatedData: false,
    opposite: false,
  },
  yAxis: [
    {
      opposite: false,
      startOnTick: false,
      labels: {
        format: '{value}',
        y: 5,
        x: -5,
      },
      title: {
        align: 'middle',
        margin: 40,
        text: '',
      },
      allowDecimals: false,
      showLastLabel: true,
    },
  ],
  xAxis: [
    {
      gridLineColor: '#B4C6DB',
      gridLineWidth: 2,
      title: {
        text: '',
        style: {
          color: '#012e59',
          fontWeight: 'bold',
        },
      },
      type: 'datetime',
      allowDecimals: false,
    },
  ],
  tooltip: {
    split: false,
    shared: true,
    useHTML: true,
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    className: 'tooltip',
    outside: true,
  },
  series: [],
};

export const baseSpreadSeriesTemplate: CustomSeries = {
  type: 'arearange',
  id: 'spread',
  zIndex: -1,
  visible: false,
  data: [],
  dashStyle: 'Solid',
};

export const baseSeriesTemplate: CustomSeries = {
  id: '',
  name: '',
  type: 'spline',
  yAxis: 0,
  zIndex: 2,
  lineIcon: 'line',
  dashStyle: 'Solid',
  lineWidth: 2,
  visible: false,
  marker: {
    enabled: false,
  },
  showInLegend: false,
  showInNavigator: false,
  data: [],
  svgIcon: `<svg width="100%" height="100%" viewBox="0 0 26 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="0.5" x2="26" y2="0.5" />
    </svg>`,
};

export const seriesModelRunMap: { [key: string]: Partial<CustomSeries> } = {
  '3km_GRAF': {
    color: 'rgb(150, 75, 0)',
  },
  OBS: {
    color: '#75BDBC',
    lineWidth: 3,
  },
  GFS_OP: {
    color: '#53B9EC',
  },
  GFS_ENS: {
    color: '#EE8031',
  },
  ECMWF_OP: {
    color: '#1063ED',
    lineWidth: 3,
  },
  ECMWF_ENS: {
    color: '#F70F1B',
  },
};

export const seriesModelRunOrderMap: any[] = [
  {
    lineIcon: 'thick-line',
    lineWidth: 3,
    opacity: 0.8,
    dashStyle: 'Solid',
    states: {
      hover: {
        lineWidth: 5,
      },
    },
    marker: {
      enabled: true,
      symbol: 'circle',
      radius: 3,
    },
    svgIcon: `<svg width="100%" height="100%" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="1.5" x2="17" y2="1.5" stroke-width="3"/>
    </svg>`,
  },
  {
    lineIcon: 'medium-line',
    lineWidth: 2,
    opacity: 0.9,
    dashStyle: 'Solid',
    states: {
      hover: {
        lineWidth: 5,
      },
    },
    svgIcon: `<svg width="100%" height="100%" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="1" x2="26" y2="1" stroke-width="4"/>
    </svg>`,
  },
  {
    lineIcon: 'line',
    lineWidth: 1,
    dashStyle: 'Solid',
    states: {
      hover: {
        lineWidth: 5,
      },
    },
    svgIcon: `<svg width="100%" height="100%" viewBox="0 0 26 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="0.5" x2="26" y2="0.5" />
    </svg>`,
  },
  {
    lineIcon: 'three-dashes-line',
    lineWidth: 2,
    dashStyle: 'LongDash',
    states: {
      hover: {
        lineWidth: 5,
      },
    },
    svgIcon: `<svg width="100%" height="100%" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="2" x2="26" y2="2" stroke-width="4" stroke-dasharray="7 7"/>
    </svg>`,
  },
  {
    lineIcon: 'thick-dashed-line',
    lineWidth: 2,
    dashStyle: 'ShortDash',
    states: {
      hover: {
        lineWidth: 5,
      },
    },
    svgIcon: `<svg width="100%" height="100%" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="1.5" x2="17" y2="1.5" stroke-width="2" stroke-dasharray="3 2"/>
    </svg>`,
  },
  {
    lineIcon: 'dashed-line-medium',
    lineWidth: 3,
    dashStyle: 'ShortDash',
    states: {
      hover: {
        lineWidth: 5,
      },
    },
    svgIcon: `<svg width="100%" height="100%" viewBox="0 0 17 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1.29931e-07" y1="2" x2="17" y2="2" stroke-width="4" stroke-dasharray="2 2"/>
    </svg>`,
  },
  {
    lineIcon: 'dashed-line-thin',
    lineWidth: 3,
    dashStyle: 'ShortDot',
    states: {
      hover: {
        lineWidth: 5,
      },
    },
    svgIcon: `<svg width="100%" height="100%" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="9.74483e-08" y1="1.5" x2="17" y2="1.5" stroke-width="3" stroke-dasharray="2 2"/>
    </svg>`,
  },
  {
    lineIcon: 'dashed-line-wide',
    lineWidth: 3,
    dashStyle: 'Dash',
    states: {
      hover: {
        lineWidth: 5,
      },
    },
    svgIcon: `<svg width="100%" height="100%" viewBox="0 0 17 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="6.49655e-08" y1="1" x2="17" y2="1" stroke-width="2" stroke-dasharray="6 6"/>
    </svg>`,
  },
];

export const baseBandTemplate = {
  type: 'areasplinerange',
  visible: true,
  forecastHour: 0,
};

export const seriesToSkip = ['GRAF'];
export const OrderOfNaSeries = ['AG2', 'LOCATION', 'OTHER'];

export const WidgetNaNavigator = {
  enabled: false,
  height: 1,
  margin: -28,
  maskInside: false,
};

export const WidgetNaYAxis = {
  offset: 50,
  title: {
    enabled: false,
  },
  labels: {
    offset: 0,
    distance: -1,
    align: 'left',
    format: '{value}',
    y: 5,
  },
};
