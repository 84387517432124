import { RENEWABLES_TAB_COMBINED_PERMISSION } from '../../../../renewable/src/lib/constants';
import { WeightedSubTab } from '@firebird-web/shared-interfaces';

export const tabConfig = [
  {
    labelKey: 'temperature',
    value: 'temperature',
    permission: ['Summary_Table', 'Min/Max_Forecast'],
  },
  {
    labelKey: 'precipitation',
    value: 'precipitation',
    permission: ['Summary_Table', 'Precip_Forecast'],
  },
  {
    labelKey: 'weighted',
    value: 'weighted',
    permission: ['Weighted_Forecasts'],
    filterTypes: [
      { type: 'iso', case: WeightedSubTab.ISO_FORECAST },
      { type: 'degree', case: WeightedSubTab.WEIGHTED_DEGREE_FORECAST },
      { type: 'gasDemand', case: WeightedSubTab.US_GAS_DEMAND_FORECAST },
    ],
    subTabs: [
      {
        labelKey: 'iso',
        value: WeightedSubTab.ISO_FORECAST,
        permission: ['Weighted_Forecasts', 'ISO-Country'],
      },
      {
        labelKey: 'degree',
        value: WeightedSubTab.WEIGHTED_DEGREE_FORECAST,
        permission: ['Weighted_Forecasts', 'Degree_Day'],
      },
      {
        labelKey: 'gasDemand',
        value: WeightedSubTab.US_GAS_DEMAND_FORECAST,
        permission: ['Weighted_Forecasts', 'BCF'],
      },
    ],
  },
  {
    labelKey: 'renewables',
    value: 'renewables',
    permission: [RENEWABLES_TAB_COMBINED_PERMISSION],
    filterTypes: [],
    subTabs: [],
  },
  {
    labelKey: 'demand',
    value: 'demand',
    permission: ['Load_Forecasts'],
    subTabs: [],
    filterTypes: [],
  },
  {
    labelKey: 'fresh-look',
    value: 'fresh-look',
    permission: ['FreshLook_Table'],
  },
];
