import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PickerTableCol } from '../../types';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'firebird-web-picker-table',
  templateUrl: './picker-table.component.html',
  styleUrls: ['./picker-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickerTableComponent implements OnInit, AfterViewInit {
  @Input() public dataSource = new MatTableDataSource<any>([]);
  @Input() public colDefs: PickerTableCol[] = [];
  @Input() public isSort = false;
  @Input() public sortDisableClear = false;
  @Input() public activeSort = this.colDefs[0]?.name;
  @Input() public sortDirection: 'asc' | 'desc' = 'desc';
  @Input() public selectedRow: any;
  @Output() public rowSelected = new EventEmitter<any>();

  public displayedColumns: string[] = [];
  private readonly formatters: Record<string, (value: any) => string | null> = {
    capacity: (value) => this.decimalPipe.transform(value),
  };

  @ViewChild('table', { read: MatSort, static: true })
  public sort: MatSort;

  constructor(private readonly decimalPipe: DecimalPipe) {}

  ngOnInit(): void {
    this.displayedColumns = [...this.colDefs.map((col) => col.name), 'symbol'];
  }

  ngAfterViewInit(): void {
    if (this.isSort) {
      this.dataSource.sort = this.sort;
    }
  }

  public poolChange(row: any): void {
    this.rowSelected.emit(row);
  }

  public formatCellData(element: any, name: string): string | any {
    const value = element[name];
    return this.formatters[name] ? this.formatters[name](value) : value;
  }
}
