<div class="settings component">
  <div class="tabs">
    <ng-container *ngFor="let tab of tabsList; trackBy: trackByKey">
      <div class="tabs-item" [ngClass]="{ active: selectedTab === tab.key }">
        <div class="label" (click)="changeTab(tab)">
          {{ tab.label }}
        </div>

        <ng-container
          *ngFor="let subTabs of tab?.children; trackBy: trackByKey"
        >
          <div
            [hasViewPermission]="subTabs.permissions"
            [alignRight]="true"
            [iconExtraStyles]="{
              fill: '#C3C2C2',
              marginTop: '-38px',
              marginRight: '10px'
            }"
            [hostExtraStyles]="{ opacity: '0.5' }"
          >
            <div
              class="tabs-sub-item"
              [ngClass]="{ active: selectedTab === subTabs.key }"
            >
              <div class="label" (click)="changeTab(subTabs)">
                {{ subTabs.label }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="tabs-content">
    <ng-container [ngSwitch]="selectedTab">
      <ng-container *ngSwitchCase="tabsKey.Units">
        <firebird-web-units></firebird-web-units>
      </ng-container>
      <ng-container *ngSwitchCase="tabsKey.Favorite">
        <firebird-web-favorite-locations></firebird-web-favorite-locations>
      </ng-container>
      <ng-container *ngSwitchCase="tabsKey.Notifications">
        <firebird-web-notifications></firebird-web-notifications>
      </ng-container>
      <ng-container *ngSwitchCase="tabsKey.Mobile">
        <firebird-web-mobile-app></firebird-web-mobile-app>
      </ng-container>
      <ng-container *ngSwitchCase="tabsKey.Notes">
        <firebird-web-notes></firebird-web-notes>
      </ng-container>
    </ng-container>
  </div>
</div>
