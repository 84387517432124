import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  combineLatest,
  of,
  tap,
  filter,
} from 'rxjs';

import * as SettingsActions from './settings.actions';
import { CustomLocationsService } from '../../services/custom-locations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from 'libs/shared/ui/src/lib/components/toast/toast.component';

@Injectable()
export class SettingsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly settingsService: CustomLocationsService,
    private readonly snackBar: MatSnackBar
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.initSettings),
      map(() => SettingsActions.loadSettingsSuccess({ settings: [] }))
    )
  );

  loadLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadLists),
      switchMap(() => this.settingsService.getLists()),
      map((favoriteLists) =>
        SettingsActions.loadListsSuccess({ favoriteLists })
      )
    )
  );

  loadCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadCities),
      switchMap(() => this.settingsService.getCities()),
      map((cities) => {
        const continents = cities.reduce<any>((acc, item) => {
          if (acc.find(({ continent }: any) => item.continent === continent)) {
            return acc;
          }
          return [
            ...acc,
            {
              continent: item.continent,
              continentName: item.continentName,
            },
          ];
        }, []);
        const regions = cities.reduce<any>((acc, item) => {
          if (
            acc.find(({ regionName }: any) => item.regionName === regionName)
          ) {
            return acc;
          }
          return [
            ...acc,
            {
              continent: item.continent,
              regionName: item.regionName,
              cities: cities.filter(
                ({ regionName }) => regionName === item.regionName
              ),
            },
          ];
        }, []);
        return SettingsActions.loadCitiesSuccess({ continents, regions });
      }),
      catchError((error) => [SettingsActions.loadCitiesFailure({ error })])
    )
  );

  createList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.createList),
      switchMap(({ list }) => this.settingsService.createList(list)),
      map((list: any) => SettingsActions.createListSuccess({ list })),
      catchError((error) => [SettingsActions.loadCitiesFailure({ error })])
    )
  );

  saveList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.saveList),
      switchMap(({ list }) => this.settingsService.saveList(list)),
      map((list: any) => SettingsActions.saveListSuccess({ list })),
      catchError((error) => [SettingsActions.loadCitiesFailure({ error })])
    )
  );

  copyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.copyList),
      switchMap(({ list }) =>
        combineLatest([of(list), this.settingsService.copyList(list)])
      ),
      tap(([list]) =>
        this.snackBar.openFromComponent(ToastComponent, {
          duration: 5000,
          data: {
            type: 'success',
            message: `Custom List <b>${list.listName}</b> has been created.`,
          },
        })
      ),
      map(([, lists]: any) => SettingsActions.copyListSuccess({ lists })),
      catchError((error) => [SettingsActions.loadCitiesFailure({ error })])
    )
  );

  deleteList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.deleteList),
      filter(({ list }) => !!list.id),
      switchMap(({ list }) => {
        return combineLatest([
          of(list),
          this.settingsService.deleteList(list.id),
        ]);
      }),
      tap(([list]) =>
        this.snackBar.openFromComponent(ToastComponent, {
          duration: 5000,
          data: {
            type: 'success',
            message: `Custom List <b>${list.listName}</b> has been deleted.`,
          },
        })
      ),
      map(([list]) => SettingsActions.deleteListSuccess({ id: list.id })),
      catchError((error) => [SettingsActions.deleteListFailure({ error })])
    )
  );
}
