import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import * as LocationPickerActions from '../+state/location-picker.actions';
import { LocationPickerActionTypes } from '../enums/location-picker.enum';
import { LocationPickerApiService } from '../services/location-picker-api.service';

export const isHydrateSuccess = ({ type }: Action): boolean =>
  Object.values(LocationPickerActions)
    .map(({ type }) => type)
    .filter((type) => type.includes('Hydrate'))
    .includes(type as LocationPickerActionTypes);

export const getLocationPickerMetaReducers = (
  service: LocationPickerApiService
): MetaReducer<any> => {
  return saveToServer(service);
};

export function saveToServer(service: LocationPickerApiService) {
  return function (reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
      const nextState = reducer(state, action);
      if (isHydrateSuccess(action)) {
        const { error: _, loaded: __, ...rest } = nextState;
        const { locationPicker } = rest;
        service.savePickerData(locationPicker).subscribe();
      }
      return nextState;
    };
  };
}
