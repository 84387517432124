import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  NOTIFICATIONS_STORE_FEATURE_KEY,
  NotificationsStoreState,
  notificationsStoreAdapter,
} from './notifications-store.reducer';

// Lookup the 'NotificationsStore' feature state managed by NgRx
export const getNotificationsStoreState =
  createFeatureSelector<NotificationsStoreState>(
    NOTIFICATIONS_STORE_FEATURE_KEY
  );

const { selectAll, selectEntities } = notificationsStoreAdapter.getSelectors();

export const getNotificationsStoreLoaded = createSelector(
  getNotificationsStoreState,
  ({ loaded }) => loaded
);

export const getNotificationsStoreError = createSelector(
  getNotificationsStoreState,
  ({ error }) => error
);

export const getAllNotificationsStore = createSelector(
  getNotificationsStoreState,
  (state: NotificationsStoreState) => selectAll(state)
);

export const getNotificationsStoreEntities = createSelector(
  getNotificationsStoreState,
  (state: NotificationsStoreState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getNotificationsStoreState,
  ({ selectedId }) => selectedId
);

export const getSelected = createSelector(
  getNotificationsStoreEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getNotificationEmails = createSelector(
  getNotificationsStoreState,
  ({ emails }) => {
    return emails;
  }
);

export const getNotifications = createSelector(
  getNotificationsStoreState,
  ({ notifications }) => {
    return notifications;
  }
);

export const getAlerts = createSelector(
  getNotificationsStoreState,
  ({ alerts }) => {
    return alerts;
  }
);

export const getAlertState = createSelector(
  getNotificationsStoreState,
  ({ alerts, notifications }) => {
    const hasUnseenAlerts = alerts.some(
      (item) => item.isInApp && item.isUnseen
    );
    const hasUnseenNotifications = notifications.some(
      (item) => item.isInApp && item.isUnseen
    );
    return hasUnseenAlerts || hasUnseenNotifications;
  }
);

export const getAlertWindowOpen = createSelector(
  getNotificationsStoreState,
  ({ isAlertWindowOpen }) => isAlertWindowOpen
);
