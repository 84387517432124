import {
  ChangeDetectionStrategy,
  Component,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StoreLinksType } from '../../enums';
import {
  AppleStoreLink,
  GoogleStoreLink,
  WindowFeatures,
} from '../../constants';

@Component({
  selector: 'firebird-web-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAppComponent {
  public readonly storeLinksType = StoreLinksType;

  constructor(private readonly sanitizer: DomSanitizer) {}

  openStoreWindow(event: MouseEvent, type: StoreLinksType): void {
    event.preventDefault();

    const url = this.sanitizer.bypassSecurityTrustResourceUrl(
      type === StoreLinksType.Apple ? AppleStoreLink : GoogleStoreLink
    );

    window.open(
      <string>this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, url),
      '_blank',
      WindowFeatures
    );
  }
}
