import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MAT_SELECT_CONFIG,
  MatSelect,
  MatSelectChange,
} from '@angular/material/select';
import { RunType } from '@firebird-web/shared-interfaces';
import { PermissionDialogComponent } from '../permission-dialog/permission-dialog.component';
import { CustomListRunDateConfig } from '../../../../../../heat-map-overview/src/lib/types';

export interface IOption {
  labelKey: string;
  value: string;
  isPermitted?: boolean;
}

export interface IGroupOption {
  name: string;
  option: IOption[];
}

@Component({
  selector: 'firebird-web-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'dropdownVariationClass' },
    },
  ],
})
export class DropdownComponent {
  public runDateType = RunType;
  @Input() dateFormat = 'mediumDate';
  @Input() panelClass!: string;
  @Input() width!: string;
  @Input() labelKey!: string;
  @Input() options!: IOption[] | null | any;
  @Input() value!: any;
  @Input() cityName!: string | null;
  @Input() triggerLabel!: string;
  @Input() type:
    | 'variation-one'
    | 'variation-two'
    | 'variation-three'
    | 'variation-four'
    | 'variation-four-shared'
    | 'widget-permissions'
    | 'variation-five' = 'variation-one';
  @Input() icon!: string;
  @Input() group_option!: IGroupOption[] | null;
  @ViewChild('selector', { static: false }) selector!: MatSelect;
  @ViewChild('variationFiveSelector', { static: false })
  variationFiveSelector!: MatSelect;
  @Input() disabled = false;
  @Input() isShort = false;
  @Input() public isMultiContinentInCustomList = false;
  @Input() public customListRunDateConfig: CustomListRunDateConfig = null;

  @Output()
  public changeEvent: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

  @Output()
  public optionClickEvent: EventEmitter<Event> = new EventEmitter<Event>();
  constructor(private matDialog: MatDialog) {}
  isDropdownOpen = false;

  trackByFnGroup(index: number) {
    return index; // or item.id
  }

  onChangeEvent(event: MatSelectChange) {
    if (this.selector) {
      this.selector.close();
    }
    if (this.variationFiveSelector) {
      this.variationFiveSelector.close();
    }
    this.changeEvent.emit(event);
  }

  onOptionClick(event: any, isPermitted = true) {
    if (!isPermitted) {
      this.showPermissionDeniedDialog();
      return;
    }
    this.selector?.close();
    this.variationFiveSelector?.close();
    this.optionClickEvent.emit(event);
  }

  getLabel(value: string) {
    return (
      this.options?.find(
        ({ value: optionValue }: IOption) => optionValue === value
      )?.labelKey || {}
    );
  }

  // TODO: delete this after component refactoring
  getRunDateLabel(value: string) {
    return this.options?.find(({ id }: any) => id === value)?.label || {};
  }

  getRunDateType(value: string) {
    const type = this.options?.find(({ id }: any) => id === value)?.type;
    return type ? type.toLowerCase() : 'primary';
  }

  showPermissionDeniedDialog() {
    this.matDialog.open(PermissionDialogComponent, {
      width: '500px',
      panelClass: ['firebird-dialog-panel'],
      autoFocus: false,
    });
  }
}
