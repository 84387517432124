import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsStoreModule } from '@firebird-web/notifications-store';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';

import { CustomLocationsStoreModule } from '@firebird-web/custom-locations-store';
import { SharedUiModule } from '@firebird-web/shared-ui';

import { ActionButtonsComponent } from './components/action-buttons/action-buttons.component';
import { CreateListComponent } from './components/create-list/create-list.component';
import { CreateListService } from './components/create-list/services';
import { FavoriteLocationsComponent } from './components/favorite-locations/favorite-locations.component';
import { UnitsComponent } from './components/units/units.component';
import { SettingsComponent } from './settings.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationEmailComponent } from './components/notification-email/notification-email.component';
import { NotificationEmailDialogComponent } from './components/notification-email-dialog/notification-email-dialog.component';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { NotificationsService } from './services/notifications.service';
import { UserSettingsStoreModule } from '@firebird-web/user-settings-store';
import { MobileAppComponent } from './components/mobile-app/mobile-app.component';
import { NotesComponent } from './components/notes/notes.component';
import { SettingsApiService } from './services/settings-api.service';
import { PermissionsModule } from 'libs/acl/src/lib/permissions.module';
// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

@NgModule({
  imports: [
    CommonModule,
    CustomLocationsStoreModule,
    DragDropModule,
    FormsModule,
    MatExpansionModule,
    ReactiveFormsModule,
    SharedUiModule,
    UserSettingsStoreModule,
    MatDialogModule,
    NotificationsStoreModule,
    PermissionsModule,
  ],
  declarations: [
    ActionButtonsComponent,
    CreateListComponent,
    FavoriteLocationsComponent,
    NotificationEmailComponent,
    NotificationEmailDialogComponent,
    NotificationsComponent,
    SettingsComponent,
    UnitsComponent,
    MobileAppComponent,
    NotesComponent,
  ],
  providers: [
    CreateListService,
    NotificationsService,
    SettingsApiService,
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
  exports: [SettingsComponent],
})
export class SettingsModule {}
