import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@firebird-web/shared-config';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { CommonUtils } from '@firebird-web/shared-utils';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WeightedService {
  /**
   * getUsGasBcfCompanyDropdown
   * @returns
   */
  public getUsGasBcfCompanyDropdown() {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getUsGasBcfCompanyDropdown}`
    );
    return this.http.get(endPointUrl).pipe(map((resp: any) => resp?.data));
  }

  /**
   * getWeightedDegreeModelForecastDropdown
   * @returns
   */
  public getWeightedDegreeModelForecastDropdown(continent: string) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getWeightedDegreeModelForecastDropdown}`,
      { continent: continent }
    );
    return this.http.get(endPointUrl).pipe(map((resp: any) => resp?.data));
  }
  public isWidget(): boolean {
    const url = this.router.url;
    return url.includes('live-monitor');
  }
  // constructor
  public constructor(private http: HttpClient, private router: Router) {}
}
