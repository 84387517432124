import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  public midnightUpdateTrigger$ = new Subject<void>();
  public tabUnfreezeUpdateTrigger$ = new ReplaySubject<void>(1);

  constructor() {
    this.tabUnfreezeUpdateTrigger$.next();

    const msPerDate = 24 * 60 * 60 * 1000;
    const now = new Date().getTime();
    const midnight = new Date().setHours(24, 0, 0, 0);
    const msToMidnight = midnight - now;

    timer(msToMidnight, msPerDate).subscribe(() => {
      this.midnightUpdateTrigger$.next();
    });
  }
}
