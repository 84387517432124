import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatMenuPanel } from '@angular/material/menu';

@Component({
  selector: 'firebird-web-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['menu-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuButtonComponent {
  @Input() public label: string | undefined;
  @Input() public text: string | undefined;
  @Input() public matMenuTriggerFor: MatMenuPanel | null;
  @Input() public iconSize = 14;
  @Input() public disabled = false;
  @Input() public isClickable = false;

  @Output() public menuCloseTrigger: EventEmitter<void> =
    new EventEmitter<void>();
}
