import { NavigationChip } from '../../../../shared/interfaces/src/lib/navigation-chips.interface';
import { WeightedPrimaryLensType } from '../enums';
import {
  ForecastTypeList,
  WeightedFilter,
  WeightedLensList,
} from '../../../../forecast/src/lib/modules/overview/enums';
import { WeightedLegendBarConfig } from '../interfaces/legends.type';

export const NUMBER_OF_DAYS = 15;
export const TWELVE_HOURS_IN_MS = 12 * 1000 * 60 * 60;

export const CurrentTypeLegend = 'current';
export const PreviousTypeLegend = 'previous';
export const NormalTypeLegend = 'normal';
export const NormalsSeries = 'normals';
export const LegendIcons = [
  'thick-line',
  'medium-line',
  'line',
  'three-dashes-line',
  'thick-dashed-line',
  'vertical-dashed-line',
  'short-dashed-line',
  'short-dashed-line',
];
export const LegendMarketFirstIcons = ['thick-line', 'medium-line'];

export const WEIGHTED_ISO_COUNTRY_FORECAST_PERMISSIONS = [
  'Weighted_Forecasts',
  'ISO-Country',
];

export const WEIGHTED_DEGREE_FORECAST_PERMISSIONS = [
  'Weighted_Forecasts',
  'Degree_Day',
];

export const WEIGHTED_US_GAS_DEMAND_FORECAST_PERMISSIONS = [
  'Weighted_Forecasts',
  'BCF',
];

export const WeightedNavigationTab: NavigationChip[] = [
  {
    labelKey: 'iso',
    value: ['iso-country-forecast'],
    permission: WEIGHTED_ISO_COUNTRY_FORECAST_PERMISSIONS,
  },
  {
    labelKey: 'degree',
    value: ['weighted-degree-forecast'],
    permission: WEIGHTED_DEGREE_FORECAST_PERMISSIONS,
  },
  {
    labelKey: 'gasDemand',
    value: ['us-gas-demand-forecast'],
    permission: WEIGHTED_US_GAS_DEMAND_FORECAST_PERMISSIONS,
  },
];

export const ContinentList = [
  { text: 'North America', id: 'NA', permission: ['WF-NA'] },
  { text: 'Europe', id: 'EUR', permission: ['WF-EUR'] },
];

export const WEIGHTED_CONTINENT_PERMISSION_KEY = 'PoolSet';

export const WEIGHTED_MF_MODEL_PERMISSIONS = ['MarketFirst'];

export const modelDaysMapping: Record<string, number> = {
  ECMWF_OP: 10,
  ECMWF_OP_SHORT: 3,
  GEM_OP: 10,
  ECMWF_ENS: 15,
  ECMWF_ENS_SHORT: 6,
  GEM_ENS: 15,
  GFS_OP: 15,
  GFS_ENS: 15,
};

export const WEIGHTED_WS_MESSAGE_MODEL = 'CFAN';

export const WEIGHTED_ISO_COUNTRY_FORECAST_WS_MESSAGE_DATA_TABLE = [
  'model_aggregated_period_fcst',
  'aggregated_period_fcst',
];

export const WEIGHTED_DEGREE_FORECAST_WS_MESSAGE_DATA_TABLE =
  'fuel_weighted_forecasts';

export const WEIGHTED_CONTINENT_QUERY_PARAM = 'weightedContinent';

export const TableHeightConfig = {
  [WeightedPrimaryLensType.AG2]: 372,
  [WeightedPrimaryLensType.Model]: 517,
};

export const ChartHeightConfig = {
  [WeightedPrimaryLensType.AG2]: 0,
  [WeightedPrimaryLensType.Model]: 675,
};

export const IsoCountryNormalsLabels: {
  [key: string]: { label: string; normalType: string };
} = {
  AvgYrThirty: { label: '(30yr Avg)', normalType: ' Normals' },
  AvgYrTen: { label: '(10yr Avg)', normalType: ' Normals' },
  LastYear: { label: '(Last Year Obs)', normalType: '' },
};

export const NOT_AVAILABLE = '-';
export const NOT_AVAILABLE_VALUE = -888;
export const NOT_APPLICABLE = 'n/a';
export const NOT_APPLICABLE_VALUE = -999;

export const DefaultLegendPosition = {
  bottom: '25px',
};
export const defaultOptionGasConsumption = {
  forecastType: ForecastTypeList.Weighted,
  filter: WeightedFilter.GasDemand,
};
export const defaultOptionIsoForecast = {
  forecastType: ForecastTypeList.Weighted,
  filter: WeightedFilter.IsoCountryForecast,
};
export const defaultOptionDegreeDaysForecast = {
  forecastType: ForecastTypeList.Weighted,
  filter: WeightedFilter.IsoCountryForecast,
};

export const DegreeDaysLegendConfig: WeightedLegendBarConfig[] = [
  {
    ...defaultOptionDegreeDaysForecast,
    primaryLens: WeightedLensList.AgDDaysDiff,
  },
  {
    ...defaultOptionDegreeDaysForecast,
    primaryLens: WeightedLensList.AgTemperature,
  },
  {
    ...defaultOptionDegreeDaysForecast,
    primaryLens: WeightedLensList.MarketFirstDifference,
  },
];

export const IsoCountryLegendConfig: WeightedLegendBarConfig[] = [
  {
    ...defaultOptionIsoForecast,
    primaryLens: WeightedLensList.AgTemperature,
  },
  {
    ...defaultOptionIsoForecast,
    primaryLens: WeightedLensList.AgHddSdd,
  },
  {
    ...defaultOptionIsoForecast,
    primaryLens: WeightedLensList.AgFeelsLike,
  },
  {
    ...defaultOptionIsoForecast,
    primaryLens: WeightedLensList.AgTempDiff,
  },
  {
    ...defaultOptionIsoForecast,
    primaryLens: WeightedLensList.AgDDaysDiff,
  },
  {
    ...defaultOptionIsoForecast,
    primaryLens: WeightedLensList.MarketFirstDifference,
  },
];

export const GasDemandLegendConfig: WeightedLegendBarConfig[] = [
  {
    ...defaultOptionGasConsumption,
    primaryLens: WeightedLensList.ConsumptionDaily,
  },
  {
    ...defaultOptionGasConsumption,
    primaryLens: WeightedLensList.ConsumptionPeriod,
  },
];
