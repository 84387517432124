import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { RenewablesLocationPopupComponent } from '../renewables-location-popup/renewables-location-popup.component';
import { ContinentNames } from '../../enums';
import { continents } from '@firebird-web/shared-constants';
import {
  LocationData,
  RenewableRegionData,
  RenewablesProducts,
} from 'libs/renewable/src/lib/models';

@Component({
  selector: 'firebird-web-renewables-location',
  templateUrl: './renewables-location.component.html',
  styleUrls: ['./renewables-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenewablesLocationComponent implements OnDestroy {
  @Input() locationData: LocationData<RenewableRegionData[]>[];
  @Input() isAggregate = false;
  @Input()
  set region(value: string) {
    this.region$.next(value);
  }
  @Input()
  set continent(value: ContinentNames) {
    value === continents.EUROPE
      ? this.continent$.next(ContinentNames.eur)
      : this.continent$.next(ContinentNames.na);
  }
  @Input()
  set pool(value: string) {
    this.pool$.next(value);
  }
  @Input()
  set product(value: RenewablesProducts) {
    this.product$.next(value);
  }
  @Output() changeEvent = new EventEmitter();
  public continent$ = new BehaviorSubject<ContinentNames>(ContinentNames.eur);
  public region$ = new BehaviorSubject('Austria');
  public pool$ = new BehaviorSubject('');
  public product$ = new BehaviorSubject<RenewablesProducts>('wind');

  private readonly destroyed$ = new Subject<void>();

  constructor(private readonly dialog: MatDialog) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openLocationPopup() {
    const data = {
      locationData: this.locationData,
      continent: this.continent$.getValue(),
      region: this.region$.getValue(),
      pool: this.pool$.getValue(),
      product: this.product$.getValue(),
    };
    this.dialog
      .open(RenewablesLocationPopupComponent, {
        width: '300px',
        panelClass: 'firebird-dialog-panel',
        autoFocus: false,
        data: {
          data,
        },
      })
      .afterClosed()
      .subscribe((locationData) => {
        if (!locationData) {
          return;
        }
        this.changeEvent.emit(locationData);
      });
  }

  public getLocationText(): string {
    return this.isAggregate ? 'aggregate' : 'windFarm';
  }
}
