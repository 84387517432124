import { SettingsTabs } from '../interfaces';
import { SettingsTabKeys } from '../enums';

export const AppleStoreLink =
  'https://apps.apple.com/us/app/wsi-trader/id608428447?itsct=apps_box_badge&itscg=30200';
export const GoogleStoreLink =
  'https://play.google.com/store/apps/details?id=com.wsi.trader&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pli=1';
export const WindowFeatures = 'left=100,top=100,width=1200,height=800';

export const SettingsTabsList: SettingsTabs[] = [
  {
    label: 'Settings',
    key: SettingsTabKeys.Settings,
    children: [
      {
        label: 'Units',
        key: SettingsTabKeys.Units,
      },
      {
        label: 'Custom Locations',
        key: SettingsTabKeys.Favorite,
        permissions: ['Summary_Table'],
      },
      {
        label: 'Notifications',
        key: SettingsTabKeys.Notifications,
      },
    ],
  },
  {
    label: 'Mobile Apps',
    key: SettingsTabKeys.Mobile,
  },
  {
    label: 'Release Notes',
    key: SettingsTabKeys.Notes,
  },
];
