import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { locationPickerReducer } from './+state/location-picker.reducer';
import { LocationPickerEffects } from './+state/location-picker.effects';
import { LOCATION_PICKER_FEATURE_KEY } from './constants/location-picker.constant';
import { LocationPickerApiService } from './services/location-picker-api.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(LOCATION_PICKER_FEATURE_KEY, locationPickerReducer),
    EffectsModule.forFeature([LocationPickerEffects]),
  ],
  providers: [LocationPickerApiService],
})
export class LocationPickerStoreModule {}
