import { createAction, props } from '@ngrx/store';
import {
  ModelConfig,
  ModelFilterConfig,
  ModelRun,
  PanelData,
} from '../../models/model-page.types';

export const loadSinglePanelModels = createAction(
  '[ModelStore Page] Load Single Panel',
  props<{ silentLoading: boolean }>()
);
export const loadSinglePanelModelsSuccess = createAction(
  '[ModelStore/API] Load Single Panel Success',
  props<{ data: PanelData[] }>()
);
export const clearSinglePanelModelsImage = createAction(
  '[ModelStore/API] Clear Single Panel Image'
);
export const loadSinglePanelModelsFailure = createAction(
  '[ModelStore/API] Load Single Panel Failure',
  props<{ error: any }>()
);
// runs
export const loadSinglePanelRuns = createAction(
  '[ModelStore Page] Load Single Panel Runs'
);
export const loadSinglePanelRunsSuccess = createAction(
  '[ModelStore/API] Load Single Panel Success Runs',
  props<{ runs: ModelRun[] }>()
);
export const loadSinglePanelRunsFailure = createAction(
  '[ModelStore/API] Load Single Panel Failure Runs',
  props<{ error: any }>()
);
// configs
export const loadSinglePanelConfigs = createAction(
  '[ModelStore Page] Load Single Panel Configs'
);
export const loadSinglePanelConfigsSuccess = createAction(
  '[ModelStore/API] Load Single Panel Success Configs',
  props<{ configs: ModelFilterConfig[] }>()
);
export const loadSinglePanelConfigsFailure = createAction(
  '[ModelStore/API] Load Single Panel Failure Configs',
  props<{ error: any }>()
);
export const changeSinglePanelConfigs = createAction(
  '[ModelStore Page] Change Single Panel Configs',
  props<{ selectedConfig: ModelConfig }>()
);
export const setSinglePanelInUse = createAction(
  '[ModelStore Page] Set Single Panel In Use',
  props<{ inUse: boolean }>()
);
