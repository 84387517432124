import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CityLocationSelectionService } from './city-location-selection.service';
import { LocationService } from 'libs/shared/services/src/lib/location.service';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  filter,
  first,
  map,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
} from 'rxjs';
import { isNull, first as lodashFirst, isEqual } from 'lodash';
import { CommonUtils } from '@firebird-web/shared-utils';
import { getLocationDetailsFavoriteLists } from '@firebird-web/custom-locations-store';
import { City, IContinent, IRegion } from '../location.interface';
import {
  FavoriteCity,
  FavoriteList,
} from '../../../../../../custom-locations-store/src/lib/models/favorite-locations.types';
import { ContinentsEntity } from '@firebird-web/continents-store';
import {
  CityLocationSelectionDialogData,
  CityLocationSelectionDialogResult,
} from '../location.types';

@Component({
  selector: 'firebird-web-city-location-selection',
  templateUrl: './city-location-selection.component.html',
  styleUrls: ['./city-location-selection.component.scss'],
  providers: [CityLocationSelectionService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityLocationSelectionComponent implements OnInit, OnDestroy {
  public customLocationLists$ = this.store.select(
    getLocationDetailsFavoriteLists
  );
  public continentList$ = this.locService.continents$;
  public regionList$ = new BehaviorSubject<IRegion[]>([]);
  public selectedCustomLocationList$ = new BehaviorSubject<FavoriteList | null>(
    null
  );
  public selectedContinent$ = new BehaviorSubject<IContinent | null>(null);
  public selectedRegion$ = new BehaviorSubject<string | null>(null);
  public customLocationList: FavoriteCity[][] = [];
  public cityList: City[][] = [];
  public isLoading = false;

  private isRegionInitialized = false;
  private destroy$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: CityLocationSelectionDialogData,
    private readonly dialogRef: MatDialogRef<
      CityLocationSelectionComponent,
      CityLocationSelectionDialogResult
    >,
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
    private readonly locService: LocationService,
    private readonly cityLocationSelectionService: CityLocationSelectionService
  ) {}

  public get isCustomLocationSelected(): boolean {
    return !!this.selectedCustomLocationList;
  }

  public get isWidget(): boolean {
    return !!this.data?.isWidget;
  }

  private get initialSiteId(): string {
    return this.data?.siteId ?? '';
  }

  private get initialCustomLocationId(): string | number | undefined {
    return this.data?.customList?.id;
  }

  private get selectedCustomLocationList(): FavoriteList | null {
    return this.selectedCustomLocationList$.getValue();
  }

  public ngOnInit(): void {
    this.initLocation();
    this.subscribeToGetCityList();
    this.subscribeToGetCustomLocationList();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.regionList$.complete();
    this.selectedCustomLocationList$.complete();
    this.selectedContinent$.complete();
    this.selectedRegion$.complete();
    this.cityLocationSelectionService.clearCache();
  }

  public onCustomLocationChange(customLocation: FavoriteList): void {
    this.selectedCustomLocationList$.next(customLocation);
  }

  public onContinentChange({ id, text: name }: ContinentsEntity): void {
    this.setContinentAndRegion(of({ id, name }));
    this.selectedCustomLocationList$.next(null);
  }

  public onRegionChange(siteId: string | null): void {
    this.selectedRegion$.next(siteId);
  }

  public onCustomLocationSelected({
    siteId,
    continent,
    displayName,
    region,
  }: FavoriteCity): void {
    const [city] = displayName.split(',');

    this.onCitySelected({
      city,
      siteId,
      region,
      continent,
    } as City);
  }

  public onCitySelected({ siteId, region, city, continent }: City): void {
    const selectedCustomLocationList = this.selectedCustomLocationList;

    this.locService
      .findContinentByIdAsync(continent)
      .pipe(first())
      .subscribe((continentName = '') => {
        this.closeDialog({
          city,
          region,
          siteId,
          continent,
          continentName,
          ...(selectedCustomLocationList && {
            customList: {
              id: selectedCustomLocationList.id,
              name: selectedCustomLocationList.listName,
              continent: selectedCustomLocationList.continent,
              continents: selectedCustomLocationList.continents,
            },
          }),
        });
      });
  }

  public isCityActive({ siteId }: City): boolean {
    return this.initialSiteId === siteId && !this.initialCustomLocationId;
  }

  public isCustomLocationActive({
    siteId,
    profileListId,
  }: FavoriteCity): boolean {
    return (
      this.initialSiteId === siteId &&
      this.initialCustomLocationId === profileListId
    );
  }

  private initLocation(): void {
    const customListId = this.initialCustomLocationId;

    if (customListId) {
      this.customLocationLists$
        .pipe(
          first(),
          map((customLocationLists) =>
            customLocationLists.find(({ id }) => id === customListId)
          ),
          filter(
            (customLocationList): customLocationList is FavoriteList =>
              !!customLocationList
          )
        )
        .subscribe((customLocationList) => {
          this.selectedCustomLocationList$.next(customLocationList);
          this.isRegionInitialized = true;
        });

      return;
    }

    this.setContinentAndRegion(this.extractContinentFromDialogData());
  }

  private subscribeToGetCityList(): void {
    combineLatest([this.selectedContinent$, this.selectedRegion$])
      .pipe(
        filter((location): location is [IContinent, string] =>
          location.every((item) => !isNull(item))
        ),
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        tap(([{ id }, region]) => {
          if (
            !this.cityLocationSelectionService.isCityListInCache(
              id as string,
              region
            )
          ) {
            this.isLoading = true;
            this.cdr.markForCheck();
          }
        }),
        switchMap(([continent, region]) =>
          this.cityLocationSelectionService.getCityList(continent, region)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((cityList) => {
        this.cityList = cityList;
        this.isLoading = false;
        this.cdr.markForCheck();
      });
  }

  private subscribeToGetCustomLocationList(): void {
    this.selectedCustomLocationList$
      .pipe(
        filter(
          (
            selectedCustomLocationList
          ): selectedCustomLocationList is FavoriteList =>
            !isNull(selectedCustomLocationList)
        ),
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        map(({ sites }) => CommonUtils.toChunksByColumnsQty(sites, 3)),
        takeUntil(this.destroy$)
      )
      .subscribe((customLocationList) => {
        this.customLocationList = customLocationList;
      });
  }

  private setContinentAndRegion(continent$: Observable<IContinent>): void {
    continent$
      .pipe(
        first(),
        tap((continent) => this.selectedContinent$.next(continent)),
        switchMap((continent) =>
          this.cityLocationSelectionService.getRegionList(continent)
        )
      )
      .subscribe((regionList) => {
        this.regionList$.next(regionList);
        this.onRegionChange(
          this.isRegionInitialized
            ? (lodashFirst(regionList) as IRegion).regionName
            : this.extractRegionFromDialogData()
        );
        this.isRegionInitialized = true;
      });
  }

  private extractContinentFromDialogData(): Observable<IContinent> {
    const { continent: id, continentName } = this.data;

    return this.locService.findContinentByIdAsync(id).pipe(
      map((widgetContinentName) => ({
        id,
        name: this.isWidget ? widgetContinentName : continentName,
      }))
    );
  }

  private extractRegionFromDialogData(): string | null {
    const { siteId = null, region } = this.data;

    return region.toLowerCase().includes('regions') ? siteId : region;
  }

  private closeDialog(result?: CityLocationSelectionDialogResult): void {
    this.dialogRef.close(result);
  }
}
