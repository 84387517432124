import { createReducer, on, Action } from '@ngrx/store';
import * as LocationPickerActions from './location-picker.actions';
import {
  initialLocationPickerState,
  LOCATION_PICKERS_KEYS,
  LOCATION_PICKERS_PROPS_CONFIG,
} from '../constants/location-picker.constant';
import {
  LocationPickers,
  LocationPickerState,
} from '../models/location-picker.model';
import { first, has, pick, tail, isEmpty, merge } from 'lodash';

const reducer = createReducer(
  initialLocationPickerState,
  on(LocationPickerActions.initLocationPicker, (state) => ({
    ...state,
  })),
  on(LocationPickerActions.reinitLocationPicker, (state, { continent }) => {
    return {
      ...state,
      [LocationPickers.REGION]: {
        ...state[LocationPickers.REGION],
        continent,
      },
    };
  }),
  on(
    LocationPickerActions.initLocationPickerSuccess,
    (state, { persistedState }) => ({
      ...merge(state, persistedState),
      isInitialized: true,
    })
  ),
  on(LocationPickerActions.initLocationPickerFailure, (state) => ({
    ...state,
    isInitialized: true,
  })),
  on(
    LocationPickerActions.initDataForCurrentPage,
    (state, { path, queryParams }) => {
      const levels = path.split('/');
      const secondLevel = (first(tail(levels)) ?? '') as LocationPickers;

      if (
        !LOCATION_PICKERS_KEYS.includes(secondLevel) ||
        isEmpty(queryParams)
      ) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        [secondLevel]: {
          ...state[secondLevel],
          ...pick(queryParams, LOCATION_PICKERS_PROPS_CONFIG[secondLevel]),
        },
      };
    }
  ),
  on(LocationPickerActions.saveDataForRegion, (state, { location }) => ({
    ...state,
    [LocationPickers.REGION]: {
      ...state[LocationPickers.REGION],
      ...location,
      customList: undefined,
    },
  })),
  on(LocationPickerActions.saveDataForCustomList, (state, { customList }) => ({
    ...state,
    [LocationPickers.REGION]: {
      ...state[LocationPickers.REGION],
      customList,
    },
  })),
  on(
    LocationPickerActions.saveDataForCity,
    (state, { location, customList }) => {
      let locationCopy = {
        ...location,
      };

      if (!has(locationCopy, 'continent')) {
        locationCopy = {
          ...locationCopy,
          continent: state[LocationPickers.REGION].continent,
        };
      }

      if (!has(locationCopy, 'region')) {
        locationCopy = {
          ...locationCopy,
          region: state[LocationPickers.REGION].region,
        };
      }

      return {
        ...state,
        [LocationPickers.CITY]: {
          ...state[LocationPickers.CITY],
          ...locationCopy,
          customList: customList ? customList : undefined,
        },
      };
    }
  )
);

export function locationPickerReducer(
  state: LocationPickerState | undefined,
  action: Action
) {
  return reducer(state, action);
}
