import { Injectable } from '@angular/core';
import {
  AppVersionMessageType,
  BaseMessageType,
  WSModelsMessage,
  WSClusterGraphicMessage,
  WSSummaryGraphicsMessage,
  forceGetNewData,
  modelsNewDataAvailable,
  newDataAvailable,
  newNotificationsAvailable,
  newStaticGraphicsAvailable,
  newSummaryGraphicsAvailable,
} from './ws-refresh.actions';
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { environment } from '@firebird-web/shared-config';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  combineLatestWith,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  startWith,
  timer,
} from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { WSModelsMessageTriggerConfig } from './ws.interface';

const maxReconnectDelay = 60;
const noConnectionRefreshTimeout = 300;
enum ConnectionStatuses {
  init = 'init',
  error = 'error',
  connected = 'connected',
}

@Injectable({
  providedIn: 'root',
})
export class WsService {
  public readonly wsMessage$ = new Subject<WSModelsMessage[]>();
  public readonly wsClusterGraphicMessage$ = new BehaviorSubject<
    WSClusterGraphicMessage[]
  >([]);
  public readonly wsTeleconnectionMessage$ = new BehaviorSubject(null);
  // resolve web lock for the tab (can be used for cases when lock is not needed)
  public readonly wsModelMessage$ = new Subject<WSModelsMessage[]>();
  private lockResolver?: (value: unknown) => void;
  private readonly wsSummaryGraphicsMessage$ = new Subject<
    WSSummaryGraphicsMessage[]
  >();
  private readonly wsStaticGraphicsMessage$ = new Subject<BaseMessageType[]>();
  private readonly wsTextProductsMessage$ = new Subject<BaseMessageType[]>();

  public readonly wsAlertsMessage$ = new Subject<void>();
  public readonly wsNotificationsMessage$ = new Subject<void>();

  private readonly wsContinentRegionMessage$ = combineLatest([
    this.activatedRoute.queryParamMap,
    this.wsMessage$,
  ]).pipe(
    filter(([params, ws]) =>
      ws.some(this.filterContinentRegionMessages(params))
    ),
    map(([params, ws]) => ws.filter(this.filterContinentRegionMessages(params)))
  );
  private readonly wsContinentMessage$ = combineLatest([
    this.activatedRoute.queryParamMap,
    this.wsStaticGraphicsMessage$,
  ]).pipe(
    filter(([params, ws]) => {
      return ws.some(
        (w: { view: string }) => w.view === params.get('continent')
      );
    }),
    map(([params, ws]) => {
      return ws.filter(
        (w: { view: string }) => w.view === params.get('continent')
      );
    })
  );

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute
  ) {
    if (!environment.wsEnabled) {
      return;
    }
    let connectionStatus = ConnectionStatuses.init;
    let startRetryCount = 0;
    let reconnectRetryCount = 0;
    const queryTransport = [1, 2, 4].includes(
      +this.activatedRoute.snapshot.queryParams['ws-transport']
    )
      ? +this.activatedRoute.snapshot.queryParams['ws-transport']
      : null;
    let connectionTransport:
      | HttpTransportType.WebSockets
      | HttpTransportType.ServerSentEvents
      | HttpTransportType.LongPolling =
      queryTransport ?? HttpTransportType.WebSockets;

    timer(noConnectionRefreshTimeout * 1000, noConnectionRefreshTimeout * 1000)
      .pipe(filter(() => connectionStatus === ConnectionStatuses.error))
      .subscribe(() => {
        this.store.dispatch(forceGetNewData());
      });

    const getRefreshTimeout = (retry: number) => {
      return maxReconnectDelay / (retry * retry) < 1
        ? maxReconnectDelay * 1000
        : retry * retry * 1000;
    };

    const getConnection = (transport: HttpTransportType) =>
      new HubConnectionBuilder()
        .withUrl(`${environment.wsDomain}/check`, {
          transport,
          skipNegotiation: transport === HttpTransportType.WebSockets,
        })
        .configureLogging(LogLevel.None)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: ({ previousRetryCount }) => {
            reconnectRetryCount = previousRetryCount;
            return getRefreshTimeout(previousRetryCount);
          },
        })
        .build();

    const changeTransport = () => {
      if (connectionTransport === HttpTransportType.WebSockets) {
        connectionTransport = HttpTransportType.ServerSentEvents;
        return;
      }
      if (connectionTransport === HttpTransportType.ServerSentEvents) {
        connectionTransport = HttpTransportType.LongPolling;
        return;
      }
      connectionTransport = HttpTransportType.WebSockets;
    };

    const connections = {
      [HttpTransportType.WebSockets]: getConnection(
        HttpTransportType.WebSockets
      ),
      [HttpTransportType.ServerSentEvents]: getConnection(
        HttpTransportType.ServerSentEvents
      ),
      [HttpTransportType.LongPolling]: getConnection(
        HttpTransportType.LongPolling
      ),
    };

    async function start() {
      try {
        await Promise.race([
          connections[connectionTransport].start(),
          lastValueFrom(timer(2000)).then(() => {
            return Promise.reject('Connection timeout');
          }),
        ]);

        if (connectionStatus !== ConnectionStatuses.init) {
          store.dispatch(forceGetNewData());
        }
        connectionStatus = ConnectionStatuses.connected;
        startRetryCount = 0;
      } catch (err) {
        connectionStatus = ConnectionStatuses.error;
        connections[connectionTransport].stop();
        changeTransport();
        setTimeout(start, getRefreshTimeout(startRetryCount));
        if (connectionTransport === HttpTransportType.LongPolling) {
          startRetryCount++;
        }
      }
    }

    connections[connectionTransport].onreconnecting(
      () => (connectionStatus = ConnectionStatuses.error)
    );

    connections[connectionTransport].on(
      'models_data_changed',
      (message: WSModelsMessage[]) => {
        this.wsModelMessage$.next(message);
        this.store.dispatch(modelsNewDataAvailable({ data: message }));
      }
    );

    connections[connectionTransport].on(
      'data_changed',
      (message: WSModelsMessage[]) => {
        this.wsMessage$.next(message);
        setTimeout(() => {
          this.store.dispatch(newDataAvailable({ data: message }));
        }, 10000);
      }
    );

    connections[connectionTransport].on(
      'static_graphics_data_changed',
      (message: BaseMessageType[]) => {
        this.wsStaticGraphicsMessage$.next(message);
        this.store.dispatch(newStaticGraphicsAvailable({ data: message }));
      }
    );

    connections[connectionTransport].on(
      'summary_graphics_changed',
      (message: WSSummaryGraphicsMessage[]) => {
        this.wsSummaryGraphicsMessage$.next(message);
        this.store.dispatch(newSummaryGraphicsAvailable({ data: message }));
      }
    );

    connections[connectionTransport].on(
      'text_products_changed',
      (message: BaseMessageType[]) => {
        this.wsTextProductsMessage$.next(message);
      }
    );

    connections[connectionTransport].on(
      'app_version_changed',
      (message: AppVersionMessageType[]) => {
        if (
          message.some(
            ({ applicationName }) =>
              applicationName.toLowerCase() === 'ag2_trader'
          )
        ) {
          // eslint-disable-next-line
          // @ts-ignore
          location.reload(true);
        }
      }
    );

    connections[connectionTransport].on(
      'cluster_graphics_changed',
      (message: WSClusterGraphicMessage[]) => {
        this.wsClusterGraphicMessage$.next(message);
      }
    );

    connections[connectionTransport].on('teleconnection_changed', () => {
      this.wsTeleconnectionMessage$.next(null);
    });

    connections[connectionTransport].on('alerts_changed', () => {
      this.wsAlertsMessage$.next();
      this.store.dispatch(newNotificationsAvailable());
    });
    connections[connectionTransport].on('notifications_changed', () => {
      this.wsNotificationsMessage$.next();
      this.store.dispatch(newNotificationsAvailable());
    });
    connections[connectionTransport].onreconnected(() => {
      connectionStatus = ConnectionStatuses.connected;
      if (reconnectRetryCount > 0) {
        this.store.dispatch(forceGetNewData());
      }
      reconnectRetryCount = 0;
    });

    if (navigator?.locks?.request) {
      navigator.locks.request('ws_lock', { mode: 'shared' }, () => {
        return new Promise((resolve) => {
          this.lockResolver = resolve;
        });
      });
    }

    start();
  }

  public triggerOnUpdateExists<T>(
    modelsToTriggerUpdate?: Array<WSModelsMessageTriggerConfig>,
    continent?: string,
    region?: string
  ): (
    source: Observable<T>
  ) => Observable<{ data: T; isTriggeredFromWs: boolean }> {
    return (
      source: Observable<T>
    ): Observable<{ data: T; isTriggeredFromWs: boolean }> => {
      if (continent) {
        const continentParam = continent === 'MEX' ? 'NA' : continent;
        return source.pipe(
          combineLatestWith(
            this.wsMessage$.pipe(
              filter((messages) =>
                messages.some(
                  ({ view }) => view === continentParam || view === region
                )
              ),
              filter(
                (messages: WSModelsMessage[]) =>
                  !modelsToTriggerUpdate?.length ||
                  this.isContinentRegionMessagesMatchTriggerUpdateConfig(
                    messages,
                    modelsToTriggerUpdate
                  )
              ),
              startWith([])
            )
          ),
          map(([data, messages]) => ({
            data,
            isTriggeredFromWs: !!messages.length,
          }))
        );
      }
      return source.pipe(
        combineLatestWith(
          this.wsContinentRegionMessage$.pipe(
            filter(
              (messages: WSModelsMessage[]) =>
                !modelsToTriggerUpdate?.length ||
                this.isContinentRegionMessagesMatchTriggerUpdateConfig(
                  messages,
                  modelsToTriggerUpdate
                )
            ),
            startWith([])
          )
        ),
        map(([data, messages]) => ({
          data,
          isTriggeredFromWs: !!messages.length,
        }))
      );
    };
  }

  triggerOnModelUpdateExists<T>(
    modelsToTriggerUpdate?: string[]
  ): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) =>
      source.pipe(
        combineLatestWith(
          this.wsModelMessage$.pipe(
            filter((data: WSModelsMessage[]) => {
              return (
                !modelsToTriggerUpdate?.length ||
                data.some(({ model }) => modelsToTriggerUpdate.includes(model))
              );
            }),
            startWith([])
          )
        ),
        map(([data]) => data)
      );
  }

  triggerOnStaticGraphicsUpdateExists(
    productIdsToTriggerUpdate: string[],
    continent: string
  ) {
    return this.wsContinentMessage$.pipe(
      filter((data: BaseMessageType[]) => {
        return (
          !productIdsToTriggerUpdate?.length ||
          data.some(
            ({ productId, view }) =>
              productIdsToTriggerUpdate.includes(productId) &&
              view === continent
          )
        );
      }),
      startWith([]),
      distinctUntilChanged()
    );
  }

  triggerOnStaticGraphicsUpdateExistsWithoutView(
    productIdsToTriggerUpdate: string[]
  ) {
    return this.wsStaticGraphicsMessage$.pipe(
      filter((data: BaseMessageType[]) => {
        return (
          !productIdsToTriggerUpdate?.length ||
          data.some(({ productId }) =>
            productIdsToTriggerUpdate.includes(productId)
          )
        );
      }),
      startWith([]),
      distinctUntilChanged()
    );
  }

  triggerOnSummaryGraphicsUpdateExists(
    productIdToTriggerUpdate: string,
    continent: string
  ) {
    return this.wsSummaryGraphicsMessage$.pipe(
      filter((data: WSSummaryGraphicsMessage[]) => {
        return data.some(
          ({ groupName, region }) =>
            productIdToTriggerUpdate === groupName && region === continent
        );
      }),
      startWith([]),
      distinctUntilChanged()
    );
  }

  triggerOnTextProductUpdateExists(
    productIdsToTriggerUpdate: string[],
    continent: string
  ) {
    return this.wsTextProductsMessage$.pipe(
      filter((data: BaseMessageType[]) => {
        return (
          !productIdsToTriggerUpdate?.length ||
          data.some(
            ({ productId, view }) =>
              productIdsToTriggerUpdate.some((item) =>
                productId.includes(item)
              ) && view === continent
          )
        );
      }),
      startWith([])
    );
  }

  triggerOnTextProductUpdateExistsWithoutView(
    productIdsToTriggerUpdate: string[]
  ) {
    return this.wsTextProductsMessage$.pipe(
      filter((data: BaseMessageType[]) => {
        return (
          !productIdsToTriggerUpdate?.length ||
          data.some(({ productId }) =>
            productIdsToTriggerUpdate.some((item) =>
              productId.toUpperCase().includes(item)
            )
          )
        );
      }),
      startWith([])
    );
  }

  triggerOnProductIdUpdateExist(productIdsToTriggerUpdate: string[]) {
    return this.wsStaticGraphicsMessage$.pipe(
      filter((data: BaseMessageType[]) => {
        return (
          !productIdsToTriggerUpdate?.length ||
          data.some(({ productId }) =>
            productIdsToTriggerUpdate.some((item) => productId.includes(item))
          )
        );
      }),
      startWith([])
    );
  }

  private isContinentRegionMessagesMatchTriggerUpdateConfig(
    messages: WSModelsMessage[],
    config: Array<WSModelsMessageTriggerConfig>
  ): boolean {
    return messages.some(
      ({ model, dataTable }) =>
        config.flatMap(({ models }) => models).includes(model) &&
        config
          .flatMap(({ dataTables }) => dataTables)
          .includes(dataTable as string)
    );
  }

  private filterContinentRegionMessages(
    params: ParamMap
  ): ({ view }: WSModelsMessage) => boolean {
    const continentParam = params.get('continent');
    const continent = continentParam === 'MEX' ? 'NA' : continentParam;

    return ({ view }) => view === continent || view === params.get('region');
  }
}
