import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOption } from 'libs/shared/ui/src/lib/components/dropdown/dropdown.component';

@Component({
  selector: 'firebird-web-model-weighted-forecast-details-dropdown',
  templateUrl: './model-weighted-forecast-details-dropdown.component.html',
  styleUrls: ['./model-weighted-forecast-details-dropdown.component.scss'],
})
export class ModelWeightedForecastDetailsDropdownComponent {
  @Input() options: IOption[] = [];
  @Input() modelWeightedForecast: string = this.options[0]?.value;
  @Input() isWidget: boolean;
  @Output() modelWeightedForecastChanged = new EventEmitter();
}
