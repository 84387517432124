import { createAction, props } from '@ngrx/store';
import { ContinentsEntity } from './continents.models';

export const initContinents = createAction('[Continents Page] Init');

export const loadContinentsSuccess = createAction(
  '[Continents Store] Load Continents Success',
  props<{ continents: ContinentsEntity[] }>()
);

export const loadContinentsFailure = createAction(
  '[Continents Store] Load Continents Failure',
  props<{ error: any }>()
);
