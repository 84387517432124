import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasViewPermissionDirective } from './has-view-permission/has-view-permission.directive';
import { ContinentsByPermissionPipe } from './continents-by-permission/continents-by-permission.pipe';
import { Injectable } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NoPermissionComponent } from './no-permission/no-permission.component';

@Injectable({
  providedIn: 'root',
})
@NgModule({
  imports: [CommonModule, MatDialogModule, TranslateModule],
  declarations: [
    HasViewPermissionDirective,
    ContinentsByPermissionPipe,
    NoPermissionComponent,
  ],
  exports: [
    HasViewPermissionDirective,
    ContinentsByPermissionPipe,
    TranslateModule,
  ],
})
export class PermissionsModule {
  static injector: Injector;

  constructor(injector: Injector) {
    PermissionsModule.injector = injector;
  }
}
