import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject,
  Subscription,
  first,
  share,
  Observable,
  map,
  tap,
} from 'rxjs';
import {
  apiEndpoints,
  apiEndpoints as endpoints,
} from '@firebird-web/shared-constants';
import { environment } from '@firebird-web/shared-config';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from 'libs/shared/ui/src/lib/components/toast/toast.component';
import { MatDialog } from '@angular/material/dialog';
import { DashEditPanelComponent } from '../../../components/edit-panel/dash-edit-panel/dash-edit-panel.component';
import { DeletePanelComponent } from '../../../components/delete-panel/delete-panel.component';
import { AddNewDialogComponent } from '../../../components/add-new-dialog/add-new-dialog.component';
import { PrimaryLensEntity } from '@firebird-web/primary-lens-store';
import { WidgetModel } from '../../../interfaces';
import { ServerResponse } from '@firebird-web/shared-interfaces';
import { Dashboard } from '../../../types';

@Injectable({
  providedIn: 'root', // Or provide it in the appropriate module
})
export class DashboardDataService {
  updateSub!: Subscription;
  getDashSub!: Subscription;
  dashTitleList!: { id: string; title: string }[];
  titleSub = new BehaviorSubject<string>('');
  private dataSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private currentDashSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    []
  );
  savingStatus = new BehaviorSubject<{
    savingInProgress: boolean;
    dashboardIsSaved: boolean;
  }>({ savingInProgress: false, dashboardIsSaved: false });
  private data: any[] = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private snackbar: MatSnackBar,
    private matDialog: MatDialog
  ) {}

  getData() {
    return this.dataSubject.asObservable();
  }
  getSingleDashData() {
    return this.currentDashSubject;
  }

  public getAllDashboards(): Observable<ServerResponse<Dashboard[]>> {
    return this.http.get<ServerResponse<Dashboard[]>>(
      `${environment.apiDomain}/${endpoints.getAllDashboards}`
    );
  }

  deleteDashboard(id: string) {
    const deleteUrl = `${environment.apiDomain}/${endpoints.deleteDashboardById}/${id}`;
    this.savingStatus.next({
      savingInProgress: true,
      dashboardIsSaved: false,
    });
    this.http.delete(deleteUrl).subscribe(() => {
      this.data = this.data.filter((item) => item.id !== id);
      this.dataSubject.next(this.data);
      this.savingStatus.next({
        savingInProgress: false,
        dashboardIsSaved: true,
      });
    });
  }

  getPrimaryLensList(): Observable<PrimaryLensEntity> {
    return this.http
      .get(`${environment.apiDomain}/${apiEndpoints.getPrimaryLensList}`)
      .pipe(map((resp: any) => resp?.data));
  }
  createDashboard(dashData: {
    title: string;
    description: string;
    widgetConfig?: string;
  }) {
    const newDashUrl = `${environment.apiDomain}/${endpoints.createDashboard}`;
    this.savingStatus.next({
      savingInProgress: true,
      dashboardIsSaved: false,
    });
    return this.http.post(newDashUrl, dashData).pipe(
      tap(() =>
        this.savingStatus.next({
          savingInProgress: false,
          dashboardIsSaved: true,
        })
      )
    );
  }

  updateDashboard(
    id: string | null,
    title?: string,
    description?: string,
    dashData?: (WidgetModel & { config: unknown })[]
  ): Observable<unknown> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const newURLObject = {
      title: title || '',
      description: description || '',
      widgetConfig: JSON.stringify(dashData) || '',
    };
    this.savingStatus.next({
      savingInProgress: true,
      dashboardIsSaved: false,
    });
    const updateObs = this.http
      .put(
        `${environment.apiDomain}/${apiEndpoints.updateDashboard}/${id}`,
        newURLObject,
        { headers }
      )
      .pipe(
        share(),
        tap(() =>
          this.savingStatus.next({
            savingInProgress: false,
            dashboardIsSaved: true,
          })
        )
      );

    this.updateSub = updateObs.subscribe();

    return updateObs;
  }

  collectDashTitle(dashTitles: { id: string; title: string }[]) {
    this.dashTitleList = dashTitles;
  }
  returnToMonitors() {
    this.router.navigate(['/live-monitor']);
  }
  showDashDeletedToast(title: string) {
    this.snackbar.openFromComponent(ToastComponent, {
      duration: 5000,
      data: {
        type: 'success',
        message: ` Dashboard <b>${title}</b> has been deleted.`,
      },
    });
  }
  showDashEditToast(title: string) {
    this.snackbar.openFromComponent(ToastComponent, {
      duration: 5000,
      data: {
        type: 'success',
        message: `Dashboard <b>${title}</b> has been updated`,
      },
    });
  }
  showDashCopyToast(title: string) {
    this.snackbar.openFromComponent(ToastComponent, {
      duration: 5000,
      data: {
        type: 'success',
        message: `Dashboard <b>${title}</b> has been duplicated`,
      },
    });
  }
  openDashEditPanel(
    title: string,
    id: string,
    widgetConfig: any,
    description?: string
  ) {
    return this.matDialog
      .open(DashEditPanelComponent, {
        width: '580px',
        height: 'auto',
        data: {
          title: title,
          description: description,
          id: id,
          widgetConfig: widgetConfig,
        },
        panelClass: ['firebird-dashboard-panel'],
      })
      .afterClosed();
  }
  openDashDeletePanel(title: string) {
    return this.matDialog
      .open(DeletePanelComponent, {
        width: '427px',
        height: '124px',
        panelClass: ['firebird-dashboard-panel'],
        data: title,
      })
      .afterClosed();
  }
  openDashCopyDialog(data: any) {
    this.matDialog
      .open(AddNewDialogComponent, {
        width: '580px',
        height: 'auto',
        panelClass: ['firebird-dashboard-panel'],
        data: { widgetParams: data },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((resp: any) => {
        if (resp?.duplicate && resp) {
          this.showDashCopyToast(resp?.title);
          this.returnToMonitors();
        }
      });
  }
  checkDuplicateTitle(
    titleToCheck: string,
    edit?: boolean,
    id?: string,
    title?: string
  ) {
    const searchString = titleToCheck.toLowerCase();
    const isTitlePresent = this.dashTitleList
      .map((item) => item.title.toLowerCase())
      .includes(searchString);
    if (edit && isTitlePresent) {
      return !(title?.toLowerCase() === searchString);
    }
    return isTitlePresent;
  }

  getDashboardById(id: any) {
    const dashUrl = `${environment.apiDomain}/${endpoints.getDashboard}/${id}`;
    return this.http.get(dashUrl);
  }
  getDashBoardByDashId(id: string): Observable<any> {
    return this.http.get(
      `${environment.apiDomain}/${endpoints.getDashboard}/${id}`
    );
  }

  getDashData(id: any) {
    const dashUrl = `${environment.apiDomain}/${endpoints.getDashboard}/${id}`;
    return this.http.get(dashUrl);
  }
  clearCurrentDash() {
    this.currentDashSubject.next('');
  }
  updateCurrentDash(dashData: any) {
    this.currentDashSubject.next(dashData);
  }
  ngOnDestroy() {
    this.updateSub?.unsubscribe();
    this.getDashSub?.unsubscribe();
    this.savingStatus.complete();
  }
}
