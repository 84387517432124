import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@firebird-web/shared-config';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { CommonUtils } from '@firebird-web/shared-utils';
import { map, Observable } from 'rxjs';

type RunDatesMethodsMap = {
  iso: Observable<any>;
  degree: Observable<any>;
  gasDemand: Observable<any>;
};

export interface WeightedRunDate {
  text: string;
  id: string;
  isPermitted: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class WeightedRundateService {
  public constructor(private readonly http: HttpClient) {}

  public getWeightedIsoRunDate(continent: string) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getWeightedIsoCountryRunDate}`,
      { continent: continent }
    );
    return this.http.get(endPointUrl).pipe(map((resp: any) => resp?.data));
  }

  public getWeightedDegreeRunDate(continent: string) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getWeightedDegreeRunDate}`,
      { continent: continent }
    );
    return this.http.get(endPointUrl).pipe(map((resp: any) => resp?.data));
  }

  public getUsGasBcfRunDate() {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getUsGasBcfRunDate}`
    );
    return this.http.get(endPointUrl).pipe(map((resp: any) => resp?.data));
  }

  public getRunDateMethodByFilter(
    continent: string,
    filter: unknown | undefined
  ): Observable<WeightedRunDate[]> {
    return (
      {
        iso: this.getWeightedIsoRunDate(continent),
        degree: this.getWeightedDegreeRunDate(continent),
        gasDemand: this.getUsGasBcfRunDate(),
      } as RunDatesMethodsMap
    )[filter as keyof RunDatesMethodsMap];
  }
}
