export enum ForecastTypeList {
  Temperature = 'temperature',
  Precipitation = 'precipitation',
  Renewables = 'renewables',
  ForecastValues = 'forecastValues',
  ForecastDifference = 'forecastDifference',
  ForecastAnalysis = 'forecastAnalysis',
  Weighted = 'weighted',
}

export enum PrimaryLensList {
  Forecast = 'Forecast',
  DepartureFromNorms = 'DepartureFromNorms',
  ForecastDifference = 'ForecastDifference',
  FriskIndex = 'FriskIndex',
}

export enum PrimaryCompareLensList {
  TemperatureNorms = 'temperatureNorms',
  ForecastDifference = 'forecastDifference',
  FRiskIndex = 'fRiskIndex',
}

export enum WeightedLensList {
  ConsumptionDaily = 'consumptionDaily',
  ConsumptionPeriod = 'consumptionPeriod',
  AgTemperature = 'AgTemperature',
  AgHddSdd = 'AgHddSdd',
  AgFeelsLike = 'AgFeelsLike',
  AgTempDiff = 'AgTempDiff',
  AgDDaysDiff = 'AgDDaysDiff',
  MarketFirstDifference = 'marketFirstDifference',
}

export enum RenewablesLensList {
  WindCapacity = 'windCapacity',
  WindCapacityDifference = 'windCapacityDifference',
  SolarCapacity = 'solarCapacity',
  SolarCapacityDifference = 'solarCapacityDifference',
  TotalCapacity = 'totalCapacity',
  TotalCapacityDifference = 'totalCapacityDifference',
}

export enum ForecastValuesTableType {
  Temperature = 'temperature',
  FLTemperature = 'feelsLikeTemperature',
  MaxDewpoint = 'maxDewpoint',
  PrecipitationAmount = 'precipitationAmount',
  PrecipitationPrecip = 'precipitationPrecip',
  Wind = 'wind',
  Irradiance = 'irradiance',
  CloudCover = 'cloudCover',
}

export enum ForecastAnalysisTableType {
  Forecast = 'forecast',
  ForecastDifference = 'forecastDiff',
}

export enum ForecastDifferenceTableType {
  Temperature = 'temperature',
  AvgTemperature = 'avgTemperature',
  FLTemperature = 'feelsLikeTemperature',
  MaxDewpoint = 'maxDewpoint',
  PrecipitationAmount = 'precipitationAmount',
  PrecipitationPrecip = 'precipitationPrecip',
  WindSpeed = 'windSpeed',
  Gust = 'gust',
  IrradianceGHI = 'irradianceGHI',
  CloudCover = 'cloudCover',
}

export enum TemperatureFilter {
  MinMax = 'MinMax',
  Peak = 'Peak',
  AverageTemp = 'AverageTemp',
  DegreeDays = 'DegreeDays',
  GasDay = 'GasDay',
  MinMaxFeelsLike = 'MinMaxFeelsLike',
  MaxDewpoint = 'MaxDewpoint',
  CurrentTemp = 'currentTemp',
  FeelsLike = 'feelsLike',
  CompareLocation = 'compareLocation',
}

export enum PrecipitationFilter {
  PrecipAmount = 'precipAmount',
  ProbabilityOfPrecip = 'probabilityOfPrecip',
}

export enum WeightedFilter {
  GasDemand = 'gasDemand',
  IsoCountryForecast = 'isoCountryForecast',
}

export enum ForecastDetailsValuesFilter {
  Daily = 'daily',
  Hourly = 'hourly',
}

export enum ForecastAnalysisFilter {
  Daily = 'daily',
}

export enum ForecastRenewablesFilter {
  Wind = 'wind',
  Solar = 'solar',
  Total = 'total',
}

export enum TemperatureUnit {
  F = 'F',
  C = 'C',
}

export enum PrecipitationUnit {
  In = 'in',
  Mm = 'mm',
}

export enum WindUnit {
  MPH = 'mph',
  MPS = 'mps',
  KPH = 'kph',
}
