<div class="container">
  <div class="subscription-modal">
    <img class="ag-logo" src="/assets/logos/ag2logo.png" alt="" />
    <div class="message">
      <div>
        <img src="/assets/icons/information-circle-red.svg" alt="" />
      </div>
      <div>
        <p>
          Your account has not been assigned a valid subscription. Please
          contact
          <a href="mailto:support@atmosphericG2.com"
            >support@atmosphericG2.com</a
          >
          for assistance.
        </p>
      </div>
    </div>
    <div class="line"></div>
    <div class="contact">
      <p>Need Help ?</p>
      <p>
        contact
        <a href="mailto:support@atmosphericG2.com">support@atmosphericG2.com</a>
      </p>
    </div>
    <img class="sup-logo" src="/assets/logos/ag2.png" alt="" />
  </div>
</div>
