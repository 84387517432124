import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as RegionsActions from './regions.actions';
import { RegionsService } from '../../services/regions.service';
import { RegionsEntity } from './regions.models';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  of,
  switchMap,
} from 'rxjs';
import { RouterNavigatedAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { IRouteState } from '@firebird-web/route-store';

@Injectable()
export class RegionsEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter(
        ({
          payload: {
            routerState: { queryParams },
          },
        }: RouterNavigatedAction<IRouteState>) => {
          return queryParams['continent']; // continue if continentId is present in the query params
        }
      ),
      distinctUntilKeyChanged('payload', (previous, current) => {
        return (
          previous.routerState.queryParams['continent'] ===
          current.routerState.queryParams['continent']
        );
      }),
      switchMap(
        ({
          payload: {
            routerState: { queryParams },
          },
        }: RouterNavigatedAction<IRouteState>) => {
          return this.regionsService.getRegionsList(queryParams['continent']);
        }
      ),
      switchMap((regions: RegionsEntity[]) => {
        return [RegionsActions.loadRegionsSuccess({ regions })];
      }),
      catchError((error) => of(RegionsActions.loadRegionsFailure(error)))
    )
  );

  loadRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegionsActions.loadRegions),
      switchMap(({ selectedContinentId }) =>
        this.regionsService.getRegionsList(selectedContinentId)
      ),
      switchMap((regions: RegionsEntity[]) => {
        return [RegionsActions.loadRegionsSuccess({ regions })];
      }),
      catchError((error) => of(RegionsActions.loadRegionsFailure(error)))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly regionsService: RegionsService
  ) {}
}
