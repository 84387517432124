import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogInput } from '@firebird-web/shared-ui';
import { SettingsTabKeys } from './enums';
import { SettingsTabsList } from './constants';
import { SettingsTabs } from './interfaces';

@Component({
  selector: 'firebird-web-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnInit {
  public readonly tabsKey = SettingsTabKeys;

  public selectedTab: string = SettingsTabKeys.Units;
  public tabsList: SettingsTabs[] = SettingsTabsList;

  constructor(@Inject(MAT_DIALOG_DATA) private data: IDialogInput) {}

  ngOnInit(): void {
    this.setActiveTab();
  }

  public changeTab(tab: SettingsTabs): void {
    if (tab.key === SettingsTabKeys.Settings) {
      this.selectedTab = SettingsTabKeys.Units;
      return;
    }

    this.selectedTab = tab.key;
  }

  public trackByKey(_: number, tab: SettingsTabs): string {
    return tab.key;
  }

  private setActiveTab(): void {
    if (!Object.keys(this.data.params)?.length) {
      return;
    }

    this.selectedTab = this.data.params.labelKey ?? SettingsTabKeys.Units;
  }
}
