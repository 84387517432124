import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ICustomList,
  LocationSelectionDialogData,
  LocationSelectionDialogResult,
} from '../../../components/src/lib/location-selection/location.interface';
import { environment } from '@firebird-web/shared-config';
import {
  BehaviorSubject,
  combineLatestWith,
  filter,
  first,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { LocationSelectionComponent } from '../../../components/src/lib/location-selection/location-selection.component';
import { MatDialog } from '@angular/material/dialog';
import { ServerResponse } from '@firebird-web/shared-interfaces';
import {
  City,
  IContinent,
  IRegion,
} from '../../../components/src/lib/location-selection/location.interface';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { CommonUtils } from '@firebird-web/shared-utils';
import { CityLocationSelectionComponent } from '../../../components/src/lib/location-selection/city-location-selection/city-location-selection.component';
import {
  WeightedLocationSelectionComponent,
  weightedFilterConfigs,
} from '../../../components/src/lib/weighted-location-selection/weighted-location-selection.component';
import {
  IWidgetLocationData,
  WeightedContinentList,
} from '../../../../live-monitor/src/lib/constants';
import {
  ContinentsEntity,
  getAllContinents,
} from '@firebird-web/continents-store';
import { Store } from '@ngrx/store';
import { getAllRegions, loadRegions } from '@firebird-web/regions-store';
import {
  CityLocationSelectionDialogData,
  CityLocationSelectionDialogResult,
} from '../../../components/src/lib/location-selection/location.types';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  selectedContinent!: string;
  selectedRegion!: string;
  isCityView!: boolean;

  continentSubject: BehaviorSubject<any> = new BehaviorSubject<any>({
    name: '',
    id: '',
  });
  private localContinentSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({ name: '', id: '' });
  private localRegionSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  citySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  city$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  regionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  continent$: Observable<any> = this.continentSubject.asObservable();
  continents$ = this.store.select(getAllContinents);
  localContinent$: Observable<any> = this.localContinentSubject.asObservable();
  region$: Observable<string> = this.regionSubject.asObservable();
  localRegion$: Observable<string> = this.localRegionSubject.asObservable();
  isWidgetChange = false;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private store: Store
  ) {}

  public setSelectedCity(city: string) {
    this.city$.next(city);
  }
  getCustomList(): Observable<ICustomList[]> {
    return this.http.get<ICustomList[]>(
      `${environment.apiDomain}/${apiEndpoints.getCustomLists}`
    );
  }
  public setSelectedRegion(region: string, isLocal: boolean = false) {
    if (isLocal) {
      this.localRegionSubject.next(region);
    } else {
      this.regionSubject.next(region);
    }
  }

  public openLocationSelection(
    continent: string | null,
    continentName: string
  ): Observable<LocationSelectionDialogResult | undefined> {
    const isNa = continent === 'NA';
    if (isNa) {
      this.store.dispatch(
        loadRegions({
          selectedContinentId: continent ?? '',
        })
      );
    }
    this.store.select(getAllRegions);
    const dialogWith$ = isNa
      ? this.store.select(getAllRegions).pipe(
          map((regionList) => {
            const selectedEIARegions = regionList.filter(({ isEia }) => isEia);
            const selectedISORegions = regionList.filter(
              ({ siteId, regionName, isEia }) =>
                !isEia &&
                siteId !== 'All Regions' &&
                regionName !== 'All Cities'
            );
            return selectedEIARegions.length && selectedISORegions.length
              ? '600px'
              : '450px';
          })
        )
      : of('600px');
    return this.createContinentEntityAsync(continent ?? '').pipe(
      combineLatestWith(dialogWith$),
      first(),
      switchMap(([continentEntity, width]) => {
        return this.dialog
          .open(LocationSelectionComponent, {
            width,
            data: {
              continent: {
                id: continent as string,
                text:
                  continentName === 'Custom List'
                    ? continentEntity.text
                    : continentName,
              },
            },
          })
          .afterClosed();
      })
    );
  }

  public openWidgetLocationSelection(
    continent: string
  ): Observable<LocationSelectionDialogResult | undefined> {
    this.isWidgetChange = true;

    return this.createContinentEntityAsync(continent).pipe(
      switchMap((continentEntity) => {
        return this.dialog
          .open(LocationSelectionComponent, {
            width: '600px',
            data: {
              continent: continentEntity,
            },
          })
          .afterClosed();
      })
    );
  }

  public openWeightedWidgetLocation(category: string, continentId: string) {
    const availableFilters = ['iso', 'degree', 'gasDemand'] as const;
    const dialogRef = this.dialog.open(WeightedLocationSelectionComponent, {
      width: '500px',
      minHeight: '300px',
      maxHeight: '90vh',
      data: {
        filter: category,
        continentId: continentId,
        continentList: WeightedContinentList.filter(({ id }) =>
          weightedFilterConfigs[
            category as typeof availableFilters[number]
          ]?.availableContinents.includes(id)
        ),
      },
    });

    return dialogRef.afterClosed();
  }

  public openWidgetCityLocation({
    continent = '',
    region = '',
  }: Pick<
    CityLocationSelectionDialogData,
    'continent' | 'region'
  >): Observable<IWidgetLocationData> {
    this.isWidgetChange = true;

    return this.createContinentEntityAsync(continent).pipe(
      switchMap(({ text: continentName }) =>
        this.dialog
          .open<
            CityLocationSelectionComponent,
            CityLocationSelectionDialogData,
            CityLocationSelectionDialogResult
          >(CityLocationSelectionComponent, {
            panelClass: 'city-location-selection-dialog-panel',
            backdropClass: 'firebird-dialog-backdrop',
            width: '600px',
            minHeight: '300px',
            data: {
              region,
              continent,
              continentName,
              isWidget: true,
            },
          })
          .afterClosed()
      ),
      first(),
      filter((result): result is CityLocationSelectionDialogResult => !!result),
      map(
        ({ continentName: continent, region, city: name, siteId }) =>
          ({
            name,
            region,
            siteId,
            continent,
          } as IWidgetLocationData)
      )
    );
  }

  public openLocalLocationSelection(
    continent: string
  ): Observable<LocationSelectionDialogResult | undefined> {
    return this.createContinentEntityAsync(continent).pipe(
      switchMap((continentEntity) => {
        return this.dialog
          .open<
            LocationSelectionComponent,
            LocationSelectionDialogData,
            LocationSelectionDialogResult
          >(LocationSelectionComponent, {
            width: '600px',
            data: {
              continent: continentEntity,
            },
          })
          .afterClosed();
      })
    );
  }

  public createContinentEntityAsync(
    continentId: string
  ): Observable<ContinentsEntity> {
    return this.findContinentByIdAsync(continentId).pipe(
      map((continentName) => {
        return {
          id: continentId,
          text: continentName,
        };
      })
    );
  }

  public setSelectedContinentById(continentId: string) {
    this.continents$.pipe(first()).subscribe((continents) => {
      const continent = continents.find((item: any) => item.id === continentId);
      this.continentSubject.next(continent);
    });
  }

  public setSelectedContinent(continent: IContinent, isLocal: boolean = false) {
    if (isLocal) {
      this.localContinentSubject.next(continent);
    } else {
      this.continentSubject.next(continent);
    }
  }

  public setSelectedContinentAsync(
    continent: IContinent,
    isLocal: boolean = false
  ) {
    return this.findContinentByIdAsync(continent.id).pipe(
      first(),
      map((continent) => {
        if (isLocal) {
          this.localContinentSubject.next(continent);
        } else {
          this.continentSubject.next(continent);
        }
      })
    );
  }

  public findContinentByIdAsync(id: string | null) {
    return this.continents$.pipe(
      map((continents) => {
        return continents?.find((item) => item.id === id)?.text ?? '';
      })
    );
  }

  public getRegionsList(continentId: string | null): Observable<IRegion[]> {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getRegionsList}`,
      { continentId }
    );

    return this.http.get<ServerResponse<any>>(endPointUrl).pipe(
      map(({ data }) =>
        data.filter(
          (item: any) =>
            !['ALL REGIONS', 'ALL CITIES'].includes(item.siteId.toUpperCase())
        )
      ),
      map((filteredData) => {
        const uniqueSites = new Map();
        filteredData.forEach((item: any) => {
          if (!uniqueSites.has(item.siteId)) {
            uniqueSites.set(item.siteId, item);
          }
        });
        return Array.from(uniqueSites.values());
      })
    );
  }

  public getRegionsByContinent(continent: string) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getContinentCitiesList}`,
      { continent }
    );
    this.http.get(endPointUrl).subscribe((resp: any) => {
      if (resp.data) {
        this.getRegionsList(resp.data);
      }
    });
  }

  public getCitiesByRegionAndContinent(
    region: string,
    continent: string | null
  ): Observable<City[]> {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getCitiesList}`,
      { continent, region: encodeURIComponent(region) }
    );

    return this.http
      .get<ServerResponse<City[]>>(endPointUrl)
      .pipe(map(({ data }) => data));
  }
}
