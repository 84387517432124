import { createReducer, on, Action } from '@ngrx/store';

import * as ModelStoreActions from '../model-store.actions';

import { ComparisonData } from '../model-store.models';
import {
  modelConfigsAdapter,
  modelRunsAdapter,
  modelDataAdapter,
} from '../model-store.adapters';

export const initialModelStoreState: ComparisonData = {
  selectedConfigs: null,
  runs: modelRunsAdapter.getInitialState({ loaded: false }),
  configs: modelConfigsAdapter.getInitialState({ loaded: false }),
  main: modelDataAdapter.getInitialState(),
  alwaysShowLatest: true,
  inUse: false,
  loaded: false,
};

const reducer = createReducer(
  initialModelStoreState,
  on(
    ModelStoreActions.changeModelComparisonModelsConfigs,
    (state, { selectedConfigs }) => ({
      ...state,
      selectedConfigs,
    })
  ),
  on(ModelStoreActions.loadModelComparisonConfigs, (state) => ({
    ...state,
    configs: { ...state.configs, loaded: false },
  })),
  on(
    ModelStoreActions.loadModelComparisonConfigsSuccess,
    (state, { configs }) => {
      return {
        ...state,
        configs: modelConfigsAdapter.setAll(configs, {
          ...state.configs,
          loaded: true,
          error: null,
        }),
      };
    }
  ),
  on(ModelStoreActions.loadModelComparisonRuns, (state) => ({
    ...state,
    runs: { ...state.runs, loaded: false },
  })),
  on(ModelStoreActions.loadModelComparisonRunsSuccess, (state, { runs }) => {
    return {
      ...state,
      runs: modelRunsAdapter.setAll(runs, {
        ...state.runs,
        loaded: true,
        error: null,
      }),
    };
  }),
  on(
    ModelStoreActions.loadModelComparisonModels,
    (state, { silentLoading }) => ({
      ...state,
      loaded: silentLoading ? state.loaded : false,
    })
  ),
  on(ModelStoreActions.loadModelComparisonModelsSuccess, (state, { data }) => {
    const mainModels = modelDataAdapter.setMany(data, state.main);
    return {
      ...state,
      main: mainModels,
      loaded: true,
    };
  }),
  on(ModelStoreActions.setModelComparisonInUse, (state, { inUse }) => ({
    ...state,
    inUse,
  })),
  on(
    ModelStoreActions.updateModelComparisonAlwaysShowLatest,
    (state, { alwaysShowLatest }) => ({
      ...state,
      alwaysShowLatest,
    })
  )
);

export function modelComparisonReducer(
  state: ComparisonData | undefined,
  action: Action
) {
  return reducer(state, action);
}
