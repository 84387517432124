import { IImapDefault } from './widget-types';
import { ISummaryTableWidgetPopoverConfig } from '../interfaces';
import { DropdownOption } from '../../../../shared/interfaces/src/lib';

export const DefaultImapSettings: IImapDefault = {
  isNew: true,
  layers: ['tileObsSatellite', 'tileRadar'],
  showRoadsAndPlaces: true,
  mapLayers: ['satelliteObs'],
};

export const SUMMARY_TABLE_WIDGET_POPOVER_DEFAULT_CONFIG: ISummaryTableWidgetPopoverConfig =
  {
    id: '',
    filter: '',
    region: '',
    runDate: '',
    continent: '',
    unitSymbol: '',
    primaryLens: '',
    secondaryLens: '',
    isOpen: false,
    positions: null,
    forecastData: null,
    customList: undefined,
  };

export const WEIGHTED_WIDGET_PANEL_NO_ENSEMBLE_SPREAD_DROPDOWN_OPTION: DropdownOption =
  {
    labelKey: 'No Ensemble Spread',
    value: 'none',
  };
