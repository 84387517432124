import { SelectedConfigs, ModelConfig } from '../../models/model-page.types';

export const getConfigs = (
  selectedConfigs: SelectedConfigs | null
): ModelConfig[] => {
  if (selectedConfigs === null) {
    return [];
  }
  const { models, parameter, view, run } = selectedConfigs;
  return models.map((model) => {
    const modelParameter = model.parameters.find(
      ({ title }) => parameter?.title === title
    );
    return {
      model,
      parameter: modelParameter,
      view,
      run,
    };
  }) as ModelConfig[];
};
