<div class="favorite-location component">
  <div class="header">
    <firebird-web-heading
      type="h3"
      [labelKey]="'favoriteLocations' | translate"
    ></firebird-web-heading>
  </div>
  <p class="description">
    Easily create a favorite list to view the locations most important to you. A
    favorite list can contain any location you have access to within your
    subscription.
  </p>
  <div class="sub-header">
    <firebird-web-heading
      type="h4"
      [labelKey]="'favoriteLocationLists' | translate"
    ></firebird-web-heading>
    <firebird-web-button
      class="create-list-button"
      iconName="add-circle"
      [iconFirst]="true"
      [style]="'additional'"
      [labelKey]="'createList' | translate"
      (click)="onListEdit(true)"
    ></firebird-web-button>
  </div>
  <div class="list-row" *ngFor="let list of favoriteLists$ | async">
    <div
      class="row-wrapper"
      (mouseenter)="onHover(list)"
      (mouseleave)="onLeave()"
    >
      <div class="list-name" (click)="onListEdit()">{{ list.listName }}</div>
      <div
        [ngClass]="{ hidden: list.id !== selectedList?.id }"
        class="action-buttons-container"
      >
        <firebird-web-settings-action-buttons
          [isEditVisible]="true"
          [list]="list"
          (edit)="onListEdit()"
          (deleteList)="onListDelete($event)"
          (copyList)="onListCopy($event)"
        ></firebird-web-settings-action-buttons>
      </div>
    </div>
  </div>
</div>
