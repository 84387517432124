import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LOCATION_PICKER_FEATURE_KEY } from '../constants/location-picker.constant';
import {
  LocationPickers,
  LocationPickerState,
} from '../models/location-picker.model';

export const getLocationPickerState =
  createFeatureSelector<LocationPickerState>(LOCATION_PICKER_FEATURE_KEY);

export const getRegionData = createSelector(
  getLocationPickerState,
  (state) => state[LocationPickers.REGION]
);

export const getCityData = createSelector(
  getLocationPickerState,
  (state) => state[LocationPickers.CITY]
);

export const isInitialized = createSelector(
  getLocationPickerState,
  (state) => state.isInitialized
);
