import { EurRenewableWidgetRuns, seriesModelRunOrderMap } from '../constants';
import { CustomSeries } from '../models';

export const isThreeDashesLineGraphIcon = (icon: string | undefined): boolean =>
  icon === seriesModelRunOrderMap[4].lineIcon;

export const getWidgetLegendOrder = (key: string): number => {
  if (key.includes('12')) {
    return 2;
  }

  if (key.includes('6')) {
    return 1;
  }

  return 0;
};

export const getEurRenewableLineVisibility = (
  isWidget: boolean,
  item: CustomSeries,
  widgetLegend: Record<string, boolean>
): boolean => {
  if (isWidget) {
    if (item.id === 'OBS') {
      return true;
    }

    const parseLine = item.id.split('_');
    const buildModel = `${parseLine[0]}_${parseLine[1]}`;
    const orderKey = EurRenewableWidgetRuns[item.modelRunOrder as number];
    const legendKey = `${buildModel}_${orderKey}`;

    return widgetLegend[legendKey] || false;
  }

  return item?.visible || false;
};
