import { environment } from '@firebird-web/shared-config';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { NaRenewablesHeatMapEndpoints } from '../types';

const { apiDomain } = environment;

export const NA_RENEWABLES_HEAT_MAP_ENDPOINTS: NaRenewablesHeatMapEndpoints = {
  heatMap: `${apiDomain}/${apiEndpoints.getNaRenewablesHeatMap}`,
  forecast: `${apiDomain}/${apiEndpoints.getNaRenewablesHeatMapForecast}`,
  csv: `${apiDomain}/${apiEndpoints.getNaRenewablesHeatMapCSV}`,
};
