import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as LocationPickerActions from './location-picker.actions';
import {
  animationFrameScheduler,
  catchError,
  distinctUntilChanged,
  first,
  map,
  observeOn,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { Router } from '@angular/router';
import { UserSettingService } from '@firebird-web/user-settings-store';
import { LocationPickerFacade } from './location-picker.facade';
import { LocationPickerApiService } from '../services/location-picker-api.service';

@Injectable()
export class LocationPickerEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly userSettingsService: UserSettingService,
    private readonly locationPicker: LocationPickerFacade,
    private readonly locationPickerApiService: LocationPickerApiService
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationPickerActions.initLocationPicker),
      switchMap(() => this.locationPickerApiService.getPickerData()),
      first(),
      switchMap((persistedState) => [
        LocationPickerActions.initLocationPickerSuccess({ persistedState }),
        LocationPickerActions.initDataForCurrentPage({
          path: this.userSettingsService.getCurrentLocationUrlArray().join('/'),
          queryParams: this.userSettingsService.getCurrentLocationQueryParams(),
        }),
        LocationPickerActions.initCityData(),
      ]),
      catchError((error) =>
        of(LocationPickerActions.initLocationPickerFailure({ error }))
      )
    )
  );

  initCityData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationPickerActions.initCityData),
      switchMap(() => this.locationPicker.getCityData()),
      first(),
      distinctUntilChanged(
        (prev, curr) =>
          prev.continent === curr.continent && prev.region === curr.region
      ),
      map((location) => {
        return LocationPickerActions.saveDataForCity({ location });
      })
    )
  );

  saveLocationPickerRegionData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationPickerActions.saveDataForRegion),
        // TODO: navigate with new query parameters is being canceled by another navigate with old parameters, added timeout to fix it, need to find a better solution
        observeOn(animationFrameScheduler),
        tap(({ location, config }) => {
          if (
            this.userSettingsService.getCurrentLocationUrlArray()[1] !==
              'region-view' &&
            !config?.redirect
          ) {
            return;
          }
          this.router.navigate(
            config?.redirect ? [`1-15days/region-view/${config?.path}`] : [],
            {
              queryParamsHandling: 'merge',
              queryParams: {
                ...location,
                ...config?.queryParams,
              },
            }
          );
        })
      ),
    { dispatch: false }
  );

  saveLocationPickerCityData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationPickerActions.saveDataForCity),
        // TODO: navigate with new query parameters is being canceled by another navigate with old parameters, added timeout to fix it, need to find a better solution
        observeOn(animationFrameScheduler),
        tap(({ location, config }) => {
          if (
            this.userSettingsService.getCurrentLocationUrlArray()[1] !==
              'city-view' &&
            !config?.redirect
          ) {
            return;
          }
          this.router.navigate(
            config?.redirect ? [`1-15days/city-view/${config?.path}`] : [],
            {
              queryParamsHandling: 'merge',
              queryParams: {
                ...location,
              },
            }
          );
        })
      ),
    { dispatch: false }
  );
}
