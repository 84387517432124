import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@firebird-web/shared-config';
import {
  filter,
  switchMap,
  map,
  of,
  combineLatest,
  share,
  Observable,
  forkJoin,
} from 'rxjs';
import {
  ModelRunResponse,
  ModelPanelConfigResponse,
  ModelViewResponse,
  RemapDisplayName,
  ModelParameterResponse,
  ModelData,
  ModelFilterConfig,
  ModelConfigIds,
  ModelCycleResponse,
  ModelRun,
  ModelConfig,
  PanelData,
  ModelConfigExtraParams,
} from '@firebird-web/model-store';
import { DatePipe } from '@angular/common';
import { ServerResponse } from '@firebird-web/shared-interfaces';
import { ImageCachingService } from 'libs/shared/services/src/lib/image-caching-service/image-caching-service.service';
import { DefaultModelImage } from '../constants/constants';
import { DataHelperService } from './data-helper.service';

@Injectable()
export class ModelDataService {
  constructor(
    private readonly http: HttpClient,
    private readonly imageService: ImageCachingService,
    private readonly datePipe: DatePipe,
    private readonly dataHelperService: DataHelperService
  ) {}

  getPanelData$(params: ModelConfigIds, loadImagesInBackground = false) {
    const subLink = `api/v1/ModelPage/single-panel`;
    const link = `${environment.apiDomain}/${subLink}`;
    return this.http.post<ServerResponse<ModelData[]>>(link, params).pipe(
      filter((response) => response?.status === 'OK'),
      switchMap(({ data }) => {
        const subArrayWithImages = data.map((image) =>
          this.imageService.addCachedImage$(image, loadImagesInBackground)
        );

        if (!subArrayWithImages.length) {
          return of([DefaultModelImage]);
        }
        return combineLatest(subArrayWithImages).pipe(
          filter((data) => !!data.length)
        );
      }),
      share()
    );
  }

  getSinglePanelConfig$() {
    const subLink = 'api/v1/ModelPage/single-panel-config';
    const link = `${environment.apiDomain}/${subLink}`;
    return this.http.get<ServerResponse<ModelPanelConfigResponse[]>>(link).pipe(
      map(({ data }) => this.remapModels(data)),
      share()
    );
  }

  getMultiPanelConfig$() {
    const subLink = 'api/v1/ModelPage/multi-panel-config';
    const link = `${environment.apiDomain}/${subLink}`;
    return this.http.get<ServerResponse<ModelPanelConfigResponse[]>>(link).pipe(
      map(({ data }) => this.remapModels(data)),
      share()
    );
  }

  getComparisonPanelConfig$() {
    const subLink = 'api/v1/ModelPage/comparison-panel-config';
    const link = `${environment.apiDomain}/${subLink}`;
    return this.http.get<ServerResponse<ModelPanelConfigResponse[]>>(link).pipe(
      map(({ data }) => this.remapModels(data)),
      share()
    );
  }

  getPeriodMatrixConfig$() {
    return this.http
      .get<ServerResponse<ModelPanelConfigResponse[]>>(
        `${environment.apiDomain}/api/v1/ModelPage/period-matrix-config`
      )
      .pipe(
        map(({ data }) => this.remapModels(data)),
        share()
      );
  }

  getRuns$(modelId: string, parameter: string, period: string) {
    const subLink = `api/v1/ModelPage/single-panel-runs/${modelId}/${parameter}/${period}`;
    const link = `${environment.apiDomain}/${subLink}`;
    return this.http.get<ServerResponse<ModelRunResponse[]>>(link).pipe(
      map(({ data }) => this.remapTitle<ModelRunResponse>(data)),
      share()
    );
  }

  getModelComparisonRuns$() {
    const subLink = `api/v1/ModelPage/comparison-panel-runs`;
    const link = `${environment.apiDomain}/${subLink}`;
    return this.http.get<ServerResponse<ModelRunResponse[]>>(link).pipe(
      map(({ data }) => this.remapTitle<ModelRunResponse>(data)),
      share()
    );
  }

  getPeriodMatrixRuns$() {
    const subLink = `api/v1/ModelPage/period-matrix-panel-runs`;
    const link = `${environment.apiDomain}/${subLink}`;
    return this.http.get<ServerResponse<ModelRunResponse[]>>(link).pipe(
      map(({ data }) => data),
      map((data) => this.remapTitle<ModelRunResponse>(data)),
      share()
    );
  }

  getDiffConfig(configs: ModelConfig[], diff: number): ModelConfig[] {
    return configs.map(({ run, ...rest }) => ({
      ...rest,
      run: this.getRunWithDiff(run, diff),
    }));
  }

  addHours(date: Date, hours: number) {
    // move to utils
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);

    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }

  public updatePanelInitTime(
    panelConfigs: ModelConfig[]
  ): Observable<ModelConfig[]> {
    return forkJoin(
      panelConfigs.map((config) => {
        const {
          model: { model, period },
          parameter: { id: parameter },
          run,
        } = config;

        return this.getRuns$(model, parameter, period).pipe(
          map(
            (runs) =>
              ({
                ...config,
                initType: this.dataHelperService.getRun(run, runs),
              } as ModelConfig)
          )
        );
      })
    );
  }

  getPanelDataForConfig(
    panelConfigs: ModelConfig[],
    loadImagesInBackground = false,
    extraParams = {} as ModelConfigExtraParams
  ): Observable<PanelData[]> {
    return combineLatest(
      panelConfigs.map((config) => {
        const params: ModelConfigIds = {
          initType: config.initType?.id || config.run.id,
          model: config.model.model,
          modelGroup: config.model.group,
          parameter: config.parameter.id,
          periodType: config.model.period,
          view: config.view.id,
          ...extraParams,
        };

        return this.getPanelData$(params, loadImagesInBackground).pipe(
          map((data) => {
            return {
              model: config.model.model,
              data,
              trackId: config.trackId || '',
              index: config.index,
            };
          })
        );
      })
    );
  }

  private getRunWithDiff({ id }: ModelRun, diff: number) {
    const date = this.addHours(new Date(id), diff) || '';
    return {
      id: date,
      title: '',
    };
  }

  private remapTitle<T extends { display_name: string }>(
    configs: T[]
  ): RemapDisplayName<T>[] {
    return configs.map(({ display_name, ...rest }) => ({
      ...rest,
      title: display_name,
    }));
  }

  private remapCycles(
    cycles: RemapDisplayName<ModelCycleResponse>[]
  ): ModelRun[] {
    return cycles.map((cycle) => ({
      ...cycle,
      id: cycle.title,
    }));
  }

  private remapModels(
    configs: ModelPanelConfigResponse[]
  ): ModelFilterConfig[] {
    return configs.map(
      ({ model, modelGroup, modelName, parameters, views, cycles, ...rest }) =>
        ({
          title: modelName,
          id: model + modelName,
          model: model,
          group: modelGroup,
          parameters:
            parameters && this.remapTitle<ModelParameterResponse>(parameters),
          views: views && this.remapTitle<ModelViewResponse>(views),
          cycles:
            cycles &&
            this.remapCycles(this.remapTitle<ModelCycleResponse>(cycles)),
          ...rest,
        } as ModelFilterConfig)
    );
  }
}
