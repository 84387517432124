<div
  class="menu-button"
  (click)="isClickable ? null : $event.stopPropagation()"
>
  <span class="menu-button__label" *ngIf="label">
    {{ label }}
  </span>
  <button
    mat-button
    class="menu-button__button"
    [matMenuTriggerFor]="matMenuTriggerFor"
    [disabled]="disabled"
    (menuClosed)="menuCloseTrigger.emit()"
  >
    <span class="menu-button__content">
      <span class="menu-button__text">{{ text }}</span>
      <firebird-web-icon
        class="menu-button__icon"
        name="arrow-down-black"
        [width]="iconSize + 'px'"
        fillColor="black"
      ></firebird-web-icon>
    </span>
  </button>
</div>
