import moment from 'moment/moment';
import { get, isEqual, reduce, first } from 'lodash';
import {
  NA_RENEWABLES_HEAT_MAP_HEADER_HEIGHT,
  NA_RENEWABLES_HEAT_MAP_ROW_HEIGHT,
  NA_RENEWABLES_HEAT_MAP_ROW_WITH_SEPARATOR_HEIGHT,
} from '../constants';
import {
  NaRenewablesHeatMapCellRenderType,
  NaRenewablesPrimaryLens,
} from '../enums';
import { NaRenewablesForecast, NaRenewablesHeatMapRowData } from '../types';

const findLatestForecast = (
  forecastList: NaRenewablesForecast[]
): NaRenewablesForecast =>
  reduce(
    forecastList,
    (acc, forecast) => {
      const { localInitTime: accLocalInitTime } = acc;
      const { localInitTime: forecastLocalInitTime } = forecast;

      if (!accLocalInitTime) {
        return forecast;
      }

      return moment(forecastLocalInitTime).isAfter(accLocalInitTime)
        ? forecast
        : acc;
    },
    {} as NaRenewablesForecast
  );

const filterLatestForecasts = (
  forecastList: NaRenewablesForecast[],
  latestLocalInitTime: string
): NaRenewablesForecast[] =>
  forecastList.filter(
    ({ localInitTime }) => localInitTime === latestLocalInitTime
  );

export const calculateHeatMapHeight = (rowCount: number): number => {
  const amountOfRowsWithSeparator = 1;
  const standardRowTotalHeight =
    (rowCount - amountOfRowsWithSeparator) * NA_RENEWABLES_HEAT_MAP_ROW_HEIGHT;
  const rowWithSeparatorTotalHeight =
    amountOfRowsWithSeparator *
    NA_RENEWABLES_HEAT_MAP_ROW_WITH_SEPARATOR_HEIGHT;
  const extraHeight = 1;

  return (
    NA_RENEWABLES_HEAT_MAP_HEADER_HEIGHT +
    standardRowTotalHeight +
    rowWithSeparatorTotalHeight +
    extraHeight
  );
};

export const isRowSeparatorRowClassRule = (
  rowData: NaRenewablesHeatMapRowData | undefined
): boolean => get(rowData, ['hour', 'isRowSeparator'], false);

export const isWeekend = (date: string): boolean =>
  [0, 6].includes(moment(date, ['MM-DD-YYYY', 'YYYY-MM-DD']).day());

export const isHourRenderType = (
  renderType: NaRenewablesHeatMapCellRenderType
): boolean => renderType === NaRenewablesHeatMapCellRenderType.HOUR;

export const isNaRenewablesForecastDifference = (
  primaryLens: NaRenewablesPrimaryLens
): boolean => primaryLens === NaRenewablesPrimaryLens.FORECAST_DIFFERENCE;

export const getNaRenewablesLatestForecast = (
  forecastList: NaRenewablesForecast[]
): NaRenewablesForecast => {
  const latestForecastByModel = forecastList.find(({ name }) =>
    name.toLowerCase().includes('latest')
  );

  if (latestForecastByModel) {
    return latestForecastByModel;
  }

  const latestForecastsByLocalTime = filterLatestForecasts(
    forecastList,
    findLatestForecast(forecastList).localInitTime
  );

  return first(latestForecastsByLocalTime) as NaRenewablesForecast;
};

export const getNaRenewablesLatestComparisonForecast = (
  forecastList: NaRenewablesForecast[]
): NaRenewablesForecast => {
  const latestForecasts = filterLatestForecasts(
    forecastList,
    findLatestForecast(forecastList).localInitTime
  );

  return first(latestForecasts) as NaRenewablesForecast;
};

export const getNaRenewablesForecastList = (
  forecastList: NaRenewablesForecast[]
): NaRenewablesForecast[] => {
  const { localInitTime: oldestLocalInitTime } = reduce(
    forecastList,
    (acc, forecast) => {
      const { localInitTime: accLocalInitTime } = acc;
      const { localInitTime: forecastLocalInitTime } = forecast;

      if (!accLocalInitTime) {
        return forecast;
      }

      return moment(forecastLocalInitTime).isBefore(accLocalInitTime)
        ? forecast
        : acc;
    },
    {} as NaRenewablesForecast
  );
  const oldestForecastsLength = forecastList.filter(
    ({ localInitTime }) => localInitTime === oldestLocalInitTime
  ).length;

  return oldestForecastsLength > 1
    ? forecastList
    : forecastList.filter(
        ({ localInitTime }) => localInitTime !== oldestLocalInitTime
      );
};

export const getNaRenewablesComparisonForecastList = (
  forecastList: NaRenewablesForecast[],
  selectedForecast: NaRenewablesForecast
): NaRenewablesForecast[] => {
  const { localInitTime: selectedForecastInitTime } = selectedForecast;

  return forecastList
    .filter((forecast) => !isEqual(selectedForecast, forecast))
    .filter(({ localInitTime }) => {
      const localInitTimeMoment = moment(localInitTime);

      return (
        localInitTimeMoment.isBefore(selectedForecastInitTime) ||
        localInitTimeMoment.isSame(selectedForecastInitTime)
      );
    });
};

export const isUpgradeToLatestNaRenewablesForecast = (
  forecastList: NaRenewablesForecast[],
  selectedForecast: NaRenewablesForecast
): boolean => !selectedForecast || !forecastList.includes(selectedForecast);

export const getUpdatedNaRenewablesForecast = (
  forecastList: NaRenewablesForecast[],
  { name: selectedForecastName }: NaRenewablesForecast
): NaRenewablesForecast =>
  forecastList.find(({ name }) => selectedForecastName === name) ??
  getNaRenewablesLatestForecast(forecastList);
