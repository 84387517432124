import { NaRenewablesPrimaryLens } from '../enums';
import { DropdownOption } from '../../../../../../shared/interfaces/src/lib';

export const NA_RENEWABLES_PRIMARY_LENS_DROPDOWN: DropdownOption<NaRenewablesPrimaryLens>[] =
  [
    {
      labelKey: 'forecastValues',
      value: NaRenewablesPrimaryLens.FORECAST,
    },
    {
      labelKey: 'forecastDifference',
      value: NaRenewablesPrimaryLens.FORECAST_DIFFERENCE,
    },
  ];
