<div class="panel-container">
  <div class="config-options">
    <div class="filter-wrapper">
      <firebird-web-single-model-filters
        [isWidget]="true"
        [configs$]="filterConfigs$"
        [widgetId]="widgetId"
        (filterChange)="filterChange($event)"
      ></firebird-web-single-model-filters>
    </div>
  </div>
  <div class="action-buttons">
    <span class="save-button" (click)="onSave()">Save</span>
    <span class="cancel-button" (click)="onClose()">X-Cancel</span>
  </div>
</div>
