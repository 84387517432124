import { Component, Inject, OnDestroy } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { MessageService } from '../../services/message.service';
import { DashboardDataService } from '../../services/data/dashboard/dashboard-data.service';
import { Subject, first } from 'rxjs';
import { dashTitleValidator } from '../../../../../shared/validators/validators';

@Component({
  selector: 'firebird-web-add-new-dialog',
  templateUrl: './add-new-dialog.component.html',
  styleUrls: ['./add-new-dialog.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AddNewDialogComponent,
    },
  ],
})
export class AddNewDialogComponent implements OnDestroy {
  submitButtonClicked = false;
  isTitleValid = true;
  private destroy$ = new Subject<void>();
  newMonitorForm: FormGroup;
  title!: string;
  duplicateTitle = 'Duplicate Dashboard';
  newTitle = 'Create New Dashboard';
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public messageService: MessageService,
    private dashData: DashboardDataService,
    private dialogRef: MatDialogRef<AddNewDialogComponent>,
    public fb: FormBuilder
  ) {
    this.newMonitorForm = fb.group({
      title: new FormControl('', [Validators.required, dashTitleValidator()]),
      description: new FormControl(''),
    });
    this.title =
      this.data?.type === 'new-dash' ? this.newTitle : this.duplicateTitle;
  }

  closeDialog() {
    this.dialogRef.close();
  }
  submitClicked() {
    if (this.data.type === 'new-dash') {
      this.createNewLiveMonitor();
    } else {
      this.copyDashboard();
    }
  }
  copyDashboard() {
    this.submitButtonClicked = true;
    this.newMonitorForm.get('title')?.updateValueAndValidity();
    this.isTitleValid = this.dashData.checkDuplicateTitle(
      this.newMonitorForm.get('title')?.value
    );
    if (this.newMonitorForm.valid && !this.isTitleValid) {
      this.data.widgetParams.title = this.newMonitorForm.get('title')?.value;
      this.data.widgetParams.description =
        this.newMonitorForm.get('description')?.value;
      this.dashData
        .createDashboard({
          title: this.newMonitorForm.get('title')?.value,
          description: this.newMonitorForm.get('description')?.value,
          widgetConfig: this.data.widgetParams.widgetConfig,
        })
        .pipe(first())
        .subscribe((resp: any) => {
          this.navigateToMonitor(resp.data.id);
        });
      this.dialogRef.close({
        title: this.newMonitorForm.get('title')?.value,
        duplicate: true,
      });
    }
  }
  createNewLiveMonitor() {
    this.submitButtonClicked = true;
    this.newMonitorForm.get('title')?.updateValueAndValidity();
    this.isTitleValid = this.dashData.checkDuplicateTitle(
      this.newMonitorForm.get('title')?.value
    );
    if (this.newMonitorForm.valid && !this.isTitleValid) {
      this.dashData
        .createDashboard({
          title: this.newMonitorForm.get('title')?.value,
          description: this.newMonitorForm.get('description')?.value,
        })
        .pipe(first())
        .subscribe((resp: any) => {
          this.navigateToMonitor(resp.data.id);
        });
      this.dialogRef.close({ title: this.newMonitorForm.get('title')?.value });
    }
  }
  navigateToMonitor(id: string) {
    if (id) {
      this.messageService.navToMonitor(id);
    }
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
