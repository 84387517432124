import { RenewablesProducts } from 'libs/renewable/src/lib/models';
import { DropdownOption } from 'libs/shared/interfaces/src/lib';

export const NA_LABELS = [
  { labelText: 'Location', class: 'label label--na-location' },
  { labelText: 'Renewables Type', class: 'label' },
  { labelText: 'Unit', class: 'label' },
  { labelText: 'Range', class: 'label' },
];

export const EUR_LABELS = [
  { labelText: 'Location', class: 'label label--eur-location' },
  { labelText: 'Renewables Type', class: 'label' },
  { labelText: 'Unit', class: 'label' },
  { labelText: 'Ensemble Spread', class: 'label' },
  { labelText: 'Range', class: 'label' },
];

export const UNIT_TYPES = [
  {
    value: 'megawatts',
    labelKey: 'Megawatts',
  },
  {
    value: 'percentCapacity',
    labelKey: 'Percent Capacity',
  },
];

export const PRODUCT_TYPES: DropdownOption<RenewablesProducts>[] = [
  {
    value: 'wind',
    labelKey: 'Wind',
  },
  {
    value: 'solar',
    labelKey: 'Solar',
  },
  {
    value: 'total',
    labelKey: 'Total Generation',
  },
];

export const NA_PRODUCTS: DropdownOption<RenewablesProducts>[] = [
  {
    value: 'wind',
    labelKey: 'Wind',
  },
  {
    value: 'solar',
    labelKey: 'Solar',
  },
];

export const WIND_PRODUCT: DropdownOption<RenewablesProducts>[] = [
  {
    value: 'wind',
    labelKey: 'Wind',
  },
];

export const SOLAR_PRODUCT: DropdownOption<RenewablesProducts>[] = [
  {
    value: 'solar',
    labelKey: 'Solar',
  },
];
