<div class="container">
  <div class="item">
    <div class="item-title">Category</div>
    <div class="item-value">
      <firebird-web-dropdown
        labelKey="label"
        class="multi-dd-start widget-panel"
        [options]="categoryDataSource"
        [value]="panelState.selectedCategory"
        (changeEvent)="changeCategory($event)"
        type="variation-five"
      ></firebird-web-dropdown>
    </div>
  </div>

  <div class="item">
    <div class="item-title">Locations</div>
    <div class="item-value" (click)="changeLocation()">
      <span
        >{{ panelState.selectedContinent | titlecase }}
        <firebird-web-icon name="chevron-right" height="11px" width="11px">
        </firebird-web-icon>
        {{ panelState.selectedRegionName }}
        <firebird-web-icon
          name="chevron-down"
          height="11px"
          width="11px"
        ></firebird-web-icon
      ></span>
    </div>
  </div>

  <div class="item">
    <div class="item-title">Forecast</div>
    <div class="item-value">
      <firebird-web-dropdown
        labelKey="label"
        class="multi-dd-start widget-panel"
        [options]="forecastDataSource"
        [value]="panelState.selectedForecast"
        (changeEvent)="changeForecast($event)"
        type="variation-five"
      ></firebird-web-dropdown>
    </div>
  </div>

  <div class="item">
    <div class="item-title">Climatology</div>
    <div class="item-value">
      <firebird-web-dropdown
        labelKey="label"
        class="multi-dd-start widget-panel"
        [options]="climatologyDataSource"
        [value]="panelState.selectedClimatology"
        (changeEvent)="changeClimatology($event)"
        type="variation-five"
      ></firebird-web-dropdown>
    </div>
  </div>

  <div class="item">
    <div class="item-title">Daily/Period</div>
    <div class="item-value">
      <firebird-web-dropdown
        labelKey="label"
        class="multi-dd-start widget-panel"
        [options]="dailyPeriodDataSource"
        [value]="panelState.selectedDailyPeriod"
        (changeEvent)="changeDailyPeriod($event)"
        type="variation-five"
      ></firebird-web-dropdown>
    </div>
  </div>

  <ng-container *ngIf="panelState.selectedForecast === forecastValues.model">
    <div class="item">
      <div class="item-title">View by</div>
      <div class="item-value double-column">
        <firebird-web-dropdown
          labelKey="label"
          class="multi-dd-start widget-panel"
          [options]="runTimeModelDataSource"
          [value]="panelState.selectedRunTimeModel"
          (changeEvent)="changeRunTimeModel($event)"
          type="variation-five"
        ></firebird-web-dropdown>
        <ng-container
          *ngIf="
            panelState.selectedRunTimeModel === runTimeModel.runTime;
            else singleRunTime
          "
        >
          <p class="run-date">
            {{ 'summaryWidgetConfigPanelForecastRunDate' | translate }}
          </p>
        </ng-container>

        <ng-template #singleRunTime>
          <firebird-web-dropdown
            labelKey="label"
            class="multi-dd-start widget-panel sup-panel"
            [options]="runTimeDataSource$ | async"
            [value]="panelState.selectedRunTimeData"
            (changeEvent)="changeRunTimeData($event)"
            type="variation-five"
          ></firebird-web-dropdown>
        </ng-template>
      </div>
    </div>

    <div
      class="item"
      *ngIf="isModelSelected && (hasMultipleParameters$ | async)"
    >
      <div class="item-title">Parameter</div>
      <div class="item-value">
        <firebird-web-dropdown
          labelKey="label"
          class="multi-dd-start widget-panel"
          [options]="parameterDataSource$ | async"
          [value]="panelState.selectedParameterValue"
          (changeEvent)="changeParameterValue($event)"
          type="variation-five"
        ></firebird-web-dropdown>
      </div>
    </div>
  </ng-container>

  <div class="item">
    <div class="item-title"></div>
    <div class="item-value">
      <firebird-web-checkbox
        (changeEvent)="changeDiffPrior($event)"
        labelKey="Differences from prior forecast"
        [checked]="panelState.diffPriorValue"
      >
      </firebird-web-checkbox>
    </div>
  </div>

  <div class="item">
    <div class="item-title"></div>
    <div class="item-value">
      <firebird-web-checkbox
        (changeEvent)="changeNormalValue($event)"
        labelKey="Normals"
        [checked]="panelState.normalValue"
      >
      </firebird-web-checkbox>
    </div>
  </div>

  <div
    class="item"
    *ngIf="
      tempUnit === cUnit &&
      panelState.selectedCategory !== categoryValues.degree
    "
  >
    <div class="item-title"></div>
    <div class="item-value">
      <firebird-web-checkbox
        (changeEvent)="showDecimalValues($event)"
        labelKey="Decimals"
        [checked]="panelState.showDecimalValue"
      >
      </firebird-web-checkbox>
    </div>
  </div>

  <div
    class="item"
    *ngIf="panelState.selectedForecast === forecastValues.model"
  >
    <div class="item-title"></div>
    <div class="item-value">
      <firebird-web-checkbox
        (changeEvent)="changeBiasCorrectedValue($event)"
        labelKey="Bias Corrected"
        [checked]="panelState.biasCorrected"
      >
      </firebird-web-checkbox>
    </div>
  </div>

  <div class="item" *ngIf="!data.widgetId">
    <!-- <div class="item-title">Widget Size</div>
    <div class="item-value">
      <firebird-web-dropdown
        labelKey="label"
        class="multi-dd-start widget-panel"
        [options]="tableSizesDataSource"
        [value]="panelState.selectedTableSize"
        (changeEvent)="changeSize($event)"
        type="variation-five"
      ></firebird-web-dropdown>
    </div> -->
  </div>

  <div class="close-save">
    <span class="save" (click)="saveWidget()">Save</span>
    <span class="cancel" (click)="closeWidget()">X-Cancel</span>
  </div>
</div>
