import { createAction, props } from '@ngrx/store';
import {
  ModelFilterConfig,
  ModelRun,
  PanelData,
  SelectedConfigs,
} from '../../models/model-page.types';

export const loadPeriodMatrixModels = createAction(
  '[ModelStore Page] Load Period Matrix',
  props<{ silentLoading: boolean }>()
);
export const loadPeriodMatrixModelsSuccess = createAction(
  '[ModelStore/API] Load Period Matrix Success',
  props<{ mainData: PanelData[]; diffData: PanelData[] }>()
);
export const loadPeriodMatrixModelsFailure = createAction(
  '[ModelStore/API] Load Period Matrix Failure',
  props<{ error: any }>()
);
// configs
export const loadPeriodMatrixConfigs = createAction(
  '[ModelStore Page] Load Period Matrix Configs'
);
export const loadPeriodMatrixConfigsSuccess = createAction(
  '[ModelStore/API] Load Period Matrix Success Configs',
  props<{ configs: ModelFilterConfig[] }>()
);
export const loadPeriodMatrixConfigsFailure = createAction(
  '[ModelStore/API] Load Period Matrix Failure Configs',
  props<{ error: any }>()
);
// runs
export const loadPeriodMatrixRuns = createAction(
  '[ModelStore Page] Load Period Matrix Runs'
);
export const loadPeriodMatrixRunsSuccess = createAction(
  '[ModelStore/API] Load Period Matrix Success Runs',
  props<{ runs: ModelRun[] }>()
);
export const loadPeriodMatrixRunsFailure = createAction(
  '[ModelStore/API] Load Period Matrix Failure Runs',
  props<{ error: any }>()
);
export const changePeriodMatrixModelsTimeDiff = createAction(
  '[ModelStore Page] Change Period Matrix Time Diff',
  props<{ selectedTimeDiff: string }>()
);
export const changePeriodMatrixModelsConfigs = createAction(
  '[ModelStore Page] Change Period Matrix Selected Configs',
  props<{ selectedConfigs: SelectedConfigs }>()
);
export const setPeriodMatrixInUse = createAction(
  '[ModelStore Page] Set Period Matrix In Use',
  props<{ inUse: boolean }>()
);
