import { createAction, props } from '@ngrx/store';
import { INotificationEmail } from './notifications-store.models';
import { INotification } from 'libs/alerts-notifications/src/lib/interfaces/notification.interface';
import { IAlert } from 'libs/alerts-notifications/src/lib/interfaces/alert.interface';

export const loadNotificationsStoreSuccess = createAction(
  '[NotificationsStore/API] Load NotificationsStore Success'
);

export const loadNotificationsStoreFailure = createAction(
  '[NotificationsStore/API] Load NotificationsStore Failure',
  props<{ error: any }>()
);

export const loadEmailNotifications = createAction(
  '[Notifications/API] Load Profile Emails'
);
export const loadEmailNotificationsSuccess = createAction(
  '[Notifications/API] Load Profile Emails Success',
  props<{ emails: INotificationEmail[] }>()
);

export const loadNotifications = createAction(
  '[NotificationsStore/API] Load Notifications'
);

export const loadNotificationsSuccess = createAction(
  '[NotificationsStore/API] Load Notifications Success',
  props<{ notifications: INotification[] }>()
);

export const loadNotificationsFailure = createAction(
  '[NotificationsStore/API] Load Notifications Failure',
  props<{ error: any }>()
);

export const loadAlerts = createAction('[NotificationsStore/API] Load Alerts');

export const loadAlertsSuccess = createAction(
  '[NotificationsStore/API] Load Alerts Success',
  props<{ alerts: IAlert[] }>()
);

export const loadAlertsFailure = createAction(
  '[NotificationsStore/API] Load Alerts Failure',
  props<{ error: any }>()
);

export const changeAlertWindowOpen = createAction(
  '[NotificationsStore/Window] Change Alert Window Open',
  props<{ isOpen: boolean }>()
);
