import {
  LocationPickerState,
  LocationPickers,
} from '../models/location-picker.model';

export const LOCATION_PICKER_FEATURE_KEY = 'locationPicker';

export const LOCATION_PICKER_STATE_STORAGE_KEY = 'location-picker-state';

export const initialLocationPickerState: LocationPickerState = {
  [LocationPickers.REGION]: {
    continent: 'NA',
    region: 'All Regions',
  },
  [LocationPickers.CITY]: {
    continent: 'NA',
    region: 'All Regions',
    siteId: null,
  },
  isInitialized: false,
};

export const LOCATION_PICKERS_PROPS_CONFIG = {
  [LocationPickers.REGION]: ['continent', 'region'],
  [LocationPickers.CITY]: ['continent', 'region', 'siteId'],
};

export const LOCATION_PICKERS_KEYS = Object.values(LocationPickers);
