<div class="container">
  <div class="header">
    <div><h4>What’s New?</h4></div>
    <firebird-web-dropdown
      width="85px"
      [options]="yearsDataSource"
      type="variation-five"
      [value]="selectedYear$ | async"
      (changeEvent)="selectedYear$.next($event.value)"
    >
    </firebird-web-dropdown>
  </div>
  <div class="content">
    <ng-container *ngFor="let note of notes; trackBy: trackById">
      <div class="notes-item">
        <div class="tags-list">
          <div
            class="tag-item"
            *ngFor="let tag of note.labels; trackBy: trackByLabel"
          >
            <p>{{ tag }}</p>
          </div>
        </div>
        <div class="note-header">
          <div class="author">
            <p>{{ note.author }}</p>
          </div>
          <div class="date">
            <p>{{ note.issueDate | date: 'MMMM d, y' }}</p>
          </div>
        </div>
        <div>
          <h4 class="note-title">{{ note.title }}</h4>
        </div>
        <div class="note-text notes-text-image" [innerHTML]="note.note"></div>
      </div>
    </ng-container>
  </div>
</div>
