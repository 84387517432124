import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as LocationPickerActions from './location-picker.actions';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';
import * as LocationPickerSelectors from './location-picker.selectors';
import {
  CityLocationPicker,
  RegionLocation,
  RegionLocationPicker,
  SaveCityDataActionPayload,
  SaveCustomListDataActionPayload,
  SaveRegionDataActionPayload,
} from '../models/location-picker.model';
import { isEmpty, isNull } from 'lodash';
import { LocationService } from '../../../../shared/services/src/lib/location.service';

@Injectable({
  providedIn: 'root',
})
export class LocationPickerFacade {
  constructor(
    private readonly store: Store,
    private readonly locService: LocationService
  ) {}

  public init(): void {
    this.store.dispatch(LocationPickerActions.initLocationPicker());
  }

  public reinitContinent(continent: string): void {
    this.store.dispatch(
      LocationPickerActions.reinitLocationPicker({ continent })
    );
  }

  public initCityData(): void {
    this.store.dispatch(LocationPickerActions.initCityData());
  }

  public getRegionData(): Observable<RegionLocationPicker> {
    return this.store.pipe(select(LocationPickerSelectors.getRegionData));
  }

  public getCityData(): Observable<CityLocationPicker> {
    return this.store.pipe(select(LocationPickerSelectors.getCityData));
  }

  public isInitialized(): Observable<boolean> {
    return this.store.pipe(select(LocationPickerSelectors.isInitialized));
  }

  public dispatchRegionData(payload: SaveRegionDataActionPayload): void {
    return this.store.dispatch(
      LocationPickerActions.saveDataForRegion(payload)
    );
  }

  public dispatchCustomListData(
    payload: SaveCustomListDataActionPayload
  ): void {
    return this.store.dispatch(
      LocationPickerActions.saveDataForCustomList(payload)
    );
  }

  public dispatchCityData(payload: SaveCityDataActionPayload): void {
    return this.store.dispatch(LocationPickerActions.saveDataForCity(payload));
  }

  public getRegionLocation(): Observable<RegionLocation> {
    return this.getRegionData().pipe(
      filter(({ continent, region }) => !isNull(continent) && !isNull(region)),
      switchMap(({ region, continent, customList }) =>
        combineLatest([
          of({ region, continent, customList }),
          this.locService.findContinentByIdAsync(continent),
        ])
      ),
      map(
        ([
          { region, continent, customList },
          continentName,
        ]): RegionLocation => {
          if (!isEmpty(customList)) {
            return {
              region,
              continent,
              customList,
              continentName: 'Custom List',
            };
          }

          return {
            region,
            continent,
            continentName,
          };
        }
      )
    );
  }
}
