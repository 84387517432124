import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { GetValidDatesReq } from '@firebird-web/shared-interfaces';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { environment } from '@firebird-web/shared-config';

@Injectable()
export class HourlyValidDatesService {
  public constructor(private http: HttpClient) {}

  /**
   * getValidDates
   * @param params
   * @returns Observable<string[]>
   */
  public getValidDates(params: GetValidDatesReq): Observable<string[]> {
    const endpoint = `${environment.apiDomain}/${apiEndpoints.getHourlyForecastValidDates}`;
    const requestBody = {
      ...params,
    };
    return this.http
      .post(endpoint, requestBody)
      .pipe(map(({ data }: any) => data || []));
  }
}
