export enum RenewablesMapsNaRegions {
  CONUS = 'CONUS',
  NORTHEAST = 'NE',
  MISO = 'MISO',
  MISO_NIOWA = 'NIowa',
  ERCOT = 'ERCOT',
  ERCOT_WEST_TEXAS = 'WTexas',
  NORTHWEST = 'NW',
  CAISO = 'CAISO',
  SPP = 'SPP',
  SOUTHWEST = 'SW',
}

export enum RenewablesMapsNaSolarRegions {
  CONUS = 'CONUS',
  ERCOT = 'ERCOT',
}
