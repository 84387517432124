import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { environment } from '@firebird-web/shared-config';
import { map, Observable } from 'rxjs';
import { CommonUtils } from '@firebird-web/shared-utils';
import { RegionsEntity } from '@firebird-web/regions-store';
import { ServerResponse } from '@firebird-web/shared-interfaces';
import { Cities } from '../+state/route/settings.models';
import { FavoriteList } from '../models/favorite-locations.types';
@Injectable({
  providedIn: 'root',
})
export class CustomLocationsService {
  constructor(private http: HttpClient) {}

  getRegionsList(continentId: string): Observable<RegionsEntity[]> {
    // ! delete this
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getRegionsList}`,
      { continentId }
    );
    return this.http.get(endPointUrl).pipe(map(({ data }: any) => data));
  }

  getLists(): Observable<FavoriteList[]> {
    const endPointUrl = `${environment.apiDomain}/api/v1/CustomList/profile-custom-lists`;
    return this.http
      .get<ServerResponse<FavoriteList[]>>(endPointUrl)
      .pipe(map(({ data }) => data));
  }

  getCities(): Observable<Cities[]> {
    const endPointUrl = `${environment.apiDomain}/api/v1/CustomList/region/city/lists`;
    return this.http
      .get<ServerResponse<Cities[]>>(endPointUrl)
      .pipe(map(({ data }) => data));
  }

  createList(list: any): Observable<any[]> {
    const endPointUrl = `${environment.apiDomain}/api/v1/CustomList/save`;
    return this.http
      .post<ServerResponse<any>>(endPointUrl, list)
      .pipe(map(({ data }) => data));
  }

  saveList({ id, ...list }: any): Observable<any[]> {
    const endPointUrl = `${environment.apiDomain}/api/v1/CustomList/edit/${id}`;
    return this.http.put<any>(endPointUrl, list);
  }

  copyList({
    id,
    listName,
  }: Partial<FavoriteList>): Observable<FavoriteList[]> {
    const endPointUrl = `${environment.apiDomain}/api/v1/CustomList/copy/${id}`;
    return this.http
      .post<ServerResponse<FavoriteList[]>>(endPointUrl, { listName })
      .pipe(map(({ data }) => data));
  }

  deleteList(id: number): Observable<never> {
    const endPointUrl = `${environment.apiDomain}/api/v1/CustomList/delete/${id}`;
    return this.http.delete<never>(endPointUrl);
  }

  isFavoriteListUnique(name: string) {
    return this.http.post<boolean>(
      `${environment.apiDomain}/api/v1/CustomList/verification/${name}`,
      {}
    );
  }
}
