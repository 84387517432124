import { Series } from 'highcharts/dashboards/es-modules/Dashboards/Plugins/HighchartsTypes';
import { NaChartSeriesModelRun } from '../enums';

export const LegendNaIconMapping: Record<NaChartSeriesModelRun, string> = {
  [NaChartSeriesModelRun.AG2_LATEST]: 'thick-line',
  [NaChartSeriesModelRun.AG2_PREVIOUS]: 'medium-line',
  [NaChartSeriesModelRun.AG2_PRIMARY]: 'three-dashes-line',
  [NaChartSeriesModelRun.AG2_EVENING]: 'line',
  [NaChartSeriesModelRun.AG2_PRIMARY_YESTERDAY]: 'vertical-dashed-line',
  [NaChartSeriesModelRun.AG2_EVENING_YESTERDAY]: 'thick-dashed-line',
  [NaChartSeriesModelRun.AG2_MORNING]: 'three-dashes-line',
  [NaChartSeriesModelRun.AG2_PREVIOUS_MORNING]: 'medium-line',
  [NaChartSeriesModelRun.AG2_PREVIOUS_EVENING]: 'line',
  [NaChartSeriesModelRun.AG2_PREVIOUS_MORNING_MID_MORNING]: 'three-dashes-line',
  [NaChartSeriesModelRun.LOCATION_MORNING]: 'line',
  [NaChartSeriesModelRun.LOCATION_LATEST]: 'thick-line',
  [NaChartSeriesModelRun.LOCATION_PREVIOUS]: 'medium-line',
  [NaChartSeriesModelRun.LOCATION_PREVIOUS_MORNING]: 'three-dashes-line',
  [NaChartSeriesModelRun.ERCOT_WGRPP_LATEST_MORNING]: 'small-dash-line',
  [NaChartSeriesModelRun.ERCOT_STWPF_LATEST_MORNING]: 'line',
  [NaChartSeriesModelRun.ERCOT_WGRPP_LATEST]: 'thick-dashed-line',
  [NaChartSeriesModelRun.ERCOT_STWPF_LATEST]: 'thick-line',
  [NaChartSeriesModelRun.ERCOT_WGRPP_PREVIOUS]: 'vertical-dashed-line',
  [NaChartSeriesModelRun.ERCOT_STWPF_PREVIOUS]: 'medium-line',
  [NaChartSeriesModelRun.ERCOT_WGRPP_MORNING]: 'large-dashed-line',
  [NaChartSeriesModelRun.ERCOT_STWPF_MORNING]: 'three-dashes-line',
  [NaChartSeriesModelRun.OBS]: 'medium-line',
  [NaChartSeriesModelRun.FARM_CAPACITY]: 'medium-line',
  [NaChartSeriesModelRun.CONFIDENCE_BANDS]: 'spread-line',
};

export const StateWidthLineThree = {
  lineWidth: 3,
  states: {
    hover: {
      lineWidth: 5,
    },
  },
};

export const StateWidthLineTwo = {
  lineWidth: 2,
  states: {
    hover: {
      lineWidth: 4,
    },
  },
};

export const StateWidthLineOne = {
  lineWidth: 1,
  states: {
    hover: {
      lineWidth: 3,
    },
  },
};

export const MarkerRadiusThree = {
  marker: {
    enabled: true,
    symbol: 'circle',
    radius: 3,
  },
};

export const MarkerRadiusTwo = {
  marker: {
    enabled: true,
    symbol: 'circle',
    radius: 2,
  },
};

export const MarkerRadiusOne = {
  marker: {
    enabled: true,
    symbol: 'circle',
    radius: 1,
  },
};

export const SeriesLineMapping: Record<
  NaChartSeriesModelRun,
  Partial<Series>
> = {
  [NaChartSeriesModelRun.AG2_LATEST]: {
    dashStyle: 'Solid',
    ...StateWidthLineThree,
    ...MarkerRadiusThree,
  },
  [NaChartSeriesModelRun.AG2_PREVIOUS]: {
    dashStyle: 'Solid',
    ...StateWidthLineTwo,
    ...MarkerRadiusTwo,
  },
  [NaChartSeriesModelRun.AG2_PRIMARY]: {
    dashStyle: 'LongDash',
    ...StateWidthLineThree,
    ...MarkerRadiusThree,
  },
  [NaChartSeriesModelRun.AG2_EVENING]: {
    dashStyle: 'Dash',
    ...StateWidthLineTwo,
    ...MarkerRadiusTwo,
  },
  [NaChartSeriesModelRun.AG2_PRIMARY_YESTERDAY]: {
    dashStyle: 'ShortDash',
    ...StateWidthLineOne,
    ...MarkerRadiusOne,
  },
  [NaChartSeriesModelRun.AG2_EVENING_YESTERDAY]: {
    dashStyle: 'Dash',
    ...StateWidthLineTwo,
    ...MarkerRadiusTwo,
  },
  [NaChartSeriesModelRun.AG2_PREVIOUS_MORNING]: {
    dashStyle: 'Solid',
    ...StateWidthLineTwo,
    ...MarkerRadiusTwo,
  },
  [NaChartSeriesModelRun.AG2_MORNING]: {
    dashStyle: 'LongDash',
    ...StateWidthLineThree,
    ...MarkerRadiusThree,
  },
  [NaChartSeriesModelRun.AG2_PREVIOUS_EVENING]: {
    dashStyle: 'Dash',
    ...StateWidthLineTwo,
    ...MarkerRadiusTwo,
  },
  [NaChartSeriesModelRun.AG2_PREVIOUS_MORNING_MID_MORNING]: {
    dashStyle: 'LongDash',
    ...StateWidthLineThree,
    ...MarkerRadiusThree,
  },
  [NaChartSeriesModelRun.ERCOT_STWPF_LATEST]: {
    dashStyle: 'Solid',
    ...StateWidthLineThree,
    ...MarkerRadiusThree,
  },
  [NaChartSeriesModelRun.ERCOT_STWPF_PREVIOUS]: {
    dashStyle: 'Solid',
    ...StateWidthLineTwo,
    ...MarkerRadiusTwo,
  },
  [NaChartSeriesModelRun.ERCOT_STWPF_LATEST_MORNING]: {
    dashStyle: 'Solid',
    ...StateWidthLineOne,
    ...MarkerRadiusOne,
  },
  [NaChartSeriesModelRun.ERCOT_STWPF_MORNING]: {
    dashStyle: 'LongDash',
    ...StateWidthLineThree,
    ...MarkerRadiusThree,
  },
  [NaChartSeriesModelRun.ERCOT_WGRPP_LATEST]: {
    dashStyle: 'Dash',
    ...StateWidthLineTwo,
    ...MarkerRadiusTwo,
  },
  [NaChartSeriesModelRun.ERCOT_WGRPP_PREVIOUS]: {
    dashStyle: 'ShortDash',
    ...StateWidthLineOne,
    ...MarkerRadiusOne,
  },
  [NaChartSeriesModelRun.ERCOT_WGRPP_LATEST_MORNING]: {
    dashStyle: 'ShortDot',
    ...StateWidthLineOne,
    ...MarkerRadiusOne,
  },
  [NaChartSeriesModelRun.ERCOT_WGRPP_MORNING]: {
    dashStyle: 'Dot',
    ...StateWidthLineOne,
    ...MarkerRadiusOne,
  },
  [NaChartSeriesModelRun.LOCATION_MORNING]: {
    dashStyle: 'Solid',
    ...StateWidthLineOne,
    ...MarkerRadiusOne,
  },
  [NaChartSeriesModelRun.LOCATION_LATEST]: {
    dashStyle: 'Solid',
    ...StateWidthLineThree,
    ...MarkerRadiusThree,
  },
  [NaChartSeriesModelRun.LOCATION_PREVIOUS]: {
    dashStyle: 'Solid',
    ...StateWidthLineTwo,
    ...MarkerRadiusTwo,
  },
  [NaChartSeriesModelRun.LOCATION_PREVIOUS_MORNING]: {
    dashStyle: 'LongDash',
    ...StateWidthLineThree,
    ...MarkerRadiusThree,
  },
  [NaChartSeriesModelRun.OBS]: {
    dashStyle: 'Solid',
    ...StateWidthLineTwo,
    ...MarkerRadiusTwo,
  },
  [NaChartSeriesModelRun.FARM_CAPACITY]: {
    dashStyle: 'Solid',
    ...StateWidthLineTwo,
    ...MarkerRadiusTwo,
    marker: {
      enabled: false,
    },
  },
  [NaChartSeriesModelRun.CONFIDENCE_BANDS]: {
    dashStyle: 'Solid',
    ...StateWidthLineTwo,
    marker: {
      enabled: false,
    },
  },
};

export const SvgIconMapper: Record<string, string> = {
  'thick-line': `<svg width="17" height="3" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="9.74483e-08" y1="1.5" x2="17" y2="1.5"  stroke-width="3"/>
</svg>
`,
  'medium-line': `
  <svg width="16" height="4" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="6.49655e-08" y1="1" x2="26" y2="1"  stroke-width="4"/>
</svg>
`,
  line: `<svg width="16" height="1" viewBox="0 0 26 1" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="3.24828e-08" y1="0.5" x2="26" y2="0.500003" />
</svg>
`,
  'three-dashes-line': `<svg width="26" height="4" viewBox="0 0 26 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="1.29931e-07" y1="2" x2="26" y2="2" stroke-width="4" stroke-dasharray="7 7"/>
</svg>
`,
  'thick-dashed-line': `<svg width="17" height="3" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="9.74483e-08" y1="1.5" x2="17" y2="1.5"  stroke-width="2" stroke-dasharray="3 2"/>
</svg>
`,
  'vertical-dashed-line': `<svg width="17" height="3" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="9.74483e-08" y1="1.5" x2="17" y2="1.5"  stroke-width="3" stroke-dasharray="1 2"/>
</svg>
`,
  'spread-line': `<svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="3.24828e-07" y1="5" x2="17" y2="5" stroke="#E2E2E2" stroke-width="10"/>
</svg>
`,
  'small-dash-line': `<svg width="17" height="2" viewBox="0 0 17 2" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="6.49655e-08" y1="1" x2="17" y2="1"  stroke-width="2" stroke-dasharray="1 1"/>
</svg>  `,
  'large-dashed-line': `<svg width="17" height="1" viewBox="0 0 17 1" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="3.24828e-08" y1="0.5" x2="17" y2="0.500002" stroke-dasharray="2 2"/>
</svg>`,
};
