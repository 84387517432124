import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { WidgetConfigPanelComponent } from '../components/widget-config-panel/widget-config-panel.component';

@Injectable()
export class MessageService {
  description = '';
  titleSub = new BehaviorSubject('');
  title = '';
  addNew = false;

  constructor(private router: Router, private dialog: MatDialog) {}

  openWidgetConfig(
    widgetType: string,
    widgetConfig: any,
    widgetId: any,
    isNew: boolean,
    width?: string
  ) {
    return this.dialog.open(WidgetConfigPanelComponent, {
      data: { widgetConfig, widgetType, widgetId, isNew },
      height: 'auto',
      width: width ?? '375px',
      panelClass: 'firebird-dialog-panel',
      autoFocus: false,
    });
  }

  navToMonitor(id: string) {
    this.router.navigate(['/live-monitor/live-monitor-page', id], {
      queryParamsHandling: 'merge',
    });
  }
}
