import { Injectable } from '@angular/core';
import {
  BaseImageData,
  CachedImageData,
  ExtendedImageData,
} from '@firebird-web/shared-interfaces';
import {
  Observable,
  race,
  fromEvent,
  first,
  combineLatest,
  of,
  map,
} from 'rxjs';
const errorImagePath = '/assets/img/missing_gfx/Error404.png';

@Injectable()
export class ImageCachingService {
  addCachedImage$<T extends BaseImageData>(
    item: T,
    loadInBackground = false
  ): Observable<CachedImageData<T>> {
    const image = new Image();
    const imageEvent = race([
      fromEvent(image, 'load').pipe(first()),
      fromEvent(image, 'error').pipe(first()),
    ]);
    image.src = item.pathURL;
    if (item.thumbPathURL) {
      const thumb = new Image();
      const thumbEvent = race([
        fromEvent(thumb, 'load').pipe(first()),
        fromEvent(thumb, 'error').pipe(first()),
      ]);
      thumb.src = item.thumbPathURL;
      if (loadInBackground) {
        return of({ ...item, image, thumb });
      }
      return combineLatest([
        of({ ...item, image, thumb }),
        imageEvent,
        thumbEvent,
      ]).pipe(
        map(([imageData, event, thumbEvent]) => {
          const isImageLoadError = event.type !== 'error';
          const isThumbLoadError = thumbEvent.type !== 'error';
          return {
            ...imageData,
            pathURL: isImageLoadError ? imageData.pathURL : errorImagePath,
            thumbPathURL: isThumbLoadError
              ? imageData.thumbPathURL
              : errorImagePath,
            image: isImageLoadError ? image : { ...image, src: errorImagePath },
            thumbEvent: isThumbLoadError
              ? thumbEvent
              : { ...thumbEvent, src: errorImagePath },
          };
        })
      );
    }
    if (loadInBackground) {
      return of({ ...item, image });
    }
    return combineLatest([of({ ...item, image }), imageEvent]).pipe(
      map(([imageData, event]) => {
        if (event.type === 'error') {
          return {
            ...imageData,
            pathURL: errorImagePath,
            image: { ...image, src: errorImagePath },
          };
        }
        return imageData;
      })
    );
  }
  isImageArray<T = ExtendedImageData>(item: unknown): item is T[] {
    return (
      Array.isArray(item) &&
      item.length !== 0 &&
      item.every((arrayItem: object) =>
        Object.prototype.hasOwnProperty.call(arrayItem, 'pathURL')
      )
    );
  }
}
