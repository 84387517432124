import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@firebird-web/shared-config';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { CommonUtils } from '@firebird-web/shared-utils';
import { map } from 'rxjs';
import {
  GetIsoCountryForecastReq,
  GetIsoCountryForecastSingleModelDailyReq,
  GetIsoCountryForecastSingleModelPeriodReq,
} from '../../../interfaces/iso-country-forecast.interface';
import { IWeightedModelParams } from '../../../interfaces/weighted-model-forecast.interface';

@Injectable()
export class IsoCountryForecastService {
  public constructor(private http: HttpClient) {}

  public getIsoCountryDailyAg2Forecast(params: GetIsoCountryForecastReq) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getIsoCountryDailyAg2Forecast}`
    );
    return this.http
      .post(endPointUrl, params)
      .pipe(map((resp: any) => resp?.data));
  }

  public getIsoCountryPeriodAg2Forecast(params: GetIsoCountryForecastReq) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getIsoCountryPeriodAg2Forecast}`
    );
    return this.http
      .post(endPointUrl, params)
      .pipe(map((resp: any) => resp?.data));
  }

  public getIsoCountryModelRunTimeDailyForecast(params: any) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getIsoCountryModelDailyForecast}`
    );
    return this.http
      .post(endPointUrl, params)
      .pipe(map((resp: any) => resp?.data));
  }

  public getIsoCountryModelRunTimePeriodForecast(params: any) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getIsoCountryModelPeriodForecast}`
    );
    return this.http
      .post(endPointUrl, params)
      .pipe(map((resp: any) => resp?.data));
  }

  public getIsoCountryAg2ForecastGraph(params: any) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getIsoCountryAg2ForecastGraph}`
    );
    return this.http
      .post(endPointUrl, params)
      .pipe(map((resp: any) => resp?.data));
  }

  public getModelWeightedForecastGraph(
    params: IWeightedModelParams,
    type: string | null = null,
    isGraphWDD: boolean = false
  ) {
    const apiController =
      type === 'weighted-degree-forecast'
        ? 'WeightedDegreeDays'
        : 'WeightedIsoCountry';
    const path = isGraphWDD ? 'graphwdd' : 'graph';
    const endPointUrl = `${environment.apiDomain}/api/v1/${apiController}/singleforecast/${path}`;
    return this.http
      .post(endPointUrl, params)
      .pipe(map((resp: any) => resp?.data));
  }

  public getIsoCountrySingleDailyModelForecast(
    params: GetIsoCountryForecastSingleModelDailyReq
  ) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getIsoCountrySingleDailyModelForecast}`
    );
    return this.http
      .post(endPointUrl, params)
      .pipe(map((resp: any) => resp?.data));
  }

  public getIsoCountrySinglePeriodModelForecast(
    params: GetIsoCountryForecastSingleModelPeriodReq
  ) {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getIsoCountrySinglePeriodModelForecast}`
    );
    return this.http
      .post(endPointUrl, params)
      .pipe(map((resp: any) => resp?.data));
  }
}
