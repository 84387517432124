export const SAVE_TO_PARENT_URLS = new Map<string, string>([
  [
    '1-15days/city-view/forecast-values/daily/heatMap',
    '1-15days/city-view/forecast-values',
  ],
  [
    '1-15days/city-view/forecast-values/hourly/heatMap',
    '1-15days/city-view/forecast-values',
  ],
  [
    '1-15days/city-view/forecast-values/daily/charts',
    '1-15days/city-view/forecast-values',
  ],
  [
    '1-15days/city-view/forecast-values/hourly/charts',
    '1-15days/city-view/forecast-values',
  ],
  [
    '1-15days/city-view/forecast-difference/daily/heatMap',
    '1-15days/city-view/forecast-difference',
  ],
  [
    '1-15days/city-view/forecast-difference/hourly/heatMap',
    '1-15days/city-view/forecast-difference',
  ],
  [
    '1-15days/city-view/forecast-difference/daily/charts',
    '1-15days/city-view/forecast-difference',
  ],
  [
    '1-15days/city-view/forecast-difference/hourly/charts',
    '1-15days/city-view/forecast-difference',
  ],
  ['seasonal', 'seasonal'],
  ['sub-seasonal', 'sub-seasonal'],
]);

export const SAVE_TO_PARENT_GENERICS = {
  '/seasonal': 'seasonal',
  '/sub-seasonal': 'sub-seasonal',
} as const;

export const SAVE_TO_CITY_VIEW_CONFIG = [
  {
    url: '1-15days/city-view/forecast-values/daily',
    fields: ['runDate', 'runDateType', 'secondaryLens'],
  },
  {
    url: '1-15days/city-view/forecast-values/hourly',
    fields: ['runDate', 'runDateType', 'secondaryLens'],
  },
  {
    url: '1-15days/city-view/forecast-difference/daily',
    fields: ['runDate', 'compareDate'],
  },
  {
    url: '1-15days/city-view/forecast-difference/hourly',
    fields: ['runDate', 'compareDate'],
  },
  {
    url: '1-15days/city-view/probabilistic-frisk/frisk-index',
    fields: ['secondaryLens'],
  },
  {
    url: '1-15days/city-view/forecast-analysis',
    fields: ['secondaryLens'],
  },
  {
    url: 'seasonal',
    fields: ['continent'],
  },
  {
    url: 'sub-seasonal',
    fields: ['continent'],
  },
  {
    url: 'window/region-view',
    fields: [
      'continent',
      'runDate',
      'compareDate',
      'secondaryLens',
      'primaryLens',
    ],
  },
];

export const SAVE_TO_RENEWABLES_CONFIG = [
  {
    url: '1-15days/region-view/renewables/maps',
    fields: ['forecastType'],
  },
];

export const SAVE_TO_MODEL_CONFIG = [
  {
    url: 'model',
    fields: ['lastDaySelectedModelRun', 'lastMonthSelectedModelRun'],
  },
];

export const SAVE_TO_SEASONALS_CONFIG = [
  {
    url: 'seasonal',
    fields: ['continent'],
  },
  {
    url: 'sub-seasonal',
    fields: ['continent'],
  },
];
