import { Injectable } from '@angular/core';
import { Observable, combineLatest, of, map, filter } from 'rxjs';

import {
  BaseImageData,
  CachedImageData,
  ExtendedImageData,
  Issuance,
  VerificationImages,
} from '@firebird-web/shared-interfaces';

@Injectable()
export class ImageDownloadService {
  addImage$<T extends BaseImageData>(item: T): Observable<CachedImageData<T>> {
    const blankImageSrc = '/assets/img/icons/Blank_GraphicNotAvail_780x585.png';
    const image = new Image();
    image.setAttribute('loading', 'lazy');
    image.src = item.pathURL || blankImageSrc;
    if (item.thumbPathURL) {
      const thumb = new Image();
      thumb.src = item.thumbPathURL || blankImageSrc;
    }
    return of({ ...item, image }).pipe(
      map((imageData) => {
        return imageData;
      })
    );
  }
  isImageArray<T = ExtendedImageData>(item: unknown): item is T[] {
    return (
      Array.isArray(item) &&
      item.length !== 0 &&
      item.every((arrayItem: object) =>
        Object.prototype.hasOwnProperty.call(arrayItem, 'pathURL')
      )
    );
  }

  addImages$(
    data:
      | Issuance<ExtendedImageData>[]
      | VerificationImages<ExtendedImageData>[]
  ): Observable<Issuance<CachedImageData>[]> {
    if (data === undefined || data.length < 1) {
      const dataWithImages = [of({})];
      return combineLatest(dataWithImages) as Observable<
        Issuance<CachedImageData>[]
      >;
    }
    const dataWithImages = data.map((entries) => {
      const entriesWithItems = Object.entries(entries).map(([key, value]) => {
        if (!this.isImageArray(value)) {
          return of({ [key]: value });
        }

        const subArrayWithImages = value.map(this.addImage$);

        if (!subArrayWithImages.length) {
          return of({});
        }
        return combineLatest(subArrayWithImages).pipe(
          filter((data) => !!data.length),
          map((data) => ({ [key]: data }))
        );
      });

      return combineLatest(entriesWithItems).pipe(
        map((data) => {
          return data.reduce((acc, item) => {
            return { ...acc, ...item };
          }, {});
        })
      );
    });

    return combineLatest(dataWithImages) as Observable<
      Issuance<CachedImageData>[]
    >;
  }
}
