import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as NotificationsStoreActions from './notifications-store.actions';
import * as wsActions from '@firebird-web/ws';
import { NotificationsStoreService } from '../services/notifications-store.service';
import {
  asyncScheduler,
  combineLatestWith,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  throttleTime,
} from 'rxjs';
import { NotificationsFacade } from '@firebird-web/notifications-store';

@Injectable()
export class NotificationsStoreEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly notificationsStoreService: NotificationsStoreService,
    private readonly notificationsStoreFacade: NotificationsFacade
  ) {}

  loadProfileEmails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsStoreActions.loadEmailNotifications),
      switchMap(() => this.notificationsStoreService.getNotificationEmails()),
      map((emails) =>
        NotificationsStoreActions.loadEmailNotificationsSuccess({ emails })
      )
    )
  );

  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsStoreActions.loadNotifications),
      switchMap(() => this.notificationsStoreService.getNotificationList()),
      map((notifications) =>
        NotificationsStoreActions.loadNotificationsSuccess({
          notifications,
        })
      )
    )
  );

  refreshNotificationsState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wsActions.newNotificationsAvailable),
      startWith(null),
      combineLatestWith(
        this.notificationsStoreFacade.alertState$,
        this.notificationsStoreFacade.isAlertWindowOpen()
      ),
      startWith([null, false, false]),
      pairwise(),
      filter(
        ([[, , prevIsAlertWindowOpen], [, alertState, isAlertWindowOpen]]) =>
          !alertState || isAlertWindowOpen || !!prevIsAlertWindowOpen
      ),
      throttleTime(500, asyncScheduler, { leading: false, trailing: true }),
      switchMap(() => {
        return [
          NotificationsStoreActions.loadAlerts(),
          NotificationsStoreActions.loadNotifications(),
          NotificationsStoreActions.loadEmailNotifications(),
        ];
      })
    )
  );

  loadAlerts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsStoreActions.loadAlerts),
      switchMap(() => this.notificationsStoreService.getAlertList()),
      map((alerts) =>
        NotificationsStoreActions.loadAlertsSuccess({
          alerts,
        })
      )
    )
  );
}
