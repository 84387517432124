import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  PanelData,
  ModelFilterConfig,
  ModelRun,
} from '@firebird-web/model-store';

export const modelDataAdapter: EntityAdapter<PanelData> =
  createEntityAdapter<PanelData>({ selectId: ({ model }) => model });

export const multiModelDataAdapter: EntityAdapter<PanelData> =
  createEntityAdapter<PanelData>({ selectId: ({ trackId }) => trackId });

export const modelConfigsAdapter: EntityAdapter<ModelFilterConfig> =
  createEntityAdapter<ModelFilterConfig>({ selectId: ({ id }) => id });

export const modelRunsAdapter: EntityAdapter<ModelRun> =
  createEntityAdapter<ModelRun>({ selectId: ({ id }) => id });
