import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CONTINENTS_FEATURE_KEY,
  ContinentsState,
  continentsAdapter,
} from './continents.reducer';

// Lookup the 'Continents' feature state managed by NgRx
export const getContinentsState = createFeatureSelector<ContinentsState>(
  CONTINENTS_FEATURE_KEY
);

const { selectAll, selectEntities } = continentsAdapter.getSelectors();

export const getContinentsLoaded = createSelector(
  getContinentsState,
  (state: ContinentsState) => state.loaded
);

export const getContinentsError = createSelector(
  getContinentsState,
  (state: ContinentsState) => state.error
);

export const getAllContinents = createSelector(
  getContinentsState,
  (state: ContinentsState) => selectAll(state)
);

export const getContinentsEntities = createSelector(
  getContinentsState,
  (state: ContinentsState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getContinentsState,
  (state: ContinentsState) => state.selectedId
);

export const getSelected = createSelector(
  getContinentsEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
