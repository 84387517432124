<!-- TODO: add hasViewPermission when regions are mapped to poolSets -->
<div [class]="filter">
  <div class="header">
    <span>Select a Location</span>
    <span (click)="closeDialog()"><mat-icon>close</mat-icon></span>
  </div>

  <div class="continent-region-selection">
    <span class="continent-text">Continent</span>
    <span [matMenuTriggerFor]="contMenu" class="continent-trigger"
      >{{ continentText$ | async | titlecase }} <mat-icon>expand_more</mat-icon>
    </span>
  </div>

  <div *ngIf="regionList$ | async as regionLists" class="regions">
    <div class="regions-container" *ngFor="let regionList of regionLists">
      <div
        class="region"
        *ngFor="let region of regionList"
        (click)="onRegionSelected(region)"
      >
        {{ region.text }}
      </div>
    </div>
  </div>

  <mat-menu #contMenu="matMenu">
    <button
      mat-menu-item
      class="continent-selection-btn"
      *ngFor="let continent of continentList"
      (click)="onContinentChange(continent)"
      [attr.aria-label]="continent.text"
    >
      <span
        [hasViewPermission]="[continent.id]"
        [key]="continentPermissionKey"
        [alignRight]="true"
      >
        {{ continent.text }}
      </span>
    </button>
  </mat-menu>
</div>
