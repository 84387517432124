import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'libs/acl/src/lib/auth.service';
import { first } from 'rxjs';

@Component({
  selector: 'firebird-web-auth-callback',
  templateUrl: './auth-callback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(first()).subscribe(() => {
      if (this.authService.hasValidAccessToken()) {
        this.handleSuccessfulAuthentication();
      } else {
        // Handle authentication failure or redirect to login page
        this.router.navigate(['/login']);
      }
    });
  }

  private handleSuccessfulAuthentication(): void {
    const routeUrl = this.authService.getState();
    if (routeUrl) {
      if (routeUrl.includes('blurbPostID') || routeUrl.includes('blogPostID')) {
        // workaround to open blurbs and blogs for the users that were not logged in
        window.open(
          `${window.location.origin}${decodeURIComponent(routeUrl)}`,
          '_self'
        );
      }
      this.router.navigateByUrl(decodeURIComponent(routeUrl));
    } else {
      this.router.navigate(['/']);
    }
  }
}
