<div class="notifications component" *ngIf="isLoaded; else loader">
  <div class="description-email-wrapper">
    <p class="description">
      Choose a notification delivery method for alerts, blogs, model and product
      updates. You can be notified on the website or via email.
    </p>
    <div
      class="emails-wrapper"
      *ngIf="notificationEmails$ | async; let notificationEmailsList"
    >
      <span
        *ngFor="let email of notificationEmailsList; let i = index"
        class="email"
      >
        <firebird-web-notification-email
          [email]="email.email"
          [emailNumber]="i"
          [isAddAllowed]="notificationEmailsList.length === 1"
        >
        </firebird-web-notification-email>
      </span>
    </div>
    <div class="separator"></div>
    <div class="temp"></div>
    <div>
      <form [formGroup]="notificationForm" class="notification-form">
        <div class="column1">
          <div class="checkbox-header">
            <p class="label">Web</p>
            <p class="label">Email</p>
          </div>
          <div *ngIf="alerts.length > 0">
            <div class="row-wrapper">
              <label class="label">Alerts</label>

              <div class="checkbox-select-all-wrapper">
                <div class="select-all-with-checkbox">
                  <span class="label-select-all">Select All</span>
                  <input
                    type="checkbox"
                    value="selectAllApp"
                    [checked]="isAllInAppAlert"
                    (change)="selectAll(alerts, $event)"
                  />
                </div>
                <input
                  type="checkbox"
                  value="selectAllEmail"
                  [checked]="isAllEmailAlert"
                  (change)="selectAll(alerts, $event)"
                />
              </div>
            </div>
            <div class="separator"></div>

            <div class="row-wrapper" *ngFor="let control of alerts">
              <label class="label">{{ control.displayName }}</label>
              <div
                class="checkbox-wrapper"
                [formArrayName]="control.notificationGroup || ''"
              >
                <input
                  *ngFor="
                    let _ of $any(
                      notificationForm.controls[control.notificationGroup || '']
                    ).controls;
                    let i = index
                  "
                  type="checkbox"
                  [value]="control.notificationGroup"
                  [formControlName]="i"
                  (change)="selectCheckboxAlert(i, $event)"
                />
              </div>
            </div>
          </div>
          <div *ngIf="blogs.length > 0">
            <div class="row-wrapper select-all-block">
              <label class="label">AG2 Forecaster Blogs</label>

              <div class="checkbox-select-all-wrapper">
                <div class="select-all-with-checkbox">
                  <span class="label-select-all">Select All</span>
                  <input
                    type="checkbox"
                    value="selectAllApp"
                    [checked]="isAllInAppBlog"
                    (change)="selectAll(blogs, $event)"
                  />
                </div>
                <input
                  type="checkbox"
                  value="selectAllEmail"
                  [checked]="isAllEmailBlog"
                  (change)="selectAll(blogs, $event)"
                />
              </div>
            </div>
            <div class="separator"></div>

            <div class="row-wrapper" *ngFor="let control of blogs">
              <label class="label">{{ control.displayName }}</label>
              <div
                class="checkbox-wrapper"
                [formArrayName]="control.notificationGroup || ''"
              >
                <input
                  *ngFor="
                    let _ of $any(
                      notificationForm.controls[control.notificationGroup || '']
                    ).controls;
                    let i = index
                  "
                  type="checkbox"
                  [value]="control.notificationGroup"
                  [formControlName]="i"
                  (change)="selectCheckboxBlog(i, $event)"
                />
              </div>
            </div>
          </div>
          <div *ngIf="startEndTimes.length > 0">
            <div class="row-wrapper select-all-block">
              <label class="label">Start and End Time</label>

              <div class="checkbox-select-all-wrapper">
                <div class="select-all-with-checkbox">
                  <span class="label-select-all">Select All</span>
                  <input
                    type="checkbox"
                    value="selectAllApp"
                    [checked]="isAllInAppStartEnd"
                    (change)="selectAll(startEndTimes, $event)"
                  />
                </div>
                <input
                  type="checkbox"
                  value="selectAllEmail"
                  [checked]="isAllEmailStartEnd"
                  (change)="selectAll(startEndTimes, $event)"
                />
              </div>
            </div>
            <div class="separator"></div>

            <div class="row-wrapper" *ngFor="let control of startEndTimes">
              <label class="label">{{ control.displayName }}</label>
              <div
                class="checkbox-wrapper"
                [formArrayName]="control.notificationGroup || ''"
              >
                <input
                  *ngFor="
                    let _ of $any(
                      notificationForm.controls[control.notificationGroup || '']
                    ).controls;
                    let i = index
                  "
                  type="checkbox"
                  [value]="control.notificationGroup"
                  [formControlName]="i"
                  (change)="selectCheckboxStartEnd(i, $event)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="column2" *ngIf="notifications.length > 0">
          <div class="checkbox-header">
            <p class="label">Web</p>
            <p class="label">Email</p>
          </div>
          <div class="row-wrapper">
            <label class="label">Product Updated</label>

            <div class="checkbox-select-all-wrapper">
              <div class="select-all-with-checkbox">
                <span class="label-select-all">Select All</span>
                <input
                  type="checkbox"
                  value="selectAllApp"
                  [checked]="isAllInAppNotifications"
                  (change)="selectAll(notifications, $event)"
                />
              </div>
              <input
                type="checkbox"
                value="selectAllEmail"
                [checked]="isAllEmailNotifications"
                (change)="selectAll(notifications, $event)"
              />
            </div>
          </div>
          <div class="separator"></div>

          <div class="row-wrapper" *ngFor="let control of notifications">
            <label class="label">{{ control.displayName }}</label>
            <div
              class="checkbox-wrapper"
              [formArrayName]="control.notificationGroup || ''"
            >
              <input
                *ngFor="
                  let _ of $any(
                    notificationForm.controls[control.notificationGroup || '']
                  ).controls;
                  let i = index
                "
                type="checkbox"
                [value]="control.notificationGroup"
                [formControlName]="i"
                (click)="disableCheckbox($event, control, i)"
                [ngClass]="{ disabled: !control.isClickable && i === 0 }"
                (change)="selectCheckboxNotification(i, $event)"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #loader>
  <div class="loader">
    <firebird-web-loader></firebird-web-loader>
  </div>
</ng-template>
