import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MODEL_STORE_FEATURE_KEY } from '../model-store.reducer';
import {
  modelConfigsAdapter,
  modelDataAdapter,
  modelRunsAdapter,
} from '../model-store.adapters';
import { ModelStoreState, SinglePanelData } from '../model-store.models';

const getModelStoreState = createFeatureSelector<ModelStoreState>(
  MODEL_STORE_FEATURE_KEY
);

const { selectAll: selectAllModels } = modelDataAdapter.getSelectors();
const { selectAll: selectAllConfigs } = modelConfigsAdapter.getSelectors();
const { selectAll: selectAllRuns } = modelRunsAdapter.getSelectors();

const getModelDataState = createSelector(
  getModelStoreState,
  (state: ModelStoreState) => state.singlePanelData
);

export const getModelSinglePanelDataLoaded = createSelector(
  getModelDataState,
  (state: SinglePanelData) => state.loaded
);
export const getSinglePanelError = createSelector(
  getModelDataState,
  (state: SinglePanelData) => state.error
);
export const getModelSinglePanelData = createSelector(
  getModelDataState,
  (state: SinglePanelData) => selectAllModels(state.data)?.[0]?.data
);
export const getSinglePanelConfigs = createSelector(
  getModelDataState,
  (state: SinglePanelData) => selectAllConfigs(state.filterConfigs)
);
export const getSinglePanelSelectedConfig = createSelector(
  getModelDataState,
  (state: SinglePanelData) => state.selectedConfig
);
export const getSinglePanelRuns = createSelector(
  getModelDataState,
  (state: SinglePanelData) => selectAllRuns(state.runs)
);
export const getSinglePanelInUse = createSelector(
  getModelDataState,
  (state: SinglePanelData) => state.inUse
);
