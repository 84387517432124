import { createReducer, on, Action } from '@ngrx/store';

import * as ModelStoreActions from '../model-store.actions';
import {
  modelConfigsAdapter,
  modelRunsAdapter,
  modelDataAdapter,
} from '../model-store.adapters';
import { PeriodMatrixData } from '../model-store.models';

export const initialModelStoreState: PeriodMatrixData = {
  selectedConfigs: null,
  runs: modelRunsAdapter.getInitialState({ loaded: false }),
  configs: modelConfigsAdapter.getInitialState({ loaded: false }),
  main: modelDataAdapter.getInitialState(),
  diff: modelDataAdapter.getInitialState(),
  selectedTimeDiff: '',
  loaded: false,
  inUse: false,
};

const reducer = createReducer(
  initialModelStoreState,
  on(
    ModelStoreActions.changePeriodMatrixModelsTimeDiff,
    (state, { selectedTimeDiff }) => ({
      ...state,
      selectedTimeDiff,
    })
  ),
  on(
    ModelStoreActions.changePeriodMatrixModelsConfigs,
    (state, { selectedConfigs }) => ({
      ...state,
      selectedConfigs,
    })
  ),
  on(ModelStoreActions.loadPeriodMatrixConfigs, (state) => ({
    ...state,
    configs: { ...state.configs, loaded: false },
  })),
  on(ModelStoreActions.loadPeriodMatrixConfigsSuccess, (state, { configs }) => {
    return {
      ...state,
      configs: modelConfigsAdapter.setAll(configs, {
        ...state.configs,
        loaded: true,
        error: null,
      }),
    };
  }),
  on(ModelStoreActions.loadPeriodMatrixRuns, (state) => ({
    ...state,
    periodMatrixData: {
      runs: { ...state.runs, loaded: false },
    },
  })),
  on(ModelStoreActions.loadPeriodMatrixRunsSuccess, (state, { runs }) => {
    return {
      ...state,
      runs: modelRunsAdapter.setAll(runs, {
        ...state.runs,
        loaded: true,
        error: null,
      }),
    };
  }),
  on(ModelStoreActions.loadPeriodMatrixModels, (state, { silentLoading }) => ({
    ...state,
    loaded: silentLoading ? state.loaded : false,
  })),
  on(
    ModelStoreActions.loadPeriodMatrixModelsSuccess,
    (state, { mainData, diffData }) => {
      const mainModels = modelDataAdapter.setMany(mainData, state.main);
      const diffModels = modelDataAdapter.setMany(diffData, state.diff);
      return {
        ...state,
        main: mainModels,
        diff: diffModels,
        loaded: true,
      };
    }
  ),
  on(ModelStoreActions.setPeriodMatrixInUse, (state, { inUse }) => ({
    ...state,
    inUse,
  }))
);

export function periodMatrixReducer(
  state: PeriodMatrixData | undefined,
  action: Action
) {
  return reducer(state, action);
}
