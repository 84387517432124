import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { map, switchMap } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { WeightedRegionsService } from '@firebird-web/shared-services';
import { CommonUtils } from '@firebird-web/shared-utils';
import { WEIGHTED_CONTINENT_PERMISSION_KEY } from '../../../../../weighted/src/lib/constants/weighted.constants';

export const availableFilters = ['iso', 'degree', 'gasDemand'] as const;
export type Filters = typeof availableFilters[number];

export const weightedFilterConfigs: Record<
  Filters,
  { availableContinents: string[] }
> = {
  iso: {
    availableContinents: ['NA', 'EUR'],
  },
  degree: {
    availableContinents: ['NA', 'EUR'],
  },
  gasDemand: {
    availableContinents: ['NA'],
  },
};

@Component({
  selector: 'firebird-web-weighted-location-selection',
  templateUrl: './weighted-location-selection.component.html',
  styleUrls: ['./weighted-location-selection.component.scss'],
})
export class WeightedLocationSelectionComponent implements OnInit {
  continentList!: Continent[];
  continentId$: BehaviorSubject<string> = new BehaviorSubject('');
  regionList$!: Observable<Region[][]>;
  continentText$ = this.continentId$.pipe(
    map(
      (continentId) =>
        this.continentList.find(({ id }) => continentId === id)?.text
    )
  );
  filter: Filters = availableFilters[0];
  public continentPermissionKey = WEIGHTED_CONTINENT_PERMISSION_KEY;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<WeightedLocationSelectionComponent>,
    private readonly weightedRegionsServices: WeightedRegionsService
  ) {}

  ngOnInit(): void {
    this.filter = this.data.filter;

    this.continentList = this.data.continentList;
    const continentId = this.data.continentId;

    this.continentId$.next(continentId);

    this.regionList$ = this.continentId$.pipe(
      switchMap((continentId) => {
        switch (this.filter) {
          case 'iso': {
            return this.weightedRegionsServices.getWeightedIsoRegions(
              continentId
            );
          }
          case 'degree': {
            return this.weightedRegionsServices.getWeightedDegreeDaysRegions(
              continentId
            );
          }
          default: {
            return this.weightedRegionsServices.getWeightedUsGasBcfRegions(
              continentId
            );
          }
        }
      }),
      map((regions) => CommonUtils.toChunksByColumnsQty(regions, 2))
    );
  }
  closeDialog() {
    this.dialogRef.close();
  }
  onContinentChange(continent: Continent) {
    this.continentId$.next(continent.id);
  }

  onRegionSelected(region: Region) {
    this.dialogRef.close({
      regionName: region.text,
      regionId: region.id,
      continentId: this.continentId$.getValue(),
    });
  }
}

interface Continent {
  id: string;
  text: string;
  permission?: string[];
}

type Region = Continent;
