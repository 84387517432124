import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, of, switchMap } from 'rxjs';
import { ContinentsService } from '../../services';
import * as ContinentsActions from './continents.actions';
import { ContinentsEntity } from './continents.models';

@Injectable()
export class ContinentsEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContinentsActions.initContinents),
      switchMap(() => this.continentsService.getContinentsList()),
      switchMap((continents: ContinentsEntity[]) => [
        ContinentsActions.loadContinentsSuccess({ continents }),
      ]),
      catchError((error) =>
        of(ContinentsActions.loadContinentsFailure({ error }))
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly continentsService: ContinentsService
  ) {}
}
