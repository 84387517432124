import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@firebird-web/shared-config';
import { map, Observable } from 'rxjs';
import { LocationPickerState } from '../models/location-picker.model';

@Injectable({
  providedIn: 'root',
})
export class LocationPickerApiService {
  constructor(private readonly http: HttpClient) {}

  public savePickerData(
    settings: Partial<LocationPickerState>
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.apiDomain}/api/v1/LocationPickerSettings/location-picker-settings/save`,
      { settings }
    );
  }

  public getPickerData(): Observable<LocationPickerState> {
    return this.http
      .get<{ settings: LocationPickerState }>(
        `${environment.apiDomain}/api/v1/LocationPickerSettings/location-picker-settings`
      )
      .pipe(map(({ settings }) => settings));
  }
}
