import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateWithoutTimezone',
})
export class DateWithoutTimezonePipe implements PipeTransform {
  transform(value: string | number | undefined, dateFormat: string): any {
    if (!value) {
      return value;
    }
    const date = new Date(value);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const newDate = new Date(date.getTime() + userTimezoneOffset);
    return new DatePipe('en-US').transform(newDate, dateFormat) || '';
  }
}
