<div class="container">
  <div class="links">
    <div>
      <img src="/assets/images/mobile-logo.png" />
    </div>
    <div class="store-link">
      <div>
        <a (click)="openStoreWindow($event, storeLinksType.Apple)">
          <img src="/assets/images/apple-store.png" />
        </a>
      </div>
      <div>
        <a (click)="openStoreWindow($event, storeLinksType.Google)">
          <img src="/assets/images/google-store.png" />
        </a>
      </div>
    </div>
  </div>
  <div class="text">
    <p>
      AG2 Trader is the premier weather decision-support platform for the global
      energy, commodity markets. Providing support for the North American,
      European, and Asian markets, a comprehensive global outlook is delivered
      in a single, easy-to-use platform. With a focus on forecast skill and
      delivering information first-to-market, AG2 Trader is designed to maximize
      your trading opportunities. AG2 Trader provides you all the tools to
      quickly and confidently assess weather’s impact on the market. Now when
      sitting at home, riding the train to work or simply away from your trading
      desk, you can easily and quickly access the key AG2 Trader content via the
      Trader mobile apps.
    </p>
    <p class="offer-title">AG2 Trader offers:</p>
    <ul>
      <li>
        Customizable forecast table displaying the latest AG2 15 Day daily
        forecast for individual cities and regions
      </li>
      <li>15 Day hourly forecasts for individual cities and regions</li>
      <li>AG2 1-5, 6-10, & 11-15 Day Forecasts</li>
      <li>AG2 Seasonal Forecast out to 3 months</li>
      <li>ECMWF Seasonal Forecast out to 7 months</li>
      <li>
        Access to global model data from the GFS, ECMWF & Canadian as well as
        the proprietary GRAF model
      </li>
      <li>Discussions from AG2 Energy Forecast Team</li>
    </ul>
  </div>
</div>
