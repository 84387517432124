import { ActionReducerMap } from '@ngrx/store';

import { periodMatrixReducer } from './reducers/period-matrix.reducer';
import { modelComparisonReducer } from './reducers/model-comparison.reducer';
import { ModelStoreState } from './model-store.models';
import { multiPanelReducer } from './reducers/multi-panel.reducer';
import { singlePanelReducer } from './reducers/single-panel.reducer';

export const MODEL_STORE_FEATURE_KEY = 'modelStore';

export interface ModelStorePartialState {
  readonly [MODEL_STORE_FEATURE_KEY]: ModelStoreState;
}

export const reducers: ActionReducerMap<ModelStoreState> = {
  periodMatrixData: periodMatrixReducer,
  comparisonData: modelComparisonReducer,
  multiPanelData: multiPanelReducer,
  singlePanelData: singlePanelReducer,
};
